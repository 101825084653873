import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogs from "../../../redux/constants/dialogConstants";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "../../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";

import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import LoadingScreen from "../../Spinner/LoadingScreen";
import AlphabetSelect from "../../../helpers/AlphabetSelect";
import { filterFirstLetter } from "../../../utils/dataUtils/masterDataUtils";

const useToolbarStyles = makeStyles((theme) => ({
  tableToolBar: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 130,
    minHeight: 45,
    fontSize: "125%",
  },
  switch: {
    marginTop: 12,
    marginBottom: 12,
  },
}));

const VertragsnehmerView = (props) => {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  const [toolbarButtons, setToolbarButtons] = React.useState("ONE");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [firstLetter, setFirstLetter] = React.useState(null);
  const { vertragsnehmerList } = useSelector(
    (state) => state.masterDataReducer
  );
  var defaultColumns = [];
  let tableLayout = {};
  const [state, setState] = React.useState({
    checkedA: false,
  });

  useEffect(() => {
    console.log("component MitgliederView LOADED!");
    setLoading(false);
  }, [setLoading]);

  const onSelectionChange = (rows) => {
    setSelectedRows(rows);
    if (rows.length > 1) {
      setToolbarButtons("MANY");
    } else {
      setToolbarButtons("ONE");
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  let filteredList = vertragsnehmerList;
  if (firstLetter) {
    filteredList = filterFirstLetter(filteredList, firstLetter);
  }

  const handleOnClickDeleteMany = () => {
    let alertData = {
      title: "Keinen Vertragsnehmer ausgewählt.",
      text: "Mindestens einen Vertragsnehmer auswählen.",
    };
    if (!selectedRows || selectedRows.length === 0) {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertData,
          "ALERT"
        )
      );
    } else {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.REMOVE_VERTRAGSNEHMER,
          selectedRows,
          "REMOVE"
        )
      );
      setSelectedRows([]);
    }
  };

  const handleOnClickAddOneMember = (event, data) => {
    let alertData = {
      title: "Keinen Vertragsnehmer ausgewählt.",
      text: "Zuerst einen Vertragsnehmer auswählen.",
    };
    if (state.checkedA && selectedRows.length !== 1) {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertData,
          "ALERT"
        )
      );
    } else {
      // TODO: reset data or pass no data
      console.log("data :>> ", data);
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.NEW_MITGLIED,
          { selectedContract: data, skipSelectContract: true },
          "ADD_MEMBER_TO_CONTRACT"
        )
      );
      setSelectedRows([]);
    }
  };

  const handleOnClickEditOne = (event, data) => {
    let alertData = {
      title: "Keinen Vertragsnehmer ausgewählt.",
      text: "Zuerst einen Vertragsnehmer auswählen.",
    };
    console.log("data :>> ", data);
    if (state.checkedA && selectedRows.length !== 1) {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertData,
          "ALERT"
        )
      );
    } else {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.EDIT_MITGLIED,
          { formData: data },
          "EDIT_CONTRACT"
        )
      );
      setSelectedRows([]);
    }
  };

  if (state.checkedA) {
    tableLayout.options = true;
    tableLayout.actions = [
      {
        tooltip: "Entferne alle ausgewählten Vertragsnehmer",
        icon: "delete",
        onClick: (evt, data) => handleOnClickDeleteMany(evt, data),
      },
    ];
  } else {
    vertragsnehmerList &&
      vertragsnehmerList.map(function (x) {
        if (x.tableData) {
          x.tableData.checked = false;
        }
        return x;
      });
    if (toolbarButtons === "MANY") {
      setToolbarButtons("ONE");
    }
    tableLayout.options = false;
    tableLayout.actions = [
      {
        icon: "edit",
        tooltip: "Bearbeiten",
        onClick: (e, data) => handleOnClickEditOne(e, data),
      },
      {
        text: "Mitglied hinzufügen",
        icon: PersonAdd,
        tooltip: "Mitglied hinzufügen",
        onClick: (event, rowData) => handleOnClickAddOneMember(event, rowData),
      },
    ];
  }

  if (Array.isArray(vertragsnehmerList) && vertragsnehmerList.length !== 0) {
    defaultColumns = [
      {
        title: "Standort",
        field: Object.keys(vertragsnehmerList[0]).find(
          (e) => e === "standortId"
        ),
        lookup: {
          1: "Augsburg",
          2: "München",
          3: "Königsbrunn",
          4: "Pfersee",
        },
      },
      {
        title: "Anrede",
        field: Object.keys(vertragsnehmerList[0]).find((e) => e === "title"),
      },
      {
        title: "Nachname",
        field: Object.keys(vertragsnehmerList[0]).find((e) => e === "lastName"),
        // defaultSort: "asc",
      },
      {
        title: "Vorname",
        field: Object.keys(vertragsnehmerList[0]).find(
          (e) => e === "firstName"
        ),
      },
      {
        title: "E-Mail",
        field: Object.keys(vertragsnehmerList[0]).find((e) => e === "email"),
      },
      {
        title: "Telefon",
        field: Object.keys(vertragsnehmerList[0]).find(
          (e) => e === "phoneNumber"
        ),
      },
      {
        title: "Mobil",
        field: Object.keys(vertragsnehmerList[0]).find((e) => e === "mobile"),
      },
      {
        title: "Wohnort",
        field: Object.keys(vertragsnehmerList[0]).find((e) => e === "city"),
      },
      // { title: "Hinzugefügt am", field: Object.keys(vertragsnehmerList[0]).find(e => e === "createdAt") },
    ];
  }

  const addMember = () => {
    if (selectedRows.length === 1) {
    }
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogs.NEW_MITGLIED,
        undefined,
        "ADD"
      )
    );
    // dispatch(masterDataActions.openMasterDataDialog(dialogs.EDIT_VERTRAGSNEHMER))
  };

  if (loading) {
    return <LoadingScreen />;
  } else
    return (
      <div style={{ width: "100%" }}>
        <FormGroup row className={classes.tableToolBar}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                color="primary"
              />
            }
            label={state.checkedA ? "" : "Auswählen für mehr Funktionen"}
          />
          {state.checkedA && (
            <FormGroup
              row
              style={{
                justifyContent: "flex-end",
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Tooltip title="Mitglied hinzufügen">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#ffcc36",
                    margin: 8,
                    minWidth: 260,
                    minHeight: 45,
                    fontSize: "120%",
                  }}
                  // style={{backgroundColor:"#ffcc36", minWidth: 200}}
                  startIcon={<PersonAdd style={{ fontSize: 40 }} />}
                  onClick={addMember}
                >
                  {selectedRows.length === 1
                    ? "Mitglied zu Vertrag hinzufügen"
                    : "Neues Mitglied hinzufügen"}
                </Button>
              </Tooltip>
              <Tooltip title="Vertrag löschen">
                <Button
                  variant="contained"
                  // color="secondary"
                  className={classes.button}
                  disabled={selectedRows.length === 0}
                  style={{
                    backgroundColor:
                      selectedRows.length === 0 ? "grey" : "#db0000",
                    color: "white",
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={handleOnClickDeleteMany}
                >
                  Löschen
                </Button>
              </Tooltip>
            </FormGroup>
          )}
        </FormGroup>
        <AlphabetSelect setFirstLetter={setFirstLetter}></AlphabetSelect>
        <Table
          title={"Vertragsnehmer"}
          data={filteredList}
          defaultColumns={defaultColumns}
          tableLayout={tableLayout}
          onSelectionChange={onSelectionChange}
        />
      </div>
    );
};

export default VertragsnehmerView;
