import { Button, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux';
import masterDataActions from '../../../redux/actions/masterDataActions';
import dialogConstants from '../../../redux/constants/dialogConstants';

export default function VertragsnehmerMenu() {
  const dispatch = useDispatch();
  // const [data, setData] = React.useState([]);
  const [showCsvImport, setVisibleCsvImport] = React.useState(true);
  const toggleCsvImport = () => {
    setVisibleCsvImport(!showCsvImport);
    dispatch(masterDataActions.openMasterDataDialog(dialogConstants.IMPORT_VERTRAGSNEHMER, undefined, 'SELECT_STANDORT'))
  }

  return (
    <div>
      <Grid item xs={12} >
        <Paper style={{ padding: 10, margin: 20, textAlign: "center" }} elevation={3}>
          <br></br>
          <Button
            variant='contained'
            // color='primary'
            style={{
              width: '50%',
            }}
            onClick={toggleCsvImport}
          >
            Daten importieren
              </Button>
        </Paper>
      </Grid>
    </div>
  )
}
