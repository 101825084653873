import React from 'react'
import { TextField } from '@material-ui/core';

export default function Input(props) {

    const { name, label, value, error = null, onChange, disabled, placeholder, required, helperText } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            disabled={disabled}
            value={value}
            onChange={onChange}
            required={required ? required : false}
            helperText={helperText}
            placeholder={placeholder ? placeholder : null}
            {...(error && { error: true, helperText: error })}
        />
    )
}
