export const GET_VERTRAGSNEHMERS_LIST = "GET_VERTRAGSNEHMERS_LIST";
export const SET_VERTRAGSNEHMERS_LIST = "SET_VERTRAGSNEHMERS_LIST";
export const ADD_VERTRAGSNEHMER = "ADD_VERTRAGSNEHMER";
export const EDIT_VERTRAGSNEHMER = "EDIT_VERTRAGSNEHMER";
export const REMOVE_VERTRAGSNEHMER = "REMOVE_VERTRAGSNEHMER";
export const REMOVE_MANY_VERTRAGSNEHMER = "REMOVE_MANY_VERTRAGSNEHMER";

export const GET_STANDORTE_LIST = "GET_STANDORTE_LIST";
export const SET_STANDORTE_LIST = "SET_STANDORTE_LIST";
export const ADD_STANDORT = "ADD_STANDORT";
export const EDIT_STANDORT = "EDIT_STANDORT";
export const REMOVE_STANDORT = "REMOVE_STANDORT";
export const REMOVE_MANY_STANDORTE = "REMOVE_MANY_STANDORTE";

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const SET_EVENTS_LIST = "SET_EVENTS_LIST";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const REMOVE_MANY_EVENTS = "REMOVE_MANY_EVENTS";

export const GET_MITGLIEDER_LIST = "GET_MITGLIEDER_LIST";
export const SET_MITGLIEDER_LIST = "SET_MITGLIEDER_LIST";
export const ADD_MITGLIED = "ADD_MITGLIED";
export const EDIT_MITGLIED = "EDIT_MITGLIED";
export const REMOVE_MITGLIED = "REMOVE_MITGLIED";
export const REMOVE_MANY_MITGLIEDER = "REMOVE_MANY_MITGLIEDER";

export const GET_STANDORTLEITER_LIST = "GET_STANDORTLEITER_LIST";
export const SET_STANDORTLEITER_LIST = "SET_STANDORTLEITER_LIST";
export const ADD_STANDORTLEITER = "ADD_STANDORTLEITER";
export const EDIT_STANDORTLEITER = "EDIT_STANDORTLEITER";
export const REMOVE_STANDORTLEITER = "REMOVE_STANDORTLEITER";
export const REMOVE_MANY_STANDORTLEITER = "REMOVE_MANY_STANDORTLEITER";

export const OPEN_MASTER_DATA_DIALOG = "OPEN_MASTER_DATA_DIALOG";
export const CLOSE_MASTER_DATA_DIALOG = "CLOSE_MASTER_DATA_DIALOG";

export const SET_MASTER_DATA_ERROR = "SET_MASTER_DATA_ERROR";
export const SET_MASTER_DATA_RESPONSE = "SET_MASTER_DATA_RESPONSE";

export const CLEAR_STATE = "CLEAR_STATE";
