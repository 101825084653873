import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import StandortItemCard from "./StandortItem";
import { useSelector } from "react-redux";
import LoadingScreen from "../../Spinner/LoadingScreen";
import AddStandortCard from "./AddStandortCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 250,
  },
}));

export default function StandorteGrid({ standortType }) {
  const classes = useStyles();
  const { standorteList } = useSelector((state) => state.masterDataReducer);

  console.log("standorteList :>> ", standorteList);
  console.log(
    "Array.isArray(standorteList) :>> ",
    Array.isArray(standorteList)
  );

  return (
    <React.Fragment>
      {!Array.isArray(standorteList) && <LoadingScreen></LoadingScreen>}
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={8}>
            {standorteList &&
              standorteList.map((site, i) => {
                if (standortType === "OWN") {
                  if (site.standortType === "AFFILIATED") {
                    return null;
                  } else
                    return (
                      <Grid key={i} item>
                        {/* <Paper className={classes.paper} > */}
                        <StandortItemCard site={site}></StandortItemCard>
                        {/* </Paper> */}
                      </Grid>
                    );
                } else {
                  if (site.standortType !== "AFFILIATED") {
                    return null;
                  }
                  return (
                    <Grid key={i} item>
                      {/* <Paper className={classes.paper} > */}
                      <StandortItemCard site={site}></StandortItemCard>
                      {/* </Paper> */}
                    </Grid>
                  );
                }
              })}
            <Grid item>
              <AddStandortCard standortType={standortType} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
