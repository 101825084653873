import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';


const ImageUpload = (props) => {
    const { onChange } = props;
    // const [file, setFile] = React.useState(null)
    // const fileHandler = (e) => {
    //     setFile(e.target.files[0])
    // }

    return (
        <div>
            <Tooltip title="Bild hochladen">
                <Button
                    variant="contained"
                    component="label"
                    style={{
                        backgroundColor: "#ffcc36",
                        margin: 8,
                        minWidth: 260,
                        minHeight: 45,
                        fontSize: '120%',
                    }}
                    // style={{backgroundColor:"#ffcc36", minWidth: 200}}
                    startIcon={<AddPhotoAlternate />}
                // onClick={fileHandler}
                >
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={onChange} />
              Bild hochladen
        </Button>
            </Tooltip>
        </div>
    )
}

export default ImageUpload