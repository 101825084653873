import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";

export default function Success({ response }) {
  const dispatch = useDispatch();

  const handleExit = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  return (
    <div>
      <Grid
        style={{ minHeight: "350px" }}
        container
        justify="space-evenly"
        direction="column"
      >
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Importieren erfolgreich!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Es wurden <b>{response && response.members.length}</b> Mitglieder
            und <b>{response && response.contracts.length}</b> Vertragsdaten
            importiert.
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "auto", marginRight: "auto" }} item xs={12}>
          <Button
            style={{
              fontSize: "150%",
              padding: "10px 50px",
            }}
            variant="contained"
            color="primary"
            onClick={handleExit}
          >
            Beenden
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
