import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import BankingAccountsTable from "../settings/Banking/BankingAccountsTable";
import BankingStandort from "./BankingStandort";
import NewSepaForm from "./NewSepaForm";
import { useHistory } from "react-router";
import BankingStatistics from "./BankingStatistics";
import { playTutorialInNewTab } from "../../../utils/dataUtils/appUtils";
import { Help } from "@material-ui/icons";

export default function BankingMenu() {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedStandorte, setSelectedStandorte] = useState([]);
  const [menuState, setMenuState] = useState(0);
  const { currentUser } = useSelector((state) => state.appReducer);
  const history = useHistory();

  const goToSettings = () => {
    history.push("/dashboard/settings");
  };

  const handleSelectAccount = (account, index) => {
    setSelectedAccount(account);
    setMenuState(1);
  };

  const selectHauptKonto = () => {
    let hauptKonto = {
      ...currentUser.bankingSettings,
    };
    delete hauptKonto.accounts;
    setSelectedAccount(hauptKonto);
    setMenuState(1);
  };

  const showStatistics = () => {
    setMenuState(3);
  };

  const handleSelectStandorteFinished = (standorte) => {
    setSelectedStandorte(standorte);
    setMenuState(2);
  };

  //   const handleChangeMenu = (menu) => {
  //     setMenuState(menu);
  //   };

  const resetMenu = () => {
    setMenuState(0);
    setSelectedAccount(null);
    setSelectedStandorte([]);
  };

  switch (menuState) {
    case 0:
      return (
        <div>
          <Button
            style={{ margin: 25 }}
            variant="contained"
            color="primary"
            startIcon={<Help />}
            onClick={() => playTutorialInNewTab("https://youtu.be/E0KzecJmmvA")}
          >
            Erklärvideo Einzug
          </Button>
          <div style={{ margin: 50, marginTop: 0 }}>
            <Typography variant="h5">
              Bitte zuerst ein Konto auswählen das für den Einzug verwendet
              werden soll
            </Typography>
            <Button
              style={{ fontSize: "150%", margin: 25, padding: 25 }}
              variant="contained"
              color="secondary"
              onClick={selectHauptKonto}
            >
              Hauptkonto verwenden
            </Button>
            <BankingAccountsTable
              bankingAccounts={currentUser.bankingSettings.accounts}
              handleSelectedAccount={handleSelectAccount}
            />
            {!currentUser.bankingSettings.accounts && (
              <div>
                <Button
                  style={{
                    fontSize: "150%",
                    margin: 25,
                    padding: 25,
                    marginTop: 0,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={goToSettings}
                >
                  Zu den Einstellungen
                </Button>
              </div>
            )}
            <div style={{ marginTop: 50 }}>
              <Typography variant="h5">Statistik</Typography>
              <Button
                style={{ fontSize: "150%", margin: 25, padding: 25 }}
                variant="contained"
                color="primary"
                // disabled={
                //   !(
                //     !process.env.NODE_ENV ||
                //     process.env.NODE_ENV === "development"
                //   )
                // }
                onClick={showStatistics}
              >
                Statistik anzeigen
              </Button>
            </div>
            <ReturnButton resetMenu={resetMenu} />
          </div>
        </div>
      );

    case 1:
      return (
        <div>
          <Typography variant="h5">
            Wählen Sie als nächstes einen oder mehrere Standorte aus von denen
            der Mitgliedsbeitrag eingezogen werden soll.
          </Typography>
          <BankingStandort
            handleSelectStandorteFinished={handleSelectStandorteFinished}
          />
          <ReturnButton resetMenu={resetMenu} />
        </div>
      );

    case 2:
      return (
        <div>
          <ReturnButton resetMenu={resetMenu} />
          <NewSepaForm
            selectedAccount={selectedAccount}
            selectedStandorte={selectedStandorte}
          />
        </div>
      );

    case 3:
      return (
        <div>
          <ReturnButton resetMenu={resetMenu} />
          <BankingStatistics />
        </div>
      );

    default:
      return (
        <div>
          <Typography variant="h5">
            Es gab ein Problem. Bitte laden Sie die Seite neu.
          </Typography>
        </div>
      );
  }
}

function ReturnButton({ resetMenu }) {
  const handleReturn = () => {
    console.log("return");
    resetMenu();
  };
  return (
    <div style={{ margin: 10 }}>
      <Button variant="contained" color="default" onClick={handleReturn}>
        Zurück
      </Button>
    </div>
  );
}
