import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SIGN_UP,
  LOG_IN,
  LOG_OUT,
  IS_LOGGED_IN,
  IS_LOGGED_OUT,
  SET_APP_ERROR,
  LOADING,
  SET_USER,
  GET_USER,
  UPDATE_USER,
} from "../constants/appConstants";

const openDialog = (dialog) => {
  return {
    type: OPEN_DIALOG,
    dialog,
  };
};

const setLoading = (load) => {
  return {
    type: LOADING,
    load,
  };
};

const closeDialog = () => {
  return {
    type: CLOSE_DIALOG,
  };
};

const signUpUser = (userData) => {
  return {
    type: SIGN_UP,
    userData,
  };
};

const logInUser = (userData) => {
  return {
    type: LOG_IN,
    userData,
  };
};

const setUser = (currentUser) => {
  return {
    type: SET_USER,
    currentUser,
  };
};

const getUser = (currentUser) => {
  return {
    type: GET_USER,
  };
};

const updateUser = (userData) => {
  return {
    type: UPDATE_USER,
    userData,
  };
};

const userLoggedIn = (user) => {
  return {
    type: IS_LOGGED_IN,
    user,
  };
};

const logOutUser = () => {
  return {
    type: LOG_OUT,
  };
};

const userLoggedOut = () => {
  return {
    type: IS_LOGGED_OUT,
  };
};

const setAppError = (errorText) => {
  return {
    type: SET_APP_ERROR,
    errorText,
  };
};
// eslint-disable-next-line
export default {
  closeDialog,
  openDialog,
  signUpUser,
  logInUser,
  logOutUser,
  setUser,
  getUser,
  updateUser,
  userLoggedIn,
  userLoggedOut,
  setAppError,
  setLoading,
};
