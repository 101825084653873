import React from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../../../redux/actions/appActions";

export default function BankingSettings(props) {
  const { currentUser } = useSelector((state) => state.appReducer);

  let defVal;
  defVal = currentUser && currentUser.bankingSettings;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...defVal },
  });

  const onSubmit = (data) => {
    let updatedUser = {
      ...currentUser,
      bankingSettings: {
        ...data,
      },
    };
    console.log("updatedUser :>> ", updatedUser);
    dispatch(appActions.updateUser(updatedUser));
  };

  return (
    <div>
      <Typography variant="h5">
        Hier können Sie die Einstellungen für Ihren SEPA-Export ändern.
      </Typography>
      <br></br>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="creditorName"
              label="Ihr Name"
              name="creditorName"
              autoComplete="creditorName"
              autoFocus
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register({ minLength: 21 })}
              required
              id="creditorIBAN"
              label="Ihre IBAN"
              name="creditorIBAN"
              autoComplete="creditorIBAN"
              error={errors.creditorIBAN}
              helperText={errors.creditorIBAN && "Bitte die IBAN kontrollieren"}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="creditorBIC"
              label="Ihre BIC"
              name="creditorBIC"
              autoComplete="creditorBIC"
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="collectionDate"
              label="Fälligkeitsdatum"
              name="collectionDate"
              autoComplete="collectionDate"
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="creditorId"
              label="Gläubiger ID"
              name="creditorId"
              autoComplete="creditorId"
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              value="Bestätigen"
            >
              Einstellungen speichern
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
