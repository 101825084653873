import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export default function SettingsList(props) {
  const { childrenList } = props;
  let defaultState = {};
  childrenList.forEach((child, i) => {
    defaultState = {
      ...defaultState,
      [i]: false,
    };
  });
  const classes = useStyles();
  const [state, setState] = React.useState(defaultState);

  const handleChange = (event, i) => {
    event.preventDefault();
    setState({ ...state, [i]: !state[i] });
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
      className={classes.root}
    >
      {childrenList.map((child, i) => {
        return (
          <React.Fragment>
            <ListItem button onClick={(e) => handleChange(e, i)}>
              <ListItemIcon>{child.icon}</ListItemIcon>
              <ListItemText primary={child.text} />
              {state[i] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={state[i]} timeout="auto" unmountOnExit>
              <div className={classes.nested}>{child.item}</div>
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );
}
