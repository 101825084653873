import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom";
import { dashboardBottomRoutes } from '../../pages/dashboard/dashboardRoutes';


export default function SimpleBottomNavigation(props) {
    const styling = {
        flexDirection: props.toggle ? null : 'column',
    }
    
  return (
      <div className="navbar-bottom-container">
        <div className="navbar-bottom">
            <div className="navbar-bottom-child" style={styling}>
            {
                dashboardBottomRoutes.map( (item, i) => { return (
                    <NavLink key={i} to={`/dashboard/${item.path}`} style={{width: props.toggle ? '33%' : '100%'}} >
                        <FontAwesomeIcon icon={item.icon} style={{fontSize: "24px"}} />
                    </NavLink>
                )
                } )
            }
            </div>
        </div>
    </div>
  )
}