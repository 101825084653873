import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import { Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faToriiGate } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 40,
    paddingBottom: 100,
  },
}));

export default function AddStandortCard(props) {
  const classes = useStyles();
  const { standortType } = props;
  const dispatch = useDispatch();

  const addNewStandort = () => {
    let dialogType = standortType === "OWN" ? "ADD" : "ADD_AFFILIATED";
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_STANDORT,
        undefined,
        dialogType
      )
    );
  };

  return (
    <div className={classes.root}>
      <Button
        style={{ padding: 0 }}
        variant="contained"
        color="secondary"
        onClick={addNewStandort}
      >
        <div>
          <Typography variant="h5" align="left" style={{ padding: 16 }}>
            Neuen {standortType === "OWN" ? "eigenen" : "angehörigen"} Standort
            anlegen
          </Typography>
          <div className={classes.iconContainer}>
            <FontAwesomeIcon
              style={{ marginTop: 45 }}
              size="3x"
              icon={faPlus}
            />
            <FontAwesomeIcon
              style={{ paddingLeft: 5 }}
              size="9x"
              icon={faToriiGate}
            />
          </div>
        </div>
      </Button>
    </div>
  );
}
