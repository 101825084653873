import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import MemberTypeCard from "./MemberTypeCard";
import { memberTypeList } from "./memberTypes";

export default function SelectMemberType() {
  const dispatch = useDispatch();
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  const handleSelectMemberType = (memberType) => {
    let newDialogData = {
      ...dialogData,
      memberType: memberType,
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_MITGLIED,
        newDialogData,
        "MEMBERTYPE_SELECTED"
      )
    );
    console.log("newDialogData :>> ", newDialogData);
  };

  return (
    <div style={{ padding: "50px" }}>
      <Grid container direction="row" justify="space-between" spacing={3}>
        {memberTypeList &&
          memberTypeList.map((memberType, i) => {
            return (
              <Grid
                item
                style={{ display: "flex" }}
                justify="center"
                key={i}
                lg={4}
                xs={12}
              >
                <MemberTypeCard
                  memberType={memberType}
                  // onClick={() => handleSelectMemberType(memberType.type)}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ width: "100%", minHeight: "60px", fontSize: 20 }}
                    onClick={() => handleSelectMemberType(memberType.type)}
                  >
                    Auswählen
                  </Button>
                </MemberTypeCard>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
