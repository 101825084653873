import {
  Button,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";

const linkStyle = {
  fontSize: "1.0rem",
  fontWeight: "bold",
};

export default function ImportVertragsnehmer() {
  const { dialogData } = useSelector((state) => state.masterDataReducer);
  const [importOption, setValue] = React.useState(null);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const submitOption = () => {
    console.log(`Next Step: ${importOption}`);
    let newDialogData;
    newDialogData = {
      ...dialogData,
      selectedOption: importOption,
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.IMPORT_VERTRAGSNEHMER,
        newDialogData,
        "IMPORT_DATA"
      )
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <div style={{ padding: "50px" }}>
            <Typography variant="h5">
              Wie sollen Ihre Daten importiert werden?
            </Typography>
            <Typography variant="subtitle1">
              Die Daten müssen im{" "}
              <Link
                style={linkStyle}
                href="https://de.wikipedia.org/wiki/CSV_(Dateiformat)"
              >
                CSV-Format
              </Link>{" "}
              vorliegen. Jede Zeile enthält dabei die Daten eines Mitglieds, des
              Vertrags, oder beides. In der Datei muss mindestens Vorname und
              Nachname des Mitglieds hinterlegt sein. Optionale Vertragsdaten
              können mit angegeben werden. Sie werden später den jeweiligen
              Feldern zugeordnet. Sind für Mitgliedsbeitrag, Mitgliedsnummer und
              andere Daten die für die Programmfunktion relevant sind, keine
              Felder vorhanden, werden diese je nach Standort automatisch
              erzeugt. Unter "Beispieldatei herunterladen" finden Sie eine
              Beispiel-Datei, die Ihnen zeigt, in welchem Format Ihre Datei für
              den Import sein sollte. In der Datei sind ebenfalls alle
              verfügbaren Felder hinterlegt, für die Daten importiert werden
              können. Fehlt einer oder mehrere relevante Felder? Geben Sie uns
              bitte umgehend Bescheid. Sollten Umlaute später nicht richtig
              dargestellt werden, so war Ihre Datei nicht in{" "}
              <Link
                style={linkStyle}
                href="https://de.wikipedia.org/wiki/UTF-8"
              >
                UTF-8{" "}
              </Link>{" "}
              kodiert. Wenn Sie nicht wissen, wie Sie die Kodierung ändern,
              kontaktieren Sie einfach den Support und Ihnen wird geholfen.
            </Typography>
            <Link
              style={linkStyle}
              href="https://firebasestorage.googleapis.com/v0/b/todo-app-tut-c2172.appspot.com/o/beispiel.csv?alt=media&token=952238b1-8ad1-4505-bad5-ddad2c9d6e16"
            >
              Beispieldatei herunterladen
            </Link>
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Alert style={{ marginTop: 60 }} variant="filled" severity="info">
            Es wird empfohlen Personen wie Kinder, bei denen ein
            Erziehungsberechtigter der Vertragsnehmer ist, seperat zu
            importieren. Dadurch kann dem Vertrag eine Person zugewiesen werden,
            die diesen stellvertretend für das Kind unterschrieben hat. Dies ist
            später für die Erstellung von Dateien zum Import für das
            Online-Banking relevant, da dort der Kontoinhaber angegeben werden
            muss. Falls Sie diese Funktion nicht nutzen werden, können alle
            Mitglieder (Kinder und Erwachsene) gesammelt unter Option 1
            importiert werden.
          </Alert>
          <FormControl component="fieldset" style={{ paddingTop: 20 }}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={importOption}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Option 1: Mitglieder und Vertragsdaten importieren. (Eine Datei)"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Option 2: Kinder und Vertragsdaten importieren. (Eine Datei)"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Option 3: Mitglieder und Vertragsdaten seperat importieren. (Zwei Dateien)"
              />
              {false && (
                <FormHelperText>
                  {"Diese Funktion ist noch im Aufbau."}
                </FormHelperText>
              )}
              {importOption === "3" && (
                <Alert variant="filled" severity="warning">
                  Wenn Sie Mitglieder und Vertragsdaten seperat importieren,
                  müssen in beiden Dateien Felder existieren, anhand derer ein
                  Mitglied einem Vertrag eindeutig zugeordnet werden kann!
                  (Beziehungsfeld)
                  <br></br>
                  Beispiel:
                  <ol>
                    <li>
                      Beziehungsfeld in Vertragsdaten:
                      <ul>
                        <li>
                          (Mitglied){" "}
                          <b style={{ color: "black" }}> Mitglieds-ID: 55 </b>;
                          Vorname: Max; Nachname: Mustermann;
                        </li>
                        <li>
                          (Vertrag / Erziehungsberechtigter) Vorname: Fritz;
                          Nachname: Mustermann;{" "}
                          <b style={{ color: "black" }}>
                            {" "}
                            gehörtZuMitglied:55{" "}
                          </b>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Beziehungsfeld in Mitgliedsdaten:
                      <ul>
                        <li>
                          (Mitglied) ID: 01; Vorname: Max; Nachname: Mustermann;
                          <b style={{ color: "black" }}>
                            {" "}
                            gehörtZuVertrag: 99{" "}
                          </b>
                        </li>
                        <li>
                          (Vertrag / Erziehungsberechtigter){" "}
                          <b style={{ color: "black" }}> Vertrags-ID:99 </b>,
                          Vorname: Fritz; Nachname: Mustermann;
                        </li>
                      </ul>
                    </li>
                  </ol>
                  Über die Felder "gehörtZuMitglied" bzw. "gehörtZuVertrag",
                  kann eindeutig die Verbindung der Datensätze zugeordnet
                  werden.
                  <br></br>
                  Stellen Sie bitte sicher das jedes Mitglied auf diese Weise
                  einem Vertrag zugeordnet werden kann. Für Mitglieder denen
                  kein Vertrag zugeordnet werden kann, wird ein leerer Datensatz
                  ohne Vertragsdaten erstellt.
                </Alert>
              )}
              {/* <FormControlLabel
                value="3"
                disabled
                control={<Radio />}
                label="Nur Vertragsnehmer importieren. (Es werden keine Mitglieder erstellt)"
              />
              <FormHelperText>{"TEST"}</FormHelperText> */}
            </RadioGroup>
          </FormControl>
          <Button
            style={{ margin: "50px" }}
            variant="contained"
            disabled={importOption === null}
            onClick={submitOption}
          >
            Weiter
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
