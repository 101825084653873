import React from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Controller, useForm } from "react-hook-form";

import twinLogo from "../../img/icon-512x512.png";
import "./Register.css";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../redux/actions/appActions";
import AppErrorMessage from "../../helpers/AppErrorMessage";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../../components/Spinner/LoadingScreen";

const CURRENT_REGISTRATION_PASSWORD = "Uv8#71FXtIsPrE!1kG";

function Copyright() {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {"Copyright © "}
      <Link color="textPrimary" href="https://twintaekwondo.de">
        Michael Angermeyer
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: theme.spacing(5),
    maxWidth: "800px",
    paddingBottom: theme.spacing(2),
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "1.25rem",
    backgroundColor: "#ffbf00",
    color: "black",
    "&:hover": {
      backgroundColor: "#a18400",
      color: "white",
    },
  },
  a: {
    textDecoration: "none",
    fontSize: "1rem",
    color: "black",
    "&:hover": {
      color: "black",
    },
    "&:visited": {
      color: "black",
    },
  },
}));

export default function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, setError } = useForm();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [validPassword, setValidPassword] = React.useState(false);

  const { user, appError } = useSelector((state) => state.appReducer);

  React.useEffect(() => {
    console.log("TEST EFFECT");
    setLoading(false);
    if (user) {
      history.push("/dashboard/");
    }
  }, [history, user, setError]);

  if (appError && loading) {
    setLoading(false);
  }

  const handleValidateRegPassword = (data) => {
    setLoading(true);
    const { registerPassword } = data;
    setTimeout(() => {
      setLoading(false);
      if (registerPassword !== CURRENT_REGISTRATION_PASSWORD) {
        setError("registerPassword", {
          type: "manual",
          message: "Das Passwort ist nicht korrekt, versuchen Sie es erneut.",
        });
        return;
      }
      setValidPassword(true);
    }, 3000);
  };

  const onSubmit = (data) => {
    setLoading(true);
    console.log("data :>> ", data);
    const { password, confirmPassword, lastName, firstName } = data;
    let userData = {};
    if (password !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Die Passwörter stimmen nicht überein.",
      });
      return;
    }
    console.log("Register User!");
    let username = lastName + "-" + firstName + "-" + new Date().getTime();
    userData = { ...data, username: username, rights: "user" };
    console.log("userData :>> ", userData);
    try {
      dispatch(appActions.signUpUser(userData));
      dispatch(appActions.setAppError(null));
    } catch (error) {
      console.log(error);
    } finally {
      alert("User created!")
    }
  };

  if (!validPassword) {
    return (
      <div>
        {loading && <LoadingScreen></LoadingScreen>}
        <div className={""}>
          <ul className="register-background">
            <li>
              <span>Image 01</span>
              <div></div>
            </li>
          </ul>
        </div>
        <div style={{ position: "relative", zIndex: 500 }}>
          <Container className={classes.container} component="div">
            <CssBaseline />
            <div className={classes.paper}>
              <img className="login__logo" src={twinLogo} alt="Logo" />
              <Typography
                align="center"
                component="h1"
                variant="h5"
                style={{ marginTop: 50 }}
                className={classes.title}
              >
                Um einen Account für die Mitgliederverwaltung zu erstellen,{" "}
                <br /> ist ein Passwort nötig.
              </Typography>
              <Typography
                align="center"
                component="h1"
                style={{ marginTop: 50 }}
                variant="h5"
                className={classes.title}
              >
                Geben Sie das Passwort für die Registrierung bitte hier ein.
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(handleValidateRegPassword)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Passwort um Registrierung freizuschalten"
                      variant="outlined"
                      margin="normal"
                      inputRef={register({ required: true })}
                      error={errors["registerPassword"]}
                      helperText={
                        errors["registerPassword"] &&
                        errors.registerPassword.message
                      }
                      required
                      fullWidth
                      type="password"
                      id="registerPassword"
                      name="registerPassword"
                      autoFocus
                    />
                  </Grid>
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    value="Submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {loading ? <CircularProgress size={30} /> : "Freischalten"}
                  </Button>
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Haben Sie bereits einen Account? Hier anmelden.
                    </Link>
                  </Grid>
                </Grid>
              </form>
              <AppErrorMessage></AppErrorMessage>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </div>
    );
  } else if (validPassword) {
    return (
      <div>
        <div className={""}>
          <ul className="register-background">
            <li>
              <span>Image 01</span>
              <div></div>
            </li>
          </ul>
        </div>
        <div style={{ position: "relative", zIndex: 500 }}>
          <Container className={classes.container} component="div">
            <CssBaseline />
            <div className={classes.paper}>
              <img className="login__logo" src={twinLogo} alt="Logo" />
              <Typography component="h1" variant="h5" className={classes.title}>
                Bitte geben Sie hier Ihre Daten ein
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: 200,
                        marginTop: 15,
                      }}
                      error={errors["title"]}
                      required
                    >
                      <InputLabel htmlFor={`title-select`}>
                        {"Anrede"}
                      </InputLabel>
                      <Controller
                        control={control}
                        name={"title"}
                        rules={{ required: true }}
                        as={
                          <Select
                            label={"Anrede"}
                            labelId={`title-select`}
                            id={`title-select`}
                            required
                          >
                            {[
                              { id: "01", value: "Herr", label: "Herr" },
                              { id: "02", value: "Frau", label: "Frau" },
                            ].map((select) => (
                              <MenuItem key={select.id} value={select.value}>
                                {select.label}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                      {errors["title"] && (
                        <FormHelperText>
                          {"Bitte einen Titel auswählen."}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      inputRef={register({ required: true })}
                      error={errors["firstName"]}
                      helperText={
                        errors["firstName"] &&
                        "Bitte geben Sie Ihren Vornamen ein."
                      }
                      label="Vorname"
                      type="firstName"
                      id="firstName"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="lastName"
                      inputRef={register({ required: true })}
                      error={errors["lastName"]}
                      helperText={
                        errors["lastName"] &&
                        "Bitte geben Sie Ihren Nachnamen ein."
                      }
                      label="Nachname"
                      id="lastName"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="E-Mail Addresse"
                      variant="outlined"
                      margin="normal"
                      inputRef={register({ required: true })}
                      error={errors["email"]}
                      helperText={
                        errors["email"] &&
                        "Bitte geben Sie eine gültige E-Mail an."
                      }
                      required
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      inputRef={register({ required: true })}
                      error={errors["password"]}
                      helperText={
                        errors["password"] &&
                        "Bitte geben Sie ein Passwort ein."
                      }
                      label="Passwort"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="confirmPassword"
                      inputRef={register({ required: true })}
                      error={errors["confirmPassword"]}
                      helperText={
                        errors["confirmPassword"] &&
                        errors.confirmPassword.message
                      }
                      label="Passwort wiederholen"
                      type="password"
                      id="confirmPassword"
                    />
                  </Grid>
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    value="Submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {loading ? <CircularProgress size={30} /> : "Registrieren"}
                  </Button>
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Haben Sie bereits einen Account? Hier anmelden.
                    </Link>
                  </Grid>
                </Grid>
              </form>
              <AppErrorMessage></AppErrorMessage>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </div>
    );
  }
}
