import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import { gradArray } from "../../../Dialogs/NewMitglied/DialogPages/inputFields";
import BeltAvatars from "../../../Avatar/Belts/BeltAvatar";
import { Card, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const DialogSelectBelt = React.memo((props) => {
  const { rowData } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [newBelt, setNewBeld] = React.useState(rowData.degree);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setNewBeld(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewBelt = () => {
    if (rowData.degree !== newBelt) {
      let newRowData = {
        ...rowData,
        degree: newBelt,
      };
      dispatch(masterDataActions.editMitglied(newRowData));
    }
    handleClose();
  };

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={handleClickOpen}>
        <BeltAvatars beltNumber={parseInt(rowData.degree)}></BeltAvatars>
      </div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Gürtelgrad auswählen</DialogTitle>
        <DialogContent>
          <Grid container justify="center" direction="column">
            <Grid item xs={12}>
              <div>
                <form className={classes.container}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-dialog-select-label">
                      Gürtelgrad:
                    </InputLabel>
                    <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={newBelt}
                      onChange={handleChange}
                      input={<Input />}
                    >
                      {gradArray.map((select) => (
                        <MenuItem key={select.id} value={select.value}>
                          {select.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </form>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Card
                  style={{
                    marginTop: 20,
                    paddingLeft: 50,
                    paddingRight: 15,
                    paddingTop: 5,
                    backgroundColor: "#bfbfbf",
                  }}
                >
                  <BeltAvatars beltNumber={newBelt}></BeltAvatars>
                </Card>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleNewBelt} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogSelectBelt;
