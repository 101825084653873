import axios from "axios";

const serverName = process.env.REACT_APP_BACKEND_SERVER;

// import { URL } from "../app/env";

const logIn = (userData) => {
  return axios
    .post(`${serverName}/login`, userData)
    .then((response) => {
      localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
      return response.data;
    })
    .catch(() => {
      throw new Error("E-Mail oder Passwort falsch");
    });
};

const signUp = (userData) => {
  return axios
    .post(`${serverName}/signup`, userData)
    .then((response) => {
      localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
      return response.data;
    })
    .catch((error) => {
      console.log("error :>> ", error.response.data);
      throw error;
    });
};

const getUser = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/user`)
    .then((response) => response.data.userCredentials)
    .catch((e) => {
      throw e;
    });
};

const updateUser = (userData) => {
  return axios
    .post(`${serverName}/user`, userData)
    .then(() => {})
    .catch((error) => {
      if (error.response.status === 403) {
        window.location.reload();
      }
      console.log(error);
    });
};
// eslint-disable-next-line
export default {
  logIn,
  signUp,
  getUser,
  updateUser,
};

// export const authMiddleWare = (history) => {
//     const authToken = localStorage.getItem('AuthToken');
//     if (authToken === null) {
//         history.push('/login');
//     }
// }

// export async function tokenData() {
//     try {
//         const response = await axios.get(`${serverName}/token`);
//         return response.data
//     } catch(error) {
//         console.log(error)
//         throw error
//     }
// }

// export async function loginUser(userData) {
//     try {
//         const response = await axios.post(`${serverName}/login`, userData)
//         localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
//         return response;
//     } catch(error) {
//         console.log(error)
//         throw error
//         // throw Error
//     }
// }

// export async function getCurrentUser() {
//     const authToken = localStorage.getItem('AuthToken');
//     axios.defaults.headers.common = { Authorization: `${authToken}` };
//     try {
//         const response = await axios.get(`${serverName}/user`);
//         return response.data.userCredentials
//         // setCurrentUser({...response.data.userCredentials});
//         // setUiLoading(false);
//     } catch(error) {
//         console.log(error);
//         throw error;
//     }
// }

// export async function addOrEditTodo(options) {
//     axios.defaults.headers.common = { Authorization: `${authToken}` };
//     try {
//         const response = await axios(options);
//         return response.data
//     } catch(error) {
//         console.log(error);
//     }
// }
