import { Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CSVReader1 from "../../../../API/CSVReader";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import ImportContractDataForm from "./ImportContractDataForm";

export default function ImportContractsPage(props) {
  const [data, setData] = React.useState([]);
  const [importAction, setImportAction] = React.useState("SELECT_FILE");
  const dispatch = useDispatch();
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  if (data === null) {
    let reset = dialogData;
    setData([]);
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.IMPORT_VERTRAGSNEHMER,
        reset,
        "IMPORT_CONTRACT_DATA"
      )
    );
    setImportAction("SELECT_FILE");
  }

  if (data && data.length > 0 && dialogType === "IMPORT_CONTRACT_DATA") {
    switch (importAction) {
      case "SELECT_FILE":
        let defaultColumnsImport = [];
        for (const [key] of Object.entries(data[0])) {
          defaultColumnsImport.push({
            title: `${key}`,
            field: `${key}`,
          });
        }
        let newDialogData;
        newDialogData = {
          ...dialogData,
          defaultContractColumns: defaultColumnsImport,
          importedContractData: data,
        };
        console.log("switch selectFile");
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.IMPORT_VERTRAGSNEHMER,
            newDialogData,
            "IMPORT_CONTRACT_DATA"
          )
        );
        setImportAction("SELECT_HEADERS");
        break;
      case "SELECT_HEADERS":
        console.log("select headers");
        break;

      default:
        break;
    }

    // dispatch(masterDataActions.openMasterDataDialog(dialogConstants.IMPORT_VERTRAGSNEHMER, newDialogData, "CHECK_DATA"))
  }

  return (
    <div style={{ padding: "25px", paddingBottom: "40px", overflow: "auto" }}>
      {true && <CSVReader1 setData={(data) => setData(data)} />}

      {data && data.length > 1 ? (
        <div>
          <Typography variant="h5" style={{ paddingBottom: 25 }}>
            {data.length} Datensätze wurden eingelesen.
          </Typography>
          <ImportContractDataForm />
        </div>
      ) : (
        <Typography variant="h5">
          Importieren Sie jetzt die Vertragsdaten.
        </Typography>
      )}
    </div>
  );
}
