import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { Grid, MenuItem, Select, Switch, Typography } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  calculateTermination,
  createFullDateString,
  isValidDate,
} from "../../../../utils/dataUtils/appUtils";
import Emoji from "../../../../helpers/Emoji";
import LoadingScreen from "../../../Spinner/LoadingScreen";
import { Edit } from "@material-ui/icons";
import {
  bezahltBisArray,
  getDateFromPayedTillString,
} from "../../../Dialogs/NewMitglied/DialogPages/inputFields";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import { addHours, lastDayOfMonth } from "date-fns";
import { sendTerminationMail } from "../../../../API/authAPI";
import { getContractDataFromMember } from "../../../../utils/dataUtils/masterDataUtils";

const DialogTerminate = (props) => {
  const { rowData, closeStatusDialog } = props;
  const [terminationOptionCalc, setTerminationOption] =
    React.useState("CALCULATE");
  const [terminationState, setTerminationState] = React.useState(
    rowData.terminationState ? rowData.terminationState : null
  );
  const [terminationData, setTerminationData] = React.useState(null);
  const [selectedDate, handleDateChange] = React.useState(
    rowData?.terminationDate ? new Date(rowData?.terminationDate) : new Date()
  );
  const [saveData, setSaveData] = React.useState(false);
  const [showTerminationDialog, setShowTerminationDialog] =
    React.useState(false);
  const [disableMail, setDisableMail] = React.useState({
    disable: false,
    loading: false,
  });
  const [lastBookingManually, setLastBookingManually] = React.useState(null);
  const { vertragsnehmerList } = useSelector(
    (state) => state.masterDataReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setTerminationState(terminationState);
  }, [terminationState]);

  const handleClose = () => {
    closeStatusDialog();
  };

  const updateLastBookingManually = (lastBooking) => {
    setLastBookingManually(lastBooking);

    let tD = {
      contractDate: rowData.contractDate,
      contractDuration: rowData.contractDuration,
      contractExtension: rowData.contractExtension,
      noticePeriod: rowData.noticePeriod,
      terminationDate: selectedDate,
    };

    // Update terminationData State
    setTerminationData({
      ...tD,
      lastMonth: lastBooking,
      contractEndDate: lastDayOfMonth(getDateFromPayedTillString(lastBooking)),
    });

    setSaveData(true);
  };

  const handleChangeTerminateOption = () => {
    if (terminationOptionCalc === "CALCULATE") {
      setTerminationOption("MANUALLY");
      setSaveData(false);
      setTerminationData(null);
    } else {
      setTerminationOption("CALCULATE");
      setSaveData(false);
    }
  };

  const handleNewStatus = () => {
    let newRowData = {
      ...rowData,
      terminationDate: selectedDate,
      status: "terminated",
      terminationOption: 3,
      terminationInformation: {
        lastMonth: terminationData
          ? terminationData.lastMonth
          : lastBookingManually,
        lastUpdated: new Date(),
        contractEndDate: terminationData.contractEndDate,
      },
    };

    setSaveData(newRowData);

    dispatch(masterDataActions.editMitglied(newRowData));
    handleClose();
  };

  // Handle Mail sending to Contractholder and CC User
  const handleSendTerminationMail = () => {
    setDisableMail({ disable: true, loading: true });
    console.log("Send Termination Mail");
    let data = {
      member: rowData,
      contract: getContractDataFromMember(
        rowData.mitgliedId,
        vertragsnehmerList
      ),
      terminationData: {
        ...terminationData,
        contractEndDate: addHours(terminationData.contractEndDate, 12),
      },
    };

    if (!data.contract.email) {
      alert(
        "Für den ausgewählten Vertrag ist keine gültige E-Mail Adresse hinterlegt.\nBitte hinterlegen Sie zuerst eine gültige Mail Adresse."
      );
      setDisableMail({ disable: true, loading: false });
      return;
    }

    sendTerminationMail(data)
      .then((response) => {
        setDisableMail((prevState) => ({ ...prevState, loading: false }));
        alert("Mail verschickt!");
      })
      .catch((error) => {
        console.log("error :>> ", error);
        alert("Probleme beim versenden der Mail!");
        setDisableMail({ disable: false, loading: false });
      });
  };

  // Handles the calculation and the UI updates for the termination
  const handleTerminationCalculation = () => {
    let tD = {
      contractDate: rowData.contractDate,
      contractDuration: rowData.contractDuration,
      contractExtension: rowData.contractExtension,
      noticePeriod: rowData.noticePeriod,
      terminationDate: selectedDate,
    };

    // Calculate Termination and get updated Member
    let lastMonth = calculateTermination(rowData, tD);

    // Update terminationData State
    setTerminationData({
      showData: true,
      ...tD,
      lastMonth: lastMonth,
      contractEndDate: lastDayOfMonth(getDateFromPayedTillString(lastMonth)),
    });
    setSaveData(true);
  };

  const handleEditTermination = () => {
    console.log("rowData :>> ", rowData);
    setShowTerminationDialog(true);
  };

  console.log("rowData :>> ", rowData);

  if (rowData.status === "terminated" && !showTerminationDialog) {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={true}
        onClose={handleClose}
      >
        <DialogTitle>
          {rowData.firstName} {rowData.lastName} wurde bereits gekündigt
        </DialogTitle>
        <DialogContent>
          {rowData.terminationInformation && (
            <Typography variant="body1">
              <b>
                Zuletzt geändert:{" "}
                {rowData.terminationInformation.lastUpdated
                  ? new Date(
                      rowData.terminationInformation.lastUpdated
                    ).toLocaleString()
                  : new Date().toLocaleString()}
              </b>
            </Typography>
          )}
          <br />
          <Typography variant="body1">
            Vertragsdatum:{" "}
            {new Date(rowData?.contractDate).toLocaleDateString()}
          </Typography>
          <Typography variant="body1">
            Vertragslaufzeit: {rowData.contractDuration} Monat(e)
          </Typography>
          <Typography variant="body1">
            Vertragsverlängerung: {rowData.contractExtension} Monat(e)
          </Typography>
          <Typography variant="body1">
            Kündigungsfrist: {rowData.noticePeriod} Monat(e)
          </Typography>
          <Typography variant="body1">
            Kündigungsdatum:{" "}
            {new Date(rowData?.terminationDate).toLocaleDateString()}
          </Typography>
          <br />
          <Typography variant="h6">
            Einzug bis einschließlich:{" "}
            {rowData?.terminationInformation?.lastMonth}
          </Typography>
          <br />
          <div>
            <Typography variant="body1">
              Möchten Sie die Kündigung bearbeiten?
            </Typography>
            <Button
              onClick={handleEditTermination}
              variant="contained"
              color="primary"
            >
              Bearbeiten
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleNewStatus}
            disabled={!saveData}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      {disableMail.loading && <LoadingScreen />}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={true}
        onClose={handleClose}
      >
        <DialogTitle>
          {rowData.firstName} {rowData.lastName} kündigen
        </DialogTitle>
        <DialogContent>
          <div id="termination-dialog">
            {rowData.terminationInformation && (
              <Typography variant="body1">
                <b>
                  Zuletzt geändert:{" "}
                  {rowData.terminationInformation.lastUpdated
                    ? new Date(
                        rowData.terminationInformation.lastUpdated
                      ).toLocaleString()
                    : new Date().toLocaleString()}
                </b>
              </Typography>
            )}
            {rowData.noticePeriod ? (
              <div>
                <div>
                  <Typography variant="h5">
                    Bitte geben Sie zuerst das Datum der Kündigung an:
                  </Typography>
                  <FormControl>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={deLocale}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-dialog"
                        label={"Kündigungsdatum"}
                        format="dd.MM.yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </div>
                <div>
                  {selectedDate && isValidDate(selectedDate) && (
                    <div>
                      <div
                        id="select-terminate-option"
                        style={{ margin: "25px 0" }}
                      >
                        <Typography variant="h4">
                          Kündigungsoption wählen:
                        </Typography>
                        <div>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="h6">
                                <b>MANUELL</b>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={terminationOptionCalc === "CALCULATE"}
                                onChange={handleChangeTerminateOption}
                                name="calculate-termination-option-switch"
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6">
                                <b>BERECHNEN</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      {terminationOptionCalc === "CALCULATE" ? (
                        <div id="terminate-option-calculated">
                          <div>
                            <Typography variant="h6">
                              Auf <b>Kündigungsdaten berechnen</b> drücken, um
                              Ende der Mitgliedschaft zu berechnen.
                            </Typography>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleTerminationCalculation}
                              style={{ margin: 15 }}
                            >
                              Kündigungsdaten berechnen
                            </Button>
                          </div>
                          {terminationData && terminationData.showData && (
                            <TerminationDataView
                              rowData={rowData}
                              terminationData={terminationData}
                            />
                          )}
                        </div>
                      ) : (
                        <div id="terminate-option-manually">
                          <TerminateManuallyView
                            lastBookingManually={lastBookingManually}
                            updateLastBookingManually={
                              updateLastBookingManually
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <Typography variant="h6">
                  Um die Kündigungsdaten korrekt zu berechnen werden folgende
                  Daten benötigt:
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>
                      Vertragsdatum{" "}
                      {rowData.contractDate ? (
                        <Emoji symbol="✅" />
                      ) : (
                        <Emoji symbol="❌" />
                      )}
                    </li>
                    <li>
                      Vertragslaufzeit{" "}
                      {rowData.contractDuration ? (
                        <Emoji symbol="✅" />
                      ) : (
                        <Emoji symbol="❌" />
                      )}
                    </li>
                    <li>
                      Vertragsverlängerung{" "}
                      {rowData.contractExtension ? (
                        <Emoji symbol="✅" />
                      ) : (
                        <Emoji symbol="❌" />
                      )}
                    </li>
                    <li>
                      Kündigungsfrist{" "}
                      {rowData.noticePeriod ? (
                        <Emoji symbol="✅" />
                      ) : (
                        <Emoji symbol="❌" />
                      )}
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1">
                  <b>Fehlende Daten</b>, sind durch ein <Emoji symbol="❌" />{" "}
                  gekennzeichnet. Bitte ergänzen Sie diese Daten für das
                  entsprechende Mitglied. Das notwendige Feld finden Sie unter{" "}
                  <b>Mitglied bearbeiten</b> <Edit />
                </Typography>
              </div>
            )}
          </div>
          <div id="termination-mail" style={{ marginTop: 20 }}>
            <Typography variant="body1">
              Falls in den Vertragsdaten eine E-Mailadresse hinterlegt wurde,
              kann automatisch eine Kündigungsbestätigung gesendet werden.
            </Typography>
            {rowData?.email ? (
              <Typography variant="body1">
                <b>E-Mail: {rowData.email}</b>
              </Typography>
            ) : (
              <Typography variant="body1">
                Keine Mailadresse hinterlegt.
              </Typography>
            )}
            <div id="termination-mail-button">
              <Button
                variant="contained"
                color="secondary"
                disabled={!saveData || disableMail.disable}
                onClick={handleSendTerminationMail}
                style={{ margin: 15 }}
              >
                Kündigungsbestätigung senden
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleNewStatus}
            disabled={!saveData}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogTerminate;

const TerminateManuallyView = ({
  lastBookingManually,
  updateLastBookingManually,
}) => {
  const handleChangeLastBooking = (event) => {
    updateLastBookingManually(event.target.value);
  };

  return (
    <div>
      <Typography variant="h5">Vertragsende manuell festlegen</Typography>
      <div id="select-vertragsende" style={{ margin: 15 }}>
        <Typography variant="h6">
          Monat der letzten Abbuchung auswählen:
        </Typography>
        <Select
          label="Abbuchen für:  *"
          labelId={`${"bookMonth"}-select`}
          id={`${"bookMonth"}-select`}
          variant="outlined"
          error={!lastBookingManually}
          value={lastBookingManually}
          onChange={handleChangeLastBooking}
          style={{ minWidth: 150 }}
        >
          {bezahltBisArray.map((select) => (
            <MenuItem key={select.id} value={select.value}>
              {select.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Typography variant="caption">
        Bis einschließlich diesen Monat wird der Mitgliedsbeitrag eingezogen.
      </Typography>
    </div>
  );
};

const TerminationDataView = ({ rowData, terminationData }) => {
  console.log("terminationData :>> ", terminationData);
  return (
    <div>
      <div>
        <Typography variant="body1">
          Vertragsdatum:{" "}
          {createFullDateString(new Date(terminationData.contractDate))}
        </Typography>
        <Typography variant="body1">
          Vertragslaufzeit: {terminationData.contractDuration} Monate
        </Typography>
        <Typography variant="body1">
          Verlängerung: {terminationData.contractExtension} Monate
        </Typography>
        <Typography variant="body1">
          Kündigungsfrist: {terminationData.noticePeriod} Monate
        </Typography>
        <Typography variant="body1">
          Gekündigt am: {createFullDateString(terminationData.terminationDate)}
        </Typography>
        <div>
          <Typography variant="body1">
            <b>Mitgliedsbeitrag fällig bis: {terminationData.lastMonth}</b>
          </Typography>
        </div>
      </div>
    </div>
  );
};
