import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { DeleteButton } from "../../../helpers/DeleteButton";
import { CheckCircle } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BuchungenTable({ buchungen, handleDeleteBuchung }) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleShow}>
        Buchungen {show ? "einklappen" : "anzeigen"}
      </Button>
      {show && (
        <div>
          <div style={{ margin: 15 }}>
            <Typography variant="h6">
              VORSICHT! <br />
              Gelöschte Buchungen können nicht wiederhergestellt werden!
            </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Buchung</TableCell>
                  <TableCell align="center">Jahresmarke</TableCell>
                  <TableCell align="right">Mitglieder</TableCell>
                  <TableCell align="right">Summe</TableCell>
                  <TableCell align="right">Standorte</TableCell>
                  <TableCell align="right">Erstellt am</TableCell>
                  <TableCell align="right">Löschen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buchungen.map((buchung) => (
                  <TableRow key={buchung.buchungId}>
                    <TableCell component="th" scope="row">
                      {buchung.bookMonth}
                    </TableCell>
                    <TableCell align="center">
                      {buchung.bookSocietyFee ? (
                        <CheckCircle color="secondary" />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">{buchung.activeMembers}</TableCell>
                    <TableCell align="right">{buchung.totalSum}</TableCell>
                    <TableCell align="right">
                      {buchung.standorte.map((standort) => standort + " ")}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(buchung.createdAt).toLocaleString("de-DE")}
                    </TableCell>
                    <TableCell align="right">
                      <DeleteButton
                        onClick={() => handleDeleteBuchung(buchung)}
                      >
                        Löschen
                      </DeleteButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
