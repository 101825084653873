import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import dialogConstants from "../../../redux/constants/dialogConstants";
import masterDataActions from "../../../redux/actions/masterDataActions";

export default function AlertDialog(props) {
  // const {title, text} = props;
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogConstants.ALERT;
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  const handleClose = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
    // window.location.reload();
    // dispatch(masterDataActions.getMitgliederList());
  };

  if (dialogData) {
    return (
      <div>
        <Dialog
          open={visible}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogData ? dialogData.title : "Warnung"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogData ? dialogData.text : "Das ist ein Hinweis"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Verstanden
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <div></div>;
  }
}
