import { Button } from '@material-ui/core'
import React, { Component } from 'react'
import { CSVReader } from 'react-papaparse'

const buttonRef = React.createRef()

export default class CSVReader1 extends Component {

  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  convertToObj = (data) => {
    let importHeaders = [];
    let importData = [];
    let objData = [];

    data.forEach((vn, i) => {
      objData.push(Object.assign({}, vn.data))
    })

    for (var field in objData[0]) {
      importHeaders.push(objData[0][field])
    }
    objData.forEach((vn) => {
      let dataSet = {}
      for (var [j, v] of Object.entries(vn)) {
        dataSet = {
          ...dataSet,
          [j]: v,
          // If first dataset contains headers use this to assign names to objects
          // [importHeaders[j]]: v,
        }
      }
      importData.push(dataSet)
    })
    console.log('importData :>> ', importData);
    return importData;
  }

  handleOnFileLoad = (data) => {
    console.log('---------------------------')
    let objData = this.convertToObj(data)
    this.props.setData(objData);
    // localStorage.setItem('ImportedData', objData);
    console.log('---------------------------')
    // this.props.toggleCsvImport();
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    this.props.setData(data)
    console.log('---------------------------')
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

  render() {
    return (
      <>
        <CSVReader
          ref={buttonRef}
          onFileLoad={this.handleOnFileLoad}
          onError={this.handleOnError}
          noClick
          noDrag
          onRemoveFile={this.handleOnRemoveFile}
        >
          {({ file }) => (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
                justifyContent: 'space-between'
              }}
            >
              <Button
                variant='contained'
                // color='primary'
                style={{
                  width: '20%',
                }}
                onClick={this.handleOpenDialog}>
                Datei auswählen...
            </Button>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#ccc',
                  height: 45,
                  lineHeight: 2.5,
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: '60%'
                }}
              >
                {file && file.name}
              </div>
              <Button
                variant='contained'
                color='secondary'
                style={{
                  // backgroundColor: 'red'
                }}
                onClick={this.handleRemoveFile}>
                Löschen
                </Button>
            </aside>
          )}
        </CSVReader>
      </>
    )
  }
}