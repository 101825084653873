import React from "react";
import { Typography } from "@material-ui/core";
import "./ImportedDataTable.css";

export default function ImportedDataTable(props) {
  const { data } = props;

  const renderTableHeader = () => {
    return data.defaultColumns.map((header, index) => {
      return <th key={index}>{header.text}</th>;
    });
  };

  var tableBody = [];

  const renderTableBody = () => {
    data.importedData.forEach((vnehmer, index) => {
      let dataRow = [];
      for (const prop in vnehmer) {
        if (vnehmer.hasOwnProperty(prop)) {
          dataRow.push(<td>{vnehmer[prop]}</td>);
        }
      }
      tableBody.push(dataRow);
    });

    return tableBody.map((row, index) => {
      return <tr key={index}>{row}</tr>;
    });
  };

  return (
    <div>
      <Typography variant="h4" align="left" gutterBottom>
        Daten kontrollieren
      </Typography>
      <table id="vertragsnehmer">
        <tbody>
          <tr>{renderTableHeader()}</tr>
          {renderTableBody()}
        </tbody>
      </table>
    </div>
  );
}
