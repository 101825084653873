import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import masterDataApi from "../../../redux/api/masterDataApi";
import BuchungenTable from "./BuchungenTable";
import LoadingScreen from "../../Spinner/LoadingScreen";
import BuchungenStatistics from "./BuchungenStatistics";

export default function BankingStatistics() {
  const [buchungen, setBuchungen] = React.useState({ data: null, chart: null });
  const [loading, setLoading] = React.useState(false);

  const handleDeleteBuchung = (buchung) => {
    setLoading(true);
    masterDataApi
      .removeSingleBuchungById(buchung.buchungId)
      .then((b) => {
        handleLoadBuchungen();
      })
      .catch((e) => {
        console.log("error :>> ", e);
        setLoading(false);
      });
  };

  const handleLoadBuchungen = () => {
    setLoading(true);
    masterDataApi
      .getBuchungen()
      .then((b) => {
        console.log("b :>> ", b);
        let chartData = [];
        b.forEach((buchung) => {
          chartData.push([
            buchung.bookMonth,
            parseInt(buchung.totalSum.replace(".", "")),
            parseInt(buchung.activeMembers),
          ]);
        });
        chartData.push(["Buchung", "Beiträge", "Mitglieder"]);
        chartData.reverse();
        setBuchungen({ data: b, chart: chartData });
        setLoading(false);
      })
      .catch((e) => {
        console.log("error :>> ", e);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <LoadingScreen />}
      <div style={{ marginTop: "20px", marginBottom: "15px" }}>
        <Typography variant="h4">Banking Statistik</Typography>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleLoadBuchungen}>
              Buchungen laden
            </Button>
          </Grid>
          <Grid item xs={12}>
            {buchungen.data && (
              <BuchungenStatistics chartData={buchungen.chart} />
            )}
          </Grid>
          <Grid item xs={12}>
            {buchungen.data && (
              <BuchungenTable
                handleDeleteBuchung={handleDeleteBuchung}
                buchungen={buchungen.data}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
