import React from "react";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import SEPA from "sepa";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faSave } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import {
  bankDataFields,
  contactDataFields,
  parentDataFields,
} from "./inputFields";

export default function ContractDataForm(props) {
  const { memberType, memberData, contractData, newContract } = props;
  const dispatch = useDispatch();
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );
  let defaultFormValues;
  let editMode = false;

  if (contractData) {
    defaultFormValues = {
      ...contractData,
    };
  } else {
    defaultFormValues = {
      isOwner: true,
      title: "Herr",
      firstName: "Max",
      lastName: "Musterzahler",
      birthdate: new Date(1992, 9, 26),
      contractDate: new Date(2007, 9, 26),
    };
  }

  if (dialogType && dialogType === "NEW_MEMBER_DATA") {
    defaultFormValues = {
      ...defaultFormValues,
      title: memberData.title,
      firstName: memberData.firstName,
      lastName: memberData.lastName,
      birthdate: new Date(memberData.birthdate),
    };
  }

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...defaultFormValues,
    },
  });

  const isChild = memberType === "child";

  const isOwner = watch("isOwner");
  const lastName = watch("lastName");
  const firstName = watch("firstName");

  var ownerName = lastName + " " + firstName;

  var memberName = memberData.lastName + " " + memberData.firstName;

  if (dialogType === "EDIT_CONTRACT") {
    editMode = true;
    memberName = ownerName;
  }

  React.useEffect(() => {
    if (isOwner === true) {
      if (isChild) {
        setTimeout(() => setValue("owner", ownerName), 100);
      } else {
        setValue("owner", memberName);
      }
    } else {
      setValue("owner", "");
      if (contractData || dialogType === "EDIT_CONTRACT") {
        setValue("owner", contractData.owner);
      }
    }
  }, [
    isOwner,
    setValue,
    memberName,
    isChild,
    ownerName,
    contractData,
    dialogType,
  ]);

  const renderInputFields = (inputFieldArray, disable) => {
    return inputFieldArray.map((item, i) => {
      if (item.required) {
        item.error = errors[item.value];
      }
      let itemToRender;
      switch (item.type) {
        case "owner":
          itemToRender = (
            <TextField
              style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
              variant="outlined"
              margin="normal"
              disabled={isOwner}
              defaultValue={ownerName}
              inputRef={register({ required: item.required })}
              f
              label={
                item.required ? item.label + "*" : item.label + " (optional)"
              }
              name={item.value}
              error={item.required ? item.error : false}
              helperText={item.required ? item.error && item.errorMsg : false}
            />
          );
          break;
        case "text":
          itemToRender = (
            <TextField
              style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
              variant="outlined"
              margin="normal"
              disabled={disable}
              inputRef={register({ required: item.required })}
              f
              label={
                item.required ? item.label + "*" : item.label + " (optional)"
              }
              name={item.value}
              error={item.required ? item.error : false}
              helperText={item.required ? item.error && item.errorMsg : false}
            />
          );
          break;
        case "select":
          itemToRender = (
            <FormControl
              variant="outlined"
              style={{ width: 200, marginBottom: 10 }}
              error={item.error}
            >
              <InputLabel htmlFor={`${item.value}-select`}>
                {item.required ? item.label + "*" : item.label}
              </InputLabel>
              <Controller
                control={control}
                name={item.value}
                rules={{ required: item.required }}
                as={
                  <Select
                    label={item.required ? item.label + "*" : item.label}
                    labelId={`${item.value}-select`}
                    id={`${item.value}-select`}
                    disabled={disable}
                  >
                    {item.selectList.map((select) => (
                      <MenuItem key={select.id} value={select.value}>
                        {select.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              {item.error && <FormHelperText>{item.errorMsg}</FormHelperText>}
            </FormControl>
          );
          break;

        case "date":
          itemToRender = (
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Controller
                  name={item.value}
                  control={control}
                  rules={{ required: item.required }}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      margin="normal"
                      error={item.required ? item.error : false}
                      helperText={
                        item.required ? item.error && item.errorMsg : false
                      }
                      inputVariant="outlined"
                      id="date-picker-dialog"
                      label={item.required ? item.label + "*" : item.label}
                      format="dd.MM.yyyy"
                      inputRef={ref}
                      disabled={disable}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          );
          break;
        default:
          itemToRender = <span>Error</span>;
          break;
      }
      itemToRender = (
        <Grid
          item
          lg={item.fieldWidth ? item.fieldWidth / 2 : 4}
          md={12}
          xs={12}
        >
          {itemToRender}
        </Grid>
      );
      if (item.value === "title") {
        itemToRender = (
          <Grid style={{ marginTop: 15 }} container xs={12} lg={12}>
            {itemToRender}
          </Grid>
        );
      }
      return itemToRender;
    });
  };

  const onSubmit = (data) => {
    if (isOwner) {
      data = {
        ...data,
        owner: isChild ? ownerName : memberName,
      };
    }

    // Check if an IBAN was typed in and if so if its correct
    if (data.iban) {
      if (!SEPA.validateIBAN(data.iban)) {
        alert("Der eingegebene IBAN ist ungültig, bitte Eingabe prüfen.");
        return;
      }
      data.iban = data.iban.replace(/\s/g, "");
      data.iban = data.iban.toUpperCase();
    }

    // Add fields for Contractdata, Contract needs person who owns it
    if (!isChild) {
      data = {
        ...data,
        title: memberData.title,
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        birthdate: memberData.birthdate,
      };
    }

    let newDialogData = {
      ...dialogData,
      contractData: data,
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_MITGLIED,
        newDialogData,
        "BANKDATA_ADDED"
      )
    );
  };

  const handleSaveEdit = () => {
    let newValues = {
      ...defaultFormValues,
    };
    var values = getValues();

    // Check if an IBAN was typed in and if so if its correct
    if (values.iban) {
      if (!SEPA.validateIBAN(values.iban)) {
        alert("Der eingegebene IBAN ist ungültig, bitte Eingabe prüfen.");
        return;
      }
      values.iban = values.iban.replace(/\s/g, "");
      values.iban = values.iban.toUpperCase();
    }

    newValues = {
      ...newValues,
      ...values,
    };
    dispatch(masterDataActions.editVertragsnehmer(newValues));
    // console.log("defaultFormValues :>> ", defaultFormValues);
    // console.log("values :>> ", values);
    console.log("Contract UPDATED! newValues :>> ", newValues);
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  if (isChild || editMode) {
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={12} lg={12} direction="row">
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                variant="h5"
              >
                Bankdaten
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <FormGroup row>
                <label style={{ fontSize: "25px" }}>
                  Ist der Vertragsnehmer der Kontoinhaber?
                </label>
                <label
                  style={{
                    color: isOwner ? "grey" : "black",
                    marginLeft: 15,
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Nein
                </label>
                <Switch
                  name="isOwner"
                  defaultChecked={isOwner}
                  inputRef={register}
                />
                <label
                  style={{
                    color: isOwner ? "black" : "grey",
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Ja
                </label>
              </FormGroup>
            </Grid>
            {renderInputFields(bankDataFields)}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  borderTopStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "lightgrey",
                }}
                variant="h5"
              >
                Vertragsnehmer / Erziehungsberechtigter
              </Typography>
            </Grid>
            {renderInputFields(parentDataFields)}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  borderTopStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "lightgrey",
                }}
                variant="h5"
              >
                Kontaktdaten
              </Typography>
            </Grid>
            {renderInputFields(contactDataFields)}
            <Grid container direction="row-reverse" lg={12} xs={12}>
              <Grid item lg={3} xs={12}>
                <FormGroup row>
                  {newContract ? (
                    <Button
                      style={{
                        width: "100%",
                        fontSize: 20,
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      value="Weiter"
                    >
                      Weiter
                      <div style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                      </div>
                    </Button>
                  ) : (
                    <Button
                      style={{ width: "100%", fontSize: 20 }}
                      variant="contained"
                      color="primary"
                      // type="submit"
                      // value="Bestätigen"
                      onClick={handleSaveEdit}
                    >
                      Speichern
                      <div style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={faSave} />
                      </div>
                    </Button>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else {
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={12} lg={12} direction="row">
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                variant="h5"
              >
                Bankdaten
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <FormGroup row>
                <label style={{ fontSize: "25px" }}>
                  Ist das Mitglied der Kontoinhaber?
                </label>
                <label
                  style={{
                    color: isOwner ? "grey" : "black",
                    marginLeft: 15,
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Nein
                </label>
                <Switch
                  name="isOwner"
                  defaultChecked={isOwner}
                  inputRef={register}
                />
                <label
                  style={{
                    color: isOwner ? "black" : "grey",
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Ja
                </label>
              </FormGroup>
            </Grid>
            {renderInputFields(bankDataFields)}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  borderTopStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "lightgrey",
                }}
                variant="h5"
              >
                Kontaktdaten
              </Typography>
            </Grid>
            {renderInputFields(contactDataFields)}

            <Grid container direction="row-reverse" lg={12} xs={12}>
              <Grid item lg={3} xs={12}>
                <FormGroup row>
                  {newContract ? (
                    <Button
                      style={{
                        width: "100%",
                        fontSize: 20,
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      value="Weiter"
                    >
                      Weiter
                      <div style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                      </div>
                    </Button>
                  ) : (
                    <Button
                      style={{ width: "100%", fontSize: 20 }}
                      variant="contained"
                      color="primary"
                      // type="submit"
                      // value="Bestätigen"
                      onClick={handleSaveEdit}
                    >
                      Speichern
                      <div style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={faSave} />
                      </div>
                    </Button>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}
