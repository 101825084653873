import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: 20,
  },
  row: {
    cursor: "pointer",
  },
});

export default function BankingAccountsTable({
  bankingAccounts,
  handleSelectedAccount,
}) {
  const classes = useStyles();

  const onSelectAccount = (account, index) => {
    console.log("index :>> ", index);
    handleSelectedAccount(account, index);
  };

  if (!bankingAccounts) {
    return (
      <div style={{ padding: 15 }}>
        <Typography variant="h5">
          Dieser Account enthält noch keine Bankkonten
        </Typography>
      </div>
    );
  }

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Inhaber</TableCell>
            <TableCell align="left">IBAN</TableCell>
            <TableCell align="left">Gläubiger-ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bankingAccounts.map((row, i) => (
            <TableRow
              onClick={() => onSelectAccount(row, i)}
              hover
              className={classes.row}
              key={row.creditorName + i.toString()}
            >
              <TableCell component="th" scope="row">
                {row.creditorName}
              </TableCell>
              <TableCell align="left">{row.creditorIBAN}</TableCell>
              <TableCell align="left">{row.creditorId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
