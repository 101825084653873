import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getGradCollection } from "../../../utils/dataUtils/appUtils";

export function AddMembersToEventTable(props) {
  const { members, handleAddMember } = props;
  const { standorteList } = useSelector((state) => state.masterDataReducer);

  let standortLookup;
  standorteList &&
    standorteList.forEach((site) => {
      standortLookup = {
        ...standortLookup,
        [site.standortId]: site.siteName,
      };
    });

  let gradCollection = getGradCollection();

  const handleMemberClick = (member) => {
    handleAddMember(member);
  };

  return (
    <TableContainer
      style={{ minHeight: 300, maxHeight: 400 }}
      component={Paper}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Standort</TableCell>
            <TableCell>Grad</TableCell>
            <TableCell align="left">Nachname</TableCell>
            <TableCell align="left">Vorname</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member, i) => (
            <TableRow
              hover
              onClick={() => handleMemberClick(member)}
              key={"Testing-Table-Row-" + i}
            >
              <TableCell>
                {" "}
                {standortLookup && standortLookup[member.standortRef]}
              </TableCell>
              <TableCell component="th" scope="row">
                {gradCollection[member.degree - 1].title}
              </TableCell>
              <TableCell align="left">{member.lastName}</TableCell>
              <TableCell align="left">{member.firstName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
