import { takeLatest, call, put, all } from "redux-saga/effects";
import appActions from "../actions/appActions";
import api from "../api/appApi";
import {
  LOG_IN,
  LOG_OUT,
  GET_USER,
  SIGN_UP,
  UPDATE_USER,
} from "../constants/appConstants";

function* logInUser(action) {
  try {
    const user = yield call(api.logIn, action.userData);
    yield put(appActions.userLoggedIn(user));
  } catch (e) {
    yield put(appActions.setAppError(e));
  }
}

function* getUser(action) {
  const currentUser = yield call(api.getUser);
  yield put(appActions.setUser(currentUser));
}

function* signUpUser(action) {
  try {
    const user = yield call(api.signUp, action.userData);
    yield put(appActions.userLoggedIn(user));
    localStorage.setItem("userLoggedIn", JSON.stringify(user));
  } catch (e) {
    console.error(e);
    yield put(appActions.setAppError(e));
  }
}

function* updateUser(action) {
  try {
    yield call(api.updateUser, action.userData);
  } catch (e) {
    console.error(e);
    yield put(appActions.setAppError(e));
  } finally {
    yield put(appActions.getUser());
  }
}

function* logOutUser() {
  console.log("Saga: logOutUser");
  localStorage.removeItem("userLoggedIn");
  localStorage.removeItem("AuthToken");
  // window.location.reload();
  yield put(appActions.userLoggedOut());
}

function* watchAppData() {
  yield takeLatest(SIGN_UP, signUpUser);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(GET_USER, getUser);
  yield takeLatest(LOG_IN, logInUser);
  yield takeLatest(LOG_OUT, logOutUser);
}

export default function* rootSaga() {
  yield all([watchAppData()]);
}
