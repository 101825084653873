import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ArrowDownwardIcon, ArrowUpwardIcon } from "@material-ui/data-grid";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import {
  createFullDateString,
  getGradCollection,
  sortArrayWithObjects,
} from "../../../utils/dataUtils/appUtils";

export function PruefungsListDetailTable(props) {
  const { prueflinge, updatePrueflinge, selectedEvent } = props;
  let prueflingeForTestingList = [...prueflinge];
  const dispatch = useDispatch();
  const [sortState, setSortState] = React.useState({
    field: null,
    direction: null,
  });
  const [renderCount, setRenderCount] = React.useState(0);
  const { standorteList } = useSelector((state) => state.masterDataReducer);

  let gradCollection = getGradCollection();

  let standortLookup;
  standorteList &&
    standorteList.forEach((site) => {
      standortLookup = {
        ...standortLookup,
        [site.standortId]: site.siteName,
      };
    });

  const handleSort = (sortCrit) => {
    let newList = [];
    if (sortState.direction !== "asc") {
      newList = sortArrayWithObjects(prueflinge, sortCrit, "asc");
      setSortState({ field: sortCrit, direction: "asc" });
    } else {
      newList = sortArrayWithObjects(prueflinge, sortCrit, "desc");
      setSortState({ field: sortCrit, direction: "desc" });
    }
    updatePrueflinge(newList);
  };

  const getSortIcon = (name) => {
    if (sortState.field !== name) {
      return <ArrowBack />;
    }
    if (sortState.field === name) {
      if (sortState.direction === "asc") {
        return <ArrowUpwardIcon />;
      } else {
        return <ArrowDownwardIcon />;
      }
    }
    return <ArrowBack />;
  };

  const toggleMemberValue = (member, key) => {
    let mId = member.mitgliedId;
    let i = prueflingeForTestingList.findIndex((x) => x.mitgliedId === mId);
    member[key] = !member[key];
    prueflingeForTestingList[i][key] = member[key];
    setRenderCount(renderCount + 1);
    return member;
  };

  const handlePayTesting = (member) => {
    let toggledMember = toggleMemberValue(member, "hasPayedTesting");
    dispatch(masterDataActions.editMitglied(toggledMember));
  };

  // After passed Testing members are shown in component above
  const handlePassedTesting = (member) => {
    let toggledMember = toggleMemberValue(member, "hasPassedTesting");
    dispatch(masterDataActions.editMitglied(toggledMember));
  };

  const handlePassport = (member) => {
    let toggledMember = toggleMemberValue(member, "hasPassport");
    dispatch(masterDataActions.editMitglied(toggledMember));
  };

  const removeMemberFromTesting = (member) => {
    // Update Member to be deleted from Testing List
    let m = {
      ...member,
      hasTesting: false,
      hasPayedTesting: false,
      hasPassedTesting: false,
      hasPassport: false,
    };

    // Create new List without member to update selected Event
    let newAttList = selectedEvent.hasAttendees.filter(
      (a) => a.mitgliedId !== m.mitgliedId
    );

    // IMPORTANT: The event update needs to be with mitgliedIds in hasAttendees only!

    // Remove Member from selected Event
    // Remove Member from Prueflinge
    // Edit Member so it has no testing status
    // Edit Event so member is no longer part of attendees
    // Edit Prueflinge so member is no longer part of Prueflinge

    // newAttList führt wohl zu undefined mapping wenn mehrere hintereinander gelöscht werden...
    // selectedEvent.hasAttendees = newAttList.map((m) => m.mitgliedId);

    dispatch(masterDataActions.editMitglied(m));
    dispatch(
      masterDataActions.editEvent({
        ...selectedEvent,
        hasAttendees: newAttList.map((m) => m.mitgliedId),
      })
    );
    updatePrueflinge(newAttList);
    selectedEvent.hasAttendees = newAttList;

    console.log("data: :>> ", {
      member: member,
      m: m,
      newAttList: newAttList,
      selectedEvent: selectedEvent,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">NR:</TableCell>
            <TableCell>
              <Button
                onClick={() => handleSort("degree")}
                endIcon={getSortIcon("degree")}
              >
                Grad
              </Button>
            </TableCell>
            <TableCell align="left">Standort</TableCell>
            <TableCell align="left">M.-Nummer</TableCell>
            <TableCell align="left">Nachname</TableCell>
            <TableCell align="left">Vorname</TableCell>
            <TableCell align="left">Geburtsdatum</TableCell>
            {/* <TableCell align="right">Prüfungsgebühr</TableCell> */}
            <TableCell align="center">Pass</TableCell>
            <TableCell align="center">Bezahlt</TableCell>
            <TableCell align="center">Bestanden</TableCell>
            <TableCell align="center">Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prueflinge.map((p, i) => (
            <TableRow hover key={"Testing-Table-Row-" + i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell component="th" scope="row">
                {gradCollection[p.degree - 1].title}
              </TableCell>
              <TableCell align="left">
                {standortLookup && standortLookup[p.standortRef]}
              </TableCell>
              <TableCell align="left">{p.memberNumber}</TableCell>
              <TableCell align="left">{p.lastName}</TableCell>
              <TableCell align="left">{p.firstName}</TableCell>
              <TableCell align="left">
                {createFullDateString(new Date(p.birthdate))}
              </TableCell>
              {/* <TableCell align="right">
                {gradCollection[p.degree].fee} €
              </TableCell> */}
              <TableCell align="center">
                <Checkbox
                  checked={p.hasPassport}
                  onChange={() => handlePassport(p)}
                />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={p.hasPayedTesting}
                  onChange={() => handlePayTesting(p)}
                />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={p.hasPassedTesting}
                  onChange={() => handlePassedTesting(p)}
                />
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => removeMemberFromTesting(p)}
                >
                  Löschen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
