import React, { useRef } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// import appActions from "../../../redux/actions/appActions";
import { updateUserPassword } from "../../../API/authAPI";

export default function ChangePassword(props) {
  const { currentUser } = useSelector((state) => state.appReducer);

  let defVal;
  defVal = currentUser && currentUser.bankingSettings;
  // const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch, reset } = useForm({
    defaultValues: { ...defVal },
  });

  const password = useRef({});
  password.current = watch("newPassword", "");

  const onSubmit = (data) => {
    updateUserPassword({
      email: currentUser.email,
      password: data.oldPassword,
      newPassword: data.newPassword,
    })
      .then((response) => {
        alert("Passwort wurde erfolgreich geändert!");
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Typography variant="h5">Hier können Sie Ihr Passwort ändern.</Typography>
      <br></br>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="oldPassword"
              label="Altes Passwort"
              name="oldPassword"
              type="password"
              autoFocus
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              id="newPassword"
              label="Neues Passwort"
              name="newPassword"
              type="password"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              inputRef={register({
                validate: (value) =>
                  value === password.current ||
                  "Die Passwörter stimmen nicht überein.",
              })}
              required
              id="verifyNewPassword"
              label="Neues Passwort wiederholen"
              name="verifyNewPassword"
              type="password"
              error={errors.verifyNewPassword}
              helperText={
                errors.verifyNewPassword && errors.verifyNewPassword.message
              }
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              value="Bestätigen"
            >
              Passwort ändern
            </Button>
            <Typography variant="h5">
              Diese Funktion steht momentan noch nicht zur Verfügung
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
