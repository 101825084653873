import React from "react";
import Chart from "react-google-charts";

const chartOptions = {
  title: "Mitglieder Übersicht",
  // Gives each series an axis that matches the vAxes number below.
  series: {
    // Gives each series an axis name that matches the Y-axis below.
    0: { axis: "Beiträge" },
    1: { axis: "Mitglieder" },
  },
  axes: {
    // Adds labels to each axis; they don't have to match the axis names.
    y: {
      Beiträge: { label: "Beiträge (Euro)" },
      Mitglieder: { label: "Mitglieder" },
    },
  },

  //   hAxis: { title: "Buchungen", titleTextStyle: { color: "#333" } },
  chartArea: { width: "80%", height: "70%" },
};

export default function BuchungenStatistics({ chartData }) {
  console.log("chartData :>> ", chartData);

  return (
    <div>
      <Chart
        chartType="Line"
        width="100%"
        height="400px"
        data={chartData}
        options={chartOptions}
        formatters={[{ column: 1, options: { prefix: "€" } }]}
      />
    </div>
  );
}
