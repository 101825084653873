import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import twinLogo from "../../img/icon-512x512.png";

import Copyright from "../../components/Brand/Copyright/Copyright";

import "./Login.css";

import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../redux/actions/appActions";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: 150,
    paddingBottom: theme.spacing(2),
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "1.25rem",
    backgroundColor: "#ffbf00",
    color: "black",
    "&:hover": {
      backgroundColor: "#a18400",
      color: "white",
    },
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progess: {
    position: "absolute",
  },
  a: {
    textDecoration: "none",
    fontSize: "1rem",
    color: "black",
    "&:hover": {
      color: "black",
    },
    "&:visited": {
      color: "black",
    },
  },
}));

export default function Login(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const { appError } = useSelector((state) => state.appReducer);

  if (loading && appError) {
    setLoading(false);
  }

  const onSubmit = (data) => {
    setLoading(true);
    const userData = {
      email: data.email,
      password: data.password,
    };
    try {
      dispatch(appActions.logInUser(userData));
      dispatch(appActions.setAppError(null));
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Render Login");

  return (
    <div style={{ position: "relative", zIndex: 500 }}>
      {/* <LoginError></LoginError> */}
      <Container className={classes.container} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img className="login__logo" src={twinLogo} alt="Logo" />
          <Typography component="h1" variant="h5" className={classes.title}>
            Bitte geben Sie hier Ihre Daten ein
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              label="E-Mail Addresse"
              inputRef={register({ required: true })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoComplete="email"
              name="email"
              autoFocus
              error={errors["email"]}
              helperText={errors["email"] && "Bitte geben Sie Ihre E-Mail an."}
            />

            <TextField
              label="Passwort"
              inputRef={register({ required: true })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              autoComplete="current-password"
              error={errors["password"]}
              helperText={
                errors["password"] && "Bitte geben Sie ein Passwort ein."
              }
            />

            {/* <section>
              <label className="stay-loggedin">Angemeldet bleiben?</label>
              <Controller
                name="stayLoggedIn"
                control={control}
                render={(props) => (
                  <Checkbox
                    color="primary"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                  />
                )}
              />
            </section> */}
            <Button
              type="submit"
              fullWidth
              value="Submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Anmelden
              {loading && (
                <CircularProgress size={30} className={classes.progess} />
              )}
            </Button>
            {false && (
              <Grid container>
                <Grid item xs>
                  <Link
                    href="/account-support"
                    color="inherit"
                    className={classes.a}
                  >
                    Passwort vergessen?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" color="inherit" className={classes.a}>
                    {"Registrieren"}
                  </Link>
                </Grid>
              </Grid>
            )}
          </form>
          <ErrorMessage></ErrorMessage>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

function ErrorMessage() {
  const classes = useStyles();
  const { appError } = useSelector((state) => state.appReducer);

  if (appError) {
    return (
      <Typography variant="body2" className={classes.customError}>
        {appError.message}
      </Typography>
    );
  } else {
    return <div></div>;
  }
}
