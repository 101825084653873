import React, { useRef } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";
import PruefungsListDetailView from "./PruefungsListDetailView";
import { Button, FormGroup, Tooltip, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAward } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import PruefungenCollapsibleTable from "./PruefungenCollapsibleTable";

export default function Pruefungen(props) {
  const [view, setView] = React.useState(0);
  const initRef = useRef(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [testingList, setTestingList] = React.useState([]);
  const { mitgliederList, eventsList, standorteList } = useSelector(
    (state) => state.masterDataReducer
  );
  const dispatch = useDispatch();

  let standortLookup;
  standorteList &&
    standorteList.forEach((site) => {
      standortLookup = {
        ...standortLookup,
        [site.standortId]: site.siteName,
      };
    });

  const initializeTestingList = () => {
    console.log("mitgliederList :>> ", mitgliederList);
    console.log("eventsList :>> ", eventsList);
    let pruefungen = [];
    eventsList.forEach((e) => {
      let attendees = [];
      if (e.eventType === "testing") {
        if ("hasAttendees" in e) {
          e.hasAttendees.forEach((m) => {
            let fM = mitgliederList.find((member) => member.mitgliedId === m);
            attendees.push({
              ...fM,
              hasTesting: true,
            });
          });
        }
        // e.hasAttendees = attendees;
        pruefungen.push({ ...e, hasAttendees: attendees });
      }
    });
    setTestingList(pruefungen);
  };

  if (!initRef.current && mitgliederList && eventsList) {
    initializeTestingList();
    initRef.current = true;
  }

  const addSO = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_EVENT,
        "testing",
        "ADD"
      )
    );
  };

  const handleView = (view, sE) => {
    console.log("view :>> ", view);
    console.log("sE :>> ", sE);
    setView(view);
    setSelectedEvent(sE);
  };

  const handleBack = () => {
    setView(0);
    setSelectedEvent(null);
    initRef.current = false;
    // mitgliederList && eventsList && initializeTestingList();
  };

  console.log("testingList :>> ", testingList);

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="lg" className={props.classes.container}>
        <FormGroup
          row
          style={{
            justifyContent: "flex-start",
            flexDirection: "row",
            flex: 1,
          }}
        >
          {view !== 0 && (
            <Tooltip title="Zurück">
              <Button
                variant="contained"
                style={{
                  marginRight: 25,
                  backgroundColor: "white",
                  minWidth: 220,
                  fontSize: "2em",
                }}
                onClick={handleBack}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 20 }}
                  size="lg"
                  icon={faArrowLeft}
                />
                Zurück
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Veranstaltung hinzufügen">
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                minWidth: 220,
                minHeight: 45,
                margin: 3,
                fontSize: "2em",
              }}
              onClick={addSO}
            >
              <FontAwesomeIcon
                style={{ marginRight: 20 }}
                size="lg"
                icon={faAward}
              />
              NEUE Prüfungsliste ANLEGEN
            </Button>
          </Tooltip>
        </FormGroup>
        <div>
          {view === 0 ? (
            testingList.length > 0 && (
              <div>
                <Typography style={{ margin: 15 }} variant="h4">
                  Prüfungslisten
                </Typography>
                <PruefungenCollapsibleTable
                  data={testingList}
                  handleView={handleView}
                  standortLookup={standortLookup}
                />
              </div>
            )
          ) : (
            <PruefungsListDetailView
              selectedEvent={selectedEvent}
              standortLookup={standortLookup}
              handleBack={handleBack}
            />
          )}
        </div>
        <Grid justify="center" container spacing={3}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
