import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
}));

export default function AppErrorMessage() {
  const classes = useStyles();
  const { appError } = useSelector((state) => state.appReducer);
  let errorText =
    "Etwas hat nicht funktioniert, bitte versuchen Sie es erneut.";

  if (appError && appError.response.data.email) {
    errorText =
      "Diese E-Mail ist bereits vergeben. Haben Sie Ihr Passwort vergessen?";
  }

  if (appError) {
    return (
      <Typography variant="body2" className={classes.customError}>
        {errorText}
      </Typography>
    );
  } else {
    return <div></div>;
  }
}
