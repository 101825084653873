import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import DatePickerFull from "./DatePickerFull";
import Button from "./Button";
import ComboBox from "./ComboBox";
import ImageUpload from "./ImageUpload";
import DateTimePicker from "./DateTimePicker";

const Controls = {
  ComboBox,
  Input,
  ImageUpload,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  Button,
  DateTimePicker,
  DatePickerFull,
};

export default Controls;
