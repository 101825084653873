import React, { useEffect } from "react";
import MaterialTable from "material-table";
import LoadingScreen from "../Spinner/LoadingScreen";

const Table = React.memo((props) => {
  const {
    data,
    title,
    tableLayout,
    onSelectionChange,
    tableStyle,
    scroll,
  } = props;
  var { defaultColumns } = props;

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    console.log("component Table LOADED!");
    setLoading(false);
  }, [setLoading]);

  let scrollOption = {};
  let windowHeight = window.innerHeight;
  let tableBodyHeight = windowHeight - 330;

  if (scroll) {
    scrollOption = {
      paging: false,
      maxBodyHeight: tableBodyHeight,
    };
  } else {
    scrollOption = {
      paging: true,
      pageSize: 20,
    };
  }

  if (!data) {
    return <LoadingScreen></LoadingScreen>;
  } else {
    // Check if headers are available in data
    defaultColumns.forEach(function (item, index, object) {
      if (item.field === undefined) {
        object.splice(index, 1);
      }
    });

    return (
      <div style={{ maxWidth: "100%", backgroundColor: "#bfbfbf" }}>
        <MaterialTable
          style={{ backgroundColor: "#bfbfbf" }}
          columns={defaultColumns}
          data={data}
          isLoading={loading}
          title={title}
          options={{
            selection: tableLayout.options,
            headerStyle: tableStyle.headerStyle,
            rowStyle: tableStyle.rowStyle,
            cellStyle: {
              padding: 0,
            },
            ...scrollOption,
            pageSizeOptions: [5, 10, 20, 50, data.length],
            // pageSize: 20,
            // paging: false,
            // maxBodyHeight: tableBodyHeight,
          }}
          actions={tableLayout.actions}
          onSelectionChange={(rows) => onSelectionChange(rows)}
        />
      </div>
    );
  }
});

// Set default props
Table.defaultProps = {
  tableStyle: {
    headerStyle: {
      backgroundColor: "#bfbfbf",
    },
    rowStyle: {
      backgroundColor: "#bfbfbf",
    },
  },
};

export default Table;
