import { Button, withStyles } from "@material-ui/core";
import React from "react";
import { red } from "@material-ui/core/colors";
// export default function DeleteButton(props) {

//   return (
//     <Button {...props} />
//   )
// }

export const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))((props) => <Button {...props} />);
