import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

export default function CreateSettingsFirst() {
  const history = useHistory();

  const goToSettings = () => {
    history.push("/dashboard/settings");
  };
  return (
    <div style={{ marginBottom: 50 }}>
      <Typography style={{ margin: 50 }} align="center" variant="h3">
        Noch keine Einstellungen zum erstellen von SEPA-Vorlagen vorhanden.
      </Typography>
      <hr />
      <Typography style={{ margin: 100 }} align="center" variant="h4">
        Geben Sie in den Banking Einstellungen die Daten für die Lastschriften
        ein.
      </Typography>
      <Grid justify="center" container>
        <Grid alignItems="center" item lg={6} xs={12}>
          <Button
            style={{ fontSize: "150%", minHeight: "60px" }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={goToSettings}
          >
            Zu den Einstellungen
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
