import { Typography } from "@material-ui/core";
import React from "react";

export default function UploadDone(props) {
  const { dialogData } = props;
  const { firstName, lastName, memberType } = dialogData.newMemberData;
  return (
    <div>
      <Typography variant="h4">Neues Mitglied!</Typography>
      <Typography
        style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "90px" }}
        variant="h5"
      >
        <b>
          {lastName} {firstName}
        </b>{" "}
        wurde erfolgreich als{" "}
        {memberType === "child" ? "Kind" : "Erwachsene(r)"} hinzugefügt
      </Typography>
    </div>
  );
}
