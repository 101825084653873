import React from "react";
import { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BottomNav from "../../BottomNav/BottomNav2";

import Copyright from "../../Brand/Copyright/Copyright";

import VertragsnehmerView from "./VertragsnehmerView";
// import AddOrEditVertragsnehmerDialog from '../../Dialogs/AddOrEditVertragsnehmerDialog';
import RemoveVertragsnehmerDialog from "../../Dialogs/RemoveVertragsnehmerDialog";
import VertragsnehmerMenu from "./VertragsnehmerMenu";
import ImportVnDialog from "../../Dialogs/ImportDialog/ImportDialog";

export default function VertragsinhaberContent(props) {
  // Set to 0 if default rendered page should be the menu
  const [menuPage, setMenuPage] = React.useState(1);
  let menu = <VertragsnehmerView></VertragsnehmerView>;

  switch (menuPage) {
    case 0:
      menu = (
        <div>
          <h1>Daten importieren</h1>
          <VertragsnehmerMenu></VertragsnehmerMenu>
        </div>
      );
      break;
    case 1:
      menu = <VertragsnehmerView></VertragsnehmerView>;
      break;
    case 2:
      menu = (
        <Typography variant="h3" gutterBottom>
          Diese Seite ist noch im Aufbau.
        </Typography>
      );
      // menu = <DropDownDataSelect></DropDownDataSelect>
      break;
    default:
      break;
  }

  useEffect(() => {
    document.title = `Vertragsnehmer`;
  });

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      {/* <NewVertragsnehmerDialog></NewVertragsnehmerDialog> */}
      {/* <AddOrEditVertragsnehmerDialog></AddOrEditVertragsnehmerDialog> */}
      <RemoveVertragsnehmerDialog></RemoveVertragsnehmerDialog>
      {menuPage === 0 && <ImportVnDialog></ImportVnDialog>}
      <Container maxWidth="false" className={props.classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{ backgroundColor: "#bfbfbf" }}>{menu}</Paper>
          </Grid>
          {/* <VertragsnehmerList></VertragsnehmerList> */}
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
      <BottomNav setMenuPage={setMenuPage}></BottomNav>
    </main>
  );
}
