import axios from "axios";

const serverName = process.env.REACT_APP_BACKEND_SERVER;

export const authMiddleWare = (history) => {
  const authToken = localStorage.getItem("AuthToken");
  if (authToken === null) {
    history.push("/login");
  }
};

export async function tokenData() {
  try {
    const response = await axios.get(`${serverName}/token`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function loginUser(userData) {
  try {
    const response = await axios.post(`${serverName}/login`, userData);
    localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
    // throw Error
  }
}

export async function updateUserPassword(data) {
  try {
    const response = await axios.post(`${serverName}/updatepassword`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function sendMail(data) {
  try {
    const response = await axios.post(`${serverName}/mail/sendmail`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function sendTerminationMail(data) {
  try {
    const response = await axios.post(
      `${serverName}/mail/terminationmail`,
      data
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getCurrentUser() {
  console.log("getUser");
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    const response = await axios.get(`${serverName}/user`);
    return response.data.userCredentials;
    // setCurrentUser({...response.data.userCredentials});
    // setUiLoading(false);
  } catch (error) {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("AuthToken");
    window.location.reload();
    console.log(error);

    throw error;
  }
}
