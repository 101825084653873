import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Construction from "../../../helpers/Construction";
import Copyright from "../../Brand/Copyright/Copyright";

export default function Calendar(props) {
  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="lg" className={props.classes.container}>
        <Grid justify="center" container spacing={3}>
          <Grid item xs={12}>
            <Construction></Construction>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
