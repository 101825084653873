import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { createFullDateString } from "../../../utils/dataUtils/appUtils";
import BeltAvatars from "../../Avatar/Belts/BeltAvatar";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row, standortLookup } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow hover onClick={() => setOpen(!open)} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="left">
          <BeltAvatars beltNumber={row.id} />
        </TableCell>
        <TableCell align="left">{row.members.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nr.</TableCell>
                    <TableCell align="left">Gürtelgrad</TableCell>
                    <TableCell align="left">Standort</TableCell>
                    <TableCell align="left">Nachname</TableCell>
                    <TableCell align="left">Vorname</TableCell>
                    <TableCell align="left">Aktiv seit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.members && row.members.length > 0 ? (
                    row.members.map((member, i) => (
                      <TableRow key={"member-" + i + 1}>
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">
                          {standortLookup && standortLookup[member.standortRef]}
                        </TableCell>
                        <TableCell align="left">{member.lastName}</TableCell>
                        <TableCell align="left">{member.firstName}</TableCell>
                        <TableCell align="left">
                          {createFullDateString(new Date(member.beginningDate))}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <Typography style={{ margin: 10 }} variant="h6">
                        Keine Mitglieder mit {row.title} vorhanden.
                      </Typography>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ViewAllCollapsibleTable({ data, standortLookup }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Gürtelgrad</TableCell>
            <TableCell />
            <TableCell align="left">Anzahl</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <Row key={i} row={row} standortLookup={standortLookup} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
