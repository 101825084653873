import { combineReducers } from "redux";

import appReducer from './appReducer';
import masterDataReducer from "./masterDataReducer";

const rootReducer = combineReducers({
    appReducer: appReducer,
    masterDataReducer: masterDataReducer,
});

export default rootReducer;