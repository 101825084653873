import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { Contacts, List, MenuBook } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#e3e3e3',
    paddingRight: '20vw',
  },
});

export default function SimpleBottomNavigation(props) {
  const classes = useStyles();
  const { setMenuPage } = props;
  const [value, setValue] = React.useState(1);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        setMenuPage(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction onClick={(event) => {setValue(0)}} label="Menü" icon={<MenuBook />} />
      <BottomNavigationAction onClick={(event) => {setValue(1)}} label="Anzeigen" icon={<List />} />
      <BottomNavigationAction onClick={(event) => {setValue(2)}} label="Einzelansicht" icon={<Contacts />} />
    </BottomNavigation>
  );
}