import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import appActions from "../../redux/actions/appActions";
import masterDataActions from "../../redux/actions/masterDataActions";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    backgroundColor: "white",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function SimpleMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  var history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickAway = () => {
    handleClose();
  };

  const handleLogout = () => {
    dispatch(appActions.logOutUser());
    dispatch(masterDataActions.clearState());
    history.push("/");
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          className={classes.menuButton}
        >
          Menü öffnen
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/dashboard/profile" style={{ color: "black" }}>
            <StyledMenuItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUser} />
              </ListItemIcon>
              <ListItemText primary="Profil" />
            </StyledMenuItem>
          </Link>
          <Link to="/dashboard/settings" style={{ color: "black" }}>
            <StyledMenuItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCog} spin />
              </ListItemIcon>
              <ListItemText primary="Einstellungen" />
            </StyledMenuItem>
          </Link>
          <Link onClick={handleLogout} style={{ color: "black" }}>
            <StyledMenuItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </ListItemIcon>
              <ListItemText primary="Beenden" />
            </StyledMenuItem>
          </Link>
        </StyledMenu>
      </div>
    </ClickAwayListener>
  );
}
