import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useSortableData from "../../../../hooks/useSortableData";
import {
  ArrowUpwardIcon,
  ArrowDownwardIcon,
  SearchIcon,
} from "@material-ui/data-grid";
import { ArrowBack } from "@material-ui/icons";
import { useTableSearch } from "../../../../hooks/useTableSearch";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import { contractMemberTypeList } from "./memberTypes";
import MemberTypeCard from "./MemberTypeCard";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 700,
  },
  tableHeadButton: {
    fontSize: "1.2rem",
  },
  tableRow: {
    "&$hover:hover": {
      cursor: "pointer",
    },
    "&$selected, &$selected:hover": {
      backgroundColor: "#304ffe",
    },
  },
  tableCell: {
    fontSize: "1.2rem",
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

export default function SelectContract() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialogData, dialogType, vertragsnehmerList } = useSelector(
    (state) => state.masterDataReducer
  );

  const [selectedVN, setSelectedVN] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [searchVal, setSearchVal] = React.useState(null);
  const { filteredData, loading } = useTableSearch({
    searchVal,
    inputData: vertragsnehmerList,
  });
  const { items, requestSort, sortConfig } = useSortableData(filteredData);

  let tableDataToRender = items;

  const getSortIcon = (name) => {
    if (!sortConfig) {
      return <ArrowBack />;
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === "ascending") {
        return <ArrowUpwardIcon />;
      } else {
        return <ArrowDownwardIcon />;
      }
    }
    return <ArrowBack />;
  };

  const handleSelectRow = (vn) => {
    setSelectedVN(vn);
    setSelectedRow(vn.id);
  };

  const handleContinue = () => {
    let newDialogData = {
      ...dialogData,
      selectedContract: selectedVN,
      contractData: selectedVN,
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_MITGLIED,
        newDialogData,
        "CONTRACT_SELECTED"
      )
    );
  };

  const handleFinish = (type) => {
    let newDialogData = {
      ...dialogData,
      memberType: type,
    };
    console.log("newDialogData :>> ", newDialogData);
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_MITGLIED,
        newDialogData,
        "CONTRACT_MEMBER_SELECTED"
      )
    );
  };

  const vertragsnehmerData = tableDataToRender.map((vn, i) => {
    return (
      <TableRow
        hover
        onClick={() => {
          handleSelectRow(vn);
        }}
        style={{ fontSize: "1.2rem" }}
        classes={{ hover: classes.hover, selected: classes.selected }}
        className={classes.tableRow}
        selected={vn.id === selectedRow}
        key={`${vn.lastName}--${i}`}
      >
        <TableCell className={classes.tableCell} align="left">
          {vn.title}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {vn.lastName}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {vn.firstName}
        </TableCell>
      </TableRow>
    );
  });

  let previewContractPersonData = (
    <div>
      <h3>Vertragsperson</h3>
      <table>
        <tr>
          <th>Anrede: </th>
          <td>{selectedVN && selectedVN.title}</td>
        </tr>
        <tr>
          <th>Nachname: </th>
          <td>{selectedVN && selectedVN.lastName}</td>
        </tr>
        <tr>
          <th>Vorname: </th>
          <td>{selectedVN && selectedVN.firstName}</td>
        </tr>
        <tr>
          <th>Geburtsdatum: </th>
          <td>
            {selectedVN && new Date(selectedVN.birthdate).toLocaleDateString()}
          </td>
        </tr>
      </table>
    </div>
  );

  if (dialogType === "CONTRACT_SELECTED") {
    return (
      <div style={{ padding: "50px" }}>
        <Grid container direction="row" justify="space-between" spacing={3}>
          {contractMemberTypeList &&
            contractMemberTypeList.map((memberType, i) => {
              return (
                <Grid
                  item
                  style={{ display: "flex" }}
                  justify="center"
                  key={i}
                  lg={4}
                  xs={12}
                >
                  <MemberTypeCard
                    memberType={memberType}
                    // onClick={() => handleSelectMemberType(memberType.type)}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ width: "100%", minHeight: "60px", fontSize: 20 }}
                      onClick={() => handleFinish(memberType.type)}
                    >
                      Auswählen
                    </Button>
                  </MemberTypeCard>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid container lg={12} xs={12}>
          <Grid item lg={6} xs={12}>
            <TableContainer
              style={{ overflow: "auto", height: "500px", fontSize: "1.2rem" }}
              component={Paper}
            >
              <TextField
                style={{ marginLeft: "24px" }}
                id="standard-basic"
                label="Suchen"
                onChange={(e) => setSearchVal(e.target.value)}
                placeholder="Suchen"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon></SearchIcon>
                    </InputAdornment>
                  ),
                }}
              />
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Button
                        className={classes.tableHeadButton}
                        onClick={() => requestSort("title")}
                        endIcon={getSortIcon("title")}
                      >
                        Anrede
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        className={classes.tableHeadButton}
                        onClick={() => requestSort("lastName")}
                        endIcon={getSortIcon("lastName")}
                      >
                        Nachname
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        className={classes.tableHeadButton}
                        onClick={() => requestSort("firstName")}
                        endIcon={getSortIcon("firstName")}
                      >
                        Vorname
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  <TableBody>{vertragsnehmerData}</TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            style={{ display: "flex", paddingLeft: "24px" }}
            item
            lg={6}
            xs={12}
          >
            <div
              style={{
                flex: "1 0 auto",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <Typography variant="h4">Ausgewählter Vertrag:</Typography>
                {previewContractPersonData}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={selectedVN === null}
                  style={{ width: "100%", minHeight: "60px", fontSize: 20 }}
                  onClick={handleContinue}
                >
                  Auswählen
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
