import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import dialogConstants from "../../../redux/constants/dialogConstants";
import masterDataActions from "../../../redux/actions/masterDataActions";
import HorizontalLinearStepper from "./Stepper";

export default function ImportVnDialog(props) {
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogConstants.IMPORT_VERTRAGSNEHMER;

  const handleClose = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={visible}
        disableBackdropClick={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <HorizontalLinearStepper></HorizontalLinearStepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
