import React from "react";
import { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";
import { MitgliederView } from "./MitgliederView";

import "./Mitglieder.css";
import { Button, FormGroup, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AffiliatedMembers from "./AffiliatedMembers";
import { useSelector } from "react-redux";
import ViewAllMembers from "./ViewAllMembers";
import { areArraysLoaded } from "../../../utils/dataUtils/appUtils";
import LoadingScreen from "../../Spinner/LoadingScreen";

const MitgliederContent = (props) => {
  const [memberView, setMemberView] = React.useState(null);
  const { mitgliederList, standorteList, vertragsnehmerList } = useSelector(
    (state) => state.masterDataReducer
  );

  let loaded = areArraysLoaded([
    mitgliederList,
    standorteList,
    vertragsnehmerList,
  ]);

  const handleSelectMemberView = (type) => {
    setMemberView(type);
  };

  const handleBack = () => {
    setMemberView(null);
  };

  useEffect(() => {
    document.title = `Mitglieder`;
  });

  console.log("loaded :>> ", loaded);

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="false" className={props.classes.container}>
        {!loaded && <LoadingScreen />}
        {!memberView ? (
          <div style={{ marginTop: "250px" }}>
            <Grid
              style={{ textAlign: "center" }}
              container
              justify="space-evenly"
              spacing={3}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: 350, fontSize: "1.5rem" }}
                  onClick={() => handleSelectMemberView("ALL")}
                  disabled={!loaded}
                >
                  Alle Mitglieder
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: 350, fontSize: "1.5rem" }}
                  onClick={() => handleSelectMemberView("OWN")}
                  disabled={!loaded}
                >
                  Eigene Mitglieder
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: 350, fontSize: "1.5rem" }}
                  onClick={() => handleSelectMemberView("AFFILIATED")}
                  disabled={!loaded}
                >
                  Angehörige Mitglieder
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid item>
              <FormGroup
                row
                style={{
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <Tooltip title="Zurück">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      minWidth: 220,
                      fontSize: "2em",
                    }}
                    onClick={handleBack}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 20 }}
                      size="lg"
                      icon={faArrowLeft}
                    />
                    Zurück
                  </Button>
                </Tooltip>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {memberView === "ALL" && <ViewAllMembers />}
              {memberView === "OWN" && (
                <Paper style={{ backgroundColor: "#bfbfbf" }}>
                  <MitgliederView
                    mitgliederList={mitgliederList}
                    standorteList={standorteList && standorteList}
                    vertragsnehmerList={
                      vertragsnehmerList && vertragsnehmerList
                    }
                  />
                </Paper>
              )}
              {memberView === "AFFILIATED" && <AffiliatedMembers />}
            </Grid>
          </Grid>
        )}
        <Box pt={4}></Box>
      </Container>
      <Copyright />
    </main>
  );
};

export default MitgliederContent;
