import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import pic from "../../../img/backgrounds/krater_vulkan.png";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit, PersonAdd, Print } from "@material-ui/icons";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import { AddMemberToAffStandort } from "./AddMemberToAffStandort";
import { Button, Grid } from "@material-ui/core";
import {
  handleTransferSite,
  sortArrayWithObjects,
} from "../../../utils/dataUtils/appUtils";
import { makeStandortlistPDFfromContent } from "../../../utils/dataUtils/pdfUtils";
import { getContractDataFromMember } from "../../../utils/dataUtils/masterDataUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const { site } = props;
  const { siteName, newImage } = site;
  const dispatch = useDispatch();
  const { mitgliederList, vertragsnehmerList } = useSelector(
    (state) => state.masterDataReducer
  );

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const openDelete = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.REMOVE_STANDORT,
        site,
        "REMOVE"
      )
    );
  };

  const handleAdd = () => {
    setShowDialog(true);
  };

  const openEdit = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_STANDORT,
        site,
        "EDIT"
      )
    );
  };

  const handlePrintList = () => {
    console.log("print list");
    let sortedMitglieder = sortArrayWithObjects(
      mitgliederList,
      "degree",
      "desc"
    );
    sortedMitglieder = sortedMitglieder.filter(
      (member) =>
        site.standortId === member.standortRef && member.status === "active"
    );
    makeStandortlistPDFfromContent(sortedMitglieder, siteName);
  };

  const clickTransferSite = () => {
    let sortedMitglieder = sortArrayWithObjects(
      mitgliederList,
      "degree",
      "desc"
    );
    sortedMitglieder = sortedMitglieder.filter(
      (member) =>
        site.standortId === member.standortRef && member.status === "active"
    );
    let contracts = sortedMitglieder.map((member) =>
      getContractDataFromMember(member.mitgliedId, vertragsnehmerList)
    );
    let transferData = {
      site: site,
      members: sortedMitglieder,
      contracts: contracts,
      newOwner: {
        id: "vojCwFCwNlQ09mrg8Jvsa4LPE6v2",
        username: "Adam-Michael-1664182611957",
      },
    };
    handleTransferSite(transferData)
      .then((values) => {
        console.log(values);
        dispatch(masterDataActions.getStandorteList());
        alert("Transfer erfolgreich!");
      })
      .catch((e) => {
        console.log("error :>> ", e);
        alert(
          "Transfer nicht erfolgreich.\nBitte erneut versuchen.\nBei Problemen bitte an den Admin wenden."
        );
      });
  };

  return (
    <Card className={classes.root}>
      {showDialog && (
        <AddMemberToAffStandort
          standort={site}
          newMember
          handleClose={handleCloseDialog}
          visible={true}
        />
      )}
      <CardHeader
        style={{ minHeight: 100 }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={`TWIN Taekwondo ${siteName}`}
        // subheader={siteName}
      />
      <CardMedia
        className={classes.media}
        image={newImage ? newImage.imageUrl : pic}
        title="Standort Image"
      />
      <CardContent>
        <div>
          <Typography variant="body1" color="textPrimary" component="p">
            Leitung: {site.siteOwner}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Trainer 1: {site.trainer1}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Trainer 2: {site.trainer2}
          </Typography>
        </div>
        <Grid alignContent="center" container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="default"
              startIcon={<Print />}
              onClick={handlePrintList}
            >
              Mitgliederliste drucken
            </Button>
          </Grid>
          {!process.env.NODE_ENV ||
            (process.env.NODE_ENV === "development" && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  disabled
                  onClick={clickTransferSite}
                >
                  Standort übertragen
                </Button>
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }} disableSpacing>
        <IconButton onClick={openEdit} aria-label="Standort bearbeiten">
          <Edit />
        </IconButton>
        <IconButton onClick={handleAdd} aria-label="Mitglied hinzufügen">
          <PersonAdd />
        </IconButton>
        <IconButton onClick={() => openDelete()} aria-label="Standort löschen">
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  );
}
