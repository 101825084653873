import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import StandortLeiterItem from "./StandortLeiterItem";
import { useSelector } from "react-redux";
import LoadingScreen from "../../Spinner/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 250,
  },
}));

export default function StandorteGrid() {
  const classes = useStyles();
  const { standortleiterList } = useSelector(
    (state) => state.masterDataReducer
  );

  return (
    <React.Fragment>
      {!Array.isArray(standortleiterList) && <LoadingScreen></LoadingScreen>}
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={8}>
            {standortleiterList &&
              standortleiterList.map((leader, i) => (
                <Grid key={i} item>
                  {/* <Paper className={classes.paper} > */}
                  <StandortLeiterItem leader={leader}></StandortLeiterItem>
                  {/* </Paper> */}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
