import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";

import { useEffect } from "react";
import EventsGrid from "./EventsGrid";
import {
  Button,
  FormGroup,
  lighten,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import { useDispatch } from "react-redux";
import RemoveEventDialog from "../../Dialogs/RemoveEventDialog";

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 130,
    minHeight: 45,
  },
}));

export default function EventsContent(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    document.title = `Veranstaltungen`;
  });

  const addSO = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_EVENT,
        undefined,
        "ADD"
      )
    );
  };

  return (
    <main className={props.classes.content}>
      <RemoveEventDialog></RemoveEventDialog>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="false" className={props.classes.container}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <FormGroup
              row
              style={{
                justifyContent: "flex-end",
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Tooltip title="Veranstaltung hinzufügen">
                <Button
                  variant="contained"
                  className={classes.button}
                  style={{
                    backgroundColor: "white",
                    minWidth: 220,
                    fontSize: "2em",
                  }}
                  onClick={addSO}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: 20 }}
                    size="lg"
                    icon={faStar}
                  />
                  NEUE VERANSTALTUNG ANLEGEN
                </Button>
              </Tooltip>
            </FormGroup>
          </Grid>
        </Grid>
        {/* {props.children} */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{ padding: 15 }}>
              <EventsGrid></EventsGrid>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
