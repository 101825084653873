import {
  faAddressCard,
  faChild,
  faMale,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const memberTypeList = [
  {
    icon: (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faMale} size="5x" />
        <FontAwesomeIcon
          style={{ marginLeft: 10, marginBottom: 26 }}
          icon={faPlus}
          size="2x"
        />
      </div>
    ),
    text: "Ein volljähriges Mitglied hinzufügen",
    info:
      "Hier können Sie ein Mitglied anlegen und für dieses Mitglied auch direkt weitere Vertragsinformationen wie Kontakt- oder Bankdaten eingeben. Wählen Sie diese Option, falls Ihr Mitglied bereits erwachsen bzw. volljährig ist und selbst den Vertrag unterzeichnet hat.",
    type: "adult",
  },
  {
    icon: (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faChild} size="3x" />
        <FontAwesomeIcon
          style={{ marginLeft: 10, marginBottom: 26 }}
          icon={faPlus}
          size="2x"
        />
      </div>
    ),
    text: "Ein Kind als Mitglied hinzufügen",
    info:
      "Möchten Sie ein Kind hinzufügen, so können Sie anschließend noch Vertragsdaten eingeben; falls Sie Kontakt- oder Bankdaten der Eltern oder Erziehungsberechtigten verwalten möchten.",
    type: "child",
  },
  {
    icon: (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faUserPlus} size="3x" />
        <FontAwesomeIcon
          style={{ marginLeft: 10, marginBottom: 5 }}
          icon={faAddressCard}
          size="3x"
        />
      </div>
    ),
    text: "Einem bestehenden Vertrag ein Mitglied hinzufügen",
    info:
      "Haben Sie zum Beispiel bereits einen Vertrag für ein Kind angelegt und es soll diesem Vertrag nun ein weiteres Kind zugeordnet werden, wählen Sie diese Option.",
    type: "addMemberToContract",
  },
];

export const contractMemberTypeList = [
  {
    icon: (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faMale} size="5x" />
        <FontAwesomeIcon
          style={{ marginLeft: 10, marginBottom: 26 }}
          icon={faPlus}
          size="2x"
        />
      </div>
    ),
    text: "Ein volljähriges Mitglied hinzufügen",
    info: "Das Mitglied wird als erwachsene Person hinzugefügt.",
    type: "adult",
  },
  {
    icon: (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faChild} size="3x" />
        <FontAwesomeIcon
          style={{ marginLeft: 10, marginBottom: 26 }}
          icon={faPlus}
          size="2x"
        />
      </div>
    ),
    text: "Ein Kind als Mitglied hinzufügen",
    info: "Das Mitglied wird als Kind hinzugefügt",
    type: "child",
  },
];
