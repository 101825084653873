import React, { useEffect } from "react";

// import

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";
import { useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import CreateSettingsFirst from "./CreateSettingsFirst";
import BankingMenu from "./BankingMenu";

export default function BankingContent(props) {
  const { currentUser } = useSelector((state) => state.appReducer);

  useEffect(() => {
    document.title = `Banking`;
  });

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="false" className={props.classes.container}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{ padding: 15 }}>
              {currentUser && currentUser.bankingSettings ? (
                <BankingMenu />
              ) : (
                // <SepaForm />
                <CreateSettingsFirst />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
