import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from 'date-fns/locale/de'

const localeMap = {
    de: deLocale
}


export default function DatePicker(props) {

    const { name, label, value, onChange } = props

    const [locale, setLocale] = React.useState("de");

    if (false) {setLocale("de")}


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]} >
            <KeyboardDatePicker disableToolbar variant="inline" inputVariant="outlined"
                label={label}
                format="dd.MM.yyyy"
                // format={"DD.MM.YYYY"}
                name={name}
                value={value}
                onChange={date =>onChange(convertToDefEventPara(name,date))}

            />
        </MuiPickersUtilsProvider>
    )
}
