import React from "react";
import ReactDOM from "react-dom";
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
import { Provider } from "react-redux";
// import { ReactReduxFirebaseProvider } from "react-redux-firebase";
// import { createFirestoreInstance } from "redux-firestore";

import App from "./App";
import store from "./redux/store";

import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { amber } from "@material-ui/core/colors";

// const firebaseConfig = {
//   apiKey: "AIzaSyCxinvQlzxQarYqks-Uo-Y6Zr9IeDxYfdc",
//   authDomain: "todo-app-tut-c2172.firebaseapp.com",
//   databaseURL: "https://todo-app-tut-c2172.firebaseio.com",
//   projectId: "todo-app-tut-c2172",
//   storageBucket: "todo-app-tut-c2172.appspot.com",
//   messagingSenderId: "111504934175",
//   appId: "1:111504934175:web:00c83c013beecdb897dc2a",
//   measurementId: "G-XYXQK4J6XF"
// };

// const rrfConfig = {
//   userProfile: "users",
//   useFirestoreForProfile: true,
//   profileParamsToPopulate: [
//     { child: 'role', root: 'roles'}
//   ]
// };

// firebase.initializeApp(firebaseConfig);
// firebase.firestore();

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance, //since we are using Firestore
// };

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#304ffe",
    },
    secondary: amber,
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
