import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const DialogSelectStatus = React.memo((props) => {
const DialogSelectStatus = (props) => {
  const { status, rowData, closeStatusDialog } = props;
  const classes = useStyles();
  const [newStatus, setNewStatus] = React.useState(status);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleClose = () => {
    setNewStatus(status);
    closeStatusDialog();
  };

  const handleNewStatus = () => {
    if (status !== newStatus) {
      let newRowData = {
        ...rowData,
        status: newStatus,
      };
      console.log("newRowData :>> ", newRowData);
      dispatch(masterDataActions.editMitglied(newRowData));
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={true}
        onClose={handleClose}
      >
        <DialogTitle>Status auswählen</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">Status:</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={newStatus}
                onChange={handleChange}
                input={<Input />}
              >
                <MenuItem value={"active"}>AKTIV</MenuItem>
                <MenuItem value={"frozen"}>EINGEFROREN</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleNewStatus} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogSelectStatus;
