/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox(props) {
    const { options, label, value, onChange, name, displayField } = props;
    return (
        <Autocomplete
            id="combo-box-demo"
            label={label}
            name={name}
            value={value}
            options={options}
            getOptionLabel={(option) => option[displayField]}
            style={{ width: 300 }}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
    );
}