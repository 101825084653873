import React from 'react';
import './BackgroundSlider.css';
 
export default function BackgroundSlider(props)
{
    // var backgrounds = props.children
 
    return (
        <div>
            <ul className="cb-slideshow">
                <li>
                    <span>Image 01</span>
                    <div>
                        
                    </div>
                </li>
                <li>
                    <span>Image 02</span>
                    <div>
                        
                    </div>
                </li>
                <li>
                    <span>Image 03</span>
                    <div>
                        
                    </div>
                </li>
                <li>
                    <span>Image 04</span>
                    <div>
                        
                    </div>
                </li>
                <li>
                    <span>Image 05</span>
                    <div>
                        
                    </div>
                </li>
                <li>
                    <span>Image 06</span>
                    <div>
                        
                    </div>
                </li>
            </ul>
        </div>
    )
}
 