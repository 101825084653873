import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Card } from "@material-ui/core";
import ImportPage from "./ImportPages/ImportPage";
import ImportContractsPage from "./ImportPages/ImportContractsPage";
import SelectStandort from "./ImportPages/SelectStandort";
import { useDispatch, useSelector } from "react-redux";
import CheckData from "./ImportPages/CheckData";
import ChooseImport from "./ImportPages/ChooseImport";
import ImportVertragsnehmer from "./ImportPages/ImportVertragsnehmer";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import Success from "./ImportPages/Success";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Standort auswählen",
    "Art des Imports auswählen",
    "Datei auswählen",
    "Daten überprüfen",
    "Importieren abschließen",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Wählen Sie einen Standort aus für welchen Sie Daten importieren möchten.";
    case 1:
      return "Wählen Sie eine Option aus, wie Sie Ihre Daten importieren möchten.";
    case 2:
      return "Wählen Sie eine CSV-Datei (UTF-8 Format) aus die Sie importieren möchten.";
    case 3:
      return "Prüfen Sie die Daten sorgfältig. Sind diese korrekt?";
    case 4:
      return "Bestätigen um Daten zu importieren.";
    default:
      return "Unknown step";
  }
}

export default function HorizontalLinearStepper(props) {
  const [disableNextStep, setDisableNextStep] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const steps = getSteps();
  const [showButtonRow, handleShowButtonRow] = React.useState(true);
  const [importCount, setImportCount] = React.useState(1);
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  let importPage = <ImportPage />;

  console.log("dialogType", dialogType);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  if (importCount > 1) {
    importPage = <ImportContractsPage />;
  }

  const tabs = [
    <SelectStandort></SelectStandort>,
    <ChooseImport></ChooseImport>,
    importPage,
    <CheckData></CheckData>,
    <ImportVertragsnehmer></ImportVertragsnehmer>,
  ];

  const handleBack = () => {
    switch (dialogType) {
      case "SELECT_OPTION":
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.IMPORT_VERTRAGSNEHMER,
            dialogData,
            "SELECT_STANDORT"
          )
        );
        break;
      case "IMPORT_DATA":
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.IMPORT_VERTRAGSNEHMER,
            dialogData,
            "SELECT_OPTION"
          )
        );
        break;
      case "CHECK_DATA":
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.IMPORT_VERTRAGSNEHMER,
            dialogData,
            "SELECT_OPTION"
          )
        );
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    switch (dialogType) {
      case "SELECT_STANDORT":
        console.log("select standort dispatched render importdialog");
        break;
      case "SELECT_OPTION":
        console.log("option selected, select type of import now");
        console.log("activeStep :>> ", activeStep);
        if (activeStep === 0) {
          handleNext();
        }
        break;
      case "IMPORT_DATA":
        console.log("dialogData :>> ", dialogData);
        console.log("import data dispatched render importdialog");
        if (activeStep === 1) {
          handleNext();
        }
        // setDisableNextStep(false);
        break;

      case "IMPORT_CONTRACT_DATA":
        console.log("IMPORT_CONTRACT_DATA now");
        if (importCount === 1) {
          console.log("set import count to 2");
          setImportCount(2);
        }
        break;
      case "CHECK_DATA":
        if (activeStep === 2) {
          setDisableNextStep(false);
          console.log("CHECK DATA in Stepper");
        }
        if (activeStep === 3) {
          console.log("Data is now shown as Table ");
        }
        if (activeStep === 4) {
          console.log("Data should now be imported ");
          handleShowButtonRow(false);
        }
        break;
      case "UPLOAD_DONE":
        console.log("upload done");
        if (activeStep === 4) {
          console.log("go to final page");
          handleNext();
        }
        break;
      default:
        break;
    }
    if (dialogData && dialogData.showButtonRow) {
      handleShowButtonRow(false);
    }
  }, [dialogType, dialogData, activeStep, importCount]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Alle Daten wurden erfolgreich importiert!
            </Typography>
            <Success response={dialogData && dialogData.response} />
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <Card style={{ paddingLeft: 20, paddingRight: 20 }}>
              {tabs[activeStep]}
            </Card>
            {props.children}
            {showButtonRow && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "25px",
                }}
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableNextStep}
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Beenden" : "Weiter"}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
