import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import dialogs from "../../redux/constants/dialogConstants";
import masterDataActions from "../../redux/actions/masterDataActions";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// import NewStandortleiterForm from './NewStandortleiterForm';

const useRemoveStyles = makeStyles((theme) => ({
  vnToDelete: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function RemoveStandortleiterDialog() {
  const classes = useRemoveStyles();
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogs.REMOVE_STANDORTLEITER;
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  const handleClose = () => {
    let alertData = {
      title: "Standortleiter erfolgreich gelöscht",
      text: "",
    };
    dispatch(masterDataActions.getStandortleiterList());
    dispatch(masterDataActions.closeMasterDataDialog());
    dispatch(
      masterDataActions.openMasterDataDialog(dialogs.ALERT, alertData, "ALERT")
    );
  };

  const handleAbort = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const handleDelete = () => {
    if (dialogData.standortleiterId) {
      try {
        dispatch(
          masterDataActions.removeStandortleiter(dialogData.standortleiterId)
        );
      } catch (error) {
        console.log(error);
      } finally {
        handleClose();
      }
    }
  };

  if (dialogData) {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={visible}
          onClose={handleClose}
          // disableBackdropClick={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Standortleiter entfernen
          </DialogTitle>
          <DialogContent>
            <Typography>Sind Sie sicher, dass Sie</Typography>
            <br></br>
            <div className={classes.vnToDelete}>
              <Typography>
                {dialogData.lastName + " " + dialogData.firstName}
              </Typography>
            </div>
            <br></br>
            <Typography>als Standortleiter entfernen möchten?</Typography>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Standortleiter löschen">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Löschen bestätigen
              </Button>
            </Tooltip>
            <Button onClick={handleAbort} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else return <div></div>;
}
