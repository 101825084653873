import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import { getGradCollection } from "../../../../utils/dataUtils/appUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const gradCollection = getGradCollection();

export default function DialogAddTesting(props) {
  const { selectedMembers, closeStatusDialog } = props;
  const { eventsList } = useSelector((state) => state.masterDataReducer);
  const [selectedTestingList, setSelectedTestingList] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [testing, setTesting] = React.useState(null);
  const dispatch = useDispatch();
  const handleChangeTesting = (event) => {
    setSelectedTestingList(event.target.value);
    if (event.target.value !== 0) {
      let tL = eventsList.find((e) => e.eventId === event.target.value);
      setTesting(tL);
    }
  };

  const handleAddTestingEvent = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_EVENT,
        "testing",
        "ADD"
      )
    );
  };

  const handleClose = () => {
    closeStatusDialog();
  };

  const handleAddMembersToTestingEvent = () => {
    setLoading(true);
    let tMs = [];
    let alertData = {
      title: "Mitglieder erfolgreich zur Prüfungsliste hinzugefügt.",
      text: (
        <Typography variant="body1">
          Es wurden <b>{selectedMembers.length} Mitglieder</b> zur Prüfungsliste{" "}
          <b>{testing.eventName}</b> hinzugefügt.
        </Typography>
      ),
    };
    selectedMembers.forEach((m) => {
      tMs.push(m.mitgliedId);
    });
    if ("hasAttendees" in testing) {
      tMs = tMs.filter(function (item) {
        return !testing.hasAttendees.includes(item);
      });
      testing.hasAttendees.push(...tMs);
    } else {
      testing.hasAttendees = [...tMs];
    }
    dispatch(masterDataActions.editEvent(testing));
    setTimeout(() => {
      setLoading(false);
      handleClose();
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogConstants.ALERT,
          alertData,
          "ALERT"
        )
      );
      dispatch(masterDataActions.getEventsList());
    }, 1000);
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        maxWidth="md"
        keepMounted
        onClose={handleClose}
        aria-labelledby="add-testing-dialog-title"
        aria-describedby="add-testing-dialog-description"
      >
        <DialogTitle id="add-testing-dialog-title">
          {"Mitglieder zur Prüfung aufstellen"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" id="add-testing-dialog-description">
            Wählen Sie bitte eine Prüfungsliste aus, zu der Sie die ausgewählten
            Mitglieder hinzufügen möchten. Alternativ können Sie auch eine neue
            Prüfungsliste erstellen und die Mitglieder zu dieser direkt
            hinzufügen.
          </Typography>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 100,
                marginBottom: 100,
              }}
            >
              <CircularProgress size={"100px"} />
            </div>
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl style={{ minWidth: 120, margin: 10 }}>
                  <InputLabel id="add-testing-dialog-select">
                    Prüfung
                  </InputLabel>
                  <Select
                    labelId="add-testing-dialog-select"
                    id="demo-dialog-select"
                    value={selectedTestingList}
                    onChange={handleChangeTesting}
                    input={<Input />}
                  >
                    <MenuItem value={0}>Prüfungsliste auswählen </MenuItem>
                    {eventsList &&
                      eventsList.map((e) => {
                        return (
                          e.eventType === "testing" && (
                            <MenuItem key={e.eventID} value={e.eventId}>
                              {e.eventName}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
                <div style={{ display: "flex", padding: 15, marginTop: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedTestingList !== 0}
                    onClick={handleAddTestingEvent}
                  >
                    Prüfungsliste erstellen
                  </Button>
                </div>
              </div>
              <div>
                <div style={{ paddingLeft: 10 }}>
                  <Typography variant="h6">Ausgewählte Prüfung:</Typography>
                  {selectedTestingList === 0 ? (
                    <Typography variant="body1">
                      Noch keine Prüfung ausgewählt
                    </Typography>
                  ) : (
                    <div>
                      <Typography variant="body1">
                        Name: {testing.eventName}
                      </Typography>
                      <Typography variant="body1">
                        Erstellt am:{" "}
                        {new Date(testing.createdAt).toLocaleString()} Uhr
                      </Typography>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: 25 }}>
                  <Typography variant="h6">Ausgewählte Mitglieder: </Typography>
                  <Typography variant="body2">
                    <ul>
                      {selectedMembers &&
                        selectedMembers.map((m) => {
                          return (
                            <li>
                              {gradCollection[m.degree - 1].title} {m.lastName}{" "}
                              {m.firstName}
                            </li>
                          );
                        })}
                    </ul>
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleAddMembersToTestingEvent}
            disabled={selectedTestingList === 0}
            variant="contained"
            color="primary"
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
