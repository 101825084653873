import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  createMailListString,
  createMobileNumbersString,
  createVCFfromArray,
  getContractDataFromMember,
  getYYYYMMDD,
} from "../../../utils/dataUtils/masterDataUtils";
import "./Mail.css";

export default function MailView() {
  const { vertragsnehmerList, mitgliederList, standorteList } = useSelector(
    (state) => state.masterDataReducer
  );
  const [selectedStandort, setSelectedStandort] = React.useState("");

  const handleChange = (event) => {
    setSelectedStandort(event.target.value);
  };

  function compare(a, b) {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  }

  const getAllEMailAdressesFromActiveMembers = () => {
    let mailList = [];
    console.log("mitgliederList :>> ", mitgliederList);
    console.log("vertragsnehmerList :>> ", vertragsnehmerList);
    if (vertragsnehmerList && mitgliederList) {
      mailList = mitgliederList.map((mitglied) => {
        if (mitglied.status !== "active") {
          return null;
        }
        let contract = getContractDataFromMember(
          mitglied.mitgliedId,
          vertragsnehmerList
        );
        // console.log("contract :>> ", contract);
        let mail = contract.email ? contract.email : null;
        return mail;
      });
    }

    mailList = mailList.filter(function (el) {
      return el !== null;
    });

    mailList = [...new Set(mailList)];
    mailList.sort();
    console.log("mailList :>> ", mailList);
    return mailList;
  };

  const downloadMailList = () => {
    let stringData = getAllEMailAdressesFromActiveMembers();
    stringData = createMailListString(stringData);
    let data = stringData.replace('encoding="null"', 'encoding="UTF-8"'); // Error in Chrome
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain;charset=UTF-8" }); // TRY IN CHROME
    // const file = new Blob([xmlDATA], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = "MailListe_AlleStandort_aktiv.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getAllEMailAdressesFromActiveMembersFromStandort = () => {
    let mailList = [];
    console.log("mitgliederList :>> ", mitgliederList);
    console.log("vertragsnehmerList :>> ", vertragsnehmerList);
    if (vertragsnehmerList && mitgliederList) {
      mailList = mitgliederList.map((mitglied) => {
        if (
          mitglied.status !== "active" ||
          mitglied.standortRef !== selectedStandort
        ) {
          return null;
        }
        let contract = getContractDataFromMember(
          mitglied.mitgliedId,
          vertragsnehmerList
        );
        // console.log("contract :>> ", contract);
        let mail = contract.email ? contract.email : null;
        return mail;
      });
    }

    mailList = mailList.filter(function (el) {
      return el !== null;
    });

    mailList = [...new Set(mailList)];
    mailList.sort();
    console.log("mailList :>> ", mailList);
    return mailList;
  };

  const downloadMailListStandort = () => {
    let stringData = getAllEMailAdressesFromActiveMembersFromStandort();
    stringData = createMailListString(stringData);
    let data = stringData.replace('encoding="null"', 'encoding="UTF-8"'); // Error in Chrome
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain;charset=UTF-8" }); // TRY IN CHROME
    // const file = new Blob([xmlDATA], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = `MailListe_${
      standorteList.find((s) => s.standortId === selectedStandort).siteName
    }_aktiv.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getAllMobileNumbersFromActiveMembers = () => {
    let mobileNrList = [];
    console.log("mitgliederList :>> ", mitgliederList);
    console.log("vertragsnehmerList :>> ", vertragsnehmerList);
    if (vertragsnehmerList && mitgliederList) {
      mobileNrList = mitgliederList.map((mitglied) => {
        let contract = getContractDataFromMember(
          mitglied.mitgliedId,
          vertragsnehmerList
        );

        // console.log("contract :>> ", contract);
        if (!contract.mobile) {
          return null;
        }

        let contact = {
          id: "TT " + mitglied.memberNumber.replace(/[0-9]/g, "") + " ",
          firstName: contract.firstName,
          lastName: contract.lastName,
          mail: contract.email ? contract.email : "",
          mobil: contract.mobile.replaceAll(" ", ""),
          birthdate: getYYYYMMDD(contract.birthdate),
        };
        return contact;
      });
    }

    mobileNrList = mobileNrList.filter(function (el) {
      return el !== null;
    });

    // Remove duplicates
    mobileNrList = mobileNrList.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.vorname === thing.vorname && t.mobil === thing.mobil
        )
    );

    mobileNrList.sort(compare);
    console.log("mobileNrList :>> ", mobileNrList);
    return mobileNrList;
  };

  const downloadMobileNumbers = () => {
    let stringData = getAllMobileNumbersFromActiveMembers();
    stringData = createMobileNumbersString(stringData);
    let data = stringData.replace('encoding="null"', 'encoding="UTF-8"'); // Error in Chrome
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain;charset=UTF-8" }); // TRY IN CHROME
    // const file = new Blob([xmlDATA], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = "Handynummern_Vertragsnehmer_aktiv.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getContactCardsFromActiveMembers = () => {
    let contacts = getAllMobileNumbersFromActiveMembers();
    let vCards = createVCFfromArray(contacts);
    console.log("vCards :>> ", vCards);
    return vCards;
  };

  const downloadContactCards = () => {
    let stringData = getContactCardsFromActiveMembers();
    let data = stringData.replace('encoding="null"', 'encoding="UTF-8"'); // Error in Chrome
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain;charset=UTF-8" }); // TRY IN CHROME
    // const file = new Blob([xmlDATA], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = "Kontaktdaten_Vertragsnehmer_aktiv.vcf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  while (!mitgliederList || !vertragsnehmerList || !standorteList) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Paper style={{ padding: 15 }}>
        <Typography align="center" className="Construction-header" variant="h3">
          Kontaktdaten
        </Typography>
        <Grid style={{ marginTop: 25 }} container justify="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              E-Mail Adressen aller Standorte exportieren:
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={downloadMailList}
            >
              Download E-Mail Adressen
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              E-Mail Adressen für einen Standort exportieren:
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={downloadMailListStandort}
              disabled={!selectedStandort}
            >
              Download E-Mail Adressen
            </Button>
            <FormControl
              variant="outlined"
              style={{ minWidth: 120, marginLeft: 20 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Standort
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedStandort}
                onChange={handleChange}
                label="Standort"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {standorteList.map((standort) => {
                  if (standort.standortType === "AFFILIATED") {
                    return null;
                  }
                  return (
                    <MenuItem value={standort.standortId}>
                      {standort.siteName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              Kontaktdaten Mobil aller Standorte exportieren:
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={downloadMobileNumbers}
            >
              Download Handynummern
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              Kontaktkarten (für Import auf Smartphone) aller Standorte
              exportieren:
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={downloadContactCards}
            >
              Download Kontaktkarten
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
