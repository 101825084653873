import { Button, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import { getDateFromString } from "../../../../utils/dataUtils/appUtils";
import {
  assignMembersToContracts,
  checkDegree,
  prepareMemberData,
  resolveAfterContractsImport,
  resolveAfterImport,
} from "../../../../utils/dataUtils/importUtils";
import { getStandortContributionFromId } from "../../../../utils/dataUtils/masterDataUtils";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "25%",
    left: "45%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ImportVertragsnehmer() {
  const [loading, toggleLoading] = React.useState(false);
  const { dialogData, standorteList } = useSelector(
    (state) => state.masterDataReducer
  );
  const classes = useStyles();
  console.log("dialogData :>> ", dialogData);
  const { selectedOption, selectedStandort } = dialogData;
  const dispatch = useDispatch();

  const uploadVertragsnehmer = async () => {
    toggleLoading(true);
    let newMemberContribution = getStandortContributionFromId(
      selectedStandort.standortId,
      standorteList
    );
    const { memberIdentifier, latestMemberNumber } = selectedStandort;
    let importListMembers = [];
    let importListContractData = [];
    if (dialogData.importedData.length > 0) {
      dialogData.importedData.forEach((m, i) => {
        let title = m.title ? m.title : "Herr";
        let degree = checkDegree(m.degree);
        let memberNumber = m.memberNumber
          ? m.memberNumber
          : `${memberIdentifier}${parseInt(latestMemberNumber) + i}`;
        let contractDate = m.contractDate
          ? getDateFromString(m.contractDate)
          : new Date();
        let contractDuration = m.contractDuration
          ? parseInt(m.contractDuration)
          : selectedStandort.contractDuration;
        let contribution = m.contribution
          ? m.contribution
          : newMemberContribution;

        let VNtitle = m.VNtitle ? m.VNtitle : title;
        let VNlastName = m.VNlastName ? m.VNlastName : m.lastName;
        let VNfirstName = m.VNfirstName ? m.VNfirstName : m.firstName;
        let VNbirthdate = m.VNbirthdate ? m.VNbirthdate : m.birthdate;
        let ownerName = m.owner ? m.owner : VNlastName + " " + VNfirstName;
        let memberType = selectedOption === "1" ? "adult" : "child";

        // Here goes member data only!
        importListMembers.push({
          title: title,
          lastName: m.lastName,
          firstName: m.firstName,
          birthdate: getDateFromString(m.birthdate),
          memberNumber: memberNumber,
          contractDate: contractDate,
          contractDuration: contractDuration,
          contribution: parseInt(contribution),
          degree: degree,
          status: "active",
          memberType: memberType,
          standortRef: selectedStandort.standortId,
        });

        // Here goes contract data only!
        importListContractData.push({
          // If there is a parent / contractpartner
          title: VNtitle,
          lastName: VNlastName,
          firstName: VNfirstName,
          birthdate: getDateFromString(VNbirthdate),
          // Contact Data
          street: m.street,
          zip: m.zip,
          city: m.city,
          phoneNumber: m.phoneNumber,
          mobile: m.mobile,
          email: m.email,
          // Bank Data
          isOwner: true,
          owner: ownerName,
          bank: m.bank,
          iban: m.iban,
          bic: m.bic,
        });
      });
    }
    let response;

    switch (selectedOption) {
      // TODO: Control and Check import
      case "1":
        // Import Member and create Contract for Each Member
        console.log("Import Member and create Contract for each Member");
        try {
          response = await resolveAfterImport(
            importListMembers,
            importListContractData
          );
        } catch (err) {
          alert(err);
        }
        break;
      case "2":
        // Import Members as Children
        console.log("Import Children and create Contract for each Child");
        try {
          response = await resolveAfterImport(
            importListMembers,
            importListContractData
          );
        } catch (err) {
          alert(err);
        }
        break;
      case "3":
        // Import Members and Contractdata seperately
        console.log("Import Member and add Member to corresponding Contract");
        let preparedMemberList = prepareMemberData(
          dialogData.importedData,
          dialogData.selectedStandort
        );

        let assignedArray = assignMembersToContracts(
          preparedMemberList,
          dialogData.importedContractData
        );
        try {
          response = await resolveAfterContractsImport(assignedArray);
        } catch (error) {
          console.log(error);
        }
        break;
      default:
        break;
    }
    console.log("After switch ");
    console.log("response :>> ", response);
    let newDialogData = {
      ...dialogData,
      response: response,
    };
    dispatch(masterDataActions.getMitgliederList());
    dispatch(masterDataActions.getVertragsnehmerList());
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.IMPORT_VERTRAGSNEHMER,
        newDialogData,
        "UPLOAD_DONE"
      )
    );
    toggleLoading(false);
  };

  // TODO: Gürtel unten als Ladebalken? 10. Kup bis 1. Dan?

  return (
    <div>
      <Grid
        style={{ padding: "50px" }}
        container
        justify="center"
        direction="column"
      >
        <Grid
          style={{ marginLeft: "auto", marginRight: "auto" }}
          item
          lg={8}
          xs={12}
        >
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <Button
                style={{
                  fontSize: "200%",
                  minHeight: "60px",
                  padding: "25px 50px",
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={uploadVertragsnehmer}
              >
                {loading ? "Daten werden importiert..." : "Daten importieren"}
              </Button>
              {loading && (
                <CircularProgress
                  size={72}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
