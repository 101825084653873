import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../controls/useForm";
import PaymentIcon from "@material-ui/icons/Payment";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  createXMLSepaData,
  getActiveMembersNumberFromMembers,
  getBankingInformationWithFee,
  getMitgliederListFromIdsCompareStandorte,
} from "../../../utils/dataUtils/masterDataUtils";
import { useTableSearch } from "../../../hooks/useTableSearch";
import useSortableData from "../../../hooks/useSortableData";
import { ArrowBack } from "@material-ui/icons";
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  SearchIcon,
} from "@material-ui/data-grid";
import { addDays } from "date-fns";
import { timeStampSepa } from "../../../utils/dataUtils/appUtils";
import {
  bezahltBisArray,
  getCurrentMonthPayedTillValue,
  getDateFromPayedTillString,
} from "../../Dialogs/NewMitglied/DialogPages/inputFields";
import masterDataActions from "../../../redux/actions/masterDataActions";
import masterDataApi from "../../../redux/api/masterDataApi";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 700,
  },
  tableHeadButton: {
    fontSize: "1.2rem",
  },
  tableRow: {
    "&$hover:hover": {
      cursor: "pointer",
    },
    "&$selected, &$selected:hover": {
      backgroundColor: "#304ffe",
    },
  },
  tableCell: {
    fontSize: "1.2rem",
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

export default function NewSepaForm({ selectedAccount, selectedStandorte }) {
  const [showData, setShowData] = useState(false);
  const [sepaData, setSepaData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [loading, setLoading] = useState(false);
  const { vertragsnehmerList, mitgliederList } = useSelector(
    (state) => state.masterDataReducer
  );
  const dispatch = useDispatch();

  const handleShowData = () => {
    setShowData(true);
  };

  let newColDate = addDays(new Date(), 2).toLocaleDateString("de", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const { register, handleSubmit, getValues, watch, control, errors } = useForm(
    {
      defaultValues: {
        ...selectedAccount,
        collectionDate: newColDate,
        bookMonth: getCurrentMonthPayedTillValue(),
      },
    }
  );
  const bookSocietyFee = watch("bookSocietyFee");
  const watchBookMonth = watch("bookMonth");

  const createSepaData = (reset) => {
    let totalContributionSum = 0;
    let sepaInfo = [],
      updateMembersInfo = [];
    let siteIdArray = selectedStandorte.map((site) => site.standortId);
    // Bei IBAN Fehler stürzt Front End Build im developed mode nicht ab
    let memberCount = getActiveMembersNumberFromMembers(mitgliederList);

    const fee = getValues("societyFee");
    let bookMonth = getValues("bookMonth");
    bookMonth = getDateFromPayedTillString(bookMonth);
    if (vertragsnehmerList && mitgliederList) {
      vertragsnehmerList.forEach((contract, i) => {
        if (contract.iban && contract.iban.length < 21) {
          return;
        }
        let memberIds = contract.hasMembers;
        let contractMemberList = getMitgliederListFromIdsCompareStandorte(
          memberIds,
          mitgliederList,
          siteIdArray
        );
        let bankingInfo;
        if (bookSocietyFee) {
          if (fee > 0) {
            bankingInfo = getBankingInformationWithFee(
              contract,
              contractMemberList,
              bookMonth,
              false,
              fee,
              true // bool to use only fee as amount for collection
            );
          }
        } else if (reset) {
          // If user wants to reset Collection use function here
          bankingInfo = getBankingInformationWithFee(
            contract,
            contractMemberList,
            bookMonth,
            true,
            0
          );
        } else {
          let f = fee ? fee : 0;
          bankingInfo = getBankingInformationWithFee(
            contract,
            contractMemberList,
            bookMonth,
            false,
            f
          );
        }
        // Check if bankingInfo was passed and push info to update member
        if (bankingInfo && bankingInfo[1]) {
          updateMembersInfo.push(...bankingInfo[1]);
        }
        if (!bankingInfo || !bankingInfo[0]) {
          return;
        }
        if (!bankingInfo[0].iban) {
          return;
        }
        sepaInfo.push(bankingInfo[0]);
        totalContributionSum += bankingInfo[0].amount;
      });
    }
    // Set total sum to show over table
    setTotalSum(totalContributionSum);

    // Set SepaData
    // 1. sepaInfo for all the information concerning the collection of the member contributions
    // 2. updateMembersInfo for information about updating the members whose contribution has already been payed or needs to be payed over more than 1 month
    setSepaData({
      sepaInfo: sepaInfo,
      updateMembersInfo: updateMembersInfo,
      totalSum: new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(totalContributionSum),
      activeMembers: memberCount,
      bookMonth: watchBookMonth,
      bookSocietyFee: bookSocietyFee ? true : false,
      standorte: selectedStandorte.map((s) => s.siteName),
    });

    if (reset) {
      // If the collection should be reseted update all members with the selected booking selection
      var r = window.confirm(
        "Der Einzug wird für alle Mitglieder auf den von Ihnen ausgewählten Monat zurückgesetzt.\n\nWollen Sie wirklich fortfahren?"
      );
      if (r === true) {
        // Create file and update members
        setLoading(true);
        masterDataApi
          .editManyMitglieder(updateMembersInfo)
          .then((r) => {
            console.log("r :>> ", r);
            setSepaData([]);
            setShowData(false);
            setTotalSum(0);
            dispatch(masterDataActions.getMitgliederList());
            dispatch(masterDataActions.getVertragsnehmerList());
            alert("Mitglieder wurden erfolgreich aktualisiert!");
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          })
          .catch((e) => {
            console.log("error :>> ", e);
            alert(
              "Es gab ein Problem beim aktualisieren der Mitglieder, bitte versuchen Sie es erneut!"
            );
          });
      } else {
        // Abort
        return null;
      }
    }
  };

  const downloadTxtFile = (xmlDATA) => {
    let data = xmlDATA.replace('encoding="null"', 'encoding="UTF-8"'); // Error in Chrome generating Sepa Data
    let dateString = timeStampSepa();
    let fileName = "sepaImport_" + dateString + ".xml";
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/xml;charset=UTF-8" }); // TRY IN CHROME
    // const file = new Blob([xmlDATA], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const createSepaTable = () => {
    // Set state to show table
    // createSepaData
    createSepaData();
    handleShowData();
  };

  const onSubmit = (formData) => {
    var r = window.confirm(
      "Der Einzug wird erstellt. Für Mitglieder deren Abbuchung mehrere Monate beinhaltet wird die Abbuchung auf den normalen Beitrag zurückgesetzt.\n\nWollen Sie wirklich fortfahren?"
    );

    if (r === true) {
      // Create file and update members
      setLoading(true);
      var data = getValues();
      let finalData = createXMLSepaData(data, sepaData.sepaInfo);
      downloadTxtFile(finalData);
      masterDataApi
        .addBuchung(sepaData)
        .then((r) => {
          console.log("r :>> ", r);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      masterDataApi
        .editManyMitglieder(sepaData.updateMembersInfo)
        .then((r) => {
          console.log("r :>> ", r);
          setSepaData([]);
          setShowData(false);
          setTotalSum(0);
          dispatch(masterDataActions.getMitgliederList());
          dispatch(masterDataActions.getVertragsnehmerList());
          alert("Mitglieder wurden erfolgreich aktualisiert!");
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((e) => {
          console.log("error :>> ", e);
          alert(
            "Es gab ein Problem beim aktualisieren der Mitglieder, bitte versuchen Sie es erneut!"
          );
        });
    } else {
      // Abort
      return null;
    }
  };

  if (loading) {
    return (
      <CircularProgress
        style={{ marginLeft: "50px", marginTop: "50px" }}
        size={150}
      />
    );
  } else
    return (
      <div>
        <div style={{ marginBottom: "15px" }}>
          <Typography variant="h4">
            Geben Sie die Daten für die Sepa Lastschrift an: <br />
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom>
          Mit Hilfe dieser Seite, können Sie eine SEPA-Datei im XML-Format
          erzeugen. Diese Datei können Sie anschließend in Ihr Online-Banking
          importieren und als Sammellastschrift ausführen.
        </Typography>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            style={{
              fontWeight: "bold",
              marginTop: "25px",
              paddingTop: "10px",
              paddingBottom: "0px",
              borderTopStyle: "solid",
              borderWidth: "2px",
              borderColor: "lightgrey",
            }}
            variant="h6"
            gutterBottom
          >
            Ihre Lastschrift-Einstellungen:
          </Typography>
          <Typography variant="body1" gutterBottom>
            Ihre Kontodaten und Banking-Einstellungen können Sie unter{" "}
            <b>Banking Einstellungen</b> in den Einstellungen ändern.
          </Typography>
          <Grid container>
            <Grid item lg={5} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                disabled
                id="creditorName"
                label="Ihr Name"
                name="creditorName"
                autoComplete="creditorName"
                autoFocus
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                // disabled
                id="collectionDate"
                label="Fälligkeitsdatum"
                name="collectionDate"
                autoComplete="collectionDate"
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                disabled
                id="creditorIBAN"
                label="Ihre IBAN"
                name="creditorIBAN"
                autoComplete="creditorIBAN"
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                disabled
                id="creditorBIC"
                label="Ihre BIC"
                name="creditorBIC"
                autoComplete="creditorBIC"
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                disabled
                id="creditorId"
                label="Gläubiger ID"
                name="creditorId"
                autoComplete="creditorId"
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <FormGroup row>
                <div style={{ marginTop: 20 }}>
                  <label
                    style={{
                      marginLeft: 15,
                      marginTop: "5px",
                      // fontWeight: 500,
                      fontSize: "1.0rem",
                    }}
                  >
                    Marke &amp; Beitrag
                  </label>
                  <Controller
                    name="bookSocietyFee"
                    control={control}
                    render={(props) => (
                      <Switch
                        onChange={(e) => props.onChange(e.target.checked)}
                        checked={props.value}
                      />
                    )}
                  />
                  <label
                    style={{
                      marginLeft: 15,
                      marginTop: "5px",
                      // fontWeight: 500,
                      fontSize: "1.0rem",
                    }}
                  >
                    Nur Marke
                  </label>
                </div>
                <TextField
                  variant="outlined"
                  style={{ maxWidth: "250px", marginLeft: 20 }}
                  margin="normal"
                  inputRef={register}
                  // disabled={!bookSocietyFee}
                  id="societyFee"
                  name="societyFee"
                  label="Jährliche Verbandspassmarke"
                />
              </FormGroup>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  style={{
                    width: 400,
                    marginTop: 0,
                  }}
                  error={errors.bookMonth}
                >
                  <InputLabel htmlFor={`bookMonth-select`}>
                    Abbuchen für: *
                  </InputLabel>
                  <Controller
                    control={control}
                    name={"bookMonth"}
                    rules={{ required: true }}
                    as={
                      <Select
                        label="Abbuchen für:  *"
                        labelId={`${"bookMonth"}-select`}
                        id={`${"bookMonth"}-select`}
                      >
                        {bezahltBisArray.map((select) => (
                          <MenuItem key={select.id} value={select.value}>
                            {select.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  {errors.bookMonth && (
                    <FormHelperText>{"Bitte auswählen"}</FormHelperText>
                  )}
                  {!watchBookMonth && (
                    <FormHelperText
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      error
                    >
                      {"Bitte zuerst eine Abbuchung auswählen."}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex" }} justify="center" xs={6}>
              <Tooltip title="Sepa-Datei anzeigen">
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!watchBookMonth}
                  onClick={() => createSepaTable()}
                  style={{
                    backgroundColor: "#ffcc36",
                    margin: 8,
                    minWidth: 260,
                    minHeight: 45,
                    fontSize: "120%",
                  }}
                  // style={{backgroundColor:"#ffcc36", minWidth: 200}}
                  startIcon={<DescriptionIcon style={{ fontSize: 40 }} />}
                >
                  Sepa-Daten anzeigen
                </Button>
              </Tooltip>
            </Grid>
            <Grid item style={{ display: "flex" }} justify="center" xs={6}>
              <Tooltip title="Sepa-Datei erzeugen">
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={!showData}
                  style={{
                    backgroundColor: "#ffcc36",
                    margin: 8,
                    minWidth: 260,
                    minHeight: 45,
                    fontSize: "120%",
                  }}
                  // style={{backgroundColor:"#ffcc36", minWidth: 200}}
                  startIcon={<PaymentIcon style={{ fontSize: 40 }} />}
                >
                  Sepa-Datei erzeugen
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Form>
        <Grid container>
          <Grid style={{ margin: 20 }} item xs={12}>
            <Typography variant="h5">Buchung zurücksetzen:</Typography>
            <Typography variant="h6">
              Wählen Sie zuerst einen Monat aus, auf den der Einzug
              zurückgesetzt werden soll. Lassen Sie sich anschließend die Daten
              anzeigen und setzen über den roten Button den Einzug auf den
              gewählten Monat zurück.
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Tooltip title="Einzug zurücksetzen">
                <Button
                  variant="contained"
                  disabled={!showData || bookSocietyFee}
                  onClick={() => createSepaData(true)}
                  style={{
                    backgroundColor: "#db0000",
                    margin: 8,
                    minWidth: 300,
                    minHeight: 45,
                    fontSize: "120%",
                  }}
                  // startIcon={<DescriptionIcon style={{ fontSize: 40 }} />}
                >
                  Einzug zurücksetzen
                </Button>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                fontWeight: "bold",
                marginTop: "25px",
                paddingTop: "10px",
                paddingBottom: "0px",
                borderTopStyle: "solid",
                borderWidth: "2px",
                borderColor: "lightgrey",
              }}
              variant="h6"
              gutterBottom
            >
              Abbuchungsdaten:
            </Typography>
          </Grid>
          {showData && (
            <Grid item xs={12}>
              {sepaData.sepaInfo.length ? (
                <SepaTable sepaList={sepaData.sepaInfo} totalSum={totalSum} />
              ) : (
                <CircularProgress
                  style={{ marginLeft: "50px", marginTop: "50px" }}
                  size={150}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
}

function SepaTable(props) {
  const classes = useStyles();
  const { sepaList, totalSum } = props;
  const [defaultList, setDefaultList] = React.useState(true);
  const { register, setValue, watch } = useForm();

  const searchVal = watch("searchVal");
  const { filteredData } = useTableSearch({
    searchVal,
    inputData: sepaList,
  });
  const { items, requestSort, sortConfig } = useSortableData(filteredData);

  let tableDataToRender = items;

  if (searchVal && defaultList) {
    setDefaultList(false);
    tableDataToRender = filteredData;
  }

  const resetList = () => {
    setValue("searchVal", null);
    setDefaultList(true);
  };

  const getSortIcon = (name) => {
    if (!sortConfig || defaultList) {
      return <ArrowBack />;
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === "ascending") {
        return <ArrowUpwardIcon />;
      } else {
        return <ArrowDownwardIcon />;
      }
    }
    return <ArrowBack />;
  };

  const renderPreviewSepaTable = (list) => {
    return list.map((debtor, i) => {
      if (debtor.mandateSignatureDate === false) {
        alert(
          `Bei folgendem Vertrag fehlt das Vertragsdatum: \nInhaber: ${debtor.debtorName}\nReferenz: ${debtor.remittanceInfo}`
        );
      }
      // if (debtor.collect) {
      return (
        <TableRow hover key={"SEPA-Table-Row-" + i}>
          <TableCell>{i + 1}</TableCell>
          <TableCell component="th" scope="row">
            {debtor.debtorName}
          </TableCell>
          <TableCell align="left">{debtor.iban}</TableCell>
          <TableCell align="left">{debtor.bic}</TableCell>
          <TableCell align="left">{debtor.amount + " €"}</TableCell>
          <TableCell align="left">
            {/* {debtor.mandateSignatureDate && debtor.mandateSignatureDate} */}
          </TableCell>
          <TableCell align="left">{debtor.remittanceInfo}</TableCell>
          <TableCell align="right">{debtor.mandateId}</TableCell>
        </TableRow>
      );
      // } else {
      //   return null;
      // }
    });
  };

  return (
    <TableContainer component={Paper}>
      <TableRow
        style={{ backgroundColor: "rgba(0, 0, 0, 0.12)" }}
        hover
        key={"SEPA-Table-Row-" + sepaList.length + 1}
      >
        <TableCell component="th" scope="row"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell style={{ fontSize: "1.2rem" }} align="left">
          Gesamtsumme:{" "}
        </TableCell>
        <TableCell style={{ fontSize: "1.2rem" }} align="left">
          {new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(totalSum)}
        </TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
      <TextField
        style={{ marginLeft: "24px", maxWidth: "400px" }}
        label="Suchen"
        id="searchVal"
        name="searchVal"
        inputRef={register}
        // value={searchVal}
        // onChange={(e) => setSearchVal(e.target.value)}
        placeholder="Suchen"
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
        }}
      />
      <Button style={{ margin: 15 }} onClick={resetList} variant="outlined">
        Zurücksetzen
      </Button>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* Zahlungspflichtiger;IBAN;BIC;BETRAG;WÄHRUNG;ZWECK; REFERENZ;GLÄUBIGERID;SIGDATUM;FÄLLIGDATUM */}
            <TableCell align="left">NR:</TableCell>
            <TableCell>
              <Button
                className={classes.tableHeadButton}
                onClick={() => {
                  setDefaultList(false);
                  requestSort("debtorName");
                }}
                endIcon={getSortIcon("debtorName")}
              >
                Zahlungspflichtiger
              </Button>
            </TableCell>
            <TableCell align="left">IBAN</TableCell>
            <TableCell align="left">BIC</TableCell>
            <TableCell align="left">BETRAG</TableCell>
            <TableCell align="left">SIGNATURDATUM</TableCell>
            <TableCell align="left">VERWENDUNGSZWECK</TableCell>
            <TableCell align="right">REFERENZ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defaultList
            ? renderPreviewSepaTable(sepaList)
            : renderPreviewSepaTable(tableDataToRender)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
