import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogs from "../../../redux/constants/dialogConstants";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "../../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";

import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { PersonAdd, AccountBox } from "@material-ui/icons";
import LoadingScreen from "../../Spinner/LoadingScreen";
import DialogSelectStatus from "./Dialogs/DialogSelectStatus";
import {
  filterFirstLetter,
  getContractDataFromMember,
  showBelts,
  showDegree,
} from "../../../utils/dataUtils/masterDataUtils";
import AlphabetSelect from "../../../helpers/AlphabetSelect";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  membersSelectSortArray,
  sortArrayWithObjects,
  filterMemberStatusList,
} from "../../../utils/dataUtils/appUtils";
import { ArrowDownwardIcon, ArrowUpwardIcon } from "@material-ui/data-grid";
import DialogAddTesting from "./Dialogs/DialogAddTesting";
import LoadingDialog from "../../Dialogs/LoadingDialog";
import DialogTerminate from "./Dialogs/DialogTerminate";

const animatedComponents = makeAnimated();

const defaultSelectSortFieldList = membersSelectSortArray;

const INITIAL_SORT = { direction: "desc", field: "degree" };

const useToolbarStyles = makeStyles((theme) => ({
  tableToolBar: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 130,
    minHeight: 45,
  },
}));

// Wanna have grey for background? background-color: #bfbfbf;

export const MitgliederView = (props) => {
  const { mitgliederList, standorteList, vertragsnehmerList } = props;
  const { masterDataResponse } = useSelector(
    (state) => state.masterDataReducer
  );

  const classes = useToolbarStyles();
  const initList = useRef(false);
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedSites, setSelectedSites] = React.useState(null);
  const [currentSort, setCurrentSort] = React.useState(INITIAL_SORT);
  const [tableMemberList, setTableMemberList] = React.useState([
    ...mitgliederList,
  ]);
  const [loading, setLoading] = React.useState(true);
  const [openStatusDialog, setOpenStatusDialog] = React.useState(false);
  const [statusDialogData, setStatusDialogData] = React.useState(null);
  var defaultColumns = [];
  let standortLookup = {};
  let standorteSelectList = [];
  let tableLayout = {};
  const [state, setState] = React.useState({
    checkedA: false,
    checkedActiveMembers: true,
    checkedTerminatedMembers: false,
    checkedFrozenMembers: false,
    memberTypeLoading: false,
  });

  const handleClickOpenStatusDialog = (e, rowData, status) => {
    setOpenStatusDialog(status);
    setStatusDialogData(rowData);
  };

  const closeStatusDialog = () => {
    setOpenStatusDialog(false);
  };

  const toggleLoadingButtonMemberType = (l) => {
    setState((prevState) => ({ ...prevState, memberTypeLoading: l }));
  };

  const sortAndFilterListForTable = useCallback(
    (list, newState) => {
      let newList = [...list];
      let sites;
      let s = newState ? newState : state;
      let sortOption = currentSort ? currentSort : INITIAL_SORT;
      if (selectedSites) {
        sites = selectedSites.map((s) => s.value);
        newList = newList.filter((member) =>
          sites.includes(member.standortRef)
        );
      }
      newList = filterMemberStatusList(newList, s);
      newList = sortArrayWithObjects(
        newList,
        sortOption.field,
        sortOption.direction
      );
      setTableMemberList(newList);
    },
    [currentSort, state, selectedSites]
  );

  useEffect(() => {
    console.log("component MitgliederView LOADED!");
    sortAndFilterListForTable(mitgliederList);
    setLoading(false);
  }, [setLoading, sortAndFilterListForTable, mitgliederList]);

  const onSelectionChange = (rows) => {
    setSelectedRows(rows);
  };

  const handleSelectStandort = (e) => {
    let sites;
    let newList = [...mitgliederList];
    if (e) {
      setSelectedSites(e);
      sites = e.map((s) => s.value);
      newList = newList.filter((member) => sites.includes(member.standortRef));
      sortAndFilterListForTable(newList);
      console.log("newList :>> ", newList);
    } else {
      setSelectedSites(null);
      sortAndFilterListForTable(newList);
    }
  };

  const handleChange = (event) => {
    let newState = { ...state, [event.target.name]: event.target.checked };
    let newTableList = [...mitgliederList];
    setState(newState);
    sortAndFilterListForTable(newTableList, newState);
  };

  const getFirstLetter = (letter) => {
    let list = [...mitgliederList];
    if (!letter) {
      sortAndFilterListForTable(list);
      return;
    }
    list = filterFirstLetter(list, letter);
    sortAndFilterListForTable(list);
  };

  const getSortDirection = (dir) => {
    let sortInput = currentSort;
    if (dir) {
      sortInput = dir;
      setCurrentSort(dir);
    }
    const { direction, field } = sortInput;
    let newList = [];
    newList = sortArrayWithObjects(tableMemberList, field, direction);
    setTableMemberList(newList);
  };

  if (!initList.current) {
    console.log("initList");
    let newList = [...mitgliederList];
    sortAndFilterListForTable(newList);
    initList.current = true;
  }

  const handleOnClickAddTesting = () => {
    let alertData = {
      title: "Keine Mitglieder ausgewählt.",
      text: "Mindestens ein Mitglied auswählen.",
    };
    if (!selectedRows || selectedRows.length === 0) {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertData,
          "ALERT"
        )
      );
    } else {
      let testingList = [];
      selectedRows.forEach((m) => {
        delete m.tableData.checked;
        testingList.push(m);
      });
      setOpenStatusDialog("ADD_TESTING");
      setStatusDialogData(testingList);
    }
    // else {
    //   let addedMembers;
    //   try {
    //     let response = await addToTestingList(selectedRows);
    //     if (Array.isArray(response)) {
    //       addedMembers = response.length;
    //       alertData.title = "Mitglieder wurden zur Prüfung aufgestellt!";
    //       alertData.text = `${addedMembers} Mitglieder wurden der Prüfungsliste hinzugefügt.`;
    //     }
    //   } catch (error) {
    //     alert(error);
    //   } finally {
    //     dispatch(
    //       masterDataActions.openMasterDataDialog(
    //         dialogs.ALERT,
    //         alertData,
    //         "ALERT"
    //       )
    //     );
    //     dispatch(masterDataActions.getMitgliederList());
    //     setSelectedRows([]);
    //     setLoading(false);
    //   }
    // }
  };

  const handleOnClickDeleteMany = () => {
    let alertData = {
      title: "Keinen Mitglieder ausgewählt.",
      text: "Mindestens einen Mitglieder auswählen.",
    };
    if (!selectedRows || selectedRows.length === 0) {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT_MITGLIED,
          alertData,
          "ALERT"
        )
      );
    } else {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.REMOVE_MITGLIED,
          selectedRows,
          "REMOVE"
        )
      );
    }
  };

  // Open new Dialog and pass data to edit member
  const handleOnClickEditOne = (event, data) => {
    let editMemberData = {
      formData: data,
      currentTableList: tableMemberList,
      // isChild: data.memberType === "child",
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogs.EDIT_MITGLIED,
        editMemberData,
        "EDIT_MEMBER"
      )
    );
  };

  const handleOnClickShowMitglied = (event, data) => {
    let showMemberData = {
      member: data,
      standort: standortLookup[data.standortRef],
      contract: getContractDataFromMember(data.mitgliedId, vertragsnehmerList),
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogs.SHOW_MITGLIED,
        showMemberData,
        "SHOW_MEMBER"
      )
    );
    console.log("Show One: ", data);
  };

  if (state.checkedA) {
    tableLayout.options = true;
    tableLayout.actions = [
      {
        tooltip: "Entferne alle ausgewählten Mitglieder",
        icon: "delete",
        onClick: (evt, data) => handleOnClickDeleteMany(evt, data),
      },
    ];
  } else {
    mitgliederList &&
      mitgliederList.map(function (x) {
        if (x.tableData) {
          x.tableData.checked = false;
        }
        return x;
      });
    tableLayout.options = false;
    tableLayout.actions = [
      {
        icon: "edit",
        tooltip: "Bearbeiten",
        onClick: (event, rowData) => handleOnClickEditOne(event, rowData),
      },
      {
        text: "show",
        icon: AccountBox,
        tooltip: "Anzeigen",
        onClick: (event, rowData) => handleOnClickShowMitglied(event, rowData),
      },
    ];
  }

  const showStatus = (rowData) => {
    let icon;
    let text;
    let statusButtonStyle;
    switch (rowData.status) {
      case "active":
        text = "AKTIV";
        statusButtonStyle = { backgroundColor: "rgba(255,0,0,0)" };
        break;
      case "frozen":
        text = "EINGEFROREN";
        statusButtonStyle = { backgroundColor: "rgba(47, 155, 238, 0.54)" };
        break;
      case "terminated":
        text = "GEKÜNDIGT";
        statusButtonStyle = { backgroundColor: "rgba(238, 47, 47, 0.82)" };
        break;
      default:
        break;
    }
    icon = (
      <Button
        variant="outlined"
        style={{ minWidth: 130, ...statusButtonStyle }}
        onClick={(e) => handleClickOpenStatusDialog(e, rowData, "STATUS")}
      >
        {text}
      </Button>
    );
    return icon;
  };

  const showTerminate = (rowData) => {
    let icon;
    let text = "Kündigen";
    let statusButtonStyle = { backgroundColor: "rgba(238, 47, 47, 0.82)" };

    let contract = getContractDataFromMember(
      rowData.mitgliedId,
      vertragsnehmerList
    );

    icon = (
      <Button
        variant="outlined"
        style={{ minWidth: 130, ...statusButtonStyle }}
        onClick={(e) =>
          handleClickOpenStatusDialog(
            e,
            { ...rowData, email: contract?.email },
            "TERMINATE"
          )
        }
      >
        {text}
      </Button>
    );
    return icon;
  };

  const changeMemberType = (member) => {
    toggleLoadingButtonMemberType(true);
    let newMemberType = "child";
    if (member.memberType === "child") {
      newMemberType = "adult";
    }
    dispatch(
      masterDataActions.editMitglied({ ...member, memberType: newMemberType })
    );
  };

  if (masterDataResponse && masterDataResponse.status === "OK") {
    console.log("Finish loading!");
    setTimeout(() => {
      toggleLoadingButtonMemberType(false);
    }, 2000);
    dispatch(masterDataActions.setMasterDataResponse(null));
  }

  const showGroup = (rowData) => {
    let icon;
    let text;
    let statusButtonStyle;
    switch (rowData.memberType) {
      case "adult":
        text = "Erw";
        statusButtonStyle = { backgroundColor: "rgba(0, 255, 100, 0.54)" };
        break;
      case "child":
        text = "Kind";
        statusButtonStyle = { backgroundColor: "rgba(232, 238, 47, 0.54)" };
        break;
      default:
        text = "Fehler";
        break;
    }
    icon = (
      <Button
        variant="outlined"
        onClick={() => changeMemberType(rowData)}
        style={{ maxWidth: 50, ...statusButtonStyle }}
      >
        {text}
      </Button>
    );
    return icon;
  };

  if (Array.isArray(mitgliederList) && mitgliederList.length !== 0) {
    standorteList &&
      standorteList.forEach((site) => {
        standorteSelectList.push({
          value: site.standortId,
          label: site.siteName,
        });
        standortLookup = {
          ...standortLookup,
          [site.standortId]: site.siteName,
        };
      });

    defaultColumns = [
      {
        align: "center",
        field: "status",
        title: "Status",
        render: (rowData) => showStatus(rowData),
      },
      {
        align: "center",
        field: "terminate",
        title: "Kündigung",
        render: (rowData) => showTerminate(rowData),
      },
      {
        align: "left",
        title: "Standort",
        field: "standortRef",
        lookup: standortLookup,
      },
      {
        align: "left",
        title: "Nummer",
        field: "memberNumber",
      },
      {
        align: "left",
        title: "Gruppe",
        field: "memberNumber",
        render: (rowData) => showGroup(rowData),
      },
      {
        align: "left",
        title: "Nachname",
        field: "lastName",
        // defaultSort: "asc",
      },
      {
        align: "left",
        title: "Vorname",
        field: "firstName",
      },
      {
        align: "left",
        title: "Gürtelgrad",
        field: "degree",
        render: (rowData) => showBelts(rowData),
      },
      {
        align: "left",
        field: "degree",
        title: "Grad",
        // defaultSort: "desc",
        render: (rowData) => showDegree(rowData),
      },
      // {
      //   align: "center",
      //   title: "Anrede",
      //   field: "title",
      // },
      {
        align: "center",
        title: "Beitrag",
        field: "contribution",
        render: (rowData) => <p style={{}}>{rowData.contribution + " €"}</p>,
      },
    ];
  }

  const addNewMember = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogs.NEW_MITGLIED,
        undefined,
        "ADD"
      )
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {loading && <LoadingScreen />}
      {openStatusDialog === "STATUS" && (
        <DialogSelectStatus
          status={statusDialogData.status}
          rowData={statusDialogData}
          closeStatusDialog={closeStatusDialog}
        />
      )}
      {openStatusDialog === "TERMINATE" && (
        <DialogTerminate
          status={statusDialogData.status}
          rowData={statusDialogData}
          closeStatusDialog={closeStatusDialog}
        />
      )}
      {openStatusDialog === "ADD_TESTING" && (
        <DialogAddTesting
          selectedMembers={statusDialogData}
          closeStatusDialog={closeStatusDialog}
        />
      )}
      {state.memberTypeLoading && <LoadingDialog />}
      <FormGroup row>
        <Tooltip title="Mitglied hinzufügen">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffcc36",
              margin: 8,
              minWidth: 260,
              minHeight: 45,
              fontSize: "120%",
            }}
            // style={{backgroundColor:"#ffcc36", minWidth: 200}}
            startIcon={<PersonAdd style={{ fontSize: 40 }} />}
            onClick={addNewMember}
          >
            MITGLIED HINZUFÜGEN
          </Button>
        </Tooltip>
        <div>
          <SelectDefaultSort
            initialSort={INITIAL_SORT}
            getSortDirection={getSortDirection}
          />
        </div>
        <div className="Select-Standort-Container">
          <div className="Select-Standort-Item">
            <MultiSelect
              onChange={handleSelectStandort}
              placeholder={"Standort auswählen"}
              isClearable
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={standorteSelectList}
              noOptionsMessage={{ inputValue: "Keine Standorte verfügbar" }}
            />
          </div>
        </div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedActiveMembers}
                onChange={handleChange}
                name="checkedActiveMembers"
                color="primary"
              />
            }
            label="Aktive Mitglieder"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedTerminatedMembers}
                onChange={handleChange}
                name="checkedTerminatedMembers"
                color="primary"
              />
            }
            label="Gekündigte Mitglieder"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedFrozenMembers}
                onChange={handleChange}
                name="checkedFrozenMembers"
                color="primary"
              />
            }
            label="Eingefrorene Mitglieder"
            labelPlacement="start"
          />
        </FormGroup>
      </FormGroup>
      <FormGroup row className={classes.tableToolBar}>
        <FormControlLabel
          control={
            <Switch
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              color="primary"
            />
          }
          label="Auswählen für mehr Funktionen."
        />
        {state.checkedA && (
          <FormGroup
            row
            style={{
              justifyContent: "flex-end",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <Tooltip title="Mitglieder zu Prüfungsliste hinzufügen">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faAward} size="" />}
                onClick={handleOnClickAddTesting}
              >
                Zur Prüfung aufstellen
              </Button>
            </Tooltip>
            <Tooltip title="Mitglied löschen">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={handleOnClickDeleteMany}
              >
                Löschen
              </Button>
            </Tooltip>
          </FormGroup>
        )}
      </FormGroup>
      <AlphabetSelect getFirstLetter={getFirstLetter}></AlphabetSelect>
      <Table
        title={"Mitglieder"}
        data={tableMemberList}
        defaultColumns={defaultColumns}
        tableLayout={tableLayout}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

const SelectDefaultSort = ({ getSortDirection, initialSort }) => {
  const [sortDirection, setSortDirection] = React.useState(
    initialSort ? initialSort.direction : "desc"
  );
  const [sortField, setSortField] = React.useState(
    initialSort ? initialSort.field : "degree"
  );

  const handleChange = (event) => {
    setSortField(event.target.value);
    setSortDirection("desc");
    getSortDirection({ direction: "desc", field: event.target.value });
  };

  const toggleSortDirection = () => {
    if (!sortDirection) {
      setSortDirection("asc");
      getSortDirection({ direction: "asc", field: sortField });
      return;
    }
    if (sortDirection === "asc") {
      setSortDirection("desc");
      getSortDirection({ direction: "desc", field: sortField });
    } else {
      setSortDirection("asc");
      getSortDirection({ direction: "asc", field: sortField });
    }
  };

  let icon = "Sort";

  switch (sortDirection) {
    case "asc":
      icon = <ArrowUpwardIcon />;
      break;
    case "desc":
      icon = <ArrowDownwardIcon />;
      break;

    default:
      icon = "Sort";
      break;
  }

  return (
    <div className="Default-Sort-Select-Container">
      <form>
        <FormControl
          style={{
            width: 200,
            marginTop: 15,
          }}
          variant="outlined"
          color="primary"
        >
          <InputLabel id="sort-value-label">Sortieren nach</InputLabel>
          <Select
            labelId="sort-value-label"
            id="sort-value"
            value={sortField}
            variant="outlined"
            onChange={handleChange}
            label="Sortieren nach"
          >
            {/* {membersSelectSortArray.map((select) => ( */}
            {defaultSelectSortFieldList.map((select) => (
              <MenuItem key={select.id} value={select.value}>
                {select.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      <Button
        style={{ maxWidth: 50, maxHeight: 50, marginTop: 16, marginLeft: 16 }}
        variant="contained"
        color="default"
        onClick={toggleSortDirection}
      >
        {icon}
      </Button>
    </div>
  );
};
