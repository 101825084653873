import { addMonths } from "date-fns";

export const anredeArray = [
  { id: "01", value: "Herr", label: "Herr" },
  { id: "02", value: "Frau", label: "Frau" },
];

export const eventTypeArray = [
  { id: "01", value: "testing", label: "Prüfung" },
  { id: "02", value: "tournament", label: "Turnier" },
  { id: "03", value: "course", label: "Lehrgang" },
  { id: "04", value: "camp", label: "Camp" },
];

export const laufzeitArray = [
  { id: "01", value: 1, label: "1 Monat" },
  { id: "02", value: 2, label: "2 Monate" },
  { id: "03", value: 3, label: "3 Monate" },
  { id: "04", value: 4, label: "4 Monate" },
  { id: "05", value: 5, label: "5 Monate" },
  { id: "06", value: 6, label: "6 Monate" },
  { id: "07", value: 7, label: "7 Monate" },
  { id: "08", value: 8, label: "8 Monate" },
  { id: "09", value: 9, label: "9 Monate" },
  { id: "10", value: 10, label: "10 Monate" },
  { id: "11", value: 11, label: "11 Monate" },
  { id: "12", value: 12, label: "12 Monate" },
];

export const bezahltBisArray = Array.from({ length: 12 }, (item, index) => {
  let indexDate = addMonths(new Date(), index - 5);
  let y = indexDate.getFullYear();
  let mm = indexDate.getMonth() + 1;
  mm = ("0000" + mm).slice(-2);
  return {
    id: index + 1,
    value: `${mm}/${y}`,
    label: `${mm}/${y}`,
  };
});

export const getNewMemberPayedTillValue = () => {
  let date = new Date();
  let y = date.getFullYear();
  let mm = date.getMonth() + 1;
  mm = ("0000" + mm).slice(-2);
  return `${mm}/${y}`;
};

export const getCurrentMonthPayedTillValue = () => {
  let date = addMonths(new Date(), 1);
  let y = date.getFullYear();
  let mm = date.getMonth() + 1;
  mm = ("0000" + mm).slice(-2);
  return `${mm}/${y}`;
};

export const getDateFromPayedTillString = (payedTill) => {
  let dateArray = payedTill.split("/").reverse();
  dateArray = dateArray.map((x) => parseInt(x));
  dateArray[1] -= 1;
  let payedTillDate = new Date(...dateArray, 1);
  return payedTillDate;
};

export const getStringFromPayedTillDate = (date) => {
  let y = date.getFullYear();
  let mm = date.getMonth() + 1;
  mm = ("0000" + mm).slice(-2);
  return `${mm}/${y}`;
};

export const gradArray = [
  { id: 1, value: 1, label: "10. Kup" },
  { id: 2, value: 2, label: "9. Kup" },
  { id: 3, value: 3, label: "8. Kup" },
  { id: 4, value: 4, label: "7. Kup" },
  { id: 5, value: 5, label: "6. Kup" },
  { id: 6, value: 6, label: "5. Kup" },
  { id: 7, value: 7, label: "4. Kup" },
  { id: 8, value: 8, label: "3. Kup" },
  { id: 9, value: 9, label: "2. Kup" },
  { id: 10, value: 10, label: "1. Kup" },
  { id: 11, value: 11, label: "POOM" },
  { id: 12, value: 12, label: "1. Dan" },
  { id: 13, value: 13, label: "2. Dan" },
  { id: 14, value: 14, label: "3. Dan" },
  { id: 15, value: 15, label: "4. Dan" },
  { id: 16, value: 16, label: "5. Dan" },
];

export const fullMember = [
  {
    type: "select",
    selectList: anredeArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Anrede",
    value: "title",
    required: true,
    errorMsg: "Bitte eine Anrede auswählen.",
  },
  {
    type: "text",
    label: "Nachname",
    value: "lastName",
    required: true,
    errorMsg: "Bitte den Nachnamen eingeben.",
  },
  {
    type: "text",
    label: "Vorname",
    value: "firstName",
    required: true,
    errorMsg: "Bitte den Vornamen eingeben.",
  },
  {
    type: "date",
    label: "Geburtstag",
    value: "birthdate",
    required: false,
    errorMsg: "Bitte das Geburtsdatum eingeben.",
  },
  {
    type: "text",
    label: "Mitgliedsnummer",
    value: "memberNumber",
    // disabled: true,
    required: true,
  },
  {
    type: "text",
    label: "Mitgliedsbeitrag in Euro",
    value: "contribution",
    required: true,
  },
  {
    type: "date",
    label: "Vertragsdatum",
    value: "contractDate",
    required: true,
    errorMsg: "Bitte das Vertragsdatum eingeben.",
  },
  {
    type: "select",
    fieldWidth: 2,
    selectList: gradArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Gürtelgrad",
    value: "degree",
    required: true,
    errorMsg: "Bitte einen Gürtelgrad auswählen.",
  },
  {
    type: "select",
    fieldWidth: 2,
    selectList: laufzeitArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Vertragslaufzeit",
    value: "contractDuration",
    required: true,
    errorMsg: "Bitte die Vertragslaufzeit auswählen.",
  },
  {
    type: "select",
    fieldWidth: 2,
    selectList: [
      { id: "01", value: 1, label: "1 Monat" },
      { id: "02", value: 2, label: "2 Monate" },
      { id: "03", value: 3, label: "3 Monate" },
      { id: "06", value: 6, label: "6 Monate" },
      { id: "12", value: 12, label: "12 Monate" },
    ], // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Vertragsverlängerung",
    value: "contractExtension",
    required: false,
    errorMsg: "Bitte die Dauer der Vertragsverlängerung auswählen.",
  },
  {
    type: "select",
    fieldWidth: 2,
    selectList: laufzeitArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Kündigungsfrist",
    value: "noticePeriod",
    required: true,
    errorMsg: "Bitte die Kündigungsfrist auswählen.",
  },
  {
    type: "date",
    label: "Aktiv seit",
    value: "beginningDate",
    required: false,
  },
];

export const affiliatedMemberFields = [
  {
    type: "select",
    selectList: anredeArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Anrede",
    value: "title",
    required: true,
    errorMsg: "Bitte eine Anrede auswählen.",
  },
  {
    type: "text",
    label: "Nachname",
    value: "lastName",
    required: true,
    errorMsg: "Bitte den Nachnamen eingeben.",
  },
  {
    type: "text",
    label: "Vorname",
    value: "firstName",
    required: true,
    errorMsg: "Bitte den Vornamen eingeben.",
  },
  {
    type: "date",
    label: "Geburtstag",
    value: "birthdate",
    required: false,
    errorMsg: "Bitte das Geburtsdatum eingeben.",
  },
  {
    type: "select",
    selectList: gradArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Gürtelgrad",
    value: "degree",
    required: true,
    errorMsg: "Bitte einen Gürtelgrad auswählen.",
  },
  {
    type: "date",
    label: "Aktiv seit",
    value: "beginningDate",
    required: false,
  },
];

export const bankData = [
  {
    type: "text",
    label: "Straße, Hausnummer",
    value: "street",
    required: false,
  },
  {
    type: "text",
    label: "PLZ",
    value: "zip",
    required: false,
  },
  {
    type: "text",
    label: "Ort",
    value: "city",
    required: false,
  },
  {
    type: "text",
    label: "Telefon",
    value: "phoneNumber",
    required: false,
  },
  {
    type: "text",
    label: "Mobil",
    value: "mobile",
    required: false,
  },
  {
    type: "text",
    label: "E-Mail",
    value: "email",
    required: false,
  },
  {
    type: "autoInput",
    label: "Inhaber",
    value: "owner",
    required: true,
    errorMsg: "Bitte den Kontoinhaber angeben",
  },
  {
    type: "text",
    label: "Kreditinstitut",
    value: "bank",
    required: false,
  },
  {
    type: "text",
    label: "IBAN",
    value: "iban",
    required: false,
  },
  {
    type: "text",
    label: "BIC",
    value: "bic",
    required: false,
  },
];

export const standortLeiterFormData = [
  {
    type: "select",
    selectList: anredeArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Anrede",
    value: "title",
    required: true,
    errorMsg: "Bitte eine Anrede auswählen.",
  },
  {
    type: "text",
    label: "Nachname",
    value: "lastName",
    required: true,
    errorMsg: "Bitte den Nachnamen eingeben.",
  },
  {
    type: "text",
    label: "Vorname",
    value: "firstName",
    required: true,
    errorMsg: "Bitte den Vornamen eingeben.",
  },
  {
    type: "date",
    label: "Geburtstag",
    value: "birthdate",
    required: false,
    errorMsg: "Bitte das Geburtsdatum eingeben.",
  },
  {
    type: "select",
    selectList: gradArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Gürtelgrad",
    value: "degree",
    required: false,
  },
  {
    type: "text",
    label: "Mitgliedsnummer",
    value: "memberNumber",
    disabled: false,
    required: false,
  },
  {
    type: "date",
    label: "Aktiv seit",
    value: "contractDate",
    required: false,
  },
  {
    type: "text",
    label: "Mitgliedsbeitrag in Euro",
    value: "contribution",
    required: false,
  },
  {
    type: "text",
    label: "E-Mail",
    value: "email",
    required: false,
  },
  {
    type: "text",
    label: "Telefon",
    value: "phoneNumber",
    required: false,
  },
  {
    type: "text",
    label: "Mobil",
    value: "mobile",
    required: false,
  },
  {
    type: "text",
    label: "Straße, Hausnummer",
    value: "street",
    required: false,
  },
  {
    type: "text",
    label: "PLZ",
    value: "zip",
    required: false,
  },
  {
    type: "text",
    label: "Ort",
    value: "city",
    required: false,
  },
];

export const eventFormData = [
  {
    type: "select",
    selectList: eventTypeArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Art der Veranstaltung",
    value: "eventType",
    required: true,
    errorMsg: "Bitte eine Veranstaltungsart auswählen.",
  },
  {
    type: "text",
    label: "Name der Veranstaltung",
    value: "eventName",
    required: true,
    errorMsg: "Bitte den Namen der Veranstaltung eingeben.",
  },
  {
    type: "date",
    label: "Beginn",
    value: "eventDateBegin",
    required: false,
  },
  {
    type: "date",
    label: "Ende",
    value: "eventDateEnd",
    required: false,
  },
  {
    type: "text",
    label: "Straße, Hausnummer",
    value: "street",
    required: false,
  },
  {
    type: "text",
    label: "PLZ",
    value: "zip",
    required: false,
  },
  {
    type: "text",
    label: "Ort",
    value: "city",
    required: false,
  },
];

export const standortFormData = [
  {
    type: "text",
    label: "Standortname",
    value: "siteName",
    required: true,
    errorMsg: "Bitte den Namen des Standorts eingeben.",
  },
  {
    type: "text",
    label: "Standortkennzeichnung",
    value: "memberIdentifier",
    placeholder: "Kürzel für Mitgliedsnummern eingeben",
    helperText: "Zum Beispiel: M für München, A für Augsburg...",
    showHelperText: true,
    required: true,
    errorMsg: "Bitte angeben.",
  },
  {
    type: "text",
    label: "Nächste Mitgliedsnummer (Nur Zahl)",
    value: "latestMemberNumber",
    helperText: "Hier können Sie die Nummer für das nächste Mitglied ändern",
    showHelperText: true,
    required: false,
  },
  {
    type: "select",
    selectList: laufzeitArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Vertragslaufzeit",
    value: "contractDuration",
    required: false,
    errorMsg: "Bitte die Vertragslaufzeit auswählen.",
  },
  {
    type: "select",
    selectList: [
      { id: "01", value: 1, label: "1 Monat" },
      { id: "02", value: 2, label: "2 Monate" },
      { id: "03", value: 3, label: "3 Monate" },
      { id: "06", value: 6, label: "6 Monate" },
      { id: "12", value: 12, label: "12 Monate" },
    ],
    label: "Vertragsverlängerung",
    value: "contractExtension",
    required: false,
    errorMsg: "Bitte die Dauer der Vertragsverlängerung auswählen.",
  },
  {
    type: "select",
    selectList: laufzeitArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Kündigungsfrist",
    value: "noticePeriod",
    required: false,
    errorMsg: "Bitte die Kündigungsfrist auswählen.",
  },
  {
    type: "text",
    label: "Mitgliedsbeitrag in €",
    value: "memberContribution",
    required: false,
    helperText: "Nur die Zahl eingeben. Zum Beispiel: 70,00",
    showHelperText: true,
  },
  {
    type: "text",
    label: "Aufnahme- / Vereinsgebühr in €",
    value: "enrollmentFee",
    required: false,
    helperText: "Nur die Zahl eingeben. Zum Beispiel: 70,00",
    showHelperText: true,
  },
  {
    type: "text",
    label: "Leitung",
    value: "siteOwner",
    required: false,
  },
  {
    type: "text",
    label: "Trainer 1",
    value: "trainer1",
    required: false,
  },
  {
    type: "text",
    label: "Trainer 2",
    value: "trainer2",
    required: false,
  },
  {
    type: "text",
    label: "E-Mail",
    value: "email",
    required: false,
  },
  {
    type: "text",
    label: "Straße, Hausnummer",
    value: "street",
    required: false,
  },
  {
    type: "text",
    label: "PLZ",
    value: "zip",
    required: false,
  },
  {
    type: "text",
    label: "Ort",
    value: "city",
    required: false,
  },
];

export const mapValuesToFieldLabels = (dataObject, fieldArray) => {
  let mappedFields = [];

  for (const [key, value] of Object.entries(dataObject)) {
    fieldArray.forEach((field) => {
      if (key === field.value && value !== "") {
        mappedFields.push({ label: field.label, value: value, key: key });
      }
      return;
    });
  }
  return mappedFields;
};

// {
//   "selectedStandort": {
//     "standortId": "9mZacTfsoFteYjmpqJSr",
//     "siteName": "Zentrale Augsburg",
//     "street": "Friedberger Str. 81",
//     "memberContribution": "80,00",
//     "openingDate": "2020-11-27T09:27:57.776Z",
//     "zip": "86161",
//     "email": "augsburg@twintaekwondo.de",
//     "creator": "2kgqwvDxy1Vs6WMemOwldOCxVaz2",
//     "newImage": {
//       "imageUrl": "https://firebasestorage.googleapis.com/v0/b/todo-app-tut-c2172.appspot.com/o/m-angermeyer.9mZacTfsoFteYjmpqJSr.stone_sahbumnim.jpg?alt=media",
//       "imageFileName": "m-angermeyer.9mZacTfsoFteYjmpqJSr.stone_sahbumnim.jpg",
//       "creator": "2kgqwvDxy1Vs6WMemOwldOCxVaz2"
//     },
//     "isMember": true,
//     "createdAt": "2020-11-27T09:29:47.527Z",
//     "city": "Augsburg",
//     "memberIdentifier": "A",
//     "isLeader": false,
//     "leader": "2"
//   },
//   "memberType": "child",
//   "newMemberData": {
//     "lastName": "Angermeyer",
//     "firstName": "Michael",
//     "memberNumber": "A101",
//     "contribution": "40",
//     "title": "Herr",
//     "birthdate": "1992-10-25T23:00:00.000Z",
//     "contractDate": "2007-10-25T22:00:00.000Z",
//     "contractDuration": "3 Monate",
//     "degree": 1,
//     "memberType": "child"
//   },
//   "contractData": {
//     "lastName": "Musterzahler",
//     "firstName": "Max",
//     "street": "Landsbergerstr. 55 ",
//     "zip": "86343",
//     "city": "Königsbrunn",
//     "phoneNumber": "08231 6900",
//     "mobile": "017632366618",
//     "email": "michael-angermeyer@hotmail.de",
//     "isOwner": true,
//     "owner": "Musterzahler Max",
//     "bank": "Kreissparkasse Augsburg",
//     "iban": "IBAN MICHAEL",
//     "bic": "BIC MICHAEL",
//     "title": "Herr",
//     "birthdate": "1992-10-25T23:00:00.000Z"
//   }
// }

export const parentDataFields = [
  {
    type: "select",
    selectList: anredeArray, // [{id: Number for Key, value: value for database, label: label for user}]
    label: "Anrede",
    value: "title",
    required: true,
    errorMsg: "Bitte eine Anrede auswählen.",
  },
  {
    type: "text",
    label: "Nachname",
    value: "lastName",
    required: true,
    errorMsg: "Bitte den Nachnamen eingeben.",
  },
  {
    type: "text",
    label: "Vorname",
    value: "firstName",
    required: true,
    errorMsg: "Bitte den Vornamen eingeben.",
  },
  {
    type: "date",
    label: "Geburtstag",
    value: "birthdate",
    required: false,
    errorMsg: "Bitte das Geburtsdatum eingeben.",
  },
];

export const contactDataFields = [
  {
    type: "text",
    label: "Straße, Hausnummer",
    value: "street",
    required: false,
  },
  {
    type: "text",
    label: "PLZ",
    value: "zip",
    required: false,
  },
  {
    type: "text",
    label: "Ort",
    value: "city",
    required: false,
  },
  {
    type: "text",
    label: "Telefon",
    value: "phoneNumber",
    required: false,
  },
  {
    type: "text",
    label: "Mobil",
    value: "mobile",
    required: false,
  },
  {
    type: "text",
    label: "E-Mail",
    value: "email",
    required: false,
  },
];

export const bankDataFields = [
  {
    type: "owner",
    label: "Inhaber",
    value: "owner",
    // disabled: isOwner,
    required: false,
    fieldWidth: 12,
  },
  {
    type: "text",
    label: "Kreditinstitut",
    value: "bank",
    required: false,
    fieldWidth: 12,
  },
  {
    type: "text",
    label: "IBAN",
    value: "iban",
    required: false,
    fieldWidth: 12,
  },
  {
    type: "text",
    label: "BIC",
    value: "bic",
    required: false,
    fieldWidth: 12,
  },
];
