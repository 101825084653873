import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BankingAccountsTable from "./BankingAccountsTable";
import ChangeBankAccountForm from "./ChangeBankAccountForm";
import DeleteBankAccountDialog from "./DeleteBankAccountDialog";
import appActions from "../../../../redux/actions/appActions";

export default function BankingAccountsActions({ selectedAction }) {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { currentUser, appError } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();

  const handleSelectAccount = (account, index) => {
    setSelectedAccount(account);
    setSelectedIndex(index);
  };

  const resetSelection = () => {
    setSelectedAccount(null);
    setSelectedIndex(null);
  };

  const handleDeleteAccount = () => {
    console.log("final delete!");
    let accountUpdate = { ...currentUser };
    // Use index to find account and delete it
    if (selectedIndex > -1) {
      accountUpdate.bankingSettings.accounts.splice(selectedIndex, 1);
    }
    // Update Settings in database
    dispatch(appActions.updateUser(accountUpdate));
    if (appError) {
      alert("Es gab ein Problem. Bitte versuchen Sie es erneut.");
    } else {
      alert("Konto wurde erfolgreich gelöscht");
      resetSelection();
    }
  };

  if (selectedAccount === null) {
    return (
      <div style={{ margin: 50, marginTop: 0 }}>
        <Typography variant="h5">Konto auswählen</Typography>
        <BankingAccountsTable
          bankingAccounts={currentUser.bankingSettings.accounts}
          handleSelectedAccount={handleSelectAccount}
        />
      </div>
    );
  } else {
    console.log("selectedAccount :>> ", selectedAccount);
    return (
      <div>
        {selectedAccount &&
          selectedIndex !== null &&
          selectedAction === "EDIT" && (
            <ChangeBankAccountForm
              accountToEdit={selectedAccount}
              accountIndex={selectedIndex}
              resetSelection={resetSelection}
            />
          )}
        {selectedAccount &&
          selectedIndex !== null &&
          selectedAction === "DELETE" && (
            <DeleteBankAccountDialog
              accountToDelete={selectedAccount}
              handleDeleteAccount={handleDeleteAccount}
              resetSelection={resetSelection}
            />
          )}
      </div>
    );
  }
}
