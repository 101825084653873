import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dialogs from "../../redux/constants/dialogConstants";
import masterDataActions from "../../redux/actions/masterDataActions";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
} from "@material-ui/core";
import Controls from "../controls/Controls";
import masterDataApi from "../../redux/api/masterDataApi";
import LoadingScreen from "../Spinner/LoadingScreen";
import {
  renderStandortInputFields,
  uploadEventImage,
} from "../../utils/dataUtils/masterDataUtils";
import { eventFormData } from "./NewMitglied/DialogPages/inputFields";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const initialFValues = {
  eventType: "",
  eventName: "",
  eventDateBegin: new Date(),
  eventDateEnd: new Date(),
  street: "",
  city: "",
  zip: "",
  image: "",
};

const NewEventDialog = () => {
  const dispatch = useDispatch();
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  let defaultFormValues;

  if (dialogData && dialogType) {
    if (dialogType === "ADD") {
      defaultFormValues = {
        ...initialFValues,
      };
      if (dialogData === "testing") {
        defaultFormValues.eventType = "testing";
      }
    } else if (dialogType === "EDIT") {
      defaultFormValues = {
        ...dialogData,
      };
    }
  }

  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogs.NEW_EVENT;
  const [previewImage, setPreviewImage] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [newImage, setNewImage] = React.useState(false);
  const [newImageFile, setNewImageFile] = React.useState(null);

  const { register, handleSubmit, control, errors, getValues } = useForm({
    defaultValues: defaultFormValues,
  });

  const newInputFields =
    eventFormData &&
    eventFormData.map((field) => {
      return {
        ...field,
        type: field.type,
        selectList: field.selectList ? field.selectList : null,
        label: field.label,
        value: field.value,
        required: field.required,
        error: field.required ? errors[field.value] : false,
        errorMsg: field.errorMsg ? field.errorMsg : "",
      };
    });

  const firstFormRow = renderStandortInputFields(
    newInputFields,
    register,
    control
  );

  const fileHandler = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setNewImageFile(e.target.files[0]);
    setNewImage(true);
    console.log("previewImage", e.target.files[0]);
  };

  const uploadImage = (image, eventId) => {
    let form_data = new FormData();
    form_data.append("image", image);
    console.log("form_data :>> ", form_data);
    masterDataApi
      .uploadEventImage(form_data, eventId)
      .then((response) => {
        console.log("response.data :>> ", response.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function resolveUpload(image, id) {
    console.log("calling Event Image Upload");
    const result = await uploadEventImage(image, id);
    if (result) {
      console.log(result);
      return result;
    }
    return new Error("FAIL");
  }

  const handleAbort = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const handleClose = () => {
    console.log("handleClose");
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const clearData = () => {
    setTimeout(() => {
      dispatch(masterDataActions.getEventsList());
      console.log("HIER");
    }, 1000);
    setPreviewImage(null);
  };

  const onSubmit = (data) => {
    setLoading(true);
    let alertText = {
      title: "Neue Veranstaltung erfolgreich angelegt.",
      text: (
        <p>
          Neue Veranstaltung <b>{data.eventName}</b> erfolgreich hinzugefügt.
        </p>
      ),
    };
    let newEvent = {
      ...data,
    };
    delete newEvent.image;
    console.log("(ADD) newEvent :>> ", newEvent);
    masterDataApi
      .addEvent(newEvent)
      .then((response) => {
        let newEvent = {
          ...response.data,
        };
        console.log("newEvent", newEvent);
        if (newImage) {
          uploadImage(newImageFile, newEvent.id);
        }
        dispatch(masterDataActions.getEventsList());
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogs.ALERT,
            alertText,
            "ALERT"
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    clearData();
    setLoading(false);
    // handleClose();
  };

  const handleSaveEdit = () => {
    let newValues = {
      ...defaultFormValues,
    };
    const values = getValues();
    newValues = {
      ...newValues,
      ...values,
    };
    let alertText = {
      title: "Veranstaltung geändert.",
      text: <p>{newValues.eventName} erfolgreich geändert.</p>,
    };
    console.log("(EDIT) newValues :>> ", newValues);
    if (newImage) {
      // let answer;
      dispatch(masterDataActions.editEvent(newValues));
      resolveUpload(newImageFile, newValues.eventId)
        .then((response) => {
          console.log(response);
          clearData();
          dispatch(
            masterDataActions.openMasterDataDialog(
              dialogs.ALERT,
              alertText,
              "ALERT"
            )
          );
        })
        .catch((e) => {
          console.log(e);
          console.log("Hochladen fehlgeschlagen");
          alertText.text = (
            <p>Hochladen des neuen Veranstaltungsbilds fehlgeschlagen.</p>
          );
          dispatch(
            masterDataActions.openMasterDataDialog(
              dialogs.ALERT,
              alertText,
              "ALERT"
            )
          );
        });
    } else {
      // No new Image
      dispatch(masterDataActions.editEvent(newValues));
      clearData();
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertText,
          "ALERT"
        )
      );
    }
  };

  return (
    <div>
      {loading && <LoadingScreen></LoadingScreen>}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={visible}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {dialogType === "ADD"
            ? "Neue Veranstaltung anlegen:"
            : "Veranstaltung bearbeiten."}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                {firstFormRow}
              </Grid>
              <Grid
                style={{
                  minHeight: 648,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  flex: 1,
                }}
                item
                lg={6}
                xs={6}
              >
                <h3>Laden Sie ein Bild für diese Veranstaltung hoch:</h3>
                <FormGroup
                  row
                  style={{
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Controls.ImageUpload onChange={fileHandler} />
                  <div>
                    <h4>{"Vorschau:"}</h4>
                    <img
                      src={
                        defaultFormValues &&
                        defaultFormValues.newImage &&
                        !newImage
                          ? defaultFormValues.newImage.imageUrl
                          : previewImage
                      }
                      alt={previewImage ? previewImage.name : null}
                      width={400}
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  row
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <hr></hr>
                  <FormGroup row style={{ justifyContent: "end" }}>
                    {dialogType === "ADD" ? (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        type="submit"
                        variant="contained"
                        value="Hinzufügen"
                        color="primary"
                      >
                        Hinzufügen
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEdit}
                      >
                        Speichern
                        <div style={{ marginLeft: 5 }}>
                          <FontAwesomeIcon icon={faSave} />
                        </div>
                      </Button>
                    )}
                  </FormGroup>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewEventDialog;
