import { takeLatest, call, put, all } from "redux-saga/effects";
import masterDataActions from "../actions/masterDataActions";
import api from "../api/masterDataApi";
import {
  GET_VERTRAGSNEHMERS_LIST,
  REMOVE_MANY_VERTRAGSNEHMER,
  ADD_VERTRAGSNEHMER,
  REMOVE_VERTRAGSNEHMER,
  EDIT_VERTRAGSNEHMER,
  GET_MITGLIEDER_LIST,
  REMOVE_MANY_MITGLIEDER,
  ADD_MITGLIED,
  EDIT_MITGLIED,
  REMOVE_MITGLIED,
  GET_STANDORTE_LIST,
  REMOVE_MANY_STANDORTE,
  ADD_STANDORT,
  EDIT_STANDORT,
  REMOVE_STANDORT,
  GET_EVENTS_LIST,
  REMOVE_MANY_EVENTS,
  ADD_EVENT,
  EDIT_EVENT,
  REMOVE_EVENT,
  GET_STANDORTLEITER_LIST,
  REMOVE_MANY_STANDORTLEITER,
  ADD_STANDORTLEITER,
  REMOVE_STANDORTLEITER,
  EDIT_STANDORTLEITER,
} from "../constants/masterDataConstants";

function* getVertragsnehmerList(action) {
  const vertragsnehmerList = yield call(api.getVertragsnehmer);
  yield put(masterDataActions.setVertragsnehmerList(vertragsnehmerList));
}

function* removeManyVertragsnehmer(action) {
  try {
    for (const vertragsnehmerId of action.vertragsnehmerIdList) {
      yield call(api.removeSingleVertragsnehmerById, vertragsnehmerId);
    }
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getVertragsnehmerList(0));
    yield put(masterDataActions.setMasterDataResponse("MANY DONE"));
    console.log("Removed many Vertragsnehmer DONE!");
  }
}

function* addVertragsnehmer(action) {
  try {
    const newVN = yield call(api.addVertragsnehmer, action.vertragsnehmerData);
    yield put(masterDataActions.setMasterDataResponse(newVN));
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getVertragsnehmerList(0));
  }
}

function* editVertragsnehmer(action) {
  try {
    yield call(api.editVertragsnehmer, action.vertragsnehmerData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    console.log("Sagas editVertragsnehmer");
    yield put(masterDataActions.getVertragsnehmerList(0));
  }
}

function* removeVertragsnehmer(action) {
  // const smsUserId = yield select(state => state.appReducer.smsUser.pk.smsUid);
  try {
    yield call(api.removeSingleVertragsnehmerById, action.vertragsnehmerId);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getVertragsnehmerList(0));
  }
}

// End Vertragsnehmer Sagas

// Begin Mitglieder Sagas

function* getMitgliederList(action) {
  const mitgliederList = yield call(api.getMitglieder);
  yield put(masterDataActions.setMitgliederList(mitgliederList));
}

function* removeManyMitglieder(action) {
  try {
    for (const mitgliedId of action.mitgliederIdList) {
      yield call(api.removeSingleMitgliedById, mitgliedId);
    }
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getMitgliederList(0));
  }
}

function* addMitglied(action) {
  try {
    yield call(api.addMitglied, action.mitgliedData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getMitgliederList(0));
  }
}

function* editMitglied(action) {
  try {
    yield call(api.editMitglied, action.mitgliedData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getMitgliederList(0));
    yield put(masterDataActions.setMasterDataResponse({ status: "OK" }));
  }
}

function* removeMitglied(action) {
  // const smsUserId = yield select(state => state.appReducer.smsUser.pk.smsUid);
  try {
    yield call(api.removeSingleMitgliedById, action.mitgliedId);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getMitgliederList(0));
  }
}

// End Mitglieder Sagas

// Begin Standorte Sagas

function* getStandorteList(action) {
  const standorteList = yield call(api.getStandorte);
  yield put(masterDataActions.setStandorteList(standorteList));
}

function* removeManyStandorte(action) {
  try {
    for (const standortId of action.standorteIdList) {
      yield call(api.removeSingleMitgliedById, standortId);
    }
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandorteList(0));
  }
}

function* addStandort(action) {
  try {
    yield call(api.addStandort, action.standortData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandorteList(0));
  }
}

function* editStandort(action) {
  try {
    yield call(api.editStandort, action.standortData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandorteList(0));
  }
}

function* removeStandort(action) {
  // const smsUserId = yield select(state => state.appReducer.smsUser.pk.smsUid);
  try {
    yield call(api.removeSingleStandortById, action.standortId);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandorteList(0));
  }
}

// End Standorte Sagas

// Begin Events Sagas

function* getEventsList(action) {
  const eventsList = yield call(api.getEvents);
  yield put(masterDataActions.setEventsList(eventsList));
}

function* removeManyEvents(action) {
  try {
    for (const eventId of action.eventsIdList) {
      yield call(api.removeSingleMitgliedById, eventId);
    }
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getEventsList(0));
  }
}

function* addEvent(action) {
  try {
    yield call(api.addEvent, action.eventData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getEventsList(0));
  }
}

function* editEvent(action) {
  try {
    yield call(api.editEvent, action.eventData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getEventsList(0));
  }
}

function* removeEvent(action) {
  // const smsUserId = yield select(state => state.appReducer.smsUser.pk.smsUid);
  try {
    yield call(api.removeSingleEventById, action.eventId);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getEventsList(0));
  }
}

// End Events Sagas

// Begin Standortleiter Sagas

function* getStandortleiterList(action) {
  const standortleiterList = yield call(api.getStandortleiter);
  yield put(masterDataActions.setStandortleiterList(standortleiterList));
}

function* removeManyStandortleiter(action) {
  try {
    for (const standortleiterId of action.standortleiterIdList) {
      yield call(api.removeSingleStandortleiterById, standortleiterId);
    }
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandortleiterList(0));
  }
}

function* addStandortleiter(action) {
  try {
    const newVN = yield call(api.addStandortleiter, action.standortleiterData);
    yield put(masterDataActions.setMasterDataResponse(newVN));
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandortleiterList(0));
  }
}

function* editStandortleiter(action) {
  try {
    yield call(api.editStandortleiter, action.standortleiterData);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    console.log("Sagas editStandortleiter");
    yield put(masterDataActions.getStandortleiterList(0));
  }
}

function* removeStandortleiter(action) {
  // const smsUserId = yield select(state => state.appReducer.smsUser.pk.smsUid);
  try {
    yield call(api.removeSingleStandortleiterById, action.standortleiterId);
  } catch (e) {
    yield put(masterDataActions.setMasterDataError(`Error ${e}`));
  } finally {
    yield put(masterDataActions.getStandortleiterList(0));
  }
}

// End Vertragsnehmer Sagas

function* watchMasterData() {
  yield takeLatest(GET_VERTRAGSNEHMERS_LIST, getVertragsnehmerList);
  yield takeLatest(REMOVE_MANY_VERTRAGSNEHMER, removeManyVertragsnehmer);
  yield takeLatest(ADD_VERTRAGSNEHMER, addVertragsnehmer);
  yield takeLatest(EDIT_VERTRAGSNEHMER, editVertragsnehmer);
  yield takeLatest(REMOVE_VERTRAGSNEHMER, removeVertragsnehmer);

  yield takeLatest(GET_MITGLIEDER_LIST, getMitgliederList);
  yield takeLatest(REMOVE_MANY_MITGLIEDER, removeManyMitglieder);
  yield takeLatest(ADD_MITGLIED, addMitglied);
  yield takeLatest(EDIT_MITGLIED, editMitglied);
  yield takeLatest(REMOVE_MITGLIED, removeMitglied);

  yield takeLatest(GET_STANDORTE_LIST, getStandorteList);
  yield takeLatest(REMOVE_MANY_STANDORTE, removeManyStandorte);
  yield takeLatest(ADD_STANDORT, addStandort);
  yield takeLatest(EDIT_STANDORT, editStandort);
  yield takeLatest(REMOVE_STANDORT, removeStandort);

  yield takeLatest(GET_EVENTS_LIST, getEventsList);
  yield takeLatest(REMOVE_MANY_EVENTS, removeManyEvents);
  yield takeLatest(ADD_EVENT, addEvent);
  yield takeLatest(EDIT_EVENT, editEvent);
  yield takeLatest(REMOVE_EVENT, removeEvent);

  yield takeLatest(GET_STANDORTLEITER_LIST, getStandortleiterList);
  yield takeLatest(REMOVE_MANY_STANDORTLEITER, removeManyStandortleiter);
  yield takeLatest(ADD_STANDORTLEITER, addStandortleiter);
  yield takeLatest(EDIT_STANDORTLEITER, editStandortleiter);
  yield takeLatest(REMOVE_STANDORTLEITER, removeStandortleiter);
}

export default function* rootSaga() {
  yield all([watchMasterData()]);
}
