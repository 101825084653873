import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { getGradCollection } from "../../../utils/dataUtils/appUtils";

export function ShowEventMembers(props) {
  const { members, handleRemoveMember } = props;

  let gradCollection = getGradCollection();

  console.log("EVENT members :>> ", members);

  return (
    <TableContainer
      style={{ minHeight: 300, maxHeight: 400, marginTop: 50 }}
      component={Paper}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">NR:</TableCell>
            <TableCell>Grad</TableCell>
            <TableCell align="left">M.-Nummer</TableCell>
            <TableCell align="left">Nachname</TableCell>
            <TableCell align="left">Vorname</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member, i) => (
            <TableRow
              hover
              onClick={() => handleRemoveMember(member)}
              key={"Testing-Table-Row-" + i}
            >
              <TableCell>{i + 1}</TableCell>
              <TableCell component="th" scope="row">
                {gradCollection[member.degree - 1].title}
              </TableCell>
              <TableCell align="left">{member.memberNumber}</TableCell>
              <TableCell align="left">{member.lastName}</TableCell>
              <TableCell align="left">{member.firstName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
