const NEW_VERTRAGSNEHMER = "NEW_VERTRAGSNEHMER";
const IMPORT_VERTRAGSNEHMER = "IMPORT_VERTRAGSNEHMER";
const ALERT_VERTRAGSNEHMER = "ALERT_VERTRAGSNEHMER";
const UPDATE_VERTRAGSNEHMER = "UPDATE_VERTRAGSNEHMER";
const REMOVE_VERTRAGSNEHMER = "REMOVE_VERTRAGSNEHMER";

const ALERT = "ALERT";

const NEW_MITGLIED = "NEW_MITGLIED";
const SHOW_MITGLIED = "SHOW_MITGLIED";
const IMPORT_MITGLIED = "IMPORT_MITGLIED";
const ALERT_MITGLIED = "ALERT_MITGLIED";
const EDIT_MITGLIED = "EDIT_MITGLIED";
const REMOVE_MITGLIED = "REMOVE_MITGLIED";

const NEW_STANDORT = "NEW_STANDORT";
const REMOVE_STANDORT = "REMOVE_STANDORT";

const NEW_EVENT = "NEW_EVENT";
const REMOVE_EVENT = "REMOVE_EVENT";

const NEW_STANDORTLEITER = "NEW_STANDORTLEITER";
const REMOVE_STANDORTLEITER = "REMOVE_STANDORTLEITER";

// eslint-disable-next-line
export default {
  ALERT_VERTRAGSNEHMER,
  NEW_VERTRAGSNEHMER,
  IMPORT_VERTRAGSNEHMER,
  UPDATE_VERTRAGSNEHMER,
  REMOVE_VERTRAGSNEHMER,

  ALERT,

  ALERT_MITGLIED,
  NEW_MITGLIED,
  SHOW_MITGLIED,
  IMPORT_MITGLIED,
  EDIT_MITGLIED,
  REMOVE_MITGLIED,

  NEW_STANDORT,
  REMOVE_STANDORT,

  NEW_EVENT,
  REMOVE_EVENT,

  NEW_STANDORTLEITER,
  REMOVE_STANDORTLEITER,
};
