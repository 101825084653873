import React from "react";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import BeltAvatars from "../../../Avatar/Belts/BeltAvatar";
import { bezahltBisArray, fullMember } from "./inputFields";
import dialogConstants from "../../../../redux/constants/dialogConstants";

export function EditMemberForm(props) {
  const { formData } = props;

  const dispatch = useDispatch();
  const { standorteList } = useSelector((state) => state.masterDataReducer);
  let standorteSelectList = [];
  standorteList &&
    standorteList.forEach((site) => {
      if (site.standortType === "AFFILIATED") {
        return;
      }
      standorteSelectList.push({
        value: site.standortId,
        label: site.siteName,
      });
    });

  let defaultFormValues = {
    ...formData,
    isChild: formData.memberType === "child",
  };
  const { register, handleSubmit, control, errors, watch, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  const degree = watch("degree");
  const isChild = watch("isChild");
  const mitgliedId = watch("mitgliedId");

  if (formData && formData.mitgliedId !== mitgliedId) {
    console.log("formData :>> ", formData);
    reset(formData);
  }

  const lastTestingField = [
    {
      type: "date",
      label: "Letzte Prüfung",
      value: "lastTesting",
      required: false,
    },
  ].map((field) => {
    return {
      ...field,
      type: field.type,
      selectList: field.selectList ? field.selectList : null,
      label: field.label,
      value: field.value,
      required: field.required,
      error: field.required ? errors[field.value] : false,
      errorMsg: field.errorMsg ? field.errorMsg : "",
    };
  });

  const newInputFields =
    fullMember &&
    fullMember.map((field) => {
      return {
        ...field,
        type: field.type,
        selectList: field.selectList ? field.selectList : null,
        label: field.label,
        value: field.value,
        required: field.required,
        error: field.required ? errors[field.value] : false,
        errorMsg: field.errorMsg ? field.errorMsg : "",
      };
    });

  const renderInputFields = (inputFieldArray) => {
    return inputFieldArray.map((item, i) => {
      let itemToRender;
      switch (item.type) {
        case "text":
          itemToRender = (
            <TextField
              style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
              variant="outlined"
              margin="normal"
              disabled={item.disabled}
              // disabled={item.disabled ? true : false}
              inputRef={register({ required: item.required })}
              label={
                item.required ? item.label + "*" : item.label + " (optional)"
              }
              name={item.value}
              error={item.required ? item.error : false}
              helperText={item.required ? item.error && item.errorMsg : false}
            />
          );
          break;
        case "select":
          itemToRender = (
            <FormControl
              variant="outlined"
              style={{
                width: 200,
                marginTop: item.value !== "title" ? 15 : 0,
              }}
              error={item.error}
            >
              <InputLabel htmlFor={`${item.value}-select`}>
                {item.required ? item.label + "*" : item.label}
              </InputLabel>
              <Controller
                control={control}
                name={item.value}
                rules={{ required: item.required }}
                as={
                  <Select
                    label={item.required ? item.label + "*" : item.label}
                    labelId={`${item.value}-select`}
                    id={`${item.value}-select`}
                  >
                    {item.selectList.map((select) => (
                      <MenuItem key={select.id} value={select.value}>
                        {select.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              {item.error && <FormHelperText>{item.errorMsg}</FormHelperText>}
            </FormControl>
          );
          break;

        case "date":
          itemToRender = (
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Controller
                  name={item.value}
                  control={control}
                  rules={{ required: item.required }}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      margin="normal"
                      error={item.required ? item.error : false}
                      helperText={
                        item.required ? item.error && item.errorMsg : false
                      }
                      inputVariant="outlined"
                      id="date-picker-dialog"
                      label={item.required ? item.label + "*" : item.label}
                      format="dd.MM.yyyy"
                      inputRef={ref}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          );
          break;
        default:
          itemToRender = <span>Error</span>;
          break;
      }
      if (item.value === "lastTesting") {
        return (
          <Grid item lg={6}>
            {itemToRender}
          </Grid>
        );
      }
      itemToRender = (
        <Grid item lg={item.fieldWidth ? item.fieldWidth : 4} md={4} xs={12}>
          {itemToRender}
        </Grid>
      );
      if (item.value === "title") {
        itemToRender = (
          <Grid container xs={12} lg={12}>
            {itemToRender}
          </Grid>
        );
      }
      return itemToRender;
    });
  };

  const onSubmit = (data) => {
    let newValues = {
      ...defaultFormValues,
    };
    newValues = {
      ...newValues,
      ...data,
      memberType: data.isChild ? "child" : "adult",
    };
    dispatch(masterDataActions.editMitglied(newValues));
    console.log("MITGLIED UPDATED! newValues :>> ", newValues);
    dispatch(masterDataActions.closeMasterDataDialog());
    let alertData = {
      title: "Mitglied erfolgreich bearbeitet!",
      text:
        newValues.lastName +
        " " +
        newValues.firstName +
        " erfolgreich bearbeitet!",
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.ALERT,
        alertData,
        "ALERT"
      )
    );
  };

  const handleDelete = () => {
    console.log("formData", formData);
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.REMOVE_MITGLIED,
        [formData],
        "REMOVE"
      )
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container xs={12} lg={12} md={12} direction="row">
          <Grid item xs={12}>
            <FormGroup row style={{ justifyContent: "space-between" }}>
              <div>
                <label
                  style={{
                    color: isChild ? "grey" : "black",
                    marginLeft: 15,
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Erwachsen
                </label>
                <Controller
                  name="isChild"
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                    />
                  )}
                />
                <label
                  style={{
                    color: isChild ? "black" : "grey",
                    marginTop: "5px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Kind
                </label>
              </div>
              <FormControl
                variant="outlined"
                style={{
                  width: 200,
                  margin: "18px 0",
                }}
                // error={item.error}
              >
                <InputLabel htmlFor={`choose-Standort-select`}>
                  {"Standort"}
                </InputLabel>
                <Controller
                  control={control}
                  name="standortRef"
                  rules={{ required: true }}
                  as={
                    <Select
                      label="Standort"
                      labelId={`choose-Standort-select`}
                      id={`choose-Standort-select`}
                    >
                      {standorteSelectList.map((select, i) => (
                        <MenuItem
                          key={`select-Standort-${i}`}
                          value={select.value}
                        >
                          {select.label}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors["standortRef"] && (
                  <FormHelperText>Bitte einen Standort wählen</FormHelperText>
                )}
              </FormControl>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Card
                    style={{
                      marginTop: 20,
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 5,
                      backgroundColor: degree > 10 ? "lightgrey" : "black",
                    }}
                  >
                    <BeltAvatars beltNumber={degree}></BeltAvatars>
                  </Card>
                </div>
                {renderInputFields(lastTestingField)}
              </div>
            </FormGroup>
          </Grid>
          {renderInputFields(newInputFields)}
          <Grid container>
            <Grid item>
              <FormControl
                variant="outlined"
                style={{
                  width: 200,
                  marginTop: 16,
                }}
                error={errors.payedTill}
              >
                <InputLabel htmlFor={`payedTill-select`}>
                  Bezahlt bis *
                </InputLabel>
                <Controller
                  control={control}
                  name={"payedTill"}
                  rules={{ required: true }}
                  as={
                    <Select
                      label="Bezahlt bis *"
                      labelId={`${"payedTill"}-select`}
                      id={`${"payedTill"}-select`}
                    >
                      {bezahltBisArray.map((select) => (
                        <MenuItem key={select.id} value={select.value}>
                          {select.label}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.payedTill && (
                  <FormHelperText>{"Bitte auswählen"}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 200, marginLeft: 15 }}
                variant="outlined"
                margin="normal"
                inputRef={register({ required: false })}
                label={"Anmeldegebühr"}
                name={"registrationFee"}
                error={errors.registrationFee}
              />
              {errors.registrationFee && (
                <FormHelperText>{"Bitte auswählen"}</FormHelperText>
              )}
            </Grid>
            <Grid item style={{ padding: 20 }}>
              <FormGroup row>
                <Controller
                  name="payRegistrationFee"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                    />
                  )}
                />
                <label
                  style={{
                    color: "black",
                    marginLeft: 0,
                    marginTop: "8px",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                  }}
                >
                  Anmeldegebühr einmalig einziehen?
                </label>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            direction="row-reverse"
            lg={12}
            xs={12}
          >
            <FormGroup row>
              <Button
                style={{ width: "100%", fontSize: 20 }}
                variant="contained"
                color="primary"
                type="submit"
                // value="Bestätigen"
                // onClick={handleSaveEdit}
              >
                Speichern
                <div style={{ marginLeft: 5 }}>
                  <FontAwesomeIcon icon={faSave} />
                </div>
              </Button>
            </FormGroup>
            <FormGroup row>
              <Button
                style={{ width: "100%", fontSize: 20 }}
                variant="contained"
                color="secondary"
                // type="submit"
                // value="Bestätigen"
                onClick={handleDelete}
              >
                Löschen
                <div style={{ marginLeft: 5 }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
