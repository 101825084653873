import masterDataApi from "../../redux/api/masterDataApi";
import { getDateFromString, hasNumber } from "./appUtils";

export const importFields = [
  { value: "empty", text: "Leer" },
  { value: "hasContract", text: "Bezug für Vertragsdaten(Mitglied)" },
  { value: "contractId", text: "Bezug für Mitglied (Vertrag)" },
  { value: "title", text: "Anrede (Mitglied)" },
  { value: "lastName", text: "Nachname (Mitglied)" },
  { value: "firstName", text: "Vorname (Mitglied)" },
  { value: "birthdate", text: "Geburtsdatum (Mitglied)" },
  { value: "degree", text: "Gürtelgrad" },
  { value: "beginningDate", text: "Aktiv seit" },
  { value: "lastTesting", text: "Letzte Prüfung" },
  { value: "contribution", text: "Mitgliedsbeitrag" },
  { value: "memberNumber", text: "Mitgliedsnummer" },
  { value: "memberType", text: "Mitgliedsart" },
  { value: "conctractDate", text: "Vertragsdatum" },
  { value: "contractDuration", text: "Vertragslaufzeit" },
  { value: "VNtitle", text: "Vertragsnehmer Anrede" },
  { value: "VNlastName", text: "Vertragsnehmer Nachname" },
  { value: "VNfirstName", text: "Vertragsnehmer Vorname" },
  { value: "VNbirthdate", text: "Vertragsnehmer Geburtsdatum" },
  { value: "email", text: "E-Mail" },
  { value: "phoneNumber", text: "Telefon" },
  { value: "mobile", text: "Mobil" },
  { value: "job", text: "Beruf" },
  { value: "street", text: "Straße" },
  { value: "zip", text: "PLZ" },
  { value: "city", text: "Ort" },
  { value: "owner", text: "Bankinhaber" },
  { value: "bank", text: "Bank" },
  { value: "iban", text: "IBAN" },
  { value: "bic", text: "BIC" },
  { value: "amount", text: "Beitrag" },
  { value: "curreny", text: "Währung" },
  { value: "mandateId", text: "Mandatsreferenz" },
  { value: "mandateSignatureDate", text: "Signaturdatum" },
  { value: "remittanceInfo", text: "Verwendungszweck" },
];

export async function importOneMemberCreateContract(member, contract) {
  //   console.log("Make async request");
  let resM = await masterDataApi.addMitglied(member);
  //   console.log("resM.data :>> ", resM.data);
  let cToImport = {
    ...contract,
    hasMembers: [await resM.data.id],
  };
  //   console.log("cToImport :>> ", cToImport);
  let resC = await masterDataApi.addVertragsnehmer(cToImport);
  //   console.log("after request");
  return { importedContract: await resC.data, importedMember: await resM.data };
}

export async function resolveAfterImport(membersArray, contractsArray) {
  let results = await Promise.all(
    membersArray.map((m, i) =>
      importOneMemberCreateContract(m, contractsArray[i])
    )
  );

  let contracts = [],
    members = [];

  results.forEach((res) => {
    members.push(res.importedMember);
    contracts.push(res.importedContract);
  });

  return {
    message: "success",
    data: results,
    contracts: contracts,
    members: members,
  };
}

export function assignMembersToContracts(membersArray, contractsArray) {
  let assignedArray = [];
  contractsArray.forEach((contract, i) => {
    let hasMembers = [];
    membersArray.forEach((member, j) => {
      if (
        member.hasContract === contract.contractId
        // parseInt(member.hasContract, 10) === parseInt(contract.contractId, 10)
      ) {
        delete member.hasContract;
        hasMembers.push(member);
        return;
      } else return;
    });
    let ownerName = contract.owner
      ? contract.owner
      : contract.VNfirstName + " " + contract.VNlastName;
    contract = {
      ...contract,
      firstName: contract.VNfirstName
        ? contract.VNfirstName
        : contract.VNlastName,
      lastName: contract.VNlastName,
      title: contract.title ? contract.title : "Herr",
      birthdate: getDateFromString(contract.birthdate),
      isOwner: true,
      owner: ownerName,
      importedMembers: hasMembers,
    };
    console.log("contract :>> ", contract);
    if (hasMembers.length === 0) {
      console.log("contract :>> ", contract);
      contract = null;
      return;
    }
    delete contract.contractId;
    assignedArray.push(contract);
  });
  return assignedArray;
}

export async function importAllMembersFromContractAndImportContract(contract) {
  let importedMembers = [];
  let newMembers = [];
  //   console.log("Make async request");
  let memberResponse = await Promise.all(
    contract.importedMembers.map((m) => masterDataApi.addMitglied(m))
  );

  memberResponse.forEach((resM) => {
    newMembers.push(resM.data.id);
    importedMembers.push(resM.data);
  });

  let cToImport = {
    ...contract,
    hasMembers: newMembers,
  };

  delete cToImport.importedMembers;

  let resC = await masterDataApi.addVertragsnehmer(cToImport);

  return {
    importedContract: await resC.data,
    importedMembers: importedMembers,
  };
  //   console.log("cToImport :>> ", cToImport);
  //   console.log("after request");
}

export async function resolveAfterContractsImport(contractsArray) {
  let results = await Promise.all(
    contractsArray.map((c) => importAllMembersFromContractAndImportContract(c))
  );

  let contracts = [],
    members = [];

  results.forEach((res) => {
    members.push(...res.importedMembers);
    contracts.push(res.importedContract);
  });

  return {
    message: "success",
    data: results,
    contracts: contracts,
    members: members,
  };
}

export function checkDegree(degree) {
  let degreeIsNumber = hasNumber(degree);
  // Check if current Degree is Number
  if (!degreeIsNumber) {
    // If degree matches poom assign poom
    if (degree && degree.toLocaleLowerCase() === "poom") {
      // Correct degree is 11
      return 11;
    } else {
      // else no degree could be matched assign 10. kup
      return 1;
    }
  } else {
    // Degree contains number but:
    // 10. Kup = number 10 => 1. Kup
    // Correct number would be: 1
    // 9. Kup = number 9 => 2. Kup
    // Correct number would be 2
    // Assign correct degree
    let degreeNumber = parseInt(degree);
    let correctDegree = 1;
    if (degreeNumber <= 10) {
      correctDegree = 11 - degreeNumber;
    }
    if (degree.toLocaleLowerCase().includes("dan")) {
      // 1. Dan => 1
      // Correct Number would be 12
      correctDegree = 11 + degreeNumber;
    }
    return correctDegree;
  }
}

export function prepareMemberData(membersArray, selectedStandort) {
  let newMembersList = [];
  const {
    standortId,
    memberContribution,
    contractDuration,
    latestMemberNumber,
    memberIdentifier,
  } = selectedStandort;
  membersArray.forEach((m, i) => {
    let title = m.title ? m.title : "Herr";
    let checkedDegree = checkDegree(m.degree);
    let degree = checkedDegree;
    let memberNumber = m.memberNumber
      ? m.memberNumber
      : `${memberIdentifier}${parseInt(latestMemberNumber) + i}`;
    let contractDate = m.contractDate
      ? getDateFromString(m.contractDate)
      : new Date();
    let duration = m.contractDuration
      ? parseInt(m.contractDuration)
      : contractDuration;
    let contribution = m.contribution ? m.contribution : memberContribution;
    let memberType = "child";

    newMembersList.push({
      ...m,
      title: title,
      lastName: m.lastName,
      firstName: m.firstName,
      birthdate: getDateFromString(m.birthdate),
      memberNumber: memberNumber,
      contractDate: contractDate,
      contractDuration: duration,
      contribution: parseInt(contribution),
      degree: degree,
      status: "active",
      memberType: memberType,
      standortRef: standortId,
    });
  });
  return newMembersList;
}
