import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { AddMemberToAffStandort } from "../standorte/AddMemberToAffStandort";
import { AffiliatedMembersOverview } from "./AffiliatedMembersOverview";

import "./Mitglieder.css";

export default function AffiliatedMembers() {
  const [standortState, setStandortState] = React.useState(null);
  const [menuState, setMenuState] = React.useState(null);
  const { standorteList } = useSelector((state) => state.masterDataReducer);

  const handleSelectStandort = (standort) => {
    setStandortState(standort);
  };

  const handleMenu = (option) => {
    setMenuState(option);
  };

  const handleShowAffMembers = () => {
    if (standortState.members && standortState.members.length > 0) {
      setMenuState(1);
    } else {
      setMenuState(99);
    }
  };

  const handleCloseAddMember = () => {
    setMenuState(null);
  };

  const goToMenu = () => {
    setMenuState(null);
    let site =
      standorteList &&
      standorteList.find((s) => s.standortId === standortState.standortId);
    setStandortState(site);
  };

  let header;

  switch (menuState) {
    case 1:
      header = <Typography variant="h5">Mitglieder anzeigen</Typography>;
      break;
    case 2:
      header = <Typography variant="h5">Mitglied hinzufügen</Typography>;
      break;
    default:
      header = (
        <Typography variant="h5">Was möchten Sie als nächstes tun?</Typography>
      );
      break;
  }

  return (
    <div>
      {!standortState && (
        <div>
          <Paper style={{ padding: 25 }}>
            <Typography variant="h4">
              Wählen Sie zuerst einen angehörigen Standort dessen Mitglieder Sie
              sich anzeigen lassen möchten.
            </Typography>
          </Paper>
          <div id="Affiliated-Standort-Container">
            {standorteList &&
              standorteList.map((standort, i) => {
                if (standort.standortType !== "AFFILIATED") {
                  return null;
                }
                return (
                  <div id="Affiliated-Standort-Item">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ fontSize: 25, minWidth: "220px" }}
                      onClick={() => handleSelectStandort(standort)}
                    >
                      {standort.siteName}
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {standortState && (
        <div>
          <Paper style={{ padding: 25 }}>
            <Typography variant="h4">
              Ausgewählter Standort: {standortState.siteName}
            </Typography>
            {header}
          </Paper>
          {menuState !== null && (
            <div style={{ margin: 15 }}>
              <Button color="default" variant="contained" onClick={goToMenu}>
                Menü
              </Button>
            </div>
          )}
          {menuState === null && (
            <div id="Affiliated-Members-Menu-Container">
              <div id="Affiliated-Members-Menu-Item">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ fontSize: 25, minWidth: "220px" }}
                  onClick={handleShowAffMembers}
                >
                  Mitglieder anzeigen
                </Button>
              </div>
              <div id="Affiliated-Members-Menu-Item">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ fontSize: 25, minWidth: "220px" }}
                  onClick={() => handleMenu(2)}
                >
                  Mitglied hinzufügen
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {menuState === 1 && <AffiliatedMembersOverview site={standortState} />}
      {menuState === 2 && (
        <AddMemberToAffStandort
          standort={standortState}
          newMember
          handleClose={handleCloseAddMember}
          visible={menuState === 2}
        />
      )}
      {menuState === 99 && (
        <div>
          <Typography variant="h4">
            Standort hat noch keine Mitglieder
          </Typography>
        </div>
      )}
    </div>
  );
}
