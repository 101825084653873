import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  IS_LOGGED_IN,
  IS_LOGGED_OUT,
  SET_APP_ERROR,
  LOADING,
  SET_USER,
} from "../constants/appConstants";

const AuthToken = localStorage.getItem("AuthToken");

const initialState = {
  openDialog: null,
  user: AuthToken ? AuthToken : null,
  appError: null,
  loading: true,
  currentUser: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, currentUser: action.currentUser };
    case OPEN_DIALOG:
      return { ...state, openDialog: action.dialog };
    case CLOSE_DIALOG:
      return { ...state, openDialog: null };
    case LOADING:
      return { ...state, loading: action.load };
    case IS_LOGGED_IN:
      return { ...state, user: action.user };
    case IS_LOGGED_OUT:
      return { ...state, user: null, currentUser: null };
    case SET_APP_ERROR:
      return { ...state, appError: action.errorText };
    default:
      return state;
  }
};

export default dialogReducer;
