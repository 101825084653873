import {
  SET_VERTRAGSNEHMERS_LIST,
  SET_MASTER_DATA_ERROR,
  OPEN_MASTER_DATA_DIALOG,
  CLOSE_MASTER_DATA_DIALOG,
  SET_STANDORTE_LIST,
  SET_EVENTS_LIST,
  SET_MITGLIEDER_LIST,
  SET_STANDORTLEITER_LIST,
  SET_MASTER_DATA_RESPONSE,
  CLEAR_STATE,
} from "../constants/masterDataConstants";

const initialState = {
  standorteList: null,
  eventsList: null,
  vertragsnehmerList: null,
  mitgliederList: null,
  standortleiterList: null,
  masterDataError: null,
  openDialog: null,
  dialogType: null,
  dialogData: null,
  masterDataResponse: null,
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return { ...initialState };
    case SET_VERTRAGSNEHMERS_LIST:
      return { ...state, vertragsnehmerList: action.vertragsnehmerList };
    case SET_MASTER_DATA_RESPONSE:
      return { ...state, masterDataResponse: action.masterDataResponse };
    case SET_STANDORTE_LIST:
      return { ...state, standorteList: action.standorteList };
    case SET_EVENTS_LIST:
      return { ...state, eventsList: action.eventsList };
    case SET_MITGLIEDER_LIST:
      return { ...state, mitgliederList: action.mitgliederList };
    case SET_STANDORTLEITER_LIST:
      return { ...state, standortleiterList: action.standortleiterList };
    case OPEN_MASTER_DATA_DIALOG:
      return {
        ...state,
        openDialog: action.dialog,
        dialogType: action.dialogType,
        dialogData: action.dialogData,
      };
    case CLOSE_MASTER_DATA_DIALOG:
      return {
        ...state,
        openDialog: null,
        dialogType: null,
        dialogData: null,
      };
    case SET_MASTER_DATA_ERROR:
      return { ...state, masterDataError: action.masterDataError };
    default:
      return state;
  }
};

export default masterDataReducer;
