import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import {
  createFullDateString,
  getGradCollection,
  sortArrayWithObjects,
} from "../../../utils/dataUtils/appUtils";
import { makePruefungslistPDFfromContent } from "../../../utils/dataUtils/pdfUtils";
import { Print } from "@material-ui/icons";

const gradCollection = getGradCollection();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  buttonContainerSubTable: {
    margin: 10,
  },
});

function Row(props) {
  const { row, handleView, handleCTL, handleDelete, standortLookup } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const handleOpenSingleTestingList = () => {
    handleView();
  };

  const handleClearTestingList = () => {
    handleCTL();
  };
  const handleDeleteTestingList = () => {
    console.log("DELETE");
    handleDelete();
  };

  const handlePrintList = () => {
    // Print Prüfungsliste with highest Degree first
    let sortedPrueflinge = sortArrayWithObjects(
      row.hasAttendees,
      "degree",
      "desc"
    );
    makePruefungslistPDFfromContent(sortedPrueflinge, row, standortLookup);
  };

  row.hasAttendees.forEach((member) => {
    if (gradCollection[member.degree - 1] === undefined) {
      console.log("member :>> ", member);
      console.log("row :>> ", row);
    }
  });

  return (
    <React.Fragment>
      <TableRow hover onClick={() => setOpen(!open)} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.eventName}</TableCell>
        <TableCell align="left">
          {createFullDateString(new Date(row.eventDateBegin))}
        </TableCell>
        <TableCell align="left">
          {row.hasAttendees ? row.hasAttendees.length : 0}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <div className={classes.buttonContainerSubTable}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpenSingleTestingList}
                >
                  Öffnen
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  color="default"
                  variant="contained"
                  startIcon={<Print />}
                  disabled={row.hasAttendees.length === 0}
                  onClick={handlePrintList}
                >
                  Drucken
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  color="default"
                  variant="contained"
                  disabled={row.hasAttendees.length === 0}
                  onClick={handleClearTestingList}
                >
                  Leeren
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  color="default"
                  variant="contained"
                  onClick={handleDeleteTestingList}
                >
                  Löschen
                </Button>
              </div>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nr.</TableCell>
                    <TableCell align="left">Gürtelgrad</TableCell>
                    <TableCell align="left">Standort</TableCell>
                    <TableCell align="left">Nachname</TableCell>
                    <TableCell align="left">Vorname</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.hasAttendees && row.hasAttendees.length > 0 ? (
                    sortArrayWithObjects(
                      row.hasAttendees,
                      "degree",
                      "desc"
                    ).map((member, i) => (
                      <TableRow key={"member-" + i + 1}>
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell align="left">
                          {gradCollection[member.degree - 1] &&
                            gradCollection[member.degree - 1].title}
                        </TableCell>
                        <TableCell align="left">
                          {standortLookup && standortLookup[member.standortRef]}
                        </TableCell>
                        <TableCell align="left">{member.lastName}</TableCell>
                        <TableCell align="left">{member.firstName}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <Typography style={{ margin: 10 }} variant="h6">
                        Noch keine Teilnehmer hinzugefügt
                      </Typography>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PruefungenCollapsibleTable({
  data,
  handleView,
  standortLookup,
}) {
  const dispatch = useDispatch();
  const handleCTL = (tL) => {
    if (tL.hasAttendees.length === 0) {
      console.log("leere liste");
      alert("Diese Liste enthält keine Prüflinge");
      return;
    }
    if ("hasAttendees" in tL) {
      tL.hasAttendees = [];
    }
    dispatch(masterDataActions.editEvent(tL));
    setTimeout(() => {
      dispatch(masterDataActions.getEventsList());
    });
  };

  const handleDelete = (row) => {
    dispatch(masterDataActions.removeEvent(row.eventId));
    alert("Prüfungsliste gelöscht!");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    // window.location.reload();
  };

  console.log("data :>> ", data);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Beginn</TableCell>
            <TableCell align="left">Prüflinge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <Row
              key={i}
              row={row}
              // sortArrayWithObjects(prueflinge, "degree", "desc")
              standortLookup={standortLookup}
              handleCTL={() => handleCTL(row)}
              handleView={() => handleView(1, row)}
              handleDelete={() => handleDelete(row)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
