export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";

export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

export const LOG_OUT = "LOG_OUT";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";

export const LOADING = "LOADING";

export const SET_APP_ERROR = "SET_APP_ERROR";
