import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default function MemberTypeCard(props) {
  const { memberType } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div>
          <div style={{ minHeight: 80, position: "relative" }}>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
              }}
            >
              {memberType.icon ? (
                memberType.icon
              ) : (
                <FontAwesomeIcon icon={faUserPlus} size="3x" />
              )}
            </div>
          </div>
          <Typography variant="h5" component="h2">
            {memberType.text}
          </Typography>
          <hr></hr>
          <Typography variant="body2" component="p">
            {memberType.info}
          </Typography>
        </div>
      </CardContent>
      <CardActions
        className={classes.actions}
        style={{ justifyContent: "center" }}
      >
        {props.children}
      </CardActions>
    </Card>
  );
}
