import axios from "axios";
// import masterDataActions from "../actions/masterDataActions";
// import { URL } from "../app/env";
// const serverName = "http://localhost:5000/todo-app-tut-c2172/us-central1/api";
const serverName = process.env.REACT_APP_BACKEND_SERVER;

const getVertragsnehmer = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/vertragsnehmer/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleVertragsnehmerById = (vertragsnehmerId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .delete(`${serverName}/vertragsnehmer/${vertragsnehmerId}`)
    .catch((error) => {
      console.log(error);
    });
};

const addVertragsnehmer = (vertragsnehmerData) => {
  return (
    axios
      .post(`${serverName}/vertragsnehmer`, { ...vertragsnehmerData })
      // .then(response => response.data)
      .catch((err) => {
        throw err.response && err.response.data && err.response.data.returncode
          ? err.response.data.returncode
          : err;
      })
  );
};

const editVertragsnehmer = (vertragsnehmerData) => {
  return axios
    .put(
      `${serverName}/vertragsnehmer/${vertragsnehmerData.vertragsnehmerId}`,
      { ...vertragsnehmerData }
    )
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

// Begin Mitglieder API
const getMitglieder = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/mitglieder/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleMitgliedById = (mitgliedId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .delete(`${serverName}/mitglieder/${mitgliedId}`)
    .catch((error) => {
      console.log(error);
    });
};

const addMitglied = (mitgliedData) => {
  return axios
    .post(`${serverName}/mitglieder`, { ...mitgliedData })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

const editMitglied = (mitgliedData) => {
  return axios
    .put(`${serverName}/mitglieder/${mitgliedData.mitgliedId}`, {
      ...mitgliedData,
    })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

const editManyMitglieder = async (mitgliederArray) => {
  try {
    return await axios.post(`${serverName}/mitglieder/multiedit`, {
      mitgliederArray: mitgliederArray,
    });
  } catch (err) {
    throw err.response && err.response.data && err.response.data.returncode
      ? err.response.data.returncode
      : err;
  }
};
// End Mitglieder API

// Begin Buchungen API
const getBuchungen = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/buchungen/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleBuchungById = (buchungId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios.delete(`${serverName}/buchungen/${buchungId}`).catch((error) => {
    console.log(error);
  });
};

const addBuchung = (buchungData) => {
  return axios
    .post(`${serverName}/buchungen`, { ...buchungData })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

const editBuchung = (buchungData) => {
  return axios
    .put(`${serverName}/buchungen/${buchungData.buchungId}`, {
      ...buchungData,
    })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};
// End Buchungen API

// Begin Standorte API
const getStandorte = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/standorte/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleStandortById = (standortId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .delete(`${serverName}/standorte/${standortId}`)
    .catch((error) => {
      console.log(error);
    });
};

const addStandort = (standortData) => {
  return axios
    .post(`${serverName}/standorte`, { ...standortData })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

const uploadStandortImage = (form_data, standortId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .post(`${serverName}/standorte/image/${standortId}`, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .catch((error) => {
      if (error.response.status === 403) {
        // history.push('/login');
        console.log("Error Code 403: ", error);
      }
      console.log(error);
    });
};

const editStandort = (standortData) => {
  return axios
    .put(`${serverName}/standorte/${standortData.standortId}`, {
      ...standortData,
    })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};
// End Standorte API

// Begin Events API
const getEvents = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/events/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleEventById = (eventId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios.delete(`${serverName}/events/${eventId}`).catch((error) => {
    console.log(error);
  });
};

const addEvent = (eventData) => {
  return axios.post(`${serverName}/events`, { ...eventData }).catch((err) => {
    throw err.response && err.response.data && err.response.data.returncode
      ? err.response.data.returncode
      : err;
  });
};

const uploadEventImage = (form_data, eventId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .post(`${serverName}/events/image/${eventId}`, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .catch((error) => {
      if (error.response.status === 403) {
        // history.push('/login');
        console.log("Error Code 403: ", error);
      }
      console.log(error);
    });
};

const editEvent = (eventData) => {
  return axios
    .put(`${serverName}/events/${eventData.eventId}`, { ...eventData })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};
// End Events API

// Begin Standortleiter API
const getStandortleiter = () => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .get(`${serverName}/standortleiter/`)
    .then((response) => response.data)
    .catch(() => []);
};

const removeSingleStandortleiterById = (standortleiterId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .delete(`${serverName}/standortleiter/${standortleiterId}`)
    .catch((error) => {
      console.log(error);
    });
};

const addStandortleiter = (standortleiterData) => {
  return axios
    .post(`${serverName}/standortleiter`, { ...standortleiterData })
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};

const uploadStandortleiterImage = (form_data, standortleiterId) => {
  const authToken = localStorage.getItem("AuthToken");
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  return axios
    .post(`${serverName}/standortleiter/image/${standortleiterId}`, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .catch((error) => {
      if (error.response.status === 403) {
        // history.push('/login');
        console.log("Error Code 403: ", error);
      }
      console.log(error);
    });
};

const editStandortleiter = (standortleiterData) => {
  return axios
    .put(
      `${serverName}/standortleiter/${standortleiterData.standortleiterId}`,
      { ...standortleiterData }
    )
    .catch((err) => {
      throw err.response && err.response.data && err.response.data.returncode
        ? err.response.data.returncode
        : err;
    });
};
// End Standortleiter API

// eslint-disable-next-line
export default {
  getVertragsnehmer,
  addVertragsnehmer,
  editVertragsnehmer,
  removeSingleVertragsnehmerById,
  getMitglieder,
  addMitglied,
  editMitglied,
  editManyMitglieder,
  removeSingleMitgliedById,
  getStandorte,
  addStandort,
  uploadStandortImage,
  editStandort,
  removeSingleStandortById,

  getBuchungen,
  addBuchung,
  editBuchung,
  removeSingleBuchungById,

  getEvents,
  addEvent,
  uploadEventImage,
  editEvent,
  removeSingleEventById,

  getStandortleiter,
  addStandortleiter,
  uploadStandortleiterImage,
  editStandortleiter,
  removeSingleStandortleiterById,
};
