import { Button } from "@material-ui/core";
import React from "react";
import "./AlphabetSelect.css";

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default function AlphabetSelect(props) {
  const { getFirstLetter } = props;
  const [selectedLetter, setSelectedLetter] = React.useState(null);
  const handleSelectLetter = (l) => {
    console.log("l :>> ", l);
    setSelectedLetter(l);
    getFirstLetter(l);
  };
  return (
    <div>
      <div id="letterContainer">
        {letters.map((letter, i) => {
          return (
            <div
              key={`letter-${i}`}
              id="letter"
              style={{
                textDecoration: selectedLetter === letter ? "underline" : null,
                fontSize: selectedLetter === letter ? "1.2rem" : "1.1rem",
              }}
              onClick={() => handleSelectLetter(letter)}
            >
              {letter}
            </div>
          );
        })}
      </div>
      {selectedLetter && (
        <div id="buttonContainer">
          <Button variant="outlined" onClick={() => handleSelectLetter(null)}>
            Auswahl zurücksetzen
          </Button>
        </div>
      )}
    </div>
  );
}
