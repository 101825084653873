import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeleteBankAccountDialog({
  accountToDelete,
  handleDeleteAccount,
  resetSelection,
}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    resetSelection();
    setOpen(false);
  };

  const handleDelete = () => {
    handleDeleteAccount();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Konto wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, dass Sie folgendes Konto wirklich löschen möchten?
            <br />
            <table>
              <tbody>
                <tr>
                  <td>Inhaber </td>
                  <td>{accountToDelete.creditorName}</td>
                </tr>
                <tr>
                  <td>IBAN</td>
                  <td>{accountToDelete.creditorIBAN}</td>
                </tr>
                <tr>
                  <td>Gl&auml;ubiger ID</td>
                  <td>{accountToDelete.creditorId}</td>
                </tr>
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            variant="contained"
            style={{ color: "white", backgroundColor: "red" }}
          >
            Löschen
          </Button>
          <Button onClick={handleClose} variant="contained" color="default">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
