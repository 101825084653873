import React from "react";

import "./Spinner.css";

const spinner = (props) => (
  <div className="spinner-container">
    <div className="yy-spinner"></div>
    <div className="spinner-text-loading">
      <h2 className="glow">{props.loadingText}</h2>
    </div>
  </div>
);

export default spinner;
