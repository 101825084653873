import { Button, FormGroup, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gradArray } from "./inputFields";
import "./ControlUploadData.css";
import masterDataApi from "../../../../redux/api/masterDataApi";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import LoadingScreen from "../../../Spinner/LoadingScreen";

const paperStyle = {
  paddingTop: 1,
  paddingLeft: 25,
  paddingBottom: 10,
};

export default function ControlUploadData() {
  const [loading, toggleLoading] = React.useState(false);
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );
  const dispatch = useDispatch();

  const handleUpload = () => {
    toggleLoading(true);
    // TODO: handle new member adult/child and new member to contract

    // Check if new member will be added or if member is added to contract

    const { newMemberData, selectedStandort, contractData } = dialogData;
    console.log("Upload Member and ContractData");
    let updateStandortLatestMemberNumber = {
      ...selectedStandort,
      latestMemberNumber: parseInt(selectedStandort.latestMemberNumber) + 1,
    };
    dispatch(masterDataActions.editStandort(updateStandortLatestMemberNumber));
    console.log("Updated Standort!");
    var uploadMitglied = new Promise((resolve, reject) => {
      masterDataApi
        .addMitglied({
          ...newMemberData,
          status: "active",
          standortRef: selectedStandort.standortId,
        })
        .then((response) => {
          console.log("response from addMitglied :>> ", response);
          let newMember = {
            ...response.data,
          };
          if (dialogData.selectedContract) {
            console.log("add member to contract and edit contract");
            dialogData.selectedContract.hasMembers.push(newMember.id);
            let updatedContract = { ...dialogData.selectedContract };
            dispatch(masterDataActions.editVertragsnehmer(updatedContract));
            resolve([
              "Member was uploaded and Contract updated! New Contract: ",
              updatedContract,
            ]);
          } else {
            console.log("upload new member and add new contract");
            let newContractData = {
              ...contractData,
              hasMembers: [newMember.id],
            };
            masterDataApi
              .addVertragsnehmer(newContractData)
              .then((response) => {
                resolve(response);
              });
          }
        });
    });

    uploadMitglied.then((response) => {
      toggleLoading(false);
      console.log("response from addVertrag :>> ", response);
      dispatch(masterDataActions.getMitgliederList());
      dispatch(masterDataActions.getVertragsnehmerList());
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogConstants.NEW_MITGLIED,
          dialogData,
          "MEMBER_UPLOADED"
        )
      );
    });
  };

  const showContractData = dialogType !== "BANKDATA_SKIPPED";

  const {
    title,
    lastName,
    firstName,
    birthdate,
    contractDate,
    contractDuration,
    contribution,
    degree,
    memberNumber,
    memberType,
  } = dialogData.newMemberData;

  let {
    email,
    city,
    mobile,
    phoneNumber,
    street,
    zip,
    owner,
    bank,
    iban,
    bic,
  } = dialogData.contractData;

  let previewMemberData = (
    <Grid container>
      <Grid item lg={4} xs={12}>
        <table>
          <tr>
            <th>Anrede:</th>
            <td>{title}</td>
          </tr>
          <tr>
            <th>Nachname:</th>
            <td>{lastName}</td>
          </tr>
          <tr>
            <th>Vorname:</th>
            <td>{firstName}</td>
          </tr>
          <tr>
            <th>Geburtsdatum:</th>
            <td>{new Date(birthdate).toLocaleDateString()}</td>
          </tr>
        </table>
      </Grid>
      <Grid item lg={4} xs={12}>
        <table>
          <tr>
            <th>Art des Mitglieds: </th>
            <td>{memberType === "child" ? "Kind" : "Erwachsen"}</td>
          </tr>
          <tr>
            <th>Mitgliedsnummer: </th>
            <td>{memberNumber}</td>
          </tr>
          <tr>
            <th>Mitgliedsbeitrag: </th>
            <td>{contribution}</td>
          </tr>
          <tr>
            <th>Gürtelgrad: </th>
            <td>{gradArray[degree - 1].label}</td>
          </tr>
        </table>
      </Grid>
      <Grid item lg={4} xs={12}>
        <table>
          <tr>
            <th>Vertragsdatum: </th>
            <td>{new Date(contractDate).toLocaleDateString()}</td>
          </tr>
          <tr>
            <th>Vertragslaufzeit: </th>
            <td>{contractDuration}</td>
          </tr>
        </table>
      </Grid>
    </Grid>
  );

  let previewContactData = dialogData.contractData && (
    <Grid item lg={4} xs={12}>
      <h3>Kontaktdaten</h3>
      <table>
        <tr>
          <th>Straße: </th>
          <td>{street}</td>
        </tr>
        <tr>
          <th>PLZ: </th>
          <td>{zip}</td>
        </tr>
        <tr>
          <th>Ort: </th>
          <td>{city}</td>
        </tr>
        <tr>
          <th>Telefon: </th>
          <td>{phoneNumber}</td>
        </tr>
        <tr>
          <th>Mobil: </th>
          <td>{mobile}</td>
        </tr>
        <tr>
          <th>E-Mail: </th>
          <td>{email}</td>
        </tr>
      </table>
    </Grid>
  );

  let previewBankData = dialogData.contractData && (
    <Grid item lg={4} xs={12}>
      <h3>Bankdaten</h3>
      <table>
        <tr>
          <th>Inhaber: </th>
          <td>{owner}</td>
        </tr>
        <tr>
          <th>Bank: </th>
          <td>{bank}</td>
        </tr>
        <tr>
          <th>IBAN: </th>
          <td>{iban}</td>
        </tr>
        <tr>
          <th>BIC:</th>
          <td>{bic}</td>
        </tr>
      </table>
    </Grid>
  );

  let previewContractPersonData = (
    <Grid item lg={4} xs={12}>
      <h3>Vertragsperson</h3>
      <table>
        <tr>
          <th>Anrede: </th>
          <td>{dialogData.contractData.title}</td>
        </tr>
        <tr>
          <th>Nachname: </th>
          <td>{dialogData.contractData.lastName}</td>
        </tr>
        <tr>
          <th>Vorname: </th>
          <td>{dialogData.contractData.firstName}</td>
        </tr>
        <tr>
          <th>Geburtsdatum: </th>
          <td>
            {new Date(dialogData.contractData.birthdate).toLocaleDateString()}
          </td>
        </tr>
      </table>
    </Grid>
  );

  return (
    <div style={{ overflow: "hidden" }}>
      {loading && <LoadingScreen></LoadingScreen>}
      <Grid container>
        <Grid item lg={12}>
          <Typography variant="h5">
            Bitte kontrollieren Sie die eingegeben Daten. Sind die Daten
            korrekt, klicken Sie auf <b>{"übernehmen".toLocaleUpperCase()}</b>{" "}
            um das neue Mitglied anzulegen.
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <h3>Ausgewählter Standort: {dialogData.selectedStandort.siteName}</h3>
        </Grid>
        <Grid item lg={6}>
          <Grid container direction="row-reverse" lg={12} xs={12}>
            <Grid item lg={6} xs={12}>
              <FormGroup row>
                <Button
                  style={{ width: "100%", fontSize: 20, marginBottom: 15 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleUpload}
                >
                  Übernehmen
                </Button>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid id="showData" spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Paper style={paperStyle}>
            <Grid item xs={12}>
              <h3>Daten des Mitglieds</h3>
            </Grid>
            {previewMemberData}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={paperStyle}>
            <Grid container>
              <Grid item xs={12}>
                <h3>Vertragsdaten</h3>
              </Grid>
              {previewContractPersonData}
              {showContractData && previewContactData}
              {showContractData && previewBankData}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row-reverse" lg={12} xs={12}>
        <Grid item lg={3} xs={12}>
          <FormGroup row>
            <Button
              style={{ width: "100%", fontSize: 20, marginBottom: 15 }}
              variant="contained"
              color="secondary"
              onClick={handleUpload}
            >
              Übernehmen
            </Button>
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}
