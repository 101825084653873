import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import {
  removeFromTestingList,
  removeFromTestingListUpadedBelt,
  sortArrayWithObjects,
} from "../../../utils/dataUtils/appUtils";
import LoadingScreen from "../../Spinner/LoadingScreen";
import { PruefungsListDetailTable } from "./PruefungsListDetailTable";

import "./Pruefungen.css";
import { makePruefungslistPDFfromContent } from "../../../utils/dataUtils/pdfUtils";
import { Print } from "@material-ui/icons";

export default function PruefungsListDetailView({
  selectedEvent,
  handleBack,
  standortLookup,
}) {
  const [loading, setLoading] = React.useState(true);
  const [prueflinge, setPrueflinge] = React.useState([]);
  const [showGraduierenDialog, setShowGraduierenDialog] = React.useState(false);
  const { mitgliederList } = useSelector((state) => state.masterDataReducer);
  const dispatch = useDispatch();

  const updatePrueflinge = (newPrueflingeList) => {
    setPrueflinge(newPrueflingeList);
  };

  const handleShowGraduierenDialog = (b) => {
    setShowGraduierenDialog(b);
  };

  useEffect(() => {
    setLoading(false);
    if (selectedEvent && "hasAttendees" in selectedEvent) {
      let pL = [];
      selectedEvent.hasAttendees.forEach((m, i) => {
        if (m.hasTesting === true) {
          let p = {
            ...m,
            hasPayedTesting: m.hasPayedTesting ? m.hasPayedTesting : false,
            hasPassedTesting: m.hasPassedTesting ? m.hasPassedTesting : false,
            hasPassport: m.hasPassport ? m.hasPassport : false,
          };
          pL.push(p);
        } else return;
      });
      let sortedPrueflinge = sortArrayWithObjects(pL, "degree", "desc");
      setPrueflinge(sortedPrueflinge);
    }
  }, [setLoading, selectedEvent]);

  const handleClearTestingList = async () => {
    let clearedEvent = { ...selectedEvent };
    clearedEvent.hasAttendees = [];
    dispatch(masterDataActions.editEvent(clearedEvent));
    try {
      await removeFromTestingList(prueflinge);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(masterDataActions.getMitgliederList(0));
      dispatch(masterDataActions.getEventsList(0));
      console.log("removed!");
      setLoading(false);
      handleBack();
    }
  };

  const handlePrintList = () => {
    // Print Prüfungsliste with highest Degree first
    let sortedPrueflinge = sortArrayWithObjects(prueflinge, "degree", "desc");
    makePruefungslistPDFfromContent(
      sortedPrueflinge,
      selectedEvent,
      standortLookup
    );
  };

  const handlePassedTestingList = async (gradeAll, keepList) => {
    setLoading(true);

    let passedEvent = { ...selectedEvent };
    let notPassed = prueflinge.filter((p) => p.hasPassedTesting === false);
    if (gradeAll && !keepList) {
      notPassed = [];
    }
    passedEvent.hasAttendees = notPassed.map((m) => m.mitgliedId);
    setPrueflinge(notPassed);
    dispatch(masterDataActions.editEvent(passedEvent));
    try {
      await removeFromTestingListUpadedBelt(
        prueflinge,
        selectedEvent.eventDateBegin,
        gradeAll
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(masterDataActions.getMitgliederList(0));
      dispatch(masterDataActions.getEventsList(0));
      setLoading(false);
    }
  };

  if (mitgliederList === null) {
    return <LoadingScreen />;
  }

  if (prueflinge.length === 0 && mitgliederList !== null) {
    return (
      <div>
        <Paper style={{ padding: 50 }}>
          <Typography variant="h4">
            Die Prüfungsliste enthält noch keine Mitglieder die zur Prüfung
            aufgestellt wurden.
          </Typography>
          <Typography style={{ marginTop: 50 }} variant="h5">
            Wählen Sie dafür im Bereich Mitglieder Ihre Prüflinge aus und fügen
            Sie diese über die Funktion{" "}
            <b>{"Zur Prüfung aufstellen".toLocaleUpperCase()}</b> der
            Prüfungsliste hinzu.
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      {loading && <LoadingScreen />}
      {showGraduierenDialog && (
        <GraduierenDialog
          handleShowGraduierenDialog={handleShowGraduierenDialog}
          handlePassedTestingList={handlePassedTestingList}
        />
      )}
      <div>
        <Typography style={{ margin: 15 }} variant="h4">
          Prüfungsliste {selectedEvent.eventName}
        </Typography>
        {prueflinge && (
          <PruefungsListDetailTable
            prueflinge={prueflinge}
            updatePrueflinge={updatePrueflinge}
            selectedEvent={selectedEvent}
          />
        )}
      </div>
      <div id="testing-button-container">
        <Button
          style={{ margin: 7 }}
          color="primary"
          variant="outlined"
          onClick={handleClearTestingList}
        >
          Prüfungsliste leeren
        </Button>
        <Button
          style={{ margin: 7 }}
          color="primary"
          variant="contained"
          startIcon={<Print />}
          onClick={handlePrintList}
        >
          Prüfungsliste drucken
        </Button>
        <Button
          style={{ margin: 7 }}
          color="primary"
          variant="contained"
          onClick={() => {
            handleShowGraduierenDialog(true);
          }}
        >
          Mitglieder graduieren
        </Button>
      </div>
    </div>
  );
}

function GraduierenDialog(props) {
  const { handleShowGraduierenDialog, handlePassedTestingList } = props;

  const handleClose = () => {
    handleShowGraduierenDialog(false);
  };

  const handleGraduieren = (gradeAll, keepList) => {
    console.log("GRADUIEREN");
    handleShowGraduierenDialog(false);
    handlePassedTestingList(gradeAll, keepList);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Sind Sie sicher, dass Sie alle bestandenen Mitglieder graduieren möchten?"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jedem Mitglied, das als <b>Bestanden</b> markiert ist, wird der
            Gürtel-Grad um einen Grad nach oben gesetzt und das Datum der
            Prüfung als letzte Prüfung eingetragen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={() => handleGraduieren(false, false)}
            variant="contained"
            color="primary"
          >
            Graduieren
          </Button>
          <Button
            onClick={() => handleGraduieren(true, false)}
            variant="contained"
            color="primary"
          >
            Alle Graduieren
          </Button>
          <Button
            onClick={() => handleGraduieren(true, true)}
            variant="contained"
            color="primary"
          >
            Alle Graduieren und Liste behalten
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
