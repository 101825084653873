import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import pic from "../../../img/backgrounds/krater_vulkan.png";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit, GroupAdd } from "@material-ui/icons";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import AddMembersToEvent from "./AddMembersToEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function EventItem(props) {
  const { mitgliederList } = useSelector((state) => state.masterDataReducer);
  const classes = useStyles();
  const { event } = props;
  const { eventName, newImage, street, zip, city, eventId } = event;
  const [showDialog, setShowDialog] = React.useState(false);
  const dispatch = useDispatch();

  const openDelete = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.REMOVE_EVENT,
        event,
        "REMOVE"
      )
    );
  };

  const openEdit = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_EVENT,
        event,
        "EDIT"
      )
    );
  };

  const handleAddMembers = () => {
    setShowDialog(true);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={`TWIN Taekwondo ${eventName}`}
        // subheader={eventName}
      />
      <CardMedia
        className={classes.media}
        image={newImage ? newImage.imageUrl : pic}
        title="Standort Image"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {street} <br></br>
          {zip}, {city}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={() => openDelete()} aria-label="Standort löschen">
          <Delete />
        </IconButton>
        <IconButton onClick={openEdit} aria-label="add to favorites">
          <Edit />
        </IconButton>
        <IconButton onClick={() => handleAddMembers()} aria-label="add-members">
          <GroupAdd />
        </IconButton>
      </CardActions>
      {showDialog && (
        <AddMembersToEvent
          eventId={eventId}
          showDialog={showDialog}
          hideDialog={hideDialog}
          mitgliederList={mitgliederList}
        />
      )}
    </Card>
  );
}
