import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";

import axios from "axios";
// import { authMiddleWare } from '../util/auth';
import { authMiddleWare } from "../../API/authAPI";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const serverName = process.env.REACT_APP_BACKEND_SERVER;

export default function Account(props) {
  const history = useHistory();
  const classes = useStyles();
  const [uiLoading, setUiLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [imageError, setImageError] = useState("");
  const [user, setUser] = useState([]);

  const setCurrentUser = (userData) => {
    setProfilePicture(userData.imageUrl);
    setUser({ ...userData });
  };

  const handleImageChange = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  // const {handleChange} = HandleFormHook();
  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const profilePictureHandler = (event) => {
    event.preventDefault();
    setUiLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    let form_data = new FormData();
    form_data.append("image", profilePicture);
    // form_data.append('content', content);
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .post(`${serverName}/user/image`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setUiLoading(false);
        setImageError("Error in posting the data");
      });
  };

  const updateFormValues = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      country: user.country,
    };
    axios
      .post(`${serverName}/user`, formRequest)
      .then(() => {
        setButtonLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    authMiddleWare(history);
    async function fetchData() {
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      try {
        const response = await axios.get(`${serverName}/user`);
        console.log("response :>> ", response);
        setCurrentUser({ ...response.data.userCredentials });
        setUiLoading(false);
      } catch (error) {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setErrorMsg({ errorMsg: "Error in retrieving the data" });
      }
    }
    fetchData();

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [history]);

  const { ...rest } = props;

  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  }
  if (errorMsg !== "") {
    return (
      <div className={classes.root}>{errorMsg && <h4>{errorMsg}</h4>}</div>
    );
  } else {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Card {...rest} className={clsx(classes.root, classes)}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography
                  className={classes.locationText}
                  gutterBottom
                  variant="h4"
                >
                  {user.firstName} {user.lastName}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                  className={classes.uploadButton}
                  onClick={profilePictureHandler}
                >
                  Upload Photo
                </Button>
                <input type="file" onChange={handleImageChange} />

                {imageError ? (
                  <div className={classes.customError}>
                    {" "}
                    Wrong Image Format || Supported Format are PNG and JPG
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
            <div className={classes.progress} />
          </CardContent>
          <Divider />
        </Card>

        <br />
        <Card {...rest} className={clsx(classes.root, classes)}>
          <form autoComplete="off" noValidate>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    margin="dense"
                    name="firstName"
                    variant="outlined"
                    value={user.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="dense"
                    name="lastName"
                    variant="outlined"
                    value={user.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="email"
                    variant="outlined"
                    disabled={true}
                    value={user.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    margin="dense"
                    name="phone"
                    type="number"
                    variant="outlined"
                    disabled={true}
                    value={user.phoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="User Name"
                    margin="dense"
                    name="userHandle"
                    disabled={true}
                    variant="outlined"
                    value={user.username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    margin="dense"
                    name="country"
                    variant="outlined"
                    value={user.country}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions />
          </form>
        </Card>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={classes.submitButton}
          onClick={updateFormValues}
          disabled={buttonLoading || !user.firstName || !user.lastName}
        >
          Save details
          {buttonLoading && (
            <CircularProgress size={30} className={classes.progess} />
          )}
        </Button>
      </main>
    );
  }
}
