import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MobileStepper,
  Typography,
} from "@material-ui/core";
import {
  Cancel,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";
import { NewMemberForm } from "./DialogPages/NewMemberForm";
import SelectMemberType from "./DialogPages/SelectMemberType";
import SelectStandort from "./DialogPages/SelectStandort";
import ContractDataForm from "./DialogPages/ContractDataForm";
import ControlUploadData from "./DialogPages/ControlUploadData";
import SelectContract from "./DialogPages/SelectContract";
import UploadDone from "./DialogPages/UploadDone";
import appActions from "../../../redux/actions/appActions";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function NewMitgliedDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [showQuitDialog, handleShowQuitDialog] = React.useState(false);
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  const dialogInput = [
    {
      step: 0,
      titleText:
        "Wählen Sie einen Standort aus dem Sie ein Mitglied hinzufügen möchten:",
      content: <SelectStandort></SelectStandort>,
      disableNext: true,
    },
    {
      step: 1,
      titleText: "Welche Art von Mitglied möchten Sie anlegen?",
      content: <SelectMemberType></SelectMemberType>,
      disableNext: true,
    },
    {
      step: 2,
      titleText:
        "Wählen Sie aus welchem Vertrag Sie ein Mitglied hinzufügen möchten.",
      content: <SelectContract></SelectContract>,
      disableNext: true,
    },
    {
      // Add full Member
      step: 3,
      titleText: `Daten für ${
        dialogData && dialogData.memberType === "adult"
          ? "volljähriges"
          : "minderjähriges"
      } Mitglied hier eingeben: `,
      content: (
        <NewMemberForm
          standort={dialogData && dialogData.selectedStandort}
          formData={dialogData && dialogData.newMemberData}
          newMember={true}
        ></NewMemberForm>
      ),
      disableNext: true,
    },
    {
      step: 4,
      titleText: "Weitere Vertragsdaten (optional)",
      content: (
        <ContractDataForm
          memberType={dialogData && dialogData.memberType}
          selectedStandort={dialogData && dialogData.selectedStandort}
          memberData={dialogData && dialogData.newMemberData}
          contractData={dialogData && dialogData.contractData}
          newContract={true}
        ></ContractDataForm>
      ),
    },
    {
      step: 5,
      titleText: "Daten prüfen",
      content: <ControlUploadData></ControlUploadData>,
    },
    {
      step: 6,
      titleText: "Hinzufügen erfolgreich",
      content: <UploadDone dialogData={dialogData && dialogData}></UploadDone>,
    },
  ];

  console.log("activeStep :>> ", activeStep);

  useEffect(() => {
    dispatch(appActions.getUser());
    switch (dialogType) {
      case "STANDORT_SELECTED":
        console.log("standort selected");
        console.log("dialogData :>> ", dialogData);
        if (activeStep === 0) {
          if (dialogData.selectedContract) {
            let newDialogData = {
              ...dialogData,
              contractData: dialogData.selectedContract,
            };
            dispatch(
              masterDataActions.openMasterDataDialog(
                dialogConstants.NEW_MITGLIED,
                newDialogData,
                "CONTRACT_SELECTED"
              )
            );
            handleNext(2);
            break;
          } else {
            handleNext();
          }
        }
        break;

      case "MEMBERTYPE_SELECTED":
        console.log("Type selected");
        console.log("dialogData :>> ", dialogData);
        if (activeStep === 1) {
          switch (dialogData.memberType) {
            case "adult":
              handleNext(3);
              break;
            case "child":
              handleNext(3);
              break;

            case "addMemberToContract":
              handleNext();
              break;

            default:
              break;
          }
        }
        break;

      case "CONTRACT_MEMBER_SELECTED":
        if (activeStep === 2) {
          handleNext();
        }
        break;

      case "NEW_MEMBER_DATA":
        console.log("new full member data");
        console.log("dialogData :>> ", dialogData);
        if (activeStep === 3) {
          if (dialogData.selectedContract) {
            console.log("a contract was added before!");
            handleNext(5);
            break;
          }
          console.log("Go to Step 4");
          handleNext(4);
        }
        break;
      case "BANKDATA_ADDED":
        console.log("bankdata added, current step: ", activeStep);
        if (activeStep === 4) {
          console.log("dialogData :>> ", dialogData);
          handleNext();
        }
        break;
      case "BANKDATA_SKIPPED":
        console.log("bankdata skipped, current step: ", activeStep);
        if (activeStep === 4) {
          console.log("dialogData :>> ", dialogData);
          handleNext();
        }
        break;

      case "MEMBER_UPLOADED":
        if (activeStep === 5) {
          handleNext();
        }
        break;
      default:
        break;
    }
  }, [dialogType, dialogData, activeStep, dispatch]);

  const handleNext = (step) => {
    if (step) {
      setActiveStep(step);
      return;
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const resetDialog = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        dispatch(
          masterDataActions.openMasterDataDialog(dialogConstants.NEW_MITGLIED)
        );
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case 2:
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.NEW_MITGLIED,
            dialogData,
            "STANDORT_SELECTED"
          )
        );
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case 3:
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.NEW_MITGLIED,
            dialogData,
            "STANDORT_SELECTED"
          )
        );
        setActiveStep(1);
        break;
      case 4:
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.NEW_MITGLIED,
            dialogData,
            "MEMBERTYPE_SELECTED"
          )
        );
        setActiveStep(3);
        break;
      case 5:
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogConstants.NEW_MITGLIED,
            dialogData,
            "NEW_MEMBER_DATA"
          )
        );
        setActiveStep(4);
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
    }
  };
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogConstants.NEW_MITGLIED;

  const handleClose = (e, askQuit) => {
    if (askQuit) {
      dispatch(masterDataActions.closeMasterDataDialog());
      resetDialog();
    } else {
      handleShowQuitDialog(true);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={visible}
      disableBackdropClick={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogActions>
        <Button onClick={handleClose} color="inherit" autoFocus>
          <Cancel />
        </Button>
      </DialogActions>
      <DialogTitle id="newMitglied-dialog-title">
        <Typography
          variant="h4"
          style={{
            borderBottomStyle: "solid",
            borderWidth: "2px",
            borderColor: "lightgrey",
          }}
        >
          {dialogInput[activeStep].titleText}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600 }}>
        {dialogInput[activeStep].content}
        {showQuitDialog && (
          <QuitDialog
            handleShowQuitDialog={handleShowQuitDialog}
            resetDialog={resetDialog}
          />
        )}
      </DialogContent>
      <MobileStepper
        variant="progress"
        steps={dialogInput.length}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          activeStep === dialogInput.length - 1 ? (
            <Button
              size="large"
              onClick={(e) => handleClose(e, true)}
              color="secondary"
              variant="contained"
            >
              Beenden
            </Button>
          ) : (
            <Button
              size="large"
              onClick={handleNext}
              color="primary"
              variant="contained"
              disabled={true}
            >
              <KeyboardArrowRight />
            </Button>
          )
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Zurück
          </Button>
        }
      />
    </Dialog>
  );
}

function QuitDialog(props) {
  const { handleShowQuitDialog, resetDialog } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    handleShowQuitDialog(false);
  };

  const handleExit = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
    resetDialog();
    handleShowQuitDialog(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sind Sie sicher, dass Sie beenden möchten?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Alle Änderungen gehen verloren.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Weiterarbeiten
          </Button>
          <Button
            onClick={handleExit}
            variant="contained"
            style={{ backgroundColor: "#db0000", color: "white" }}
          >
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
