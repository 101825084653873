import React from "react";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

const localeMap = {
  de: deLocale,
};

export default function DatePicker(props) {
  const { name, label, value, onChange, onError } = props;

  const [locale, setLocale] = React.useState("de");

  if (false) {
    setLocale("de");
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <KeyboardDateTimePicker
        // variant="inline"
        inputVariant="outlined"
        ampm={false}
        label={label}
        format="dd.MM.yyyy HH:mm"
        // format={"DD.MM.YYYY"}
        name={name}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    </MuiPickersUtilsProvider>
  );
}
