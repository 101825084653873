import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import EventItemCard from "./EventItem";
import { useSelector } from "react-redux";
import LoadingScreen from "../../Spinner/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 250,
  },
}));

export default function EventsGrid() {
  const classes = useStyles();
  const { eventsList } = useSelector((state) => state.masterDataReducer);

  console.log("eventsList :>> ", eventsList);
  console.log("Array.isArray(eventsList) :>> ", Array.isArray(eventsList));

  return (
    <React.Fragment>
      {!Array.isArray(eventsList) && <LoadingScreen></LoadingScreen>}
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={8}>
            {eventsList &&
              eventsList.map((event, i) => (
                <Grid key={i} item>
                  {/* <Paper className={classes.paper} > */}
                  <EventItemCard event={event}></EventItemCard>
                  {/* </Paper> */}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
