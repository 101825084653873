import { Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  assignMembersToContracts,
  prepareMemberData,
} from "../../../../utils/dataUtils/importUtils";
import ImportedDataCollapsibleTable from "./ImportedDataCollapsibleTable";
import ImportedDataTable from "./ImportedDataTable";

export default function CheckData() {
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  console.log("dialogData :>> ", dialogData);

  let option, tableData;

  if (dialogData && dialogData.selectedOption === "3") {
    option = 3;
    let preparedMemberList =
      dialogData &&
      prepareMemberData(dialogData.importedData, dialogData.selectedStandort);

    let assignedArray =
      dialogData &&
      assignMembersToContracts(
        preparedMemberList,
        dialogData.importedContractData
      );

    tableData = assignedArray;
  }
  let siteName;
  siteName =
    dialogData &&
    dialogData.selectedStandort &&
    dialogData.selectedStandort.siteName;

  if (dialogData) {
    return (
      <div style={{ margin: "15px" }}>
        <div style={{ marginBottom: "15px" }}>
          <Typography variant="h4">
            Ausgewählter Standort: {siteName}
          </Typography>
        </div>
        {option === 3 ? (
          <ImportedDataCollapsibleTable data={tableData} />
        ) : (
          <ImportedDataTable data={dialogData} />
        )}
      </div>
    );
  } else {
    return <div>Keine Daten. Bitte neu Laden.</div>;
  }
}
