import React from "react";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faCheck,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { affiliatedMemberFields } from "../../Dialogs/NewMitglied/DialogPages/inputFields";
import BeltAvatars from "../../Avatar/Belts/BeltAvatar";
import Slide from "@material-ui/core/Slide";
import masterDataActions from "../../../redux/actions/masterDataActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddMemberToAffStandort(props) {
  const { standort, newMember, visible, handleClose } = props;
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  let defaultFormValues = {
    standortRef: standort.standortId,
    birthdate: new Date(),
    beginningDate: new Date(),
  };

  const { register, handleSubmit, control, errors, watch } = useForm({
    defaultValues: defaultFormValues,
  });

  const degree = watch("degree");

  const newInputFields =
    affiliatedMemberFields &&
    affiliatedMemberFields.map((field) => {
      return {
        ...field,
        type: field.type,
        selectList: field.selectList ? field.selectList : null,
        label: field.label,
        value: field.value,
        required: field.required,
        error: field.required ? errors[field.value] : false,
        errorMsg: field.errorMsg ? field.errorMsg : "",
      };
    });

  const renderInputFields = (inputFieldArray) => {
    return inputFieldArray.map((item, i) => {
      let itemToRender;
      switch (item.type) {
        case "text":
          itemToRender = (
            <TextField
              style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
              autoComplete={false}
              variant="outlined"
              margin="normal"
              disabled={item.disabled}
              // disabled={item.disabled ? true : false}
              inputRef={register({ required: item.required })}
              label={
                item.required ? item.label + "*" : item.label + " (optional)"
              }
              name={item.value}
              error={item.required ? item.error : false}
              helperText={item.required ? item.error && item.errorMsg : false}
            />
          );
          break;
        case "select":
          itemToRender = (
            <FormControl
              variant="outlined"
              style={{
                width: 200,
                marginTop: item.value !== "title" ? 15 : 0,
              }}
              error={item.error}
            >
              <InputLabel htmlFor={`${item.value}-select`}>
                {item.required ? item.label + "*" : item.label}
              </InputLabel>
              <Controller
                control={control}
                name={item.value}
                rules={{ required: item.required }}
                as={
                  <Select
                    label={item.required ? item.label + "*" : item.label}
                    labelId={`${item.value}-select`}
                    id={`${item.value}-select`}
                  >
                    {item.selectList.map((select) => (
                      <MenuItem key={select.id} value={select.value}>
                        {select.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              {item.error && <FormHelperText>{item.errorMsg}</FormHelperText>}
            </FormControl>
          );
          break;

        case "date":
          itemToRender = (
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Controller
                  name={item.value}
                  control={control}
                  rules={{ required: item.required }}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      margin="normal"
                      error={item.required ? item.error : false}
                      helperText={
                        item.required ? item.error && item.errorMsg : false
                      }
                      inputVariant="outlined"
                      id="date-picker-dialog"
                      label={item.required ? item.label + "*" : item.label}
                      format="dd.MM.yyyy"
                      inputRef={ref}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          );
          break;
        default:
          itemToRender = <span>Error</span>;
          break;
      }
      itemToRender = (
        <Grid item lg={item.fieldWidth ? item.fieldWidth : 4} md={4} xs={12}>
          {itemToRender}
        </Grid>
      );
      if (item.value === "title") {
        itemToRender = (
          <Grid container xs={12} lg={12}>
            {itemToRender}
          </Grid>
        );
      }
      return itemToRender;
    });
  };

  const onSubmit = (data) => {
    setLoading(true);
    const newMemberData = {
      ...data,
      isAffiliated: true,
    };
    standort.members
      ? standort.members.push(newMemberData)
      : (standort.members = [newMemberData]);

    console.log("standort", standort);
    dispatch(masterDataActions.editStandort(standort));
    setTimeout(() => {
      setLoading("SUCCESS");
    }, 1000);
    setTimeout(() => {
      setLoading(false);
      dispatch(masterDataActions.getStandorteList());
      handleClose();
    }, 1800);
  };

  const handleSaveEdit = () => {};

  return (
    <div>
      <Dialog
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        minHeight="700px"
        maxWidth={"lg"}
        disableBackdropClick={true}
        onClose={handleClose}
        aria-labelledby="member-dialog-title"
        open={visible}
      >
        <DialogTitle id="member-dialog-title">
          Mitglied zu {standort.siteName} hinzufügen
        </DialogTitle>
        <DialogContent>
          {loading === "SUCCESS" && (
            <div
              style={{
                minHeight: "400px",
                display: "flex",
                justifyContent: "center",
                paddingTop: 80,
              }}
            >
              <FontAwesomeIcon
                style={{ color: "#1dc81d" }}
                size="10x"
                icon={faCheck}
              />
              <Typography variant="h4" align="center">
                Mitglied hinzugefügt!
              </Typography>
            </div>
          )}
          {loading && loading !== "SUCCESS" && (
            <div
              style={{
                minHeight: "400px",
                display: "flex",
                justifyContent: "center",
                paddingTop: 80,
              }}
            >
              <CircularProgress size={"200px"} />
            </div>
          )}
          {!loading && (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container xs={12} lg={12} md={12} direction="row">
                {renderInputFields(newInputFields)}
                <Grid item>
                  <div>
                    <Card
                      style={{
                        marginTop: 20,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 5,
                        backgroundColor: degree > 10 ? "lightgrey" : "black",
                      }}
                    >
                      <BeltAvatars beltNumber={degree}></BeltAvatars>
                    </Card>
                  </div>
                </Grid>
                <Grid container direction="row-reverse" lg={12} xs={12}>
                  <FormGroup row>
                    {newMember ? (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        value="Bestätigen"
                        disabled={loading}
                      >
                        Bestätigen
                        <div style={{ marginLeft: 5 }}>
                          <FontAwesomeIcon icon={faArrowCircleRight} />
                        </div>
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        variant="contained"
                        color="primary"
                        // type="submit"
                        // value="Bestätigen"
                        onClick={handleSaveEdit}
                      >
                        Speichern
                        <div style={{ marginLeft: 5 }}>
                          <FontAwesomeIcon icon={faSave} />
                        </div>
                      </Button>
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
