import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";

export default function MemberOverview() {
  const { currentUser } = useSelector((state) => state.appReducer);
  const dataRef = useRef(false);
  const [data, setData] = React.useState(null);

  // let data = [];

  const prepareOverviewData = () => {
    let dataChart1 = [],
      dataChart2 = [];
    dataChart1.push(["Standortname", "Mitgliederanzahl (Aktiv)"]);
    dataChart2.push(["Standortname", "Summe Mitgliedsbeiträge"]);
    const { statistics } = currentUser;
    console.log("statistics :>> ", statistics);
    const { standorteStatistic } = statistics;
    for (let key in standorteStatistic) {
      console.log(key, standorteStatistic[key]);
      dataChart1.push([
        standorteStatistic[key].name,
        standorteStatistic[key].memberCounter,
      ]);
      dataChart2.push([
        standorteStatistic[key].name,
        standorteStatistic[key].memberContributionSum,
      ]);
    }
    // data = [
    //   // { name: 'NAME 1', value: 94}
    //   { name: "Aktive Mitglieder", value: statistics.activeMembers },
    //   { name: "Eingefrorene Mitglieder", value: statistics.frozenMembers },
    //   { name: "Gekündigte Mitglieder", value: statistics.terminatedMembers },
    // ];
    setData({ chart1: dataChart1, chart2: dataChart2 });
  };

  if (!dataRef.current && currentUser && currentUser.statistics) {
    prepareOverviewData();
    dataRef.current = true;
  }

  if (data) {
    return (
      <div>
        <Paper
          style={{
            textAlign: "left",
            minHeight: "300px",
            height: 600,
            padding: "20px 20px 50px 20px",
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12} md={12} lg={6}>
              <div>
                <Typography variant="h5">
                  Anzahl Mitglieder nach Standorten
                </Typography>{" "}
                <Typography
                  variant="subtitle1"
                  style={{ paddingTop: 25, fontSize: "1.25rem" }}
                >
                  Gesamt:{" "}
                  {`${currentUser && currentUser.statistics.activeMembers}`}
                </Typography>
                {currentUser && (
                  <StandortMembersPieChart data={data && data.chart1} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <div>
                <Typography variant="h5">
                  Summe Mitgliedsbeiträge nach Standorten
                </Typography>{" "}
                <Typography
                  variant="subtitle1"
                  style={{ paddingTop: 25, fontSize: "1.25rem" }}
                >
                  Gesamt:{" "}
                  {`${currentUser && currentUser.statistics.contributionSum} €`}
                </Typography>
                {<StandortContributionPieChart data={data && data.chart2} />}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  } else
    return (
      <div>
        <h3>Keine Daten vorhanden</h3>
      </div>
    );
}

const pieOptions = {
  title: "",
  pieHole: 0.4,
  pieSliceText: "value",
  pieSliceTextStyle: { fontSize: 18 },
  slices: [
    {
      color: "#52d726",
    },
    {
      color: "#ff0000",
    },
    {
      color: "#ffec00",
    },
    {
      color: "#007ed6",
    },
  ],
  legend: {
    position: "bottom",
    textStyle: {
      color: "233238",
      fontSize: 18,
    },
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    // left: 0,
    // top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Roboto",
};

const StandortMembersPieChart = React.memo(({ data }) => {
  return (
    <div style={{ position: "relative" }}>
      <Chart
        chartType="PieChart"
        data={data}
        // data={[
        //   ["Age", "Weight"],
        //   ["a", 12],
        //   ["b", 5.5],
        // ]}
        options={pieOptions}
        graph_id="PieChart"
        width={"100%"}
        height={"300px"}
        legend_toggle
      />
    </div>
  );
});

const StandortContributionPieChart = React.memo(({ data }) => {
  return (
    <div style={{ position: "relative" }}>
      <Chart
        chartType="PieChart"
        data={data}
        // data={[
        //   ["Age", "Weight"],
        //   ["a", 12],
        //   ["b", 5.5],
        // ]}
        options={pieOptions}
        graph_id="PieChart2"
        width={"100%"}
        height={"300px"}
        legend_toggle
      />
    </div>
  );
});
