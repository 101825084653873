import React, { useEffect, useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SideNavListItems from "./listItems";

import BackgroundImage from "../../img/backgroundBlack.jpg";
import BackgroundImage2 from "../../img/taekwondoHANGUL.jpg";
import { Tooltip } from "@material-ui/core";
import BottomNav from "../../components/BottomNav/BottomNav";
import TopBarDropDownMenu from "../../components/SimpleMenu/SimpleMenu";
import "./Dashboard.css";

import { dashboardSideRoutes, dashboardBottomRoutes } from "./dashboardRoutes";
import AlertDialog from "../../components/Dialogs/AlertDialog/AlertDialog";
import DialogTabPages from "../../components/DashboardPages/mitglieder/Dialogs/DialogTabPages";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../redux/actions/masterDataActions";
import appActions from "../../redux/actions/appActions";
import NewMitgliedDialog from "../../components/Dialogs/NewMitglied/NewMitgliedDialog";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import dialogConstants from "../../redux/constants/dialogConstants";
import NewStandortDialog from "../../components/Dialogs/NewStandortDialog";
import Welcome from "../../components/DashboardPages/welcome/Welcome";
import { getCurrentUser } from "../../API/authAPI";
import MemberViewDialog from "../../components/Dialogs/MemberViewDialog";
import { checkTermination } from "../../utils/dataUtils/masterDataUtils";
import NewStandortLeiterDialog from "../../components/Dialogs/NewStandortLeiterDialog";
import NewEventDialog from "../../components/Dialogs/NewEventDialog";
import RemoveMitgliedDialog from "../../components/Dialogs/RemoveMitgliedDialog";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#e3e3e3",
    color: "#777",
  },
  toolbarBrand: {
    position: "relative",
    display: "flex",
    fontFamily: "earth",
    fontSize: 16,
    textDecoration: "none",
    color: "white",
    whiteSpace: "normal",
  },
  toolbarText: {
    textAlign: "left",
    color: "white",
    fontSize: 16,
    paddingBottom: 5,
    fontFamily: "Aldrich",
    padding: "0 8px",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    backgroundColor: "rgba(200, 200, 200, 0.5);",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundImage: `url(${BackgroundImage})`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  list: {
    // color: 'white',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundImage: `url(${BackgroundImage2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundAttachment: "fixed",
    backgroundSize: "240px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const umlautConverter = (umlautString) => {
  let newString = umlautString.toLocaleLowerCase();
  newString = newString.replace("ue", "ü");
  newString = newString.replace("ae", "ä");
  newString = newString.replace("oe", "ö");
  return newString;
};

export default function Dashboard() {
  const classes = useStyles();
  const dataTrackRef = useRef(false);
  const statisticTrackRef = useRef(false);
  const dispatch = useDispatch();
  const { openDialog, mitgliederList, standorteList } = useSelector(
    (state) => state.masterDataReducer
  );
  const { currentUser } = useSelector((state) => state.appReducer);
  let location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [toggle, setToggle] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  // const history = useHistory();

  let pathText = location.pathname.replace("dashboard", "");
  pathText = pathText.replaceAll("/", "");

  let newStatistics = {};

  const createStatistics = () => {
    let activeMemberCount = 0,
      frozenMemberCount = 0,
      terminatedMemberCount = 0,
      contributionSumCount = 0;
    let standorteStatistic = {};
    standorteList.forEach((site) => {
      standorteStatistic = {
        ...standorteStatistic,
        [site.standortId]: {
          name: site.siteName,
          memberCounter: 0,
          memberContributionSum: 0,
        },
      };
    });
    mitgliederList.forEach((member) => {
      if (member.status === "active") {
        activeMemberCount += 1;
        let newSum =
          parseInt(
            standorteStatistic[member.standortRef].memberContributionSum
          ) + parseInt(member.contribution);
        standorteStatistic[member.standortRef] = {
          ...standorteStatistic[member.standortRef],
          memberCounter:
            standorteStatistic[member.standortRef].memberCounter + 1,
          memberContributionSum: newSum,
        };
      }
      if (member.status === "frozen") {
        frozenMemberCount += 1;
        return;
      }
      if (member.status === "terminated") {
        terminatedMemberCount += 1;
        let collectBOOL = checkTermination(
          member.terminationDate,
          member.contractDuration
        );
        if (!collectBOOL) {
          return;
        }
      }
      contributionSumCount += parseInt(member.contribution);
    });
    newStatistics = {
      activeMembers: activeMemberCount,
      frozenMembers: frozenMemberCount,
      terminatedMembers: terminatedMemberCount,
      contributionSum: contributionSumCount,
      standorteStatistic: standorteStatistic,
    };
    let newCU = {
      ...currentUser,
      statistics: newStatistics,
    };
    dispatch(appActions.updateUser(newCU));
  };

  if (!statisticTrackRef.current && mitgliederList && standorteList) {
    createStatistics();
    statisticTrackRef.current = true;
  }

  let dashboardToolbarText =
    pathText === ""
      ? "HOME"
      : umlautConverter(
          location.pathname.replace("/dashboard/", "")
        ).toLocaleUpperCase();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const toggleDrawer = () => {
    setToggle(!toggle);
  };

  if (!dataTrackRef.current) {
    console.log("Loading data from Firebase... ");
    dispatch(masterDataActions.getStandorteList(0));
    dispatch(masterDataActions.getEventsList(0));
    dispatch(masterDataActions.getMitgliederList(0));
    dispatch(masterDataActions.getVertragsnehmerList(0));
    dispatch(masterDataActions.getStandortleiterList(0));
    dispatch(appActions.getUser());
    dataTrackRef.current = true;
  }

  useEffect(() => {
    console.log("useEffect");
    getCurrentUser()
      .then((response) => {})
      .catch((e) => {
        console.log(e);
        console.log("ERROR DASHBOARD USER");
        window.location.reload();
      });
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const mediaQueryBig = window.matchMedia("(min-width: 768px)");
    const mediaQuerySmall = window.matchMedia("(max-width: 768px)");
    if (mediaQueryBig.matches && toggle === null) {
      handleDrawerOpen();
      setToggle(true);
    }
    if (mediaQuerySmall.matches && toggle === null) {
      handleDrawerClose();
      setToggle(false);
    }
    if (toggle) {
      handleDrawerOpen();
    }
    if (!toggle) {
      handleDrawerClose();
    }

    mediaQuery.addListener((mq) => {
      if (mq.matches) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [toggle]);

  if (!currentUser) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className={classes.root}>
      {loading && <LoadingScreen></LoadingScreen>}
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Tooltip title="Menü öffnen">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {dashboardToolbarText}
          </Typography>
          <Tooltip title="Öffnen">
            <TopBarDropDownMenu></TopBarDropDownMenu>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        ModalProps={{
          keepMounted: true,
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div className={classes.toolbarBrand}>
            <a className="brand__link" href="https://www.twintaekwondo.de">
              TWIN TAEKWONDO
            </a>
          </div>
          <Tooltip title="Menü schließen">
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.toolbarText}>
          {toggle ? <p>MITGLIEDERVERWALTUNG</p> : <span></span>}
        </div>
        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }} />
        <List component="nav" className={classes.list}>
          <SideNavListItems></SideNavListItems>
        </List>
        <Divider />
        {toggle ? <BottomNav toggle={toggle}></BottomNav> : <span></span>}
      </Drawer>
      {openDialog && openDialog === dialogConstants.EDIT_MITGLIED && (
        <DialogTabPages></DialogTabPages>
      )}
      {openDialog && openDialog === dialogConstants.SHOW_MITGLIED && (
        <MemberViewDialog />
      )}
      {openDialog && openDialog === dialogConstants.NEW_MITGLIED && (
        <NewMitgliedDialog></NewMitgliedDialog>
      )}
      {openDialog && openDialog === dialogConstants.NEW_STANDORT && (
        <NewStandortDialog />
      )}
      {openDialog && openDialog === dialogConstants.NEW_STANDORTLEITER && (
        <NewStandortLeiterDialog />
      )}
      {openDialog && openDialog === dialogConstants.NEW_EVENT && (
        <NewEventDialog />
      )}
      {openDialog && openDialog === dialogConstants.REMOVE_MITGLIED && (
        <RemoveMitgliedDialog />
      )}
      {openDialog && (
        <React.Fragment>
          <AlertDialog></AlertDialog>
        </React.Fragment>
      )}
      <Switch>
        <Route path={"/"} exact>
          <Welcome currentUser={currentUser && currentUser} classes={classes} />
        </Route>
        {
          // TODO: Access rights
          dashboardSideRoutes.map((routeItem, i) => {
            const Component = (
              <routeItem.component classes={classes}></routeItem.component>
            );
            if (routeItem.exact === false) {
              return (
                <Route
                  key={`Side-Route-${i}`}
                  path={`/dashboard/${routeItem.path}`}
                >
                  {Component}
                </Route>
              );
            } else {
              return (
                <Route
                  key={`Side-Route-${i}`}
                  path={`/dashboard/${routeItem.path}`}
                  exact
                >
                  {Component}
                </Route>
              );
            }
          })
        }
        {dashboardBottomRoutes.map((routeItem, i) => {
          const Component = (
            <routeItem.component classes={classes}>
              <h1>{routeItem.text}</h1>
            </routeItem.component>
          );
          return (
            <Route
              key={`Bottom-Route-${i}`}
              path={`/dashboard/${routeItem.path}`}
            >
              {Component}
            </Route>
          );
        })}
        <Redirect to={"/"} />
      </Switch>
    </div>
  );
}
