import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import kup10 from "../../../img/belts/10.Kup.png";
import kup09 from "../../../img/belts/09.Kup.png";
import kup08 from "../../../img/belts/08.Kup.png";
import kup07 from "../../../img/belts/07.Kup.png";
import kup06 from "../../../img/belts/06.Kup.png";
import kup05 from "../../../img/belts/05.Kup.png";
import kup04 from "../../../img/belts/04.Kup.png";
import kup03 from "../../../img/belts/03.Kup.png";
import kup02 from "../../../img/belts/02.Kup.png";
import kup01 from "../../../img/belts/01.Kup.png";
import poom from "../../../img/belts/11.Poom.png";
import dan from "../../../img/belts/11.Dan.png";

import "./BeltAvatar.css";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: "100px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const belts = [
  {
    id: "10.Kup",
    img: kup10,
  },
  {
    id: "10.Kup",
    img: kup10,
  },
  {
    id: "09.Kup",
    img: kup09,
  },
  {
    id: "08.Kup",
    img: kup08,
  },
  {
    id: "07.Kup",
    img: kup07,
  },
  {
    id: "06.Kup",
    img: kup06,
  },
  {
    id: "05.Kup",
    img: kup05,
  },
  {
    id: "04.Kup",
    img: kup04,
  },
  {
    id: "03.Kup",
    img: kup03,
  },
  {
    id: "02.Kup",
    img: kup02,
  },
  {
    id: "01.Kup",
    img: kup01,
  },
  {
    id: "Poom",
    img: poom,
  },
  {
    id: "Dan",
    img: dan,
  },
  {
    id: "Dan",
    img: dan,
  },
  {
    id: "Dan",
    img: dan,
  },
  {
    id: "Dan",
    img: dan,
  },
  {
    id: "Dan",
    img: dan,
  },
];

function BeltAvatars(props) {
  const { size, beltNumber } = props;
  const classes = useStyles();
  const [beltSize, setBeltSize] = React.useState(classes.medium);

  React.useEffect(() => {
    if (size === "small") {
      setBeltSize(classes.small);
    } else if (size === "large") {
      setBeltSize(classes.large);
    }
  }, [setBeltSize, size, classes.large, classes.small]);

  return (
    <div id="beltContainer">
      <Avatar
        alt={belts[beltNumber].id}
        src={belts[beltNumber].img}
        className={beltSize}
      >
        {belts[beltNumber].id}
      </Avatar>
    </div>
  );
}

// Set default props
BeltAvatars.defaultProps = {
  beltNumber: 0,
};

export default BeltAvatars;
