import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ArrowDownwardIcon, ArrowUpwardIcon } from "@material-ui/data-grid";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import {
  createFullDateString,
  getGradCollection,
  sortArrayWithObjects,
} from "../../../utils/dataUtils/appUtils";

export const AffiliatedMembersOverview = (props) => {
  const { site } = props;
  const dispatch = useDispatch();
  const [affMembers, setAffMembers] = React.useState(site.members);
  const [sortState, setSortState] = React.useState({
    field: null,
    direction: null,
  });

  let gradCollection = getGradCollection();

  const handleUpdateAffStandort = (tempItems) => {
    let updatedSite = {
      ...site,
      members: tempItems,
    };
    dispatch(masterDataActions.editStandort(updatedSite));
    dispatch(masterDataActions.getStandorteList(0));
  };

  const handleDeleteAffMember = (m) => {
    let tempItems = [...affMembers];
    const index = tempItems.findIndex((p) => p === m);
    if (index > -1) {
      tempItems.splice(index, 1);
    }
    setAffMembers(tempItems);
    handleUpdateAffStandort(tempItems);
  };

  const handleSort = (sortCrit) => {
    let newList = [];
    if (sortState.direction !== "asc") {
      newList = sortArrayWithObjects(affMembers, sortCrit, "asc");
      setSortState({ field: sortCrit, direction: "asc" });
    } else {
      newList = sortArrayWithObjects(affMembers, sortCrit, "desc");
      setSortState({ field: sortCrit, direction: "desc" });
    }
    setAffMembers(newList);
  };

  const getSortIcon = (name) => {
    if (sortState.field !== name) {
      return <ArrowBack />;
    }
    if (sortState.field === name) {
      if (sortState.direction === "asc") {
        return <ArrowUpwardIcon />;
      } else {
        return <ArrowDownwardIcon />;
      }
    }
    return <ArrowBack />;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">NR:</TableCell>
            <TableCell>
              <Button
                onClick={() => handleSort("degree")}
                endIcon={getSortIcon("degree")}
              >
                Grad
              </Button>
            </TableCell>
            <TableCell align="left">
              <Button
                onClick={() => handleSort("lastName")}
                endIcon={getSortIcon("lastName")}
              >
                Nachname
              </Button>
            </TableCell>
            <TableCell align="left">
              <Button
                onClick={() => handleSort("firstName")}
                endIcon={getSortIcon("firstName")}
              >
                Vorname
              </Button>
            </TableCell>
            <TableCell align="left">Geburtsdatum</TableCell>
            <TableCell align="left">Aktiv seit</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {affMembers.map((p, i) => (
            <TableRow hover key={"Testing-Table-Row-" + i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell component="th" scope="row">
                {gradCollection[p.degree - 1].title}
              </TableCell>
              <TableCell align="left">{p.lastName}</TableCell>
              <TableCell align="left">{p.firstName}</TableCell>
              <TableCell align="left">
                {createFullDateString(new Date(p.birthdate))}
              </TableCell>
              <TableCell align="left">
                {createFullDateString(new Date(p.beginningDate))}
              </TableCell>
              <TableCell>
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  variant="contained"
                  onClick={() => handleDeleteAffMember(p)}
                >
                  Löschen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
