import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";
import BirthDayOverview from "../../../helpers/BirthDayOverview";
import MemberOverview from "../../../helpers/MemberOverview";

export default function Home(props) {
  useEffect(() => {
    document.title = `Home`;
  });

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="false" className={props.classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={5}>
            <BirthDayOverview />
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <MemberOverview />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
