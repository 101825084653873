import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import BankingSettings from "./BankingSettings";
import NewBankAccountForm from "./NewBankAccountForm";
import BankingAccountsActions from "./BankingAccountsActions";

export default function BankingSettingsMenu(props) {
  const [menuState, setMenuState] = useState(0);

  const menuSelectionItems = [
    { id: 1, text: "Hauptkonto", component: <BankingSettings /> },
    { id: 2, text: "Konto hinzufügen", component: <NewBankAccountForm /> },
    {
      id: 3,
      text: "Konto bearbeiten",
      component: <BankingAccountsActions selectedAction={"EDIT"} />,
    },
    {
      id: 4,
      text: "Konto löschen",
      component: <BankingAccountsActions selectedAction={"DELETE"} />,
    },
  ];

  const handleChangeMenu = (menu) => {
    setMenuState(menu);
  };

  if (menuState === 0) {
    return (
      <div style={{ margin: 50, marginTop: 0 }}>
        <Typography variant="h5">Auswählen</Typography>
        <Grid
          container
          style={{ textAlign: "center" }}
          alignContent="center"
          justify="center"
          spacing={3}
        >
          {menuSelectionItems.map((item) => (
            <Grid item xs={12}>
              <Button
                style={{ minWidth: "250px", fontSize: "125%", padding: 7 }}
                variant="contained"
                color="default"
                onClick={() => handleChangeMenu(item.id)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  } else {
    console.log("menuState :>> ", menuState);
    return (
      <div>{menuState && menuSelectionItems[menuState - 1].component}</div>
    );
  }
}
