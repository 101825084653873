import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTableSearch } from "../../../hooks/useTableSearch";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import { AddMembersToEventTable } from "./AddMembersToEventTable";
import { ShowEventMembers } from "./ShowEventMembers";

export default function AddMembersToEvent({
  showDialog,
  hideDialog,
  eventId,
  mitgliederList,
}) {
  const [searchVal, setSearchVal] = React.useState(null);
  const [renderCount, setRenderCount] = React.useState(0);
  const [members, setMembers] = React.useState([...mitgliederList]);
  const [membersForEventList, setMembersForEventList] = React.useState([]);
  const searchRef = useRef(false);

  const { filteredData } = useTableSearch({
    searchVal,
    inputData: members,
  });

  const handleSearchField = () => {
    setSearchVal(searchRef.current.value);
  };

  const handleClose = () => {
    hideDialog();
  };

  const handleAddMember = (member) => {
    let list = [...membersForEventList];
    let memberList = [...members];
    let index = members.findIndex((x) => x.mitgliedId === member.mitgliedId);
    if (index > -1) {
      list.push(member);
      memberList.splice(index, 1);
      setMembers(memberList);
      setMembersForEventList(list);
      setSearchVal(null);
      searchRef.current.value = "";
    }
    console.log(memberList.length);
    console.log("membersForEventList :>> ", membersForEventList);
    setRenderCount(renderCount + 1);
  };

  const handleRemoveMember = (member) => {
    let memberList = [...members];
    let eventList = [...membersForEventList];
    let i1 = eventList.findIndex((x) => x.mitgliedId === member.mitgliedId);
    if (i1 > -1) {
      eventList.splice(i1, 1);
      memberList.unshift(member);
      setMembers(memberList);
      setMembersForEventList(eventList);
    }
    console.log("member :>> ", member);
  };

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Members to Event with id: " + eventId}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <TextField
                style={{ marginLeft: "24px" }}
                id="standard-basic"
                label="Suchen"
                name="searchInput"
                inputRef={searchRef}
                onChange={handleSearchField}
                placeholder="Suchen"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <AddMembersToEventTable
                members={searchVal ? filteredData : members}
                handleAddMember={handleAddMember}
              />
            </Grid>
            <Grid item lg={6}>
              <ShowEventMembers
                members={membersForEventList}
                handleRemoveMember={handleRemoveMember}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
