import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import pic from "../../../img/backgrounds/krater_vulkan.png";
import { Delete, Edit } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import masterDataActions from "../../../redux/actions/masterDataActions";
import dialogConstants from "../../../redux/constants/dialogConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 275,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const { leader } = props;
  const dispatch = useDispatch();

  const openDelete = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.REMOVE_STANDORTLEITER,
        leader,
        "REMOVE"
      )
    );
  };

  const openEdit = () => {
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.NEW_STANDORTLEITER,
        leader,
        "EDIT"
      )
    );
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        style={{ minHeight: 100 }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={`${leader.firstName} ${leader.lastName}`}
        // subheader={name}
      />
      <CardMedia
        className={classes.media}
        image={leader.newImage ? leader.newImage.imageUrl : pic}
        title="Standort Image"
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="p">
          {/* inhaber: {leader} */}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {leader.street} <br></br>
          {leader.zip}, {leader.city}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }} disableSpacing>
        <IconButton
          onClick={() => openDelete()}
          aria-label="Standortleiter löschen"
        >
          <Delete />
        </IconButton>
        <IconButton onClick={openEdit} aria-label="add to favorites">
          <Edit />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
