/* eslint-disable no-eval */

import {
  GET_VERTRAGSNEHMERS_LIST,
  SET_VERTRAGSNEHMERS_LIST,
  ADD_VERTRAGSNEHMER,
  EDIT_VERTRAGSNEHMER,
  REMOVE_VERTRAGSNEHMER,
  REMOVE_MANY_VERTRAGSNEHMER,
  SET_MASTER_DATA_ERROR,
  SET_MASTER_DATA_RESPONSE,
  OPEN_MASTER_DATA_DIALOG,
  CLOSE_MASTER_DATA_DIALOG,
  GET_MITGLIEDER_LIST,
  SET_MITGLIEDER_LIST,
  ADD_MITGLIED,
  EDIT_MITGLIED,
  REMOVE_MITGLIED,
  REMOVE_MANY_MITGLIEDER,
  GET_STANDORTE_LIST,
  SET_STANDORTE_LIST,
  REMOVE_MANY_STANDORTE,
  ADD_STANDORT,
  REMOVE_STANDORT,
  EDIT_STANDORT,
  GET_EVENTS_LIST,
  SET_EVENTS_LIST,
  REMOVE_MANY_EVENTS,
  ADD_EVENT,
  REMOVE_EVENT,
  EDIT_EVENT,
  GET_STANDORTLEITER_LIST,
  SET_STANDORTLEITER_LIST,
  ADD_STANDORTLEITER,
  EDIT_STANDORTLEITER,
  REMOVE_STANDORTLEITER,
  REMOVE_MANY_STANDORTLEITER,
  CLEAR_STATE,
} from "../constants/masterDataConstants";

const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

const setMasterDataError = (masterDataError) => {
  return {
    type: SET_MASTER_DATA_ERROR,
    masterDataError,
  };
};

const setMasterDataResponse = (masterDataResponse) => {
  return {
    type: SET_MASTER_DATA_RESPONSE,
    masterDataResponse,
  };
};
// All Vertragsnehmer Actions:
const getVertragsnehmerList = () => {
  return {
    type: GET_VERTRAGSNEHMERS_LIST,
  };
};

const setVertragsnehmerList = (vertragsnehmerList) => {
  return {
    type: SET_VERTRAGSNEHMERS_LIST,
    vertragsnehmerList,
  };
};

const addVertragsnehmer = (vertragsnehmerData) => {
  return {
    type: ADD_VERTRAGSNEHMER,
    vertragsnehmerData,
  };
};

const editVertragsnehmer = (vertragsnehmerData) => {
  return {
    type: EDIT_VERTRAGSNEHMER,
    vertragsnehmerData,
  };
};

const removeVertragsnehmer = (vertragsnehmerId) => {
  return {
    type: REMOVE_VERTRAGSNEHMER,
    vertragsnehmerId,
  };
};

const removeManyVertragsnehmer = (vertragsnehmerIdList) => {
  return {
    type: REMOVE_MANY_VERTRAGSNEHMER,
    vertragsnehmerIdList,
  };
};
// End Vertragsnehmer Actions

// All Mitglieder Actions
const getMitgliederList = () => {
  return {
    type: GET_MITGLIEDER_LIST,
  };
};

const setMitgliederList = (mitgliederList) => {
  return {
    type: SET_MITGLIEDER_LIST,
    mitgliederList,
  };
};

const removeManyMitglieder = (mitgliederIdList) => {
  return {
    type: REMOVE_MANY_MITGLIEDER,
    mitgliederIdList,
  };
};

const addMitglied = (mitgliedData) => {
  return {
    type: ADD_MITGLIED,
    mitgliedData,
  };
};

const editMitglied = (mitgliedData) => {
  return {
    type: EDIT_MITGLIED,
    mitgliedData,
  };
};

const removeMitglied = (mitgliedId) => {
  return {
    type: REMOVE_MITGLIED,
    mitgliedId,
  };
};
// End Mitglieder Actions

// All Standorte Actions
const getStandorteList = () => {
  return {
    type: GET_STANDORTE_LIST,
  };
};

const setStandorteList = (standorteList) => {
  return {
    type: SET_STANDORTE_LIST,
    standorteList,
  };
};

const removeManyStandorte = (standorteIdList) => {
  return {
    type: REMOVE_MANY_STANDORTE,
    standorteIdList,
  };
};

const addStandort = (standortData) => {
  return {
    type: ADD_STANDORT,
    standortData,
  };
};

const editStandort = (standortData) => {
  return {
    type: EDIT_STANDORT,
    standortData,
  };
};

const removeStandort = (standortId) => {
  return {
    type: REMOVE_STANDORT,
    standortId,
  };
};
// End Standorte Actions

// All Events Actions
const getEventsList = () => {
  return {
    type: GET_EVENTS_LIST,
  };
};

const setEventsList = (eventsList) => {
  return {
    type: SET_EVENTS_LIST,
    eventsList,
  };
};

const removeManyEvents = (eventsIdList) => {
  return {
    type: REMOVE_MANY_EVENTS,
    eventsIdList,
  };
};

const addEvent = (eventData) => {
  return {
    type: ADD_EVENT,
    eventData,
  };
};

const editEvent = (eventData) => {
  return {
    type: EDIT_EVENT,
    eventData,
  };
};

const removeEvent = (eventId) => {
  return {
    type: REMOVE_EVENT,
    eventId,
  };
};
// End Events Actions

// All Standortleiter Actions:
const getStandortleiterList = () => {
  return {
    type: GET_STANDORTLEITER_LIST,
  };
};

const setStandortleiterList = (standortleiterList) => {
  return {
    type: SET_STANDORTLEITER_LIST,
    standortleiterList,
  };
};

const addStandortleiter = (standortleiterData) => {
  return {
    type: ADD_STANDORTLEITER,
    standortleiterData,
  };
};

const editStandortleiter = (standortleiterData) => {
  return {
    type: EDIT_STANDORTLEITER,
    standortleiterData,
  };
};

const removeStandortleiter = (standortleiterId) => {
  return {
    type: REMOVE_STANDORTLEITER,
    standortleiterId,
  };
};

const removeManyStandortleiter = (standortleiterIdList) => {
  return {
    type: REMOVE_MANY_STANDORTLEITER,
    standortleiterIdList,
  };
};
// End Standortleiter Actions

const openMasterDataDialog = (dialog, dialogData = null, dialogType = null) => {
  return {
    type: OPEN_MASTER_DATA_DIALOG,
    dialog,
    dialogData,
    dialogType,
  };
};

const closeMasterDataDialog = () => {
  return {
    type: CLOSE_MASTER_DATA_DIALOG,
  };
};
// eslint-disable-next-line
export default {
  clearState,

  setMasterDataError,
  setMasterDataResponse,

  getVertragsnehmerList,
  setVertragsnehmerList,
  addVertragsnehmer,
  editVertragsnehmer,
  removeVertragsnehmer,
  removeManyVertragsnehmer,

  getMitgliederList,
  setMitgliederList,
  removeManyMitglieder,
  addMitglied,
  editMitglied,
  removeMitglied,

  getStandorteList,
  setStandorteList,
  removeManyStandorte,
  addStandort,
  editStandort,
  removeStandort,

  getEventsList,
  setEventsList,
  removeManyEvents,
  addEvent,
  editEvent,
  removeEvent,

  getStandortleiterList,
  setStandortleiterList,
  addStandortleiter,
  editStandortleiter,
  removeStandortleiter,
  removeManyStandortleiter,

  closeMasterDataDialog,
  openMasterDataDialog,
};
