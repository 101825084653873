import { Link, Typography } from "@material-ui/core";
import React from "react";

export default function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ position: "fixed", bottom: 0, right: 0, zIndex: 5 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Michael Angermeyer
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
