import React from "react";

import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import masterDataActions from "../../redux/actions/masterDataActions";
import { Grid, Typography } from "@material-ui/core";
import { gradArray } from "./NewMitglied/DialogPages/inputFields";

export default function MemberViewDialog() {
  const dispatch = useDispatch();
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  console.log("dialogData :>> ", dialogData);
  const { member, contract, standort } = dialogData && dialogData;

  const handleClose = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const {
    title,
    lastName,
    firstName,
    birthdate,
    contractDate,
    contractDuration,
    contribution,
    degree,
    memberNumber,
    memberType,
    lastTesting,
  } = member;

  let {
    email,
    city,
    mobile,
    phoneNumber,
    street,
    zip,
    owner,
    bank,
    iban,
    bic,
  } = contract;

  let previewMemberData = (
    <Grid item xs={12}>
      <table>
        <tr>
          <th>Standort: </th>
          <td>{standort}</td>
        </tr>
        <tr>
          <th>Mitgliedsnummer: </th>
          <td>{memberNumber}</td>
        </tr>
        <tr>
          <th>Anrede:</th>
          <td>{title}</td>
        </tr>
        <tr>
          <th>Nachname:</th>
          <td>{lastName}</td>
        </tr>
        <tr>
          <th>Vorname:</th>
          <td>{firstName}</td>
        </tr>
        <tr>
          <th>Geburtsdatum:</th>
          <td>{new Date(birthdate).toLocaleDateString()}</td>
        </tr>
        <tr>
          <th>Art des Mitglieds: </th>
          <td>{memberType === "child" ? "Kind" : "Erwachsen"}</td>
        </tr>
        <tr>
          <th>Mitgliedsbeitrag: </th>
          <td>{contribution} €</td>
        </tr>
        <tr>
          <th>Gürtelgrad: </th>
          <td>{gradArray[degree - 1].label}</td>
        </tr>
        <tr>
          <th>Letzte Prüfung: </th>
          <td>{new Date(lastTesting).toLocaleDateString()}</td>
        </tr>
        <tr>
          <th>Vertragsdatum: </th>
          <td>{new Date(contractDate).toLocaleDateString()}</td>
        </tr>
        <tr>
          <th>Vertragslaufzeit: </th>
          <td>{contractDuration} Monate</td>
        </tr>
      </table>
    </Grid>
  );

  let previewContractData = (
    <Grid item xs={12}>
      <h3>Vertragsperson</h3>
      <table>
        <tr>
          <th>Anrede: </th>
          <td>{contract.title}</td>
        </tr>
        <tr>
          <th>Nachname: </th>
          <td>{contract.lastName}</td>
        </tr>
        <tr>
          <th>Vorname: </th>
          <td>{contract.firstName}</td>
        </tr>
        <tr>
          <th>Geburtsdatum: </th>
          <td>{new Date(contract.birthdate).toLocaleDateString()}</td>
        </tr>
      </table>
      <h3>Kontaktdaten</h3>
      <table>
        <tr>
          <th>Straße: </th>
          <td>{street}</td>
        </tr>
        <tr>
          <th>PLZ: </th>
          <td>{zip}</td>
        </tr>
        <tr>
          <th>Ort: </th>
          <td>{city}</td>
        </tr>
        <tr>
          <th>Telefon: </th>
          <td>{phoneNumber}</td>
        </tr>
        <tr>
          <th>Mobil: </th>
          <td>{mobile}</td>
        </tr>
        <tr>
          <th>E-Mail: </th>
          <td>{email}</td>
        </tr>
      </table>
      <h3>Bankdaten</h3>
      <table>
        <tr>
          <th>Inhaber: </th>
          <td>{owner}</td>
        </tr>
        <tr>
          <th>Bank: </th>
          <td>{bank}</td>
        </tr>
        <tr>
          <th>IBAN: </th>
          <td>{iban}</td>
        </tr>
        <tr>
          <th>BIC:</th>
          <td>{bic}</td>
        </tr>
      </table>
    </Grid>
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid id="showData" spacing={3} container xs={12}>
            <Grid item lg={6} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h4">Daten des Mitglieds</Typography>
              </Grid>
              {previewMemberData}
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid item xs={6}>
                <Typography variant="h4">Vertragsdaten</Typography>
              </Grid>
              {previewContractData}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
