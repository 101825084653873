import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import dialogs from "../../redux/constants/dialogConstants";
import masterDataActions from "../../redux/actions/masterDataActions";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// import NewEventForm from './NewEventForm';

const useRemoveStyles = makeStyles((theme) => ({
  vnToDelete: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function RemoveEventDialog() {
  const classes = useRemoveStyles();
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogs.REMOVE_EVENT;
  const { dialogData } = useSelector((state) => state.masterDataReducer);

  const handleClose = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const handleDelete = () => {
    if (dialogData.eventId) {
      try {
        dispatch(masterDataActions.removeEvent(dialogData.eventId));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(masterDataActions.getEventsList());
        dispatch(masterDataActions.closeMasterDataDialog());
        let alertData = {
          title: "Event erfolgreich gelöscht",
          text: "",
        };
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogs.ALERT,
            alertData,
            "ALERT"
          )
        );
      }
    }
  };

  if (dialogData) {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={visible}
          onClose={handleClose}
          // disableBackdropClick={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Event entfernen</DialogTitle>
          <DialogContent>
            <Typography>Sind Sie sicher, dass Sie</Typography>
            <br></br>
            <div className={classes.vnToDelete}>
              <Typography>{dialogData.eventName + " "}</Typography>
            </div>
            <br></br>
            <Typography>als Event entfernen möchten?</Typography>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Event löschen">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Löschen bestätigen
              </Button>
            </Tooltip>
            <Button onClick={handleClose} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else return <div></div>;
}
