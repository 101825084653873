import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getGradCollection } from "../../../utils/dataUtils/appUtils";
import ViewAllCollapsibleTable from "./ViewAllCollabsibleTable";

export default function ViewAllMembers() {
  const { standorteList, mitgliederList } = useSelector(
    (state) => state.masterDataReducer
  );
  var allMembers = [];
  let gradCollection = getGradCollection();
  let collapsibleData = gradCollection.map((g) => {
    return { ...g, members: [] };
  });
  collapsibleData = collapsibleData.filter((c) => c.id < 17);
  let standortLookup;
  standorteList &&
    standorteList.forEach((site) => {
      standortLookup = {
        ...standortLookup,
        [site.standortId]: site.siteName,
      };
    });

  if (standorteList && mitgliederList) {
    standorteList.forEach((s) => {
      if (
        s.standortType === "AFFILIATED" &&
        s.members &&
        s.members.length > 0
      ) {
        let aMs = s.members.map((m) => {
          return { ...m, standortRef: s.standortId };
        });
        allMembers.push(...aMs);
      }
    });
    mitgliederList.forEach((m) => {
      if (m.status === "active") {
        allMembers.push(m);
      }
    });

    allMembers.forEach((m) => {
      collapsibleData[m.degree - 1].members.push(m);
    });

    collapsibleData.reverse();

    console.log("collapsibleData", collapsibleData);
  }

  console.log("allMembers", allMembers);

  return (
    <div>
      <Paper style={{ padding: 25, textAlign: "center" }}>
        <Typography variant="h4">Alle Mitglieder</Typography>
      </Paper>
      <ViewAllCollapsibleTable
        data={collapsibleData}
        standortLookup={standortLookup}
      />
    </div>
  );
}
