import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import { useSelector } from "react-redux";
import { EditMemberForm } from "../../../Dialogs/NewMitglied/DialogPages/EditMemberForm";
import ContractDataForm from "../../../Dialogs/NewMitglied/DialogPages/ContractDataForm";
import {
  getContractDataFromMember,
  getMitgliederListFromMitgliedIds,
} from "../../../../utils/dataUtils/masterDataUtils";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { gradArray } from "../../../Dialogs/NewMitglied/DialogPages/inputFields";
import BeltAvatars from "../../../Avatar/Belts/BeltAvatar";
import Construction from "../../../../helpers/Construction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { createFullDateString } from "../../../../utils/dataUtils/appUtils";

const useTableStyles = makeStyles({
  root: {},
  table: {
    minWidth: 700,
  },
  tableHeadButton: {
    fontSize: "1.2rem",
  },
  tableRow: {
    "&$hover:hover": {
      cursor: "pointer",
    },
    "&$selected, &$selected:hover": {
      backgroundColor: "#848691",
    },
  },
  tableCell: {
    fontSize: "1.2rem",
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: 500,
  },
  selected: {
    backgroundColor: "white",
    color: "black",
  },
}));

export default function TabPages(props) {
  const { openDefault } = props;
  const memberDataRef = useRef(false);
  const classes = useStyles();
  const {
    dialogData,
    dialogType,
    vertragsnehmerList,
    mitgliederList,
  } = useSelector((state) => state.masterDataReducer);
  const [value, setValue] = React.useState(openDefault);
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(null);
  const [mID, setmID] = React.useState(null);
  const [allowMemberTab, setAllowMemberTab] = React.useState(true);
  const [memberData, setMemberData] = React.useState();
  const { currentTableList } = dialogData;

  let contractData, memberList, memberCount;

  let isActiveMember = memberData && memberData.status === "active";

  const handleSelectRow = (member) => {
    setSelectedMember(member);
    setMemberData({ ...member });
    // memberData = {
    //   ...memberData,
    //   ...member,
    // };
    setAllowMemberTab(true);
  };

  const handlePreviousMember = () => {
    let index = currentTableList.findIndex((x) => x.mitgliedId === mID);
    if (index === 0) {
      index = currentTableList.length;
    }
    let previousMember = currentTableList[index - 1];
    let nextIndex = index - 1;
    while (previousMember.status !== "active") {
      nextIndex--;
      if (nextIndex === -1) {
        nextIndex = currentTableList.length - 1;
      }
      previousMember = currentTableList[nextIndex];
    }
    previousMember.isChild = previousMember.memberType === "child";
    console.log("previousMember :>> ", previousMember);
    setmID(previousMember.mitgliedId);
    setMemberData(previousMember);
  };

  const handleNextMember = () => {
    let index = currentTableList.findIndex((x) => x.mitgliedId === mID);
    if (index === currentTableList.length - 1) {
      index = -1;
    }
    let nextMember = currentTableList[index + 1];
    let nextIndex = index + 1;
    while (nextMember.status !== "active") {
      nextIndex++;
      if (nextIndex === currentTableList.length) {
        nextIndex = 0;
      }
      nextMember = currentTableList[nextIndex];
    }
    nextMember = {
      ...nextMember,
      isChild: nextMember.memberType === "child",
    };
    console.log("nextMember :>> ", nextMember);
    setmID(nextMember.mitgliedId);
    setMemberData(nextMember);
  };

  if (!memberDataRef.current && dialogData && dialogData.formData) {
    setMemberData(dialogData.formData);
    memberDataRef.current = true;
  }

  if (
    dialogData &&
    dialogData.formData &&
    dialogType === "EDIT_MEMBER" &&
    memberData
  ) {
    // Check if formdata is from member or contract
    console.log("memberData :>> ", memberData);
    if (!mID) {
      setmID(memberData.mitgliedId);
    }
    memberCount = 1;
    contractData = getContractDataFromMember(
      memberData.mitgliedId,
      vertragsnehmerList
    );
  } else if (
    dialogData &&
    dialogData.formData &&
    dialogType === "EDIT_CONTRACT"
  ) {
    // Set contractdata
    // memberData = {
    //   ...memberData,
    //   memberType: "adult",
    // };

    memberCount = 2;
    contractData = dialogData.formData;
    let memberids = dialogData && dialogData.formData.hasMembers;
    memberList = getMitgliederListFromMitgliedIds(memberids, mitgliederList);
  }

  useEffect(() => {
    if (dialogType === "EDIT_CONTRACT") {
      setValue(1);
    }
    if (memberCount > 1) {
      setAllowMemberTab(false);
    }
    return () => {
      setSelectedMember(null);
      setMemberData(null);
      setmID(null);
    };
  }, [setValue, dialogData, dialogType, memberCount]);

  const handleChange = (event, newValue) => {
    if (!allowMemberTab && newValue === 0) {
      setOpen(true);
      return;
    }
    setValue(newValue);
  };

  if (dialogData && memberData) {
    return (
      <div className={classes.root}>
        <AppBar position="static" style={{ backgroundColor: "black" }}>
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              classes={{ selected: classes.selected }}
              style={{ fontSize: "150%" }}
              label="Mitgliedsdaten"
              {...a11yProps(0)}
            />
            <Tab
              classes={{ selected: classes.selected }}
              style={{ fontSize: "150%" }}
              label="Vertragsdaten"
              {...a11yProps(1)}
            />
            <Tab
              classes={{ selected: classes.selected }}
              style={{ fontSize: "150%" }}
              label="Sonstiges"
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <Collapse in={open}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Bitte zuerst eine Mitglied auswählen.</AlertTitle>
            Um ein Mitglied zu bearbeiten, müssen Sie dieses zuerst aus der
            Liste auswählen.
          </Alert>
        </Collapse>
        <TabPanel value={value} index={0}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isActiveMember ? "space-between" : "center",
                margin: "25px 0",
              }}
            >
              {isActiveMember && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handlePreviousMember(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                </Button>
              )}
              <Typography
                style={{ minWidth: "200px" }}
                align="center"
                variant="h4"
              >
                {memberData.lastName + " " + memberData.firstName}
              </Typography>
              {isActiveMember && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleNextMember(1);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </Button>
              )}
            </div>
            {dialogType === "EDIT_MEMBER" && selectedMember ? (
              selectedMember && <EditMemberForm formData={selectedMember} />
            ) : (
              <EditMemberForm formData={memberData} />
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            {dialogType === "EDIT_CONTRACT" && (
              <div>
                <Typography variant="h6" component="h6">
                  Auf diesen Vertrag laufen folgende Mitglieder:
                </Typography>
                <MitgliederPreviewTable
                  selectedMember={selectedMember}
                  handleSelectRow={handleSelectRow}
                  memberList={memberList}
                ></MitgliederPreviewTable>
              </div>
            )}
            <ContractDataForm
              memberType={memberData && memberData.memberType}
              memberData={memberData}
              contractData={contractData}
            ></ContractDataForm>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>Sonstiges</div>
          <Construction></Construction>
        </TabPanel>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function MitgliederPreviewTable(props) {
  const { selectedMember, handleSelectRow, memberList } = props;
  const classes = useTableStyles();

  const handleSelect = (member, index) => {
    handleSelectRow(member);
  };

  const memberPreview = memberList.map((m, i) => {
    console.log("m :>> ", m);
    return (
      <TableRow
        hover
        onClick={() => {
          handleSelect(m, i);
        }}
        style={{ fontSize: "1.2rem" }}
        classes={{ hover: classes.hover, selected: classes.selected }}
        className={classes.tableRow}
        selected={selectedMember && m.mitgliedId === selectedMember.mitgliedId}
        key={`${m.lastName}--${i}`}
      >
        <TableCell className={classes.tableCell} align="left">
          <BeltAvatars beltNumber={m.degree} />
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {gradArray[m.degree - 1].label}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {m.title}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {m.lastName}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {m.firstName}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {createFullDateString(new Date(m.birthdate))}
        </TableCell>
      </TableRow>
    );
  });
  return (
    <div>
      <TableContainer
        style={{ marginTop: 20, marginBottom: 20, fontSize: "1.2rem" }}
        component={Paper}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Gürtel</TableCell>
              <TableCell align="left">Grad</TableCell>
              <TableCell align="left">Anrede</TableCell>
              <TableCell align="left">Nachname</TableCell>
              <TableCell align="left">Vorname</TableCell>
              <TableCell align="left">Geburtsdatum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{memberList && memberPreview}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
