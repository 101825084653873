import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function DatePickers(props) {

  const { name, label, value, onChange, disabled } = props


  return (
    <TextField
      // id="date"
      label={label}
      type="date"
      variant="outlined"
      disabled={disabled}
      defaultValue={new Date().toLocaleDateString()}
      InputLabelProps={{
        shrink: true,
      }}
      name={name}
      value={value}
      // onChange={date =>onChange(convertToDefEventPara(name,date))}
      onChange={onChange}
    />
  );
}