import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";

import { useEffect } from "react";
import StandorteGrid from "./StandorteGrid";
import {
  Button,
  FormGroup,
  lighten,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveStandortDialog from "../../Dialogs/RemoveStandortDialog";

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 130,
    minHeight: 45,
  },
  standortTypeButton: {
    fontSize: "1.5rem",
  },
}));

export default function StandorteContent(props) {
  const [standortType, selectStandortType] = React.useState(null);
  const classes = useStyles();

  useEffect(() => {
    document.title = `Standorte`;
  });

  const handleSelectStandortType = (type) => {
    selectStandortType(type);
  };

  const handleBack = () => {
    selectStandortType(null);
  };

  return (
    <main className={props.classes.content}>
      <RemoveStandortDialog></RemoveStandortDialog>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="false" className={props.classes.container}>
        {!standortType ? (
          <div style={{ marginTop: "250px" }}>
            <Grid
              style={{ textAlign: "center" }}
              container
              justify="space-evenly"
              spacing={3}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.standortTypeButton}
                  onClick={() => handleSelectStandortType("OWN")}
                >
                  Eigene Standorte
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.standortTypeButton}
                  onClick={() => handleSelectStandortType("AFFILIATED")}
                >
                  Angehörige Standorte
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container justify="space-between" spacing={2}>
              <Grid item>
                <FormGroup
                  row
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    flex: 1,
                  }}
                >
                  <Tooltip title="Zurück">
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{
                        backgroundColor: "white",
                        minWidth: 220,
                        fontSize: "2em",
                      }}
                      onClick={handleBack}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 20 }}
                        size="lg"
                        icon={faArrowLeft}
                      />
                      Zurück
                    </Button>
                  </Tooltip>
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div style={{ padding: 15 }}>
                  <StandorteGrid standortType={standortType}></StandorteGrid>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
