import React from "react";

import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import BackgroundSlider from "./helpers/BackgroundSlider";

import { useSelector } from "react-redux";

function LoginPage() {
  return (
    <React.Fragment>
      <BackgroundSlider />
      <Login />
    </React.Fragment>
  );
}

function App() {
  const { user } = useSelector((state) => state.appReducer);
  let register = false;

  if (register) {
    return (
      <div>
        <Register />
      </div>
    );
  }

  if (user) {
    return <Dashboard />;
  } else {
    return <LoginPage />;
  }
}

export default App;
