import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Tooltip } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { dashboardSideRoutes } from "./dashboardRoutes";

import "./Dashboard.css";

const useStyles = makeStyles((theme) => ({
  navListItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    // '&:active': {
    //   backgroundColor: 'rgba(255, 255, 255, 0.2)',
    // },
  },
  navListItemActive: {
    backgroundColor: "rgba(255, 255, 255, 0.2) !important",
  },
  navListItemText: {
    color: "white",
  },
  navListItemIcon: {
    color: "white",
    fontSize: 25,
  },
}));

export default function SideNavListItems(props) {
  const classes = useStyles();
  let location = useLocation();

  return (
    <div>
      {
        // primarySideNavListItems.map( (item, i) => { return (
        dashboardSideRoutes.map((item, i) => {
          return (
            <div key={`SideNav-Link-${i}`} className={classes.navListItems}>
              <Tooltip title={item.text}>
                <NavLink
                  to={`/dashboard/${item.path}`}
                  exact
                  activeClassName="sidenav__list-item-active"
                >
                  <ListItem
                    button
                    key={i}
                    classes={{ selected: classes.navListItemActive }}
                    selected={`/dashboard/${item.path}` === location.pathname}
                    className={classes.navListItem}
                  >
                    <ListItemIcon className={classes.navListItemIcon}>
                      <FontAwesomeIcon icon={item.icon} size="" />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      className={classes.navListItemText}
                    />
                  </ListItem>
                </NavLink>
              </Tooltip>
            </div>
          );
        })
      }
    </div>
  );
}
