import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Copyright from "../../Brand/Copyright/Copyright";
import { Paper, Typography } from "@material-ui/core";
import MemberOverview from "../../../helpers/MemberOverview";
import BirthDayOverview from "../../../helpers/BirthDayOverview";

export default function Welcome(props) {
  const { currentUser, classes } = props;
  console.log("currentUser :>> ", currentUser);
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 15 }}>
              <Typography variant="h4">
                Wilkommen, {currentUser.title} {currentUser.lastName}!
              </Typography>
              <br />
              <Typography variant="h5">Neue Updates:</Typography>
              <Typography variant="h6">
                <ul>
                  <li>
                    SEPA-Datei Erstellung aktualisiert
                    <br />
                    <Typography variant="caption">
                      Datei hat nun aktuelles SEPA Format nach neuesten
                      Richtlinien.
                      <br />
                      Probleme mit Umlauten behoben.
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <Typography variant="body2">Stand: 25.06.2024</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <BirthDayOverview />
          </Grid>
          <Grid item xs={12} md={12} lg={7}>
            <MemberOverview />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
}
