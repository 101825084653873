import { Button, FormGroup, Switch, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import { importFields } from "../../../../utils/dataUtils/importUtils";

const dataColumns = importFields;

const firstTableHeaderStyle = { fontSize: "110%" };

const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const clone = (obj) => Object.assign({}, obj);

export default function ImportDataFormClassic(props) {
  const dispatch = useDispatch();
  const [valid, setValid] = React.useState(null);
  const [validationError, setValidationError] = React.useState("");
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    formState: { isSubmitted },
  } = useForm();

  const hasHeaderRow = watch("hasHeaderRow");

  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  var selectHeaders = [];
  var sampleData = [];

  // When importing members:
  // Error handling leads to errors

  const onSubmit = (data) => {
    let validationCounter = 0;
    if (errors) {
      console.log("errors :>> ", errors);
    }

    // Check which option the user selected and verify the imported Data
    switch (dialogData.selectedOption) {
      // Upload of full members
      case "1":
        // Check if the fields lastName and firstName have been assigned:
        for (const [, value] of Object.entries(data)) {
          if (value === "lastName" || value === "firstName") {
            validationCounter = validationCounter + 1;
          }
        }
        if (validationCounter !== 2) {
          // lastName or firstName was not assigned
          setValid(false);
          setValidationError({ fieldError: true });
          return;
        } else {
          // validationCounter === 2 which means a lastName and firstName have been assigned
          // set all flags to valid
          setValid(true);
          setValidationError(false);
        }
        break;
      // Upload of children and contract data
      case "2":
        // Check if the fields lastName and firstName have been assigned:
        for (const [, value] of Object.entries(data)) {
          if (value === "lastName" || value === "firstName") {
            validationCounter = validationCounter + 1;
          }
        }
        if (validationCounter !== 2) {
          // lastName or firstName was not assigned
          setValid(false);
          setValidationError({ fieldError: true });
          return;
        } else {
          // validationCounter === 2 which means a lastName and firstName have been assigned
          // set all flags to valid
          setValid(true);
          setValidationError(false);
        }
        break;
      // Upload of two seperate files with members and contract data
      case "3":
        // Check if the fields lastName and firstName have been assigned:
        for (const [, value] of Object.entries(data)) {
          if (value === "lastName" || value === "firstName") {
            validationCounter = validationCounter + 1;
          }
        }
        if (validationCounter !== 2) {
          // lastName or firstName was not assigned
          setValid(false);
          setValidationError({ fieldError: true });
          return;
        } else {
          // validationCounter === 2 which means a lastName and firstName have been assigned

          // Check if hasContract Field has been assigned
          let hasContract_FLAG = false;
          for (const [, value] of Object.entries(data)) {
            if (value === "hasContract") {
              hasContract_FLAG = true;
            }
          }
          if (hasContract_FLAG === true) {
            // set all flags to valid
            setValid(true);
            setValidationError(false);
          } else {
            // hasContract_FLAG === false
            // Set the error for the missing hasContract field
            setValid(false);
            setValidationError({ bezugError: true });
            return;
          }
        }
        break;

      default:
        break;
    }

    // Check if the first row of the Data contains the headers, if true remove them
    if (data.hasHeaderRow) {
      dialogData.importedData.shift();
    }
    let newDefaultColumns = [];
    let testArray = Object.assign([], dialogData.importedData);
    for (let k = 0; k < testArray.length; k++) {
      // Iterate over every dataset of the imported data
      for (var oldKey in testArray[k]) {
        // Assign the imported data the right Object-Key for the software/database to handle
        let number = oldKey;
        let dataIndex = `header-${("0" + number).slice(-2)}`;
        // If the user has not assigned a Header as a Key to a column the column will be removed
        if (data[dataIndex] === "empty") {
          delete testArray[k][number];
        } else {
          // else the column will be assigned to a Key that was specified in the Header (Dropdown)
          testArray[k] = renameKey(testArray[k], number, data[dataIndex]);
        }
      }
    }

    // Create new default Headers to preview the data in the next step
    for (let j = 0; j < dataColumns.length; j++) {
      // iterate over the predefined Headers to assign them to the selected Keys
      let headerIndex = `header-${("0" + j).slice(-2)}`;
      // If a Header was selected in the Dropdown assign the corresponding Key
      if (data[headerIndex] !== "empty" && data[headerIndex] !== undefined) {
        let newHeaderIndex = dataColumns.findIndex(
          (x) => x.value === data[headerIndex]
        );
        newDefaultColumns.push(dataColumns[newHeaderIndex]);
      }
    }

    // Sometimes the last row of a dataset is empty which will create a flawed dataset
    // If so the empty row will be removed here
    if (
      testArray[testArray.length - 1].firstName === undefined ||
      testArray[testArray.length - 1].lastName === undefined
    ) {
      testArray.pop();
    }

    // Update the Dialogdata with the imported Data and the Headers here
    let newDialogData;
    newDialogData = {
      ...dialogData,
      defaultColumns: newDefaultColumns,
      importedData: testArray,
    };

    // If the user selected a seperate import of two files
    // then the user will be directed to the correct page
    if (dialogData.selectedOption === "3") {
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogConstants.IMPORT_VERTRAGSNEHMER,
          newDialogData,
          "IMPORT_CONTRACT_DATA"
        )
      );
      return;
    }

    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.IMPORT_VERTRAGSNEHMER,
        newDialogData,
        "CHECK_DATA"
      )
    );
  };

  if (dialogData && dialogData.importedData.length > 0) {
    for (let i = 0; i < dialogData.defaultColumns.length; i++) {
      selectHeaders.push(i);
    }

    for (let j = 0; j < 2; j++) {
      if (!dialogData.importedData[j]) {
        console.log(dialogData.importedData[j]);
      }
      let sample = Object.values(dialogData.importedData[j]);
      sampleData.push(sample);
    }

    console.log("selectHeaders :>> ", selectHeaders);
    console.log("dataColumns :>> ", dataColumns);
    console.log("sampleData :>> ", sampleData);

    return (
      <div>
        <Alert style={{ padding: "30px 0" }} variant="filled" severity="info">
          <Typography variant="h5">
            Hier sehen Sie die Daten die eingelesen wurden. Ordnen Sie bitte nun
            Zeile für Zeile den Daten Ihre entsprechenden Werte zu. Wählen Sie
            dazu in den gelb hinterlegten Auswahlfeldern den passenden Titel für
            die jeweilige Zeile aus.
          </Typography>
          <Typography style={{ marginTop: "10px" }} variant="h5">
            Wählen Sie anschließend unten "Bestätigen", um Ihre Auswahl zu
            übernehmen
          </Typography>
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <table>
            {!valid && (
              <tr>
                <th style={firstTableHeaderStyle}>
                  <p style={{ fontSize: "120%px" }}>WICHTIG!</p>
                  Ordnen Sie in dieser Spalte das entsprechende Feld zu
                </th>
                <th style={firstTableHeaderStyle}>
                  <p style={{ fontSize: "120%px" }}>WICHTIG!</p>
                  Sind in dieser Spalte die Überschriften der Felder abgebildet?
                  <br></br>
                  {dialogType === "IMPORT_DATA" && (
                    <div style={{ marginTop: "5px", minWidth: "150px" }}>
                      <FormGroup row>
                        <label
                          onClick={() => {
                            setValue("hasHeaderRow", false);
                          }}
                          style={{
                            color: hasHeaderRow ? "grey" : "black",
                            marginLeft: 15,
                            marginTop: "5px",
                            fontWeight: 500,
                            fontSize: "1.1rem",
                          }}
                        >
                          Nein
                        </label>
                        <Switch
                          name="hasHeaderRow"
                          defaultChecked={hasHeaderRow}
                          inputRef={register}
                        />
                        <label
                          onClick={() => {
                            setValue("hasHeaderRow", true);
                          }}
                          style={{
                            color: hasHeaderRow ? "black" : "grey",
                            marginTop: "5px",
                            fontWeight: 500,
                            fontSize: "1.1rem",
                          }}
                        >
                          Ja
                        </label>
                      </FormGroup>
                    </div>
                  )}
                </th>
                <th style={firstTableHeaderStyle}>
                  Hier sehen Sie Beispieldaten aus Ihrer importierten Datei
                </th>
              </tr>
            )}
            {selectHeaders.map((i) => {
              if (isSubmitted && valid) {
                return (
                  <tr
                    style={{
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <th style={{ minWidth: "120px" }}>
                      {dialogData.defaultColumns[i].text}
                    </th>
                    {sampleData.map((sample) => {
                      return <td>{sample[i]}</td>;
                    })}
                  </tr>
                );
              } else {
                return (
                  <tr style={{ borderStyle: "solid", textAlign: "left" }}>
                    <th
                      style={{
                        backgroundColor: "#ffc400",
                        borderRightStyle: "solid",
                      }}
                    >
                      <select
                        name={`header-${("0" + i).slice(-2)}`}
                        ref={register}
                      >
                        {dataColumns.map((col, i) => {
                          return <option value={col.value}>{col.text}</option>;
                        })}
                      </select>
                    </th>
                    {sampleData.map((sample) => {
                      return <td>{sample[i]}</td>;
                    })}
                  </tr>
                );
              }
            })}
          </table>
          <br></br>
          <br></br>
          {valid === false && validationError.fieldError && (
            <Alert variant="filled" severity="error">
              <AlertTitle>{"Pflichtfelder nicht zugewiesen!"}</AlertTitle>
              {
                "Bitte weisen Sie die Felder Nachname (Mitglied) und Vorname (Mitglied) Ihren entsprechenden Daten zu."
              }
            </Alert>
          )}
          {valid === false && validationError.bezugError && (
            <Alert variant="filled" severity="error">
              <AlertTitle>{"Bezugsfeld nicht zugewiesen!"}</AlertTitle>
              {
                "Bitte wählen Sie ein Bezugsfeld aus, anhand dessen Wert ein eindeutiger Vertrag in Ihrer separaten Vertragsdatei zugewiesen werden kann."
              }
            </Alert>
          )}
          <Button
            disabled={dialogType !== "IMPORT_DATA"}
            variant="contained"
            style={{
              fontSize: "200%",
              minHeight: "60px",
              padding: "25px 50px",
            }}
            type="submit"
            value="Bestätigen"
          >
            Bestätigen
          </Button>
          <br></br>
          {dialogType !== "IMPORT_DATA" && (
            <Typography variant="h6">
              Datensätze wurden erfolgreich übernommen. Klicken Sie auf{" "}
              <b>Weiter</b> um zur nächsten Seite zu gelangen und die Daten zu
              prüfen.
            </Typography>
          )}
        </form>
      </div>
    );
  } else {
    return <div></div>;
  }
}
