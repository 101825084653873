import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TabPages from "./TabPages";
import { useDispatch, useSelector } from "react-redux";
import dialogConstants from "../../../../redux/constants/dialogConstants";
import masterDataActions from "../../../../redux/actions/masterDataActions";

import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    "&:first-child": {
      padding: 0,
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogTabs() {
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogConstants.EDIT_MITGLIED;

  const handleClose = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
    dispatch(masterDataActions.getMitgliederList());
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        minHeight="700px"
        maxWidth={"lg"}
        disableBackdropClick={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={visible}
      >
        <DialogContent>
          <TabPages openDefault={0}></TabPages>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
