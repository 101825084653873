import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Copyright from "../../Brand/Copyright/Copyright";
import Account from "../../Account/Account";
import SettingsList from "./SettingsList";
import SendIcon from "@material-ui/icons/Send";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChangePassword from "./ChangePassword";
import BankingSettingsMenu from "./Banking/BankingSettingsMenu";

export default function Settings(props) {
  let testList = [
    {
      icon: <SendIcon />,
      text: "Account Einstellungen",
      item: <Account></Account>,
    },
    {
      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />,
      text: "Banking Einstellungen",
      item: (
        <div>
          <BankingSettingsMenu />
        </div>
      ),
    },
    {
      icon: <SendIcon />,
      text: "Password ändern",
      item: (
        <div>
          <ChangePassword />
        </div>
      ),
    },
  ];

  return (
    <main className={props.classes.content}>
      <div className={props.classes.appBarSpacer} />
      <Container maxWidth="lg" className={props.classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SettingsList childrenList={testList}></SettingsList>
          </Grid>
          <Grid item xs={12}>
            {/* <Account></Account> */}
          </Grid>
        </Grid>
        <Copyright></Copyright>
      </Container>
    </main>
  );
}
