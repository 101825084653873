import { Paper, Typography } from "@material-ui/core";
import React from "react";

import logo from "../img/architect.svg";
import "./Construction.css";

export default function Construction() {
  return (
    <div className="Construction-container">
      <Paper style={{ padding: 15 }}>
        <Typography align="center" className="Construction-header" variant="h3">
          Diese Seite befindet sich noch im Aufbau
        </Typography>
        <img src={logo} className="Construction-logo" alt="construction-logo" />
      </Paper>
    </div>
  );
}
