import {
  faArrowLeft,
  faArrowRight,
  faBirthdayCake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { createFullDateString, monthNames } from "../utils/dataUtils/appUtils";

import "./BirthDayOverview.css";

export default function BirthDayOverview() {
  const { mitgliederList } = useSelector((state) => state.masterDataReducer);
  const [selectedMonth, setSelectedMonth] = React.useState(
    new Date().getMonth()
  );
  let birthDayList = [];
  let todayDate = new Date().getDate();
  let todayMonth = new Date().getMonth();

  mitgliederList &&
    mitgliederList.forEach((member) => {
      let memberBirthMonth = new Date(member.birthdate).getMonth();
      let memberBirthDay = new Date(member.birthdate).getDate();
      if (memberBirthMonth === selectedMonth && member.status === "active") {
        birthDayList.push({
          ...member,
          birthdayDate: new Date(member.birthdate),
          hasBirthDay:
            memberBirthDay === todayDate && memberBirthMonth === todayMonth
              ? true
              : false,
        });
      }
    });

  birthDayList &&
    birthDayList.sort(function compare(a, b) {
      var dateA = new Date(a.birthdate).getDate();
      var dateB = new Date(b.birthdate).getDate();
      return dateA - dateB;
    });

  const handleMonth = (i) => {
    if (i > 0) {
      // Check if first Click
      setSelectedMonth((prevMonth) => (prevMonth + 1) % 12);
    }
    if (i < 0) {
      if (selectedMonth === 0) {
        setSelectedMonth(11);
        return;
      } else setSelectedMonth((prevMonth) => prevMonth - 1);
    }
  };

  return (
    <div>
      <Paper
        style={{
          textAlign: "left",
          minHeight: "300px",
          height: 600,
          padding: "20px 20px 50px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Geburtstage im</Typography>
          <FontAwesomeIcon icon={faBirthdayCake} size="3x" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            margin: "25px 0",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleMonth(-1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </Button>
          <Typography style={{ minWidth: "200px" }} align="center" variant="h4">
            {monthNames[selectedMonth]}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              handleMonth(1);
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </Button>
        </div>
        <div style={{ height: 420, overflow: "auto" }}>
          <table style={{ border: 0, fontSize: "1.25rem" }}>
            {birthDayList.map((m) => {
              if (m.hasBirthDay) {
                return (
                  <tr className="birthday-glow" id="birthday-row">
                    <th>
                      {m.lastName} {m.firstName}
                    </th>
                    <td>{createFullDateString(m.birthdayDate)}</td>
                    <td>
                      <FontAwesomeIcon icon={faBirthdayCake} size="1x" />
                    </td>
                  </tr>
                );
              }
              return (
                <tr>
                  <th>
                    {m.lastName} {m.firstName}
                  </th>
                  <td>{createFullDateString(m.birthdayDate)}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </Paper>
    </div>
  );
}
