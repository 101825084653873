import {
  addMonths,
  differenceInCalendarMonths,
  isBefore,
  startOfMonth,
} from "date-fns";
import { getStringFromPayedTillDate } from "../../components/Dialogs/NewMitglied/DialogPages/inputFields";
import masterDataApi from "../../redux/api/masterDataApi";

export const playTutorialInNewTab = (link) => {
  window.open(link, "_blank", "noreferrer");
};

// function to check if an array is empty
export const isArrayEmpty = (arr) => arr.length === 0;

// Function to check if an array of arrays contains an empty array
export const doesArrayOfArraysContainEmpty = (arrayOfArrays) => {
  // Use the some() method to check if any array is empty
  return !arrayOfArrays.some((innerArray) => innerArray.length === 0);
};

// Function to check if all arrays in a list are loaded (not null and arrays)
export const areArraysLoaded = (arrayList) => {
  return arrayList.every(
    (content) => content !== null && Array.isArray(content)
  );
};

export const sortArrayWithObjects = (array, key, direction) => {
  let sortableItems = [...array];
  sortableItems.sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === "asc" ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  return sortableItems;
};

export const filterMemberStatus = (member, status) => {
  return member.status !== status;
};

export const filterMemberStatusList = (list, state) => {
  let newTableList = [...list];
  if (!state.checkedActiveMembers) {
    newTableList = newTableList.filter((member) =>
      filterMemberStatus(member, "active")
    );
  }
  if (!state.checkedTerminatedMembers) {
    newTableList = newTableList.filter((member) =>
      filterMemberStatus(member, "terminated")
    );
  }
  if (!state.checkedFrozenMembers) {
    newTableList = newTableList.filter((member) =>
      filterMemberStatus(member, "frozen")
    );
  }
  return newTableList;
};

export const getGradCollection = () => [
  { id: 1, title: "10. Kup", fee: 0 },
  { id: 2, title: "9. Kup", fee: 40 },
  { id: 3, title: "8. Kup", fee: 40 },
  { id: 4, title: "7. Kup", fee: 40 },
  { id: 5, title: "6. Kup", fee: 40 },
  { id: 6, title: "5. Kup", fee: 40 },
  { id: 7, title: "4. Kup", fee: 50 },
  { id: 8, title: "3. Kup", fee: 50 },
  { id: 9, title: "2. Kup", fee: 70 },
  { id: 10, title: "1. Kup", fee: 70 },
  { id: 11, title: "POOM", fee: 100 },
  { id: 12, title: "1. Dan", fee: 200 },
  { id: 13, title: "2. Dan", fee: 200 },
  { id: 14, title: "3. Dan", fee: 300 },
  { id: 15, title: "4. Dan", fee: 400 },
  { id: 16, title: "5. Dan", fee: 500 },
  { id: 17, title: "6. Dan", fee: 500 },
];

export const membersSelectSortArray = [
  {
    id: 0,
    value: "status",
    label: "Status",
  },
  {
    id: 1,
    value: "standortRef",
    label: "Standort",
  },
  {
    id: 2,
    value: "memberNumber",
    label: "Mitgliedsnummer",
  },
  {
    id: 3,
    value: "firstName",
    label: "Vorname",
  },
  {
    id: 4,
    value: "createdAt",
    label: "Erstelldatum",
  },
  {
    id: 7,
    value: "degree",
    label: "Gürtelgrad",
  },
  {
    id: 9,
    value: "contribution",
    label: "Beitrag",
  },
  {
    id: 10,
    value: "lastName",
    label: "Nachname",
  },
  {
    id: 11,
    value: "hasTesting",
    label: "Zur Prüfung aufgestellt",
  },
  {
    id: 12,
    value: "contractDuration",
    label: "Vertragsdauer",
  },
  {
    id: 13,
    value: "contractDate",
    label: "Vertragsdatum",
  },
  {
    id: 14,
    value: "memberType",
    label: "Kind / Erwachsen",
  },
  {
    id: 15,
    value: "birthdate",
    label: "Geburtstag",
  },
  {
    id: 16,
    value: "title",
    label: "Anrede",
  },
];

export const monthNames = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export function hasNumber(myString) {
  return /\d/.test(myString);
}

export const sortListDir = (list, fieldToSortBy) => {
  var i,
    switching,
    b,
    shouldSwitch,
    dir,
    switchcount = 0;
  // list = document.getElementById("id01");
  switching = true;
  // Set the sorting direction to ascending:
  dir = "asc";
  // Make a loop that will continue until no switching has been done:
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    b = list;
    // Loop through all list-items:
    for (i = 0; i < b.length - 1; i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Check if the next item should switch place with the current item,
      based on the sorting direction (asc or desc): */
      if (dir === "asc") {
        if (
          b[i][fieldToSortBy].toLowerCase() >
          b[i + 1][fieldToSortBy].toLowerCase()
        ) {
          /* If next item is alphabetically lower than current item,
          mark as a switch and break the loop: */
          shouldSwitch = true;
          break;
        }
      } else if (dir === "desc") {
        if (
          b[i][fieldToSortBy].toLowerCase() <
          b[i + 1][fieldToSortBy].toLowerCase()
        ) {
          /* If next item is alphabetically higher than current item,
          mark as a switch and break the loop: */
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
      b[i].parentNode.insertBefore(b[i + 1], b[i]);
      switching = true;
      // Each time a switch is done, increase switchcount by 1:
      switchcount++;
    } else {
      /* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
      if (switchcount === 0 && dir === "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
};

export const getDateFromString = (dateString) => {
  // console.log("dateString :>> ", dateString);
  let hasN = hasNumber(dateString);
  if (!hasN) {
    return;
  }
  let date;
  if (dateString) {
    date = dateString;
  } else {
    return null;
  }
  date = date.split(".");
  if (date[2].length > 4) {
    date[2] = date[2] ? date[2].slice(0, date[2].search(" ")) : "2020";
  }
  date = date.reverse();
  date[1] = date[1] - 1;
  let newDate = new Date(...date);
  return newDate;
};

// export const checkBirthday = (birthdate) => {
//   let today = new Date();
//   // birthdate format: "2008-10-16T22:00:00.000Z"
// };

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const createFullDateString = (MyDate) => {
  if (!isValidDate(MyDate)) {
    return "Kein Datum";
  }
  let MyDateString =
    ("0" + MyDate.getDate()).slice(-2) +
    "." +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "." +
    MyDate.getFullYear();
  return MyDateString;
};

export const timeStampSepa = () => {
  let d = new Date();
  let dateString =
    d.getFullYear() +
    "_" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "_" +
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + d.getHours()).slice(-2) +
    "_" +
    ("0" + d.getMinutes()).slice(-2) +
    "_" +
    ("0" + d.getSeconds()).slice(-2);
  return dateString;
};

// export const addMonths = (date, months) => {
//   var d = date.getDate();
//   date.setMonth(date.getMonth() + +months);
//   if (date.getDate() !== d) {
//     date.setDate(0);
//   }
//   return date;
// };

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const firstDayOfMonthDate = (d) => {
  let nD = new Date(d.getFullYear(), d.getMonth(), 1);
  return nD;
};

export const getDebitEndDate = (
  terminationDate,
  contractDate,
  contractDuration,
  extension,
  frist
) => {
  console.log("FUNCTION CALL");
  console.log("frist :>> ", frist);
  let tD = new Date(terminationDate);
  let day = tD.getDate();
  let month = tD.getMonth();
  let year = tD.getFullYear();
  tD = new Date(year, month, day);
  let today = new Date();
  let stopCollect = false;
  let pastTerminationDeadline = null;

  let vertragLaufzeitEnde = addMonths(new Date(contractDate), contractDuration);
  let mmSinceContract = monthDiff(new Date(contractDate), today);
  let mmSeitLaufzeitEnde = monthDiff(vertragLaufzeitEnde, today);
  let mmSinceTermination = monthDiff(tD, today);
  let currentPeriod = extension - (mmSinceContract % extension) - 1;
  let newEndDate = addMonths(
    firstDayOfMonthDate(new Date(contractDate)),
    contractDuration - 1
  );
  let currentTerminationDeadline = addMonths(
    new Date(contractDate),
    contractDuration - frist
  );
  console.log("vertragLaufzeitEnde :>> ", vertragLaufzeitEnde);
  console.log("currentPeriod :>> ", currentPeriod);
  console.log("currentTerminationDeadline :>> ", currentTerminationDeadline);
  console.log("contractDuration :>> ", contractDuration);
  console.log("extension :>> ", extension);
  console.log("contractDate :>> ", new Date(contractDate));

  if (vertragLaufzeitEnde < tD) {
    // console.log("Das Kündigungsdatum ist nach Ende der Vertragslaufzeit");
    if (mmSeitLaufzeitEnde > extension) {
      // console.log("TEST 1");
      pastTerminationDeadline = addMonths(
        new Date(currentTerminationDeadline),
        -extension
      );
    }
    currentPeriod = extension - (mmSeitLaufzeitEnde % extension) - 1;
    newEndDate = addMonths(firstDayOfMonthDate(today), currentPeriod);
    currentTerminationDeadline = addMonths(
      new Date(contractDate),
      mmSinceContract + currentPeriod - frist + 1
    );
    if (mmSinceTermination >= extension + frist - currentPeriod) {
      stopCollect = true;
      // console.log("Vertrag wurde bereits vor Vertragsverlängerung gekündigt");
    }
    if (currentPeriod >= frist) {
      // console.log("Kündigung bewirkt keine Vertragsverlängerung");
      pastTerminationDeadline = addMonths(
        new Date(currentTerminationDeadline),
        -extension
      );
    } else {
      // console.log("Verlängern");
      currentTerminationDeadline = addMonths(
        currentTerminationDeadline,
        extension
      );
      pastTerminationDeadline = addMonths(
        new Date(currentTerminationDeadline),
        -extension
      );
      newEndDate = addMonths(newEndDate, extension);
    }
  } else {
    // console.log("Kündigung vor Ende der Vertragslaufzeit");
    // Check if Termination is before deadline
    if (currentTerminationDeadline < tD) {
      // Termination is after deadline, contract will be extended end then ended
      // console.log("Kündigung nach Frist, Verlängern dann Ende");
      // console.log("newEndDate :>> ", newEndDate);

      newEndDate = firstDayOfMonthDate(
        addMonths(contractDate, contractDuration + extension)
      );
    } else {
      // Termination is before deadline, set end of contract to end of vertragLaufzeitEnde
      // console.log("Kündigung vor Frist, Ende = Vertragslaufzeitende");
      newEndDate = firstDayOfMonthDate(
        addMonths(contractDate, contractDuration)
      );
    }
  }

  console.log("newEndDate :>> ", newEndDate);

  console.log("pastTerminationDeadline :>> ", pastTerminationDeadline);

  if (tD)
    if (tD < pastTerminationDeadline) {
      // console.log("TEST 2");
      stopCollect = true;
    }
  return {
    newEndDate: newEndDate,
    currentTerminationDeadline: currentTerminationDeadline,
    pastTerminationDeadline: pastTerminationDeadline,
    stopCollect: stopCollect,
    tD: tD,
    frist: frist,
  };
};

export const addToTestingList = async (memberArray) => {
  let testingList = [];
  memberArray.forEach((m) => {
    delete m.tableData.checked;
    testingList.push({
      ...m,
      hasTesting: true,
    });
  });
  try {
    await Promise.all(testingList.map((m) => masterDataApi.editMitglied(m)));
  } catch (error) {
    return error;
  } finally {
    return testingList;
  }
};

export const removeFromTestingList = async (memberArray) => {
  let testingList = [];
  memberArray.forEach((m) => {
    // if (!m.hasPayedTesting || !m.hasPassedTesting) {
    //   return;
    // }
    delete m.tableData;
    testingList.push({
      ...m,
      hasTesting: false,
      hasPayedTesting: false,
      hasPassedTesting: false,
      hasPassport: false,
    });
  });
  try {
    await Promise.all(testingList.map((m) => masterDataApi.editMitglied(m)));
  } catch (error) {
    return error;
  } finally {
    return testingList;
  }
};

export const handleTransferSite = async (transferData) => {
  let { site, members, contracts, newOwner } = transferData;
  console.log("Begin to transfer site!");
  // Begin with site
  let updatedSite = {
    ...site,
    creator: newOwner.id,
  };
  let updatedMembers = members.map((member) => {
    return {
      ...member,
      creator: newOwner.id,
    };
  });
  let updatedContracts = contracts.map((contract) => {
    return {
      ...contract,
      creator: newOwner.id,
      username: newOwner.username,
    };
  });

  const updateMembersPromise = updatedMembers.map((m) =>
    masterDataApi.editMitglied(m)
  );
  const updateContractsPromise = updatedContracts.map((c) =>
    masterDataApi.editVertragsnehmer(c)
  );
  const updateStandortPromise = masterDataApi.editStandort(updatedSite);

  return Promise.all([
    updateStandortPromise,
    updateMembersPromise,
    updateContractsPromise,
  ]);
};

export const removeFromTestingListUpadedBelt = async (
  memberArray,
  testingDate,
  gradeAll
) => {
  let testingList = [];
  memberArray.forEach((m) => {
    if (!m.hasPassedTesting && !gradeAll) {
      return;
    }
    delete m.tableData;
    testingList.push({
      ...m,
      degree: m.degree + 1,
      lastTesting: testingDate,
      hasTesting: false,
      hasPayedTesting: false,
      hasPassedTesting: false,
      hasPassport: false,
    });
  });
  try {
    await Promise.all(testingList.map((m) => masterDataApi.editMitglied(m)));
  } catch (error) {
    return error;
  } finally {
    return testingList;
  }
};

/**
 * Function that returns the updated Memberdata with the end of the Contract and the Data when the last Collection is due
 * @author   Michael Angermeyer
 * @param    {Object} member                  member that terminates
 * @param    {Object} terminationData         data of the termination
 * @return   {Object}                         updated Member
 */
export const calculateTermination = (member, terminationData) => {
  const {
    contractDate,
    contractDuration,
    contractExtension,
    noticePeriod,
    terminationDate,
  } = terminationData;

  /** 
   @type {String}
   @default
   Variable wann letzte Abbuchung erfolgt.
   Format: MM/YYYY
*/
  let lastMonth = null;

  /** 
   @type {String}
   @default
   Variable zur Fallunterscheidung welche Kündigungsoption angewendet werden muss:

   Fall 1:	Kündigung innerhalb Kündigungsfrist innerhalb Vertragslaufzeit, Vertragsende = Anmeldung + Vertragslaufzeit
   Fall 2:	Kündigung nach Kündigungsfrist innerhalb/nach Vertragslaufzeit, Kündigungsfrist >= Vertragsverlängerung ==> Vertragsende = Kündigungsmonat + KF + VV
   Fall 3:	Kündigung nach Kündigungsfrist innerhalb Vertragslaufzeit,  Kündigungsfrist < Vertragsverlängerung, Vertragsende = Anmeldung + Vertragslaufzeit + Vertragsverlängerung
   Fall 4:	Kündigung nach Vertragslaufzeit innerhalb Kündigungsfrist Vertragsverlängerung, Kündigungsfrist < Vertragsverlängerung, Vertragsende = nach aktueller Vertragsverlängerung
   Fall 5:	Kündigung nach Vertragslaufzeit nach Kündigungsfrist Vertragsverlängerung, Kündigungsfrist < Vertragsverlängerung, Vertragsende = aktuelle Vertragsverlängerung + Vertragsverlängerung

*/
  let terminationCase = "FALL1";

  /** 
   @type {Date}
   @default
   Datum für Ende der regulären VL
   Beispiel: Start: 8.8.2018, VL 12 Monate -> Ende: 1.8.2019
*/
  let contractEnd = startOfMonth(
    addMonths(new Date(contractDate), contractDuration)
  );

  /** 
   @type {Date}
   @default
   Datum für Kündigungsfrist der regulären VL
   Beispiel: Start: 8.8.2018, VL 12 Monate, KF, 3 Monate -> Ende: 1.8.2019, Kündigung möglich bis 1.5.2019
*/
  let noticePeriodDate = startOfMonth(
    addMonths(new Date(contractDate), contractDuration - noticePeriod)
  );

  /** 
   @type {number}
   @default
   Anzahl der Monate zwischen Vertragsdatum und Kündigungsdatum
   Beispiel: Start: 8.8.2018, Kündigung: 7.2.2023 -> diff: 54
*/
  let diff = differenceInCalendarMonths(
    terminationDate,
    new Date(contractDate)
  );

  // Fall 1:	Kündigung innerhalb Kündigungsfrist innerhalb Vertragslaufzeit, Vertragsende = Anmeldung + Vertragslaufzeit
  // Prüfe, ob innerhalb regulärer VL gekündigt wurde:
  if (isBefore(terminationDate, contractEnd)) {
    // Es wurde innerhalb der regulären VL gekündigt.
    // Prüfe, ob vor KF gekündigt wurde:
    if (isBefore(terminationDate, noticePeriodDate)) {
      // Es wurde VOR der KF in der regulären VL gekündigt.
      terminationCase = "FALL1";
      // Calculate the month of the last collection:
      contractEnd = addMonths(new Date(contractDate), contractDuration - 1);
      contractEnd = getStringFromPayedTillDate(contractEnd);
    } else {
      // Es wurde NACH der KF in der regulären VL gekündigt.
      // Check if Kündigungsfrist or Vertragsverlängerung is bigger
      if (noticePeriod >= contractExtension) {
        // Kündigungsfrist ist größer als Vertragsverlängerung.
        terminationCase = "FALL2";
      } else {
        // Kündigungsfrist ist nicht größer als Vertragsverlängerung
        terminationCase = "FALL3";
      }
    }
  } else {
    // Es wurde nach der regulären VL gekündigt.
    if (noticePeriod >= contractExtension) {
      // Kündigungsfrist ist größer als Vertragsverlängerung.
      terminationCase = "FALL2";
    } else {
      // Kündigungsfrist ist nicht größer als Vertragsverlängerung
      // Prüfe, ob innerhalb innerhalb KF der VV gekündigt wurde
      // Beispiel: Start: 8.8.2018, VL 12 Monate, VV: 6 Monate, KF: 3 Monate,
      // Kündigung: 7.2.2023 -> diff: 54
      // Aktuelle VV: (diff-VL)%VV -> (54-12)%6 = 0 => 1. Monat der VV-Periode => Ende nach aktueller Periode
      // Kündigung: 7.6.2023 -> diff 58
      // Aktuelle VV: (diff-VL)%VV -> (58-12)%6 = 4 => 5. Monat der VV-Periode => Ende nach nächster Periode
      // VV - Monat der VV-Periode > KF ? Ja -> Ende aktuelle Periode : Nein -> Ende nächste Periode
      // Ja => terminationCase = "FALL4";
      // Nein => terminationCase = "FALL5";
      if (
        contractExtension - ((diff - contractDuration) % contractExtension) >
        noticePeriod
      ) {
        // Kündigung innerhalb KF der VV
        terminationCase = "FALL4";
      } else {
        // Kündigung nach KF der VV
        terminationCase = "FALL5";
      }
    }
  }

  switch (terminationCase) {
    case "FALL1":
      // Fall 1:	Kündigung innerhalb Kündigungsfrist innerhalb Vertragslaufzeit, Vertragsende = Anmeldung + Vertragslaufzeit
      // console.log("FALL 1");
      lastMonth = addMonths(new Date(contractDate), contractDuration);

      break;
    case "FALL2":
      // Fall 2:	Kündigung nach Kündigungsfrist innerhalb/nach Vertragslaufzeit, Kündigungsfrist >= Vertragsverlängerung
      // ==> Vertragsende = Kündigungsmonat + KF + VV - 1
      // Minus ein Monat wegen aktuell laufendem Monat
      // console.log("FALL 2");
      lastMonth = addMonths(
        new Date(terminationDate),
        noticePeriod + contractExtension - 1
      );

      break;
    case "FALL3":
      // Fall 3:	Kündigung nach Kündigungsfrist innerhalb Vertragslaufzeit,  Kündigungsfrist < Vertragsverlängerung, Vertragsende = Anmeldung + Vertragslaufzeit + Vertragsverlängerung
      // console.log("FALL 3");
      lastMonth = addMonths(
        new Date(contractDate),
        contractDuration + contractExtension
      );

      break;
    case "FALL4":
      // Fall 4:	Kündigung nach Vertragslaufzeit innerhalb Kündigungsfrist Vertragsverlängerung, Kündigungsfrist < Vertragsverlängerung, Vertragsende = nach aktueller Vertragsverlängerung
      // console.log("FALL 4");

      // Subtract one because of the current month

      lastMonth = addMonths(
        new Date(terminationDate),
        contractExtension - ((diff - contractDuration) % contractExtension) - 1
      );

      break;
    case "FALL5":
      // Fall 5:	Kündigung nach Vertragslaufzeit nach Kündigungsfrist Vertragsverlängerung, Kündigungsfrist < Vertragsverlängerung, Vertragsende = aktuelle Vertragsverlängerung + Vertragsverlängerung
      // console.log("FALL 5");
      lastMonth = addMonths(
        new Date(terminationDate),
        contractExtension -
          ((diff - contractDuration) % contractExtension) -
          1 +
          contractExtension
      );

      break;

    default:
      alert(
        "Hoppla, da ist irgendetwas schief gelaufen. Bitte versuchen Sie es erneut."
      );
      break;
  }

  lastMonth = getStringFromPayedTillDate(lastMonth);

  return lastMonth;
};
