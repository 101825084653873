import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dialogs from "../../redux/constants/dialogConstants";
import masterDataActions from "../../redux/actions/masterDataActions";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
} from "@material-ui/core";
import Controls from "../controls/Controls";
import masterDataApi from "../../redux/api/masterDataApi";
import LoadingScreen from "../Spinner/LoadingScreen";
import {
  renderStandortLeiterInputFields,
  uploadStandortleiterImage,
} from "../../utils/dataUtils/masterDataUtils";
import { standortLeiterFormData } from "./NewMitglied/DialogPages/inputFields";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const initialFValues = {
  memberIdentifier: "",
  siteName: "",
  memberContribution: 70.0,
  openingDate: new Date(),
  email: "@twintaekwondo.de",
  street: "",
  city: "",
  zip: "",
  isLeader: true,
  latestMemberNumber: 1,
  enrollmentFee: 80,
  // leader: "",
  // owner: '',
  // bank: '',
  // iban: '',
  // bic: '',
  // standortleiterId: '',
  image: "",
};

const NewStandortLeiterDialog = () => {
  const dispatch = useDispatch();
  const { dialogData, dialogType } = useSelector(
    (state) => state.masterDataReducer
  );

  let defaultFormValues;

  if (dialogData && dialogType) {
    if (dialogType === "ADD") {
      defaultFormValues = {
        ...initialFValues,
      };
    } else if (dialogType === "EDIT") {
      defaultFormValues = {
        ...dialogData,
      };
    }
  }

  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogs.NEW_STANDORTLEITER;
  const [previewImage, setPreviewImage] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [newImage, setNewImage] = React.useState(false);
  const [newImageFile, setNewImageFile] = React.useState(null);

  const { register, handleSubmit, control, errors, getValues } = useForm({
    defaultValues: defaultFormValues,
  });

  const newInputFields =
    standortLeiterFormData &&
    standortLeiterFormData.map((field) => {
      return {
        ...field,
        type: field.type,
        selectList: field.selectList ? field.selectList : null,
        label: field.label,
        value: field.value,
        required: field.required,
        error: field.required ? errors[field.value] : false,
        errorMsg: field.errorMsg ? field.errorMsg : "",
      };
    });

  const firstFormRow = renderStandortLeiterInputFields(
    newInputFields.slice(0, 7),
    register,
    control
  );
  const secondFormRow = renderStandortLeiterInputFields(
    newInputFields.slice(7, newInputFields.length),
    register,
    control
  );

  const fileHandler = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    // setPreviewImage(e.target.files[0]);
    setNewImageFile(e.target.files[0]);
    setNewImage(true);
    console.log("previewImage", e.target.files[0]);
  };

  const uploadImage = (image, id) => {
    let form_data = new FormData();
    form_data.append("image", image);
    console.log("form_data :>> ", form_data);
    masterDataApi
      .uploadStandortleiterImage(form_data, id)
      .then((response) => {
        console.log("response.data :>> ", response.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function resolveUpload(image, id) {
    console.log("calling Standort Image Upload");
    const result = await uploadStandortleiterImage(image, id);
    if (result) {
      console.log(result);
      return result;
    }
    return new Error("FAIL");
  }

  const handleAbort = () => {
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const handleClose = () => {
    // console.log("close", visible)
    // dispatch(masterDataActions.getStandortleiterList());
    console.log("handleClose");
    dispatch(masterDataActions.closeMasterDataDialog());
  };

  const clearData = () => {
    console.log("clearData");
    setTimeout(() => {
      dispatch(masterDataActions.getStandortleiterList());
    }, 1000);
    setPreviewImage(null);
  };

  useEffect(() => {}, []);

  const onSubmit = async (data) => {
    if (dialogType === "ADD") {
      let alertText = {
        title: "Neuen Standortleiter erfolgreich angelegt.",
        text: (
          <p>
            Neuen Standortleiter <b>{data.siteName}</b> erfolgreich hinzugefügt.
          </p>
        ),
      };
      let newStandortleiter = {
        ...data,
      };
      delete newStandortleiter.image;
      console.log("(ADD) newStandortleiter :>> ", newStandortleiter);
      try {
        let response = await masterDataApi.addStandortleiter(newStandortleiter);
        let uploadedStandortleiter = {
          ...response.data,
        };
        console.log("uploadedStandortleiter", uploadedStandortleiter);
        if (newImage) {
          uploadImage(newImageFile, uploadedStandortleiter.id);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(masterDataActions.getStandortleiterList());
        console.log("test");
        dispatch(
          masterDataActions.openMasterDataDialog(
            dialogs.ALERT,
            alertText,
            "ALERT"
          )
        );
        setLoading(false);
        clearData();
      }
      return;
    }
    setLoading(false);
    // handleClose();
  };

  const handleSaveEdit = () => {
    let newValues = {
      ...defaultFormValues,
    };
    const values = getValues();
    newValues = {
      ...newValues,
      ...values,
    };
    let alertText = {
      title: "Standortleiter geändert.",
      text: (
        <p>
          {newValues.lastName + " " + newValues.firstName} erfolgreich geändert.
        </p>
      ),
    };
    console.log("(EDIT) newValues :>> ", newValues);
    if (newImage) {
      // let answer;
      dispatch(masterDataActions.editStandortleiter(newValues));
      resolveUpload(newImageFile, newValues.standortleiterId)
        .then((response) => {
          console.log(response);
          clearData();
          dispatch(
            masterDataActions.openMasterDataDialog(
              dialogs.ALERT,
              alertText,
              "ALERT"
            )
          );
        })
        .catch((e) => {
          console.log(e);
          console.log("Hochladen fehlgeschlagen");
          alertText.text = (
            <p>Hochladen des neuen Standortleiterbildes fehlgeschlagen.</p>
          );
          dispatch(
            masterDataActions.openMasterDataDialog(
              dialogs.ALERT,
              alertText,
              "ALERT"
            )
          );
        });
    } else {
      // No new Image
      dispatch(masterDataActions.editStandortleiter(newValues));
      clearData();
      dispatch(
        masterDataActions.openMasterDataDialog(
          dialogs.ALERT,
          alertText,
          "ALERT"
        )
      );
    }
  };

  return (
    <div>
      {loading && <LoadingScreen></LoadingScreen>}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={visible}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {dialogType === "ADD"
            ? "Neuen Standortleiter anlegen:"
            : "Standortleiter bearbeiten."}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Grid container>
                  {firstFormRow}
                  {secondFormRow}
                </Grid>
              </Grid>
              <Grid
                style={{
                  padding: "0 25px",
                  // minHeight: 400,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  flex: 1,
                }}
                item
                lg={4}
                xs={12}
              >
                <h3>Laden Sie ein Bild für diesen Standortleiter hoch:</h3>
                <FormGroup
                  row
                  style={{
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Controls.ImageUpload onChange={fileHandler} />
                  <div>
                    <h4>{"Vorschau:"}</h4>
                    <img
                      src={
                        defaultFormValues &&
                        defaultFormValues.newImage &&
                        !newImage
                          ? defaultFormValues.newImage.imageUrl
                          : previewImage
                      }
                      alt={previewImage ? previewImage.name : null}
                      style={{ maxWidth: 300 }}
                    />
                  </div>
                </FormGroup>
                {/* </Grid>
              <Grid item lg={4} xs={12}> */}
                <FormGroup
                  row
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <FormGroup row style={{ justifyContent: "end" }}>
                    {dialogType === "ADD" ? (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        type="submit"
                        variant="contained"
                        value="Hinzufügen"
                        color="primary"
                      >
                        Hinzufügen
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "100%", fontSize: 20 }}
                        variant="contained"
                        color="primary"
                        // type="submit"
                        // value="Bestätigen"
                        onClick={handleSaveEdit}
                      >
                        Speichern
                        <div style={{ marginLeft: 5 }}>
                          <FontAwesomeIcon icon={faSave} />
                        </div>
                      </Button>
                    )}
                  </FormGroup>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewStandortLeiterDialog;
