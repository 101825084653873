import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import masterDataActions from "../../../../redux/actions/masterDataActions";
import dialogConstants from "../../../../redux/constants/dialogConstants";

export default function SelectStandort() {
  const dispatch = useDispatch();
  const { standorteList, dialogData } = useSelector(
    (state) => state.masterDataReducer
  );

  const handleSelectStandort = (standort) => {
    let selectedStandort = standort;
    let newDialogData = {
      ...dialogData,
      selectedStandort: selectedStandort,
    };
    dispatch(
      masterDataActions.openMasterDataDialog(
        dialogConstants.IMPORT_VERTRAGSNEHMER,
        newDialogData,
        "SELECT_OPTION"
      )
    );
  };

  return (
    <div style={{ padding: "50px" }}>
      <Grid container justify="center" spacing={3}>
        {standorteList &&
          standorteList.map((standort, i) => {
            return (
              <Grid
                item
                style={{ display: "flex" }}
                justify="center"
                key={i}
                lg={12}
                xs={12}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSelectStandort(standort)}
                >
                  {standort.siteName}
                </Button>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
