import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import Spinner from './Spinner';


export default function LoadingScreen(props) {
    return (
      <Backdrop>
        <Spinner loadingText={"Wird geladen..."}>
        </Spinner>
      </Backdrop>
    )
  }