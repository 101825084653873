import Content from "../../components/DashboardPages/home/Content";
import Banking from "../../components/DashboardPages/banking/Banking";
import Mitglieder from "../../components/DashboardPages/mitglieder/Mitglieder";
import Pruefungen from "../../components/DashboardPages/pruefungen/Pruefungen";
import Standorte from "../../components/DashboardPages/standorte/Standorte";
import Standortleiter from "../../components/DashboardPages/standortleiter/Standortleiter";
import Trainer from "../../components/DashboardPages/trainer/Trainer";
import Turniere from "../../components/DashboardPages/turniere/Turniere";
import Veranstaltungen from "../../components/DashboardPages/events/Events";
import Vertragsdaten from "../../components/DashboardPages/vertragsdaten/Vertragsdaten";
import Mail from "../../components/DashboardPages/mail/Mail";
import Settings from "../../components/DashboardPages/settings/Settings";
import Calendar from "../../components/DashboardPages/calendar/Calendar";
import {
  faAddressBook,
  faAward,
  // faBook,
  faCalendarAlt,
  faCogs,
  faEnvelope,
  faFileInvoiceDollar,
  // faGlobeEurope,
  faHome,
  faStar,
  faStreetView,
  faToriiGate,
  faTrophy,
  faUserCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export const dashboardSideRoutes = [
  // {
  //   path: "home",
  //   text: "Home",
  //   icon: faHome,
  //   exact: false,
  //   component: Content,
  // },
  {
    path: "",
    text: "Home",
    icon: faHome,
    exact: true,
    component: Content,
  },
  {
    path: "standorte",
    text: "Standorte",
    icon: faToriiGate,
    exact: false,
    component: Standorte,
  },
  {
    path: "mitglieder",
    text: "Mitglieder",
    icon: faUsers,
    exact: false,
    component: Mitglieder,
  },
  {
    path: "vertragsdaten",
    text: "Vertragsdaten",
    icon: faAddressBook,
    exact: false,
    component: Vertragsdaten,
  },
  {
    path: "banking",
    text: "Banking",
    icon: faFileInvoiceDollar,
    exact: false,
    component: Banking,
  },
  {
    path: "standortleiter",
    text: "Standortleiter",
    icon: faStreetView,
    exact: false,
    component: Standortleiter,
  },
  {
    path: "trainer",
    text: "Trainer",
    icon: faUserCircle,
    exact: false,
    component: Trainer,
  },
  {
    path: "pruefungen",
    text: "Prüfungen",
    icon: faAward,
    exact: false,
    component: Pruefungen,
  },
  {
    path: "turniere",
    text: "Turniere",
    icon: faTrophy,
    exact: false,
    component: Turniere,
  },
  // {
  //   path: "camps",
  //   text: "Camps",
  //   icon: faGlobeEurope,
  //   exact: false,
  //   component: Veranstaltungen,
  // },
  {
    path: "veranstaltungen",
    text: "Veranstaltungen",
    icon: faStar,
    exact: false,
    component: Veranstaltungen,
  },
  // {
  //   path: "lehrgaenge",
  //   text: "Lehrgänge",
  //   icon: faBook,
  //   exact: false,
  //   component: Veranstaltungen,
  // },
];

export const dashboardBottomRoutes = [
  {
    path: "mails",
    text: "Mail",
    icon: faEnvelope,
    exact: false,
    component: Mail,
  },
  {
    path: "calendar",
    text: "Kalender",
    icon: faCalendarAlt,
    exact: false,
    component: Calendar,
  },
  {
    path: "settings",
    text: "Einstellungen",
    icon: faCogs,
    exact: false,
    component: Settings,
  },
];

export const dropdownNavRoutes = [
  {
    path: "profile",
    text: "Profil",
    // component: Profile
  },
];
