import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function BankingStandort({ handleSelectStandorteFinished }) {
  const { standorteList } = useSelector((state) => state.masterDataReducer);
  const [standorte, setStandorte] = useState([]);

  const onSelectStandort = (standort) => {
    let nA = [...standorte];
    nA.push(standort);
    setStandorte(nA);
    // handleSelectStandorteFinished(standort);
  };

  const onRemoveStandort = (standort) => {
    let index = standorte.indexOf(standort);
    let newArray = [...standorte];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setStandorte(newArray);
  };

  const finishedSelection = () => {
    handleSelectStandorteFinished(standorte);
  };

  const selectAllSites = () => {
    let sS = standorteList.filter((s) => {
      return s.standortType !== "AFFILIATED";
    });
    setStandorte(sS);
  };

  const clearSList = () => {
    setStandorte([]);
  };

  return (
    <div style={{ padding: "50px" }}>
      <Grid container justify="center" spacing={3}>
        {standorteList &&
          standorteList.map((standort, i) => {
            if (standort.standortType === "AFFILIATED") {
              return null;
            }
            return (
              <Grid
                item
                style={{ display: "flex" }}
                justify="center"
                key={i}
                lg={12}
                xs={12}
              >
                <Button
                  variant="contained"
                  style={{ fontSize: 25, minWidth: "220px" }}
                  onClick={() => onSelectStandort(standort)}
                  disabled={standorte.includes(standort)}
                >
                  {standort.siteName}
                </Button>
                {standorte.includes(standort) && (
                  <div style={{ margin: 7 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRemoveStandort(standort)}
                    >
                      Entfernen
                    </Button>
                  </div>
                )}
              </Grid>
            );
          })}
        <Grid item md={4} xs={12}>
          <Button
            style={{ fontSize: "150%", padding: 25 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={clearSList}
            disabled={standorte.length === 0}
          >
            Auswahl aufheben
          </Button>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button
            style={{ fontSize: "150%", padding: 25 }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={selectAllSites}
            // disabled={standorte.length === 0}
          >
            Alle Standorte auswählen
          </Button>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button
            style={{ fontSize: "150%", padding: 25 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={finishedSelection}
            disabled={standorte.length === 0}
          >
            Weiter
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
