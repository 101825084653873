import React from "react";
import { CircularProgress, Dialog, DialogTitle } from "@material-ui/core";

export default function LoadingDialog() {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={true}>
      <DialogTitle id="simple-dialog-title">Bitte warten...</DialogTitle>
      <div style={{ minWidth: 350, minHeight: 250, textAlign: "center" }}>
        <div style={{ margin: 25, padding: 25 }}>
          <CircularProgress color="primary" size={150} />
        </div>
      </div>
    </Dialog>
  );
}
