import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import dialogs from "../../redux/constants/dialogConstants";
import masterDataActions from "../../redux/actions/masterDataActions";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingScreen from "../Spinner/LoadingScreen";
import masterDataApi from "../../redux/api/masterDataApi";

// import NewVertragsnehmerForm from './NewVertragsnehmerForm';

const useRemoveStyles = makeStyles((theme) => ({
  vnToDelete: {
    paddingLeft: theme.spacing(2),
  },
}));

const RemoveVertragsnehmerDialog = () => {
  const classes = useRemoveStyles();
  const [loading, toggleLoading] = React.useState(false);
  const dispatch = useDispatch();
  const visible =
    useSelector((state) => state.masterDataReducer.openDialog) ===
    dialogs.REMOVE_VERTRAGSNEHMER;
  const { dialogData } = useSelector((state) => state.masterDataReducer);
  let text;

  const handleClose = (deletedVns) => {
    let alertData = {
      title: "Löschen erfolgreich!",
      text: deletedVns ? deletedVns + " Verträge erfolgreich gelöscht!" : "",
    };
    dispatch(masterDataActions.getVertragsnehmerList());
    dispatch(masterDataActions.closeMasterDataDialog());
    dispatch(
      masterDataActions.openMasterDataDialog(dialogs.ALERT, alertData, "ALERT")
    );
  };

  const handleDelete = () => {
    toggleLoading(true);
    if (dialogData.length === 1) {
      try {
        dispatch(
          masterDataActions.removeVertragsnehmer(dialogData[0].vertragsnehmerId)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          handleClose();
          toggleLoading(false);
        }, 3000);
      }
    } else if (dialogData.length > 1) {
      var deleteManyVN = new Promise((resolve, reject) => {
        console.log("start deleting...");
        dialogData.forEach((vn, index, array) => {
          masterDataApi
            .removeSingleVertragsnehmerById(vn.vertragsnehmerId)
            .then((response) => {
              // console.log("response :>> ", response);
              if (index === array.length - 1) {
                let data = { index };
                console.log("finished deleting!");
                // console.log("data in IF :>> ", data);
                resolve(data);
              }
            });
        });
      });

      deleteManyVN.then((data) => {
        console.log(
          `Es wurden ${data.index + 1} Vertragsnehmer erfolgreich gelöscht!`
        );
        console.log("data :>> ", data);
        setTimeout(() => {
          handleClose(data.index + 1);
          toggleLoading(false);
        }, 5000);
      });
    }
  };

  if (dialogData) {
    if (dialogData.length === 1) {
      text = (
        <>
          <Typography>Sind Sie sicher, dass Sie</Typography>
          <br></br>
          <div className={classes.vnToDelete}>
            <Typography>
              {dialogData[0].title +
                " " +
                dialogData[0].lastName +
                " " +
                dialogData[0].firstName}
            </Typography>
          </div>
          <br></br>
          <Typography>entfernen möchten?</Typography>
        </>
      );
    } else if (dialogData.length > 1) {
      text = (
        <>
          <Typography>Sind Sie sicher, dass Sie</Typography>
          <br></br>
          <div className={classes.vnToDelete}>
            <Typography>
              {dialogData.map((vn, i) => (
                <li key={i}>
                  <b>{vn.title + " " + vn.lastName + " " + vn.firstName}</b>
                </li>
              ))}
            </Typography>
          </div>
          <br></br>
          <Typography>entfernen möchten?</Typography>
        </>
      );
    }
    return (
      <div>
        {loading && <LoadingScreen></LoadingScreen>}
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={visible}
          onClose={handleClose}
          // disableBackdropClick={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Vertragsnehmer entfernen
          </DialogTitle>
          <DialogContent>{text}</DialogContent>
          <DialogActions>
            <Tooltip title="Vertragsnehmer löschen">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Löschen bestätigen
              </Button>
            </Tooltip>
            <Button onClick={handleClose} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else return <div></div>;
};

export default RemoveVertragsnehmerDialog;
