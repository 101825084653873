import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { Controller } from "react-hook-form";
import SEPA from "sepa";

import deLocale from "date-fns/locale/de";
import api from "../../redux/api/masterDataApi";
import DialogSelectBelt from "../../components/DashboardPages/mitglieder/Dialogs/DialogSelectBelt";
import {
  getDateFromPayedTillString,
  getStringFromPayedTillDate,
  gradArray,
} from "../../components/Dialogs/NewMitglied/DialogPages/inputFields";
import masterDataApi from "../../redux/api/masterDataApi";
import { addDays, differenceInMonths } from "date-fns";
import { getDateFromString } from "./appUtils";
import { substituteNonSwiftLatin } from "./sepaUtils";

export const checkBirthday = (dateString) => {
  const today = new Date();
  const birthdate = new Date(dateString);
  const dayTrue = today.getDay() === birthdate.getDay();
  const monthTrue = today.getMonth() === birthdate.getMonth();
  if (dayTrue && monthTrue) {
    return true;
  }
  return false;
};

export const getStandortContributionFromId = (standortId, standorteList) => {
  let vnStandort = standorteList.find((x) => x.standortId === standortId);
  return vnStandort.memberContribution;
};

export const getStandortFromStandortId = (standortId, standorteList) => {
  let standort = standorteList.find((x) => x.standortId === standortId);
  return standort;
};

export const getContractDataFromMember = (memberId, contractList) => {
  let contract = contractList.find((x) => x.hasMembers.includes(memberId));
  return contract;
};

export const getVertragsnehmerById = (vnId, vnList) => {
  let vn = vnList.find((x) => x.vertragsinhaberId === vnId);
  return vn;
};

export const getAllMitgliederFromVnById = (vnId, mitgliederList) => {
  let membersFromVn = mitgliederList.filter((x) => x.hasVertrag === vnId);
  return membersFromVn;
};

export const checkTermination = (terminationDate, contractDuration) => {
  let termDate = new Date(terminationDate);
  let today = new Date();
  let deltaYY = today.getFullYear() - termDate.getFullYear();
  let deltaMM = today.getMonth() - termDate.getMonth();
  let collect = deltaYY * 12 + deltaMM < contractDuration;
  return collect;
};

export const checkTerminationOption2 = (endDate) => {
  let termDate = new Date(endDate);
  let today = new Date();
  let collect = termDate > today;
  return collect;
};

export const checkTerminationOption3 = (data) => {
  // Compare terminationInformation.lastMonth with current booking month: mm/yyyy in string
  // lastMonth: Last month of collecting member contribution
  // newPayedTill: Month that will be collected
  let { terminationInformation, newPayedTill } = data;

  // By default don't collect the contribution if member is terminated, only set collect to true if lastMonth >= newPayedTill
  let collect = false;

  // Compare Dates not strings
  if (
    getDateFromPayedTillString(terminationInformation.lastMonth) >=
    getDateFromPayedTillString(newPayedTill)
  ) {
    collect = true;
  }

  // return collect = true if contribution should be booked from member
  return collect;
};

export const updateMembersAfterCollection = (updateMembersArray) => {
  return Promise.all(updateMembersArray.map((m) => api.editMitglied(m)));
};

export const getActiveMembersNumberFromMembers = (membersArray) => {
  let n = 0;
  membersArray.forEach((member, i) => {
    if (member.status === "active") {
      n += 1;
    }
  });
  return n;
};

export const getBankingInformationWithFee = (
  contractData,
  members,
  bookMonth,
  reset,
  fee,
  onlyFee
) => {
  let bInfo;
  let collect = true;
  let newPayedTill = getStringFromPayedTillDate(bookMonth);
  // console.log("bookMonth :>> ", bookMonth);
  let memberNames = [],
    memberNumbers = [],
    updateMembers = [],
    contributionSum = 0,
    contractDate;
  const { owner, iban, bic } = contractData;
  members.forEach((member, i) => {
    if (!onlyFee) {
      if (member.status === "frozen") {
        return;
      }
      if (member.status === "terminated") {
        let collectBOOL = false;
        if (member.terminationDate && member.terminationOption === 1) {
          collectBOOL = checkTermination(
            member.terminationDate,
            member.contractDuration
          );
        }
        if (member.terminationInformation && member.terminationOption === 2) {
          collectBOOL = checkTerminationOption2(
            member.terminationInformation.newEndDate
          );
          if (member.terminationInformation.stopCollect) {
            collectBOOL = false;
          }
        }

        if (member.terminationInformation && member.terminationOption === 3) {
          collectBOOL = checkTerminationOption3({
            terminationInformation: member.terminationInformation,
            newPayedTill: newPayedTill,
          });
          if (member.terminationInformation.stopCollect) {
            collectBOOL = false;
          }
        }

        if (!collectBOOL) {
          return;
        }
      }
      if (i === 0) {
        memberNames.push(member.lastName);
      }
      memberNames.push(" " + member.firstName);
      memberNumbers.push(
        i > 0 ? " " + member.memberNumber : member.memberNumber
      );

      // Use this if you want to reset the collection to the selected bookMonth:
      if (reset) {
        updateMembers.push({ ...member, payedTill: newPayedTill });
      }

      // Check if new Member and registration Fee needs to be contributed
      if ("payRegistrationFee" in member) {
        if (member.payRegistrationFee === true) {
          contributionSum = contributionSum + parseInt(member.registrationFee);
        }
      }

      if ("payedTill" in member) {
        const { payedTill } = member;
        if (typeof payedTill === "string") {
          let pT = getDateFromPayedTillString(payedTill);
          let mDiff = differenceInMonths(bookMonth, pT);

          // mDiff === 1: Collect 1 contribution
          if (mDiff === 1) {
            contributionSum = contributionSum + parseInt(member.contribution);
            if (member.status !== "terminated") {
              contributionSum += parseInt(fee);
            }
            // updateMembers.push({ ...member, payedTill: newPayedTill });
          }

          // mDiff > 1: Collect mDiff * contribution, update payedTill to current bookMonth
          if (mDiff > 1) {
            contributionSum =
              contributionSum + parseInt(member.contribution) * mDiff;
            if (mDiff === 2) {
              memberNames.push(" " + String(mDiff - 1) + " Monat rueckwirkend");
            } else {
              memberNames.push(
                " " + String(mDiff - 1) + " Monate rueckwirkend"
              );
            }
            contributionSum += parseInt(fee);
            // updateMembers.push({ ...member, payedTill: newPayedTill });
          }

          // mDiff < 1: Don't collect contribution, no need for update, next booking mDiff will automatically be one more
          if (mDiff < 1) {
            console.log("-----------Member payed already---------------");
            console.log("member :>> ", member.firstName, member.lastName);
            console.log("payedTill :>> ", payedTill);
            console.log("----------------------------------------------");
          }
        }
      } else {
        contributionSum =
          contributionSum + parseInt(member.contribution) + parseInt(fee);
      }
    } else {
      if (member.status === "active") {
        if (i === 0) {
          memberNames.push(member.lastName);
        }
        memberNames.push(" " + member.firstName);
        memberNumbers.push(
          i > 0 ? " " + member.memberNumber : member.memberNumber
        );
        contributionSum = contributionSum + parseInt(fee);
      } else return;
    }

    contractDate = member.contractDate
      ? member.contractDate.substring(0, 10)
      : false;

    updateMembers.push({
      ...member,
      payedTill: newPayedTill,
      payRegistrationFee: false,
    });
  });

  if (reset) {
    return [null, updateMembers];
  }

  if (contributionSum === 0 && collect === true) {
    return [null, updateMembers];
  }

  var rInfo = "TWIN Taekwondo ";

  if (fee > 0 && !onlyFee) {
    rInfo =
      rInfo +
      "Jahresmarke und Mitgliedsbeitrag " +
      newPayedTill +
      " " +
      memberNames.toString(); // Verwendungszweck
  } else if (fee > 0 && onlyFee) {
    rInfo = rInfo + "Jahresmarke " + memberNames.toString(); // Verwendungszweck
  } else {
    rInfo =
      rInfo + "Mitgliedsbeitrag " + newPayedTill + " " + memberNames.toString(); // Verwendungszweck
  }

  bInfo = {
    debtorName:
      owner.length > 2
        ? owner
        : members[0].lastName + " " + members[0].lastName, // Kontoinhaber
    iban: iban, // IBAN
    bic: bic, // BIC
    collect: collect,
    mandateId: memberNumbers.toString(), // Mandatsreferenz
    mandateSignatureDate: contractDate, // Mandatsdatum
    amount: parseFloat(contributionSum), // Betrag, Summe der Beiträge
    remittanceInfo: rInfo, // Verwendungszweck
  };

  if (contributionSum === 0 && collect === false) {
    return [null, updateMembers];
  }

  return [bInfo, updateMembers];
};

export const createXMLSepaData = (data, sepaData) => {
  let finalData;
  var sepaValidationError = false;
  let colDate = getDateFromString(data.collectionDate);
  colDate = addDays(colDate, 1);

  var doc = new SEPA.Document("pain.008.001.02");
  // doc.grpHdr.id = "XMPL.20140201.TR0";
  // https://www.hettwer-beratung.de/sepa-spezialwissen/sepa-technische-anforderungen/xml-sepa-zeilen-inhaltlicher-aufbau/
  // Siehe MsgID SEPA Message Identification A35
  // doc.grpHdr.id = "MSG76d6b5578b2d58fdded2be9ff2fc4afe";
  let grpHdrID = data.creditorBIC + "2600" + String(new Date().getTime());
  doc.grpHdr.id = grpHdrID;
  doc.grpHdr.created = new Date();

  // Name of the creditor
  doc.grpHdr.initiatorName = data.creditorName;

  var info = doc.createPaymentInfo();

  // Collection Date here:
  info.collectionDate = colDate;

  // Creditor IBAN here:
  info.creditorIBAN = data.creditorIBAN.replaceAll(" ", "");

  // Creditor BIC here:
  info.creditorBIC = data.creditorBIC;

  // Creditor Name here:
  info.creditorName = data.creditorName;

  // Creditor ID provided by Central banking institute here:
  info.creditorId = data.creditorId;

  // Type of Payment Sequence: (default RCUR)
  info.sequenceType = "RCUR";

  // info.batchBooking = true; //optional
  doc.addPaymentInfo(info);

  sepaData.forEach(function (debtor, index) {
    var tx = info.createTransaction();
    // Debtor name is created in masterDataUtils.js, if no Owner is setted, the Membername will be used
    tx.debtorName = substituteNonSwiftLatin(debtor.debtorName);

    // Check if IBAN has spaces in it remove them:
    // eg.: DE25 7205 0101 0030 8547 56 => DE25720501010030854756
    if (debtor.iban.length > 22) {
      debtor.iban = debtor.iban.replaceAll(" ", "");
      debtor.iban = debtor.iban.replaceAll("\t", "");
      // console.log("debtor.iban :>> ", debtor.iban);
    }

    // Check if IBAN is correct
    if (SEPA.validateIBAN(debtor.iban)) {
      // Add IBAN
      tx.debtorIBAN = debtor.iban;
    } else {
      // IBAN is not correct
      alert(
        `Ungültige IBAN erkannt! IBAN: ${debtor.iban} Inhaber: ${debtor.debtorName}`
      );
      sepaValidationError = true;
      return;
    }

    // Möglichkeit den BIC zu übergeben:
    // tx.debtorBIC = debtor.bic;

    // Mandatsreferenz = Mitgliedsnummern
    tx.mandateId = substituteNonSwiftLatin(debtor.mandateId);

    // Mandatsignaturdatum für Sepa
    let pDate = debtor.mandateSignatureDate;
    let dd = pDate.split("-")[0].padStart(2, "0");
    let mm = pDate.split("-")[1].padStart(2, "0");
    let yyyy = pDate.split("-")[2].split(" ")[0];
    tx.mandateSignatureDate = new Date(yyyy, mm - 1, dd);

    if (debtor.amount === 0 || !debtor.amount) {
      alert(`BETRAG LEER! Inhaber: ${debtor.debtorName}`);
      return;
    } else {
      tx.amount = parseFloat(debtor.amount);
    }

    // Grund der Buchung:
    if (debtor.remittanceInfo.length === 0) {
      let alertString = `Verwendungszweck darf nicht leer sein!\n ${debtor.owner}`;
      alert(alertString);
      console.log("debtor :>> ", debtor);
    }
    tx.remittanceInfo = substituteNonSwiftLatin(debtor.remittanceInfo);

    tx.end2endId = "NOTPROVIDED";
    info.addTransaction(tx);
  });

  if (sepaValidationError) {
    return;
  }

  finalData = doc.toString();
  // Remove <InstrID> from XML-File (Will submit an Error when trying to upload to Kreissparkasse)
  finalData = finalData.replace(/<InstrId.*?<\/InstrId>/g, "");
  // finalData = finalData.replace('encoding="null"', 'encoding="UTF-8"');
  // Somehow in Chrome the encoding is null by default
  return finalData;
};

export const getMitgliederListFromMitgliedIds = (
  arrayWithIds,
  mitgliederList
) => {
  let memberArray = [];
  arrayWithIds.forEach((memberId) => {
    memberArray.push(
      ...mitgliederList.filter((x) => x.mitgliedId === memberId)
    );
  });
  return memberArray;
};

function filterStandortIds(item, memberId, standorteIdArray) {
  // console.log("memberId :>> ", memberId);
  if (
    item["mitgliedId"] === memberId &&
    standorteIdArray.includes(item.standortRef)
  ) {
    // let isIn = standorteIdArray.includes(item.standortRef);
    // console.log("standorteIdArray :>> ", standorteIdArray);
    // console.log("item.standortRef :>> ", item.standortRef);
    return true;
  } else {
    return false;
  }
}

export const getMitgliederListFromIdsCompareStandorte = (
  arrayWithIds,
  mitgliederList,
  standorteIdArray
) => {
  let memberArray = [];
  // arrayWithIds.forEach((memberId) => {
  //   memberArray.push(
  //     ...mitgliederList.filter(
  //       (x) =>
  //         x.mitgliedId === memberId && standorteIdArray.includes(x.standortRef)
  //     )
  //   );
  // });
  arrayWithIds.forEach((memberId) => {
    memberArray.push(
      ...mitgliederList.filter((mitglied) =>
        filterStandortIds(mitglied, memberId, standorteIdArray)
      )
    );
  });
  return memberArray;
};

export const showBelts = (rowData) => {
  let icon;
  icon = <DialogSelectBelt rowData={rowData}></DialogSelectBelt>;
  return icon;
};

export const showDegree = (rowData) => {
  let text = "ZUWEISEN";
  let icon;
  if (parseInt(rowData.degree) > 0) {
    text = gradArray[rowData.degree - 1].label
      ? gradArray[rowData.degree - 1].label
      : 1;
  }
  icon = <p>{text}</p>;
  return icon;
};

export const filterFirstLetter = (list, letter) => {
  let newList;
  newList = list.filter(
    (item) =>
      item.lastName[0].toLocaleLowerCase() === letter.toLocaleLowerCase()
  );
  return newList;
};

export const uploadStandortImage = (image, standortId) => {
  let form_data = new FormData();
  form_data.append("image", image);
  console.log("form_data :>> ", form_data);
  return new Promise((resolve) => {
    masterDataApi
      .uploadStandortImage(form_data, standortId)
      .then((response) => {
        console.log("response.data :>> ", response.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const uploadStandortleiterImage = (image, id) => {
  let form_data = new FormData();
  form_data.append("image", image);
  console.log("form_data :>> ", form_data);
  return new Promise((resolve) => {
    masterDataApi
      .uploadStandortleiterImage(form_data, id)
      .then((response) => {
        console.log("response.data :>> ", response.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const uploadEventImage = (image, eventId) => {
  let form_data = new FormData();
  form_data.append("image", image);
  console.log("form_data :>> ", form_data);
  return new Promise((resolve) => {
    masterDataApi
      .uploadEventImage(form_data, eventId)
      .then((response) => {
        console.log("response.data :>> ", response.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const renderStandortInputFields = (
  inputFieldArray,
  register,
  control
) => {
  return inputFieldArray.map((item, i) => {
    let itemToRender;
    switch (item.type) {
      case "text":
        itemToRender = (
          <TextField
            style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
            variant="outlined"
            margin="normal"
            disabled={item.disabled}
            required={item.required}
            // disabled={item.disabled ? true : false}
            inputRef={register({ required: item.required })}
            label={item.label}
            name={item.value}
            error={item.required ? item.error : false}
            helperText={
              item.showHelperText
                ? item.helperText
                : item.error && item.errorMsg
            }
          />
        );
        break;
      case "select":
        itemToRender = (
          <FormControl
            variant="outlined"
            style={{
              marginTop: 15,
              width: 250,
            }}
            error={item.error}
          >
            <InputLabel htmlFor={`${item.value}-select`}>
              {item.required ? item.label + "*" : item.label}
            </InputLabel>
            <Controller
              control={control}
              name={item.value}
              rules={{ required: item.required }}
              as={
                <Select
                  label={item.required ? item.label + "*" : item.label}
                  labelId={`${item.value}-select`}
                  id={`${item.value}-select`}
                >
                  {item.selectList.map((select) => (
                    <MenuItem key={select.id} value={select.value}>
                      {select.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            {item.error && <FormHelperText>{item.errorMsg}</FormHelperText>}
          </FormControl>
        );
        break;

      case "date":
        itemToRender = (
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <Controller
                name={item.value}
                control={control}
                rules={{ required: item.required }}
                render={({ ref, ...rest }) => (
                  <KeyboardDatePicker
                    margin="normal"
                    error={item.required ? item.error : false}
                    helperText={
                      item.required ? item.error && item.errorMsg : false
                    }
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label={item.required ? item.label + "*" : item.label}
                    format="dd.MM.yyyy"
                    inputRef={ref}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    {...rest}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        );
        break;
      default:
        itemToRender = <span>Error</span>;
        break;
    }

    return itemToRender;
  });
};

export const renderStandortLeiterInputFields = (
  inputFieldArray,
  register,
  control
) => {
  return inputFieldArray.map((item, i) => {
    let itemToRender;
    switch (item.type) {
      case "text":
        itemToRender = (
          <TextField
            // style={{ width: item.fieldWidth ? item.fieldWidth * 40 : 350 }}
            variant="outlined"
            margin="normal"
            disabled={item.disabled}
            required={item.required}
            // disabled={item.disabled ? true : false}
            inputRef={register({ required: item.required })}
            label={item.label}
            name={item.value}
            error={item.required ? item.error : false}
            helperText={
              item.showHelperText
                ? item.helperText
                : item.error && item.errorMsg
            }
          />
        );
        break;
      case "select":
        itemToRender = (
          <FormControl
            variant="outlined"
            style={{
              width: 250,
              marginTop: item.value !== "title" ? 15 : 0,
            }}
            error={item.error}
          >
            <InputLabel htmlFor={`${item.value}-select`}>
              {item.required ? item.label + "*" : item.label}
            </InputLabel>
            <Controller
              control={control}
              name={item.value}
              rules={{ required: item.required }}
              as={
                <Select
                  label={item.required ? item.label + "*" : item.label}
                  labelId={`${item.value}-select`}
                  id={`${item.value}-select`}
                >
                  {item.selectList.map((select) => (
                    <MenuItem key={select.id} value={select.value}>
                      {select.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            {item.error && <FormHelperText>{item.errorMsg}</FormHelperText>}
          </FormControl>
        );
        break;

      case "date":
        itemToRender = (
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <Controller
                name={item.value}
                control={control}
                rules={{ required: item.required }}
                render={({ ref, ...rest }) => (
                  <KeyboardDatePicker
                    margin="normal"
                    error={item.required ? item.error : false}
                    helperText={
                      item.required ? item.error && item.errorMsg : false
                    }
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label={item.required ? item.label + "*" : item.label}
                    format="dd.MM.yyyy"
                    inputRef={ref}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    {...rest}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        );
        break;
      default:
        itemToRender = <span>Error</span>;
        break;
    }

    itemToRender = (
      <Grid item lg={item.fieldWidth ? item.fieldWidth : 4} md={4} xs={12}>
        {itemToRender}
      </Grid>
    );
    if (item.value === "title") {
      itemToRender = (
        <Grid container xs={12} lg={12}>
          {itemToRender}
        </Grid>
      );
    }
    return itemToRender;
  });
};

export const getStandortLookup = (standorteList) => {
  let standortLookup = {};
  standorteList.forEach((site) => {
    standortLookup = {
      ...standortLookup,
      [site.standortId]: site.siteName,
    };
  });
};

export const getYYYYMMDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const createMobileNumbersString = (contacts) => {
  var numbers = "";
  contacts.forEach((contact) => {
    numbers +=
      contact.lastName + ";" + contact.firstName + ";" + contact.mobil + ";\n";
  });
  return numbers;
};

export const createMailListString = (array) => {
  var mailAdresses = "";
  array.forEach((mail) => {
    mailAdresses += mail + ";\n";
  });
  return mailAdresses;
};

export const createVCFfromArray = (contacts) => {
  var vcard = "";
  contacts.forEach((contact) => {
    vcard += "BEGIN:VCARD\n";
    vcard += "VERSION:2.1\n";
    vcard +=
      "N:" + contact.lastName + ";" + contact.id + contact.firstName + ";;;\n";
    vcard += "FN:" + contact.id + contact.firstName + contact.lastName + "\n";
    vcard += "TEL;CELL:" + contact.mobil + "\n";
    vcard += "TEL;CELL:" + contact.mobil + "\n";
    vcard += "EMAIL;HOME:" + contact.mail + "\n";
    vcard += "ORG:Taekwondo\n";
    vcard += "BDAY:" + contact.birthdate + "\n";
    vcard += "END:VCARD\n";
  });
  return vcard;
};

// BEGIN:VCARD
// VERSION:2.1
// N:Drollmann;Lea;;;
// FN:Lea Drollmann
// TEL;CELL:015789028522
// TEL;CELL:015789028522
// EMAIL;HOME:dr_lea@web.de
// ORG:Taekwondo
// BDAY:1998-06-10
// END:VCARD

// BEGIN:VCARD
// VERSION:3.0
// N:Klein;Alexander;;Herr;
// FN:Herr Alexander Klein
// EMAIL;TYPE=INTERNET;TYPE=HOME:sissiundalex@web.de
// ORG:PferseeOrga
// CATEGORIES:Pfersee
// END:VCARD

// CSV: [     0   ,      1    ,      2     ,     3    ]
//         ORG: TTK    Name        Vorname     Nummer

// function createVCF(daten) {
//   var vcards, vcard;
//   daten.forEach(element => {
//       vcard += "BEGIN:VCARD\n";
//       vcard += "VERSION:2.1\n"
//       vcard += "N:"+"Z0_"+element[1]+";"+"Z0_"+element[2]+";;;\n";
//       vcard += "FN:"+"Z0_"+element[2]+" "+"Z0_"+element[1]+"\n";
//       vcard += "TEL;CELL:"+element[3]+"\n";
//       vcard += "TEL;CELL:"+element[3]+"\n";
//       vcard += "ORG:"+element[0]+"\n";
//       vcard += "END:VCARD\n";
//   });
//   fs.writeFile(OUTPUT_FILE_NAME, vcard, "utf8", function (err) {
//       if (err) throw err;
//       console.log(vcard);

//       console.log('Vcards.vcf saved!');
//   });
// }

// BEGIN:VCARD
// VERSION:2.1
// N:Drollmann;Lea;;;
// FN:Lea Drollmann
// TEL;CELL:015789028522
// TEL;CELL:015789028522
// ORG:Taekwondo
// END:VCARD

// BEGIN:VCARD
// VERSION:2.1
// N:Drollmann;Lea;;;
// FN:Lea Drollmann
// TEL;CELL:015789028522
// TEL;CELL:015789028522
// EMAIL;HOME:dr_lea@web.de
// ORG:Taekwondo
// BDAY:1998-06-10
// END:VCARD
